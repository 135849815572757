import usePolling from '@/hooks/usePolling';
import { useLazyGetLatestTelemetryUpdateQuery } from '@/redux/api/fleet/telemetryApiSlice';
import { Polling, sendPollingUpdate } from '@/redux/slices/pollingSlice';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { PollProps } from './typings';
import { Operation, STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { OPERATION_STATUS } from '@/shared/constants';
import { removePendingCommand } from '@/redux/slices/deviceSlice';

export default function PollDeviceTelemetryUpdate({ polling, id }: PollProps) {
  const { lastArgs } = polling as Omit<Polling, 'lastArgs'> & { lastArgs?: { id: string; latestUpdate: string } };
  const { t } = useTranslation();

  const previousLastUpdate = lastArgs?.latestUpdate;

  const { result, isPolling, stop, remainingAttempts } = usePolling({
    useQuery: useLazyGetLatestTelemetryUpdateQuery,
    interval: polling?.interval,
    maxAttempts: polling.maxAttempts,
    pollingState: { ...polling, lastArgs: lastArgs?.id },
  });

  const partialOperation = useMemo(
    () =>
      ({
        entity: lastArgs?.id!,
        location: ' ',
        operationId: id,
        read: false,
        showed: false,
        subject: lastArgs?.id || (t('unknownEntityXXX', { entity: t('device') }) as string),
        timestamp: dayjs().valueOf(),
        tag: TAGS.DEVICE_TELEMETRY_UPDATE,
      } satisfies Partial<Operation>),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, lastArgs]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendPollingUpdate({ endpoint: 'getLatestTelemetryUpdate', result, isPolling, id, remainingAttempts }));
    if (!isPolling && result.error) {
      dispatch(
        upsertOperation({
          ...partialOperation,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.ERROR,
          uniqueId: `${id}-${STATUS.ERROR}`,
          error: result.error,
        })
      );
      dispatch(removePendingCommand({ serialNumber: lastArgs?.id as string, command: 'telemetry.get' }));
      return;
    }

    if (
      polling.remainingAttempts &&
      polling.remainingAttempts > 0 &&
      isPolling &&
      result.data?.data &&
      result.data?.data?.lastUpdate !== previousLastUpdate
    ) {
      dispatch(
        upsertOperation({
          ...partialOperation,
          state: OPERATION_STATUS.FULFILLED,
          status: STATUS.SUCCESS,
          uniqueId: `${id}-${STATUS.SUCCESS}`,
        })
      );
      dispatch(removePendingCommand({ serialNumber: lastArgs?.id as string, command: 'telemetry.get' }));
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, isPolling, dispatch, stop]);

  return <></>;
}
