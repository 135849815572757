import { useEffect, useRef } from 'react';
import { NotificationVirtualizationProps } from '../types';
import { Operation } from '@/redux/slices/operationSlice';

type Props = Pick<NotificationVirtualizationProps, 'setNotificationHeight'> & { id: Operation['uniqueId'] };

export const useNotificationHeight = ({ id, setNotificationHeight }: Props) => {
  const notificationRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (notificationRef?.current) {
      setNotificationHeight(id, notificationRef.current.offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationRef.current]);

  return notificationRef;
};
