import { Box, lighten, useTheme } from '@mui/material';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { VictoryContainer, VictoryPie } from 'victory';
import CustomLabel from './CustomLabel';
import useSize from '@/hooks/useSize';
import AnimatedSlice from './AnimatedSlice';
import AreaContainer, { AreaBody } from '../Card/Area';

const calculateOrigin = (containerRef?: DOMRect) => {
  const x = (containerRef?.width || 0) / 2;
  const y = (containerRef?.height || 0) / 2;
  return { x, y };
};

const normalizeData = (data: { x: string; y: number }[]) => {
  const tot = data.reduce((acc, { y }) => acc + y, 0);
  const minimumPercentage = (tot * 10) / 100;

  return data.map(({ x, y }) => {
    const percentage = (y * 100) / tot;
    if (percentage < 1) {
      return { x, y: minimumPercentage };
    }

    return { x, y };
  });
};

export default function PieChart({ data }: { data: any[] }) {
  const theme = useTheme();
  const colors = useMemo(() => {
    return data.map((_, i, flavors) => lighten(theme.palette.primary.dark, i * (1 / flavors.length)));
  }, [data, theme.palette.primary.dark]);

  const chartContainerRef = useRef<HTMLBaseElement>(null);
  const size = useSize(chartContainerRef.current);
  const [origin, setOrigin] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useLayoutEffect(() => {
    if (chartContainerRef.current) {
      const containerRect = chartContainerRef.current.getBoundingClientRect();
      setOrigin(calculateOrigin(containerRect));
    }
  }, [size]);

  const radius = Math.min(size.width, size.height) / 2.5;
  const innerRadius = radius / 2.5;

  return (
    <AreaContainer className="relative" sx={{ width: '100%', height: '100%' }}>
      <AreaBody sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ width: '100%', height: '100%' }} ref={chartContainerRef}>
          {size.width > 0 && size.height > 0 && (
            <VictoryContainer width={size.width} height={size.height}>
              <VictoryPie
                origin={origin}
                colorScale={colors}
                style={{
                  labels: {
                    fill: theme.palette.text.primary,
                  },
                }}
                radius={radius}
                innerRadius={innerRadius}
                cornerRadius={5}
                padAngle={3}
                data={normalizeData(data)}
                labelComponent={
                  <CustomLabel
                    colors={colors}
                    origin={origin}
                    radius={radius}
                    innerRadius={innerRadius}
                    dy={20}
                    rawData={data}
                  />
                }
                dataComponent={<AnimatedSlice />}
                standalone={false}
              />
            </VictoryContainer>
          )}
        </Box>
      </AreaBody>
    </AreaContainer>
  );
}
