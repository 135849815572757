import { Operation } from '@/redux/slices/operationSlice';
import BaseOperationTypography from '../../../Operation/BaseOperationTypography';

export default function TelemetryUpdateMessage({
  opening,
  entity,
  status,
  read,
}: {
  opening: string;
  entity: string;
  status: Operation['status'];
  read: Operation['read'];
}) {
  return (
    <BaseOperationTypography read={read} status={status}>
      <BaseOperationTypography read={read} status={status}>
        {opening}{' '}
      </BaseOperationTypography>
      <BaseOperationTypography read={read} status={status} fontWeight={600}>
        {entity}
      </BaseOperationTypography>
    </BaseOperationTypography>
  );
}
