import { useTranslation } from 'react-i18next';
import { Device } from '../components/Fleet/Devices/DevicesPanel/typings';
import { useGetDevicesQuery } from '../redux/api/fleet/devicesApiSlice';
import { useFilters } from '../components/Shared/Filters/LocalFilters';
import { autocompleteFilterDefaultAssert } from '../components/Shared/Filters/AutocompleteFilter';
import { LocalFilterConfig } from '@/components/Shared/Filters/typings';
import { useDevicesOptions } from './useDevicesOptions';

type UseDevices = () => {
  remoteFetchState: { isLoading: boolean; isError: boolean };
  filterConfigs: LocalFilterConfig<Device>[];
  applyFilters: Function;
  filteredData: Device[];
};

const useDevices: UseDevices = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isError } = useGetDevicesQuery();

  const { applyFilters, filteredData } = useFilters<Device>(data?.data?.items || []);

  const devicesOptions = useDevicesOptions(data?.data?.items as Device[]);
  /* Not needed at the moment.
   const locations = useMemo(
     () => (data?.data?.items ? [...new Set((data.data.items as Device[]).map((d) => d.region?.code))] : []),
     [data]
   );
  */
  const filters: LocalFilterConfig<Device>[] = [
    {
      kind: 'autocomplete',
      id: 'devicesAutocompleteDeviceType',
      label: t('deviceName'),
      options: devicesOptions.map((dt) => ({
        label: dt?.label || '',
        optionId: dt.value,
        filterFx: (device) => autocompleteFilterDefaultAssert(device.model, dt.value),
      })),
    },
    /* Until further notice we are hiding this section.

     {
       kind: 'autocomplete',
       id: 'devicesAutocompleteLocationArea',
       label: t('locationArea'),
       options: locations.map((l) => ({
         label: l,
         optionId: l,
         filterFx: (device) => autocompleteFilterDefaultAssert(device.region?.code, l),
       })),
     },
    */

    {
      kind: 'autocomplete',
      id: 'devicesRadioConnected',
      label: t('connectionStatus'),
      options: [
        {
          label: t('all'),
          optionId: 'all',
          filterFx: (_device) => true,
        },
        {
          label: t('connected'),
          optionId: 'connected',
          filterFx: (device) => autocompleteFilterDefaultAssert(device.status?.connection?.online, true),
        },
        {
          label: t('disconnected'),
          optionId: 'notConnected',
          filterFx: (device) => autocompleteFilterDefaultAssert(device.status?.connection?.online, false),
        },
      ],
    },
  ];

  return {
    remoteFetchState: { isLoading: isLoading || isFetching, isError },
    filterConfigs: filters,
    applyFilters,
    filteredData,
  };
};

export default useDevices;
