import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../store';
import dayjs from 'dayjs';

type Args =
  | {
      [key in string]: string | number | boolean;
    }
  | string
  | number
  | boolean
  | undefined;

type PollingOptions = {
  interval: number;
  maxAttempts: number;
};

export type Polling = Partial<
  {
    pollingRequestedAt: number;
    result: any;
    isPolling: boolean;
    lastArgs: Args;
    remainingAttempts: number;
  } & PollingOptions
>;

export const pollingInitialState = {
  polls: {
    getLatestTelemetryUpdate: {} as { [key: string]: Polling },
    postOTAJob: {} as { [key: string]: Polling },
    postOperatingModeCommand: {} as { [key: string]: Polling },
    postPowerProfileCommand: {} as { [key: string]: Polling },
    postRebootCommand: {} as { [key: string]: Polling },
    postLogLevel: {} as { [key: string]: Polling },
  },
};

const pollingSlice = createSlice({
  name: 'pollings',
  initialState: pollingInitialState,
  reducers: {
    start: (
      state,
      {
        payload: { id, args, endpoint, ...options },
      }: {
        payload: Partial<PollingOptions> & { endpoint: keyof typeof pollingInitialState.polls; args: Args; id: string };
      }
    ) => {
      state.polls[endpoint] = {
        ...state.polls[endpoint],
        [id]: {
          ...options,
          pollingRequestedAt: dayjs().valueOf(),
          lastArgs: args,
        },
      };
    },
    sendUpdate: (
      state,
      {
        payload: { endpoint, isPolling, result, id, remainingAttempts },
      }: {
        payload: {
          endpoint: keyof typeof pollingInitialState.polls;
          isPolling: boolean;
          result: any;
          id: string;
          remainingAttempts: number;
        };
      }
    ) => {
      state.polls[endpoint] = {
        ...state.polls[endpoint],
        [id]: {
          ...state.polls[endpoint][id],
          isPolling,
          result,
          remainingAttempts,
        },
      };
    },
    stop: (
      state,
      { payload: { endpoint, id } }: { payload: { endpoint: keyof typeof pollingInitialState.polls; id: string } }
    ) => {
      state.polls[endpoint][id].pollingRequestedAt = undefined;
    },
    remove: (
      state,
      { payload: { endpoint, id } }: { payload: { endpoint: keyof typeof pollingInitialState.polls; id: string } }
    ) => {
      delete state.polls[endpoint][id];
    },
  },
});

export const selectPollings = (state: AppState) => state.pollings.polls;

export const selectPollingById = createSelector(
  [
    selectPollings,
    (state: AppState, id: string) => id,
    (state: AppState, id: string, poll: keyof typeof pollingInitialState.polls) => poll,
  ],
  (polls: AppState['pollings']['polls'], id: string, poll: keyof typeof pollingInitialState.polls) => polls[poll]?.[id]
);

export const {
  start: startPolling,
  stop: stopPolling,
  sendUpdate: sendPollingUpdate,
  remove: removePolling,
} = pollingSlice.actions;

export default pollingSlice.reducer;
