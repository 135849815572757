import React, { useCallback, useMemo } from 'react';
import { Operations } from '../../../Operation/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { STATUS, Tag, TAGS } from '@/redux/slices/operationSlice';
import useDefaultErrorCallback from '../../hooks/useDefaultErrorCallback';
import BaseNotification, { StatusNotification } from '../../BaseNotification';
import BaseOperationAvatar from '../../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../../Operation/BaseOperationIcon';
import BaseOperationIconButton from '../../../Operation/BaseOperationIconButton';
import DefaultNotificationRightAction from '../../DefaultNotificationRightAction';
import OneToolMessage from '../OneTool/Message';
import { getDefaultStatusIcon } from '../utils';

import OpacityIcon from '@mui/icons-material/Opacity';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import { NotificationConfig, NotificationConfigSlice, NotificationVirtualizationProps } from '../../types';
import { useNotificationHeight } from '../../hooks/useNotificationHeight';

type ConsumableTag = Extract<
  Tag,
  typeof TAGS.DEVICE_CONSUMABLE_SET | typeof TAGS.DEVICE_CONSUMABLE_DEPLETE | typeof TAGS.DEVICE_CONSUMABLE_REMOVE
>;
type ConsumableNotificationProps = Operations.Props & { tag: ConsumableTag } & NotificationVirtualizationProps;

const notificationConfig: NotificationConfig<ConsumableTag> = {
  'device-consumable-deplete': {
    icon: OpacityIcon,
    translations: {
      error: 'deviceConsumableDepleteError',
      pending: 'deviceConsumableDepletePending',
      success: 'deviceConsumableDepleteSuccess',
    },
  },
  'device-consumable-remove': {
    icon: InvertColorsOffIcon,
    translations: {
      error: 'deviceConsumableRemoveError',
      pending: 'deviceConsumableRemovePending',
      success: 'deviceConsumableRemoveSuccess',
    },
  },
  'device-consumable-set': {
    icon: OpacityIcon,
    translations: {
      error: 'deviceConsumableSetError',
      pending: 'deviceConsumableSetPending',
      success: 'deviceConsumableSetSuccess',
    },
  },
};
const MemoizedAvatar = React.memo(
  ({ config, status }: { config: NotificationConfigSlice; status: Operations.Props['status'] }) => (
    <BaseOperationAvatar
      status={status}
      tagIcon={<BaseOperationIcon Icon={config.icon} />}
      statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(status)} />}
    />
  )
);

const MemoizedMainHoverButton = React.memo(
  ({
    read,
    status,
    Icon,
  }: {
    read: Operations.Props['read'];
    status: Operations.Props['status'];
    uniqueId: Operations.Props['uniqueId'];
    Icon: Operations.Icon;
  }) => <BaseOperationIconButton read={read} status={status} icon={<BaseOperationIcon Icon={Icon} color="inherit" />} />
);

const MemoizedRightActionHoverButton = React.memo(
  ({
    read,
    status,
    uniqueId,
  }: {
    read: Operations.Props['read'];
    status: Operations.Props['status'];
    uniqueId: Operations.Props['uniqueId'];
  }) => <DefaultNotificationRightAction read={read} status={status} uniqueId={uniqueId} />
);

function Consumable(props: ConsumableNotificationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotificationHeight, entity, uniqueId, tag, status, read, subject } = props;
  const notificationRef = useNotificationHeight({
    id: uniqueId,
    setNotificationHeight: setNotificationHeight,
  });
  const defaultErrorCallback = useDefaultErrorCallback({ uniqueId: uniqueId });
  const path = `/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${entity}#consumables`;
  const config = useMemo(() => notificationConfig[tag], [tag]);

  const handleErrorClick = useCallback(() => defaultErrorCallback(), [defaultErrorCallback]);
  const handleInfoClick = useCallback(() => navigate(path), [navigate, path]);
  const handleSuccessClick = useCallback(
    () => navigate(path, { state: { searchText: subject } }),
    [navigate, path, subject]
  );

  const states = useMemo(
    () => ({
      [STATUS.ERROR]: {
        label: t('whatHappened?'),
        message: (
          <OneToolMessage
            read={read}
            status={status}
            opening={t(config.translations.error)}
            subject={subject}
            entity={t(entity)}
          />
        ),
        onClick: handleErrorClick,
        hoverIconButtons: {
          main: <MemoizedMainHoverButton read={read} status={status} uniqueId={uniqueId} Icon={VisibilityIcon} />,
          rightAction: <MemoizedRightActionHoverButton read={read} status={status} uniqueId={uniqueId} />,
        },
      },
      [STATUS.INFO]: {
        message: (
          <OneToolMessage
            read={read}
            status={status}
            opening={t(config.translations.pending)}
            subject={subject}
            entity={t(entity)}
          />
        ),
        onClick: handleInfoClick,
        hoverIconButtons: {
          main: <MemoizedMainHoverButton read={read} status={status} uniqueId={uniqueId} Icon={VisibilityIcon} />,
          rightAction: <MemoizedRightActionHoverButton read={read} status={status} uniqueId={uniqueId} />,
        },
      },
      [STATUS.SUCCESS]: {
        message: (
          <OneToolMessage
            read={read}
            status={status}
            opening={t(config.translations.success)}
            entity={t(entity)}
            subject={subject}
          />
        ),
        onClick: handleSuccessClick,
        hoverIconButtons: {
          main: <MemoizedMainHoverButton read={read} status={status} uniqueId={uniqueId} Icon={VisibilityIcon} />,
          rightAction: <MemoizedRightActionHoverButton read={read} status={status} uniqueId={uniqueId} />,
        },
      },
    }),
    [
      t,
      read,
      status,
      config.translations.error,
      config.translations.pending,
      config.translations.success,
      subject,
      entity,
      handleErrorClick,
      uniqueId,
      handleInfoClick,
      handleSuccessClick,
    ]
  );

  return (
    <StatusNotification
      {...props}
      ref={notificationRef}
      baseComponent={{ Component: BaseNotification }}
      avatar={<MemoizedAvatar config={config} status={status} />}
      hoverIconButtons={undefined}
      states={states}
    />
  );
}
export default React.memo(Consumable);
