import useConfigColumns from './useConfigColumns';
import {
  useDeleteConfigMutation,
  useGetConfigsQuery,
  useLazyGetConfigQuery,
  usePutConfigMutation,
  usePostConfigMutation,
  useLazyIsConfigUniqueQuery,
} from '@/redux/api/system/configsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CHANNELS, PRIMITIVES, ROLES } from '@/shared/constants';
import { ConfigReq, ConfigRes } from './typings';
import { FieldsUndefined } from '@/shared/typings';
import useUniqueness from '@/hooks/useUniqueness';

export const ConfigsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ConfigRes>));

export default function ConfigPanel() {
  const { t } = useTranslation();

  // Queries and mutations
  const { data, isLoading, isError } = useGetConfigsQuery();
  const [getConfig] = useLazyGetConfigQuery();
  const [postConfigTrigger] = usePostConfigMutation();
  const [putConfigTrigger] = usePutConfigMutation();
  const [deleteConfigTrigger] = useDeleteConfigMutation();
  const [isConfigUnique] = useLazyIsConfigUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<ConfigRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isConfigUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<ConfigReq>({
    title: t(CHANNELS.CONFIGS),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
        options: { required: t('fieldRequiredError') as string },
      },
      {
        type: 'checkbox',
        name: 'editable',
        placeholder: t('editable'),
        initialValue: false,
      },
      {
        type: 'autocomplete',
        name: 'type',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(PRIMITIVES).map((primitive: string) => ({
            label: t(primitive),
            value: primitive,
          })),
        },
        mutations: (_, hideFields, toggleFields) => ({
          onChange: {
            impacts: {
              form: {
                handler: ({ getValues }) => {
                  const type = getValues()?.type?.value;

                  if (!type || type === PRIMITIVES.BOOLEAN) {
                    hideFields('string', 'fullRangeMin', 'fullRangeMax', 'safeRangeMax', 'safeRangeMin');
                    return;
                  }

                  if (type === PRIMITIVES.STRING) {
                    toggleFields(
                      (name) => !['fullRangeMin', 'fullRangeMax', 'safeRangeMax', 'safeRangeMin'].includes(name)
                    );
                    return;
                  }

                  if (type === PRIMITIVES.NUMBER) {
                    toggleFields((name) => name !== 'string');
                  }
                },
              },
            },
          },
        }),
      },
      {
        name: 'string',
        placeholder: t('stringOptions'),
        options: {
          required: true,
          deps: ['type'],
          validate: (value, formValues) => {
            if (formValues.type.value === PRIMITIVES.STRING) {
              return value && Array.isArray(new Array(value)) ? true : (t('arrayInvalid') as string);
            }
            return true;
          },
        },
        helperText: t('fieldListOfString') as string,
      },
      {
        name: 'fullRangeMin',
        placeholder: t('fullRangeMin'),
        type: 'number',
        options: {
          required: true,
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'fullRangeMax',
        type: 'number',
        placeholder: t('fullRangeMax'),
        options: {
          required: true,
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'safeRangeMin',
        type: 'number',
        placeholder: t('safeRangeMin'),
        options: {
          required: false,
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        name: 'safeRangeMax',
        placeholder: t('safeRangeMax'),
        type: 'number',
        options: {
          required: false,
          deps: ['type'],
        },
        helperText: t('fieldNumberError') as string,
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
          multiple: true,
        },
      },
    ],
    getEditData: async (id: string | boolean) => {
      const res = await getConfig(id as string);
      const config: any = { ...res.data?.data };
      return config;
    },
    onSubmit: async (id: string | boolean, data: any) => {
      const isEditing = typeof id === 'string';

      const submitData = { ...data };

      if (submitData.type === PRIMITIVES.STRING) {
        submitData.string = submitData.string.split(',').map((el: string) => el.trim());
      }

      if (isEditing) {
        await putConfigTrigger({ id, body: submitData });
      } else {
        await postConfigTrigger(submitData);
      }
    },
    mutations: (_, hideFields, toggleFields) => ({
      onMount: {
        impacts: {
          form: {
            handler: ({ getValues }) => {
              const type = getValues()?.type?.value;

              if (!type || type === PRIMITIVES.BOOLEAN) {
                hideFields('string', 'fullRangeMin', 'fullRangeMax', 'safeRangeMax', 'safeRangeMin');
                return;
              }

              if (type === PRIMITIVES.STRING) {
                toggleFields(
                  (name) => !['fullRangeMin', 'fullRangeMax', 'safeRangeMax', 'safeRangeMin'].includes(name)
                );
                return;
              }

              if (type === PRIMITIVES.NUMBER) {
                toggleFields((name) => name !== 'string');
              }
            },
          },
        },
      },
    }),
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t(CHANNELS.CONFIGS),
    message: (extra: any) =>
      t('deleteMessage', {
        entity: t(CHANNELS.CONFIGS),
        name: extra.name,
        interpolation: { escapeValue: false },
      }) as string,
    onConfirm: (extra: any) => deleteConfigTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useConfigColumns(openDialog, confirm);

  return (
    <>
      <ConfigsTable
        title={t(CHANNELS.CONFIGS)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('config')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
