import { Box, CircularProgress, circularProgressClasses } from '@mui/material';
import CircularProgressWithLabel from './Circular';
import { ProgressWithLabelProps } from './typings';

export default function CircularWithBasePath({
  size,
  type,
  externalLabel,
  innerLabel,
  containerStyle,
  externalLabelStyle,
  innerLabelStyle,
  circleContainerStyle,
  color,
  thickness,
  ...props
}: ProgressWithLabelProps & { size: number | undefined }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        {...props}
        color={color}
        sx={{
          opacity: 0.1,
          color: (theme) => (color && color !== 'inherit' ? theme.palette[color].dark : theme.palette.grey[800]),
        }}
        variant={'determinate'}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgressWithLabel
        {...props}
        color={color}
        type={type}
        externalLabel={externalLabel}
        innerLabel={innerLabel}
        circleContainerStyle={circleContainerStyle}
        containerStyle={{
          position: 'absolute',
          left: 0,
          padding: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        externalLabelStyle={externalLabelStyle}
        innerLabelStyle={innerLabelStyle}
        size={size}
        thickness={thickness}
      />
    </Box>
  );
}
