import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

const DrawerMenuItem = styled(MenuItem)(({ theme }) => ({
  disableRipple: true,
  display: 'block',
  p: 0,
  transition: theme.transitions.create('background-color'),
}));

export default DrawerMenuItem;
