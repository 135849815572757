import { LocalOffer } from '@mui/icons-material';
import { Box, Divider, Typography, useTheme } from '@mui/material';

export default function DeviceItem({
  numDevices,
  totDevices,
  name,
  model,
}: {
  numDevices: number;
  totDevices: number;
  name: string;
  model?: string;
}) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      gap={theme.spacing(1)}
      alignItems={'center'}
      width={'100%'}
      sx={{
        backgroundColor: theme.palette.background.grayShades[0],
        borderRadius: 1.5,
        pr: theme.spacing(2),
        pl: theme.spacing(1.5),
        py: theme.spacing(1),
        height: '100%',
      }}
    >
      <LocalOffer />
      <Divider flexItem orientation="vertical" />
      <Box flexGrow={2}>
        <Box display="flex" alignItems={'baseline'} justifyContent={'space-between'}>
          <Box display="flex" gap={'0.5rem'} alignItems={'baseline'}>
            <Typography variant="h6" fontWeight={'600'}>
              {numDevices}
            </Typography>
          </Box>
          <Typography variant="caption" fontWeight={'800'}>
            {((numDevices * 100) / totDevices).toFixed(2)}
            <Typography variant="caption" fontWeight={'400'}>
              %
            </Typography>
          </Typography>
        </Box>

        <Typography variant="body2">{`${name} (${model})`}</Typography>
      </Box>
    </Box>
  );
}
