import { STATUS, Tag, TAGS } from '@/redux/slices/operationSlice';
import BaseNotification, { StatusNotification } from '../../BaseNotification';
import { getDefaultStatusIcon } from '../utils';
import { useTranslation } from 'react-i18next';
import OTAUpdateMessage from './OTAUpdateMessage';
import BaseOperationTypography, { BoldOperationTypography } from '../../../Operation/BaseOperationTypography';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DefaultNotificationRightAction from '../../DefaultNotificationRightAction';
import BaseOperationAvatar from '../../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../../Operation/BaseOperationIcon';
import { useNavigate } from 'react-router';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import BaseOperationIconButton from '../../../Operation/BaseOperationIconButton';
import { Operations } from '../../../Operation/types';
import { NotificationVirtualizationProps } from '../../types';
import { useNotificationHeight } from '../../hooks/useNotificationHeight';

type OTAUpdateTag = Extract<Tag, typeof TAGS.OTA_UPDATE>;
type Props = Operations.Props & NotificationVirtualizationProps & { tag: OTAUpdateTag };

export default function OTAUpdate(props: Props) {
  const { uniqueId, subject, status, read, style, setNotificationHeight } = props;
  const notificationRef = useNotificationHeight({
    id: uniqueId,
    setNotificationHeight,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StatusNotification
      {...props}
      ref={notificationRef}
      uniqueId={uniqueId}
      subject={subject}
      status={status}
      read={read}
      baseComponent={{ Component: BaseNotification }}
      style={style}
      avatar={
        <BaseOperationAvatar
          status={status}
          tagIcon={<BaseOperationIcon Icon={SystemUpdateAltIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(status)} />}
        />
      }
      hoverIconButtons={{
        main: (
          <BaseOperationIconButton
            read={read}
            status={status}
            icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
          />
        ),
        rightAction: <DefaultNotificationRightAction read={read} status={status} uniqueId={uniqueId} />,
      }}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${subject}#ota_jobs`),
          message: (
            <BaseOperationTypography read={read} status={status}>
              <BaseOperationTypography read={read} status={status}>
                {t('OTAUpdateError')}{' '}
              </BaseOperationTypography>
              <BoldOperationTypography read={read} status={status}>
                {subject}{' '}
              </BoldOperationTypography>
              <BaseOperationTypography read={read} status={status}>
                {t('somethingWentWrong')}.{' '}
              </BaseOperationTypography>
            </BaseOperationTypography>
          ),
        },
        [STATUS.INFO]: {
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${subject}#ota_jobs`),
          message: (
            <OTAUpdateMessage read={read} status={status} actionStatement={t('OTAUpdatePending')} subject={subject} />
          ),
        },
        [STATUS.SUCCESS]: {
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${subject}#ota_jobs`),
          message: (
            <OTAUpdateMessage read={read} status={status} actionStatement={t('OTAUpdateSuccess')} subject={subject} />
          ),
        },
        [STATUS.WARNING]: {
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${subject}#ota_jobs`),
          message: (
            <OTAUpdateMessage read={read} status={status} actionStatement={t('OTAUpdateWarning')} subject={subject} />
          ),
        },
      }}
    />
  );
}
