import Box from '@mui/material/Box';
import { CulliganTooltipChartProps } from './typings';

const CulliganTooltipChart = (props: CulliganTooltipChartProps) => {
  return (
    <Box
      position={'absolute'}
      bgcolor={'text.primary'}
      borderRadius={1}
      {...(props.id ? { id: props.id } : {})}
      {...(props.position === 'managed' ? { top: props.coordinates.top, left: props.coordinates.left } : {})}
      color={'background.default'}
      sx={(theme) => ({
        zIndex: theme.zIndex.mobileStepper - 1,
        transform: `translate(-50%, ${props.position === 'managed' ? '-102%' : '-140%'})`,
        overflow: 'visible',
        width: 'fit-content',
      })}
    >
      {props.body}
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '20px',
          top: 'calc(100% - 0.3 px)',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: 0,
            height: 0,
            border: '10px solid transparent',
            borderTopColor: 'text.primary',
            opacity: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default CulliganTooltipChart;
