import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AlarmsPanel from '@/components/Fleet/AlarmsAndErrors/AlarmsPanel';
import ErrorsPanel from '@/components/Fleet/AlarmsAndErrors/ErrorsPanel';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import BaseTabsLayout from '@/components/Shared/TabsLayout/BaseTabsLayout';
import dayjs, { Dayjs } from 'dayjs';
import { CHANNELS } from '@/shared/constants';

const AlarmsAndErrorsPage = ({
  navigationTabs,
  filters = true,
  pageSize = 10,
  selection = true,
  exportData = true,
  hideTableHeader = false,
  defaultRange = {
    end: dayjs().endOf('day'),
    start: dayjs().subtract(1, 'month').startOf('day'),
  },
}: {
  navigationTabs?: boolean;
  filters?: boolean;
  pageSize?: number;
  selection?: boolean;
  search?: boolean;
  exportData?: boolean;
  hideTableHeader?: boolean;
  defaultRange?: {
    end: Dayjs;
    start: Dayjs;
  };
}) => {
  const { t } = useTranslation();

  const tabsConfig = [
    {
      id: CHANNELS.ALARMS,
      label: t(CHANNELS.ALARMS),
      ariaControls: true,
      ContentComponent: (
        <AlarmsPanel
          filters={filters}
          pageSize={pageSize}
          selection={selection}
          defaultRange={defaultRange}
          exportData={exportData}
          hideTableHeader={hideTableHeader}
          isFleet={true}
        />
      ),
    },
    {
      id: 'errors',
      label: t('errors'),
      ariaControls: true,
      ContentComponent: (
        <ErrorsPanel
          filters={filters}
          pageSize={pageSize}
          selection={selection}
          defaultRange={defaultRange}
          exportData={exportData}
          hideTableHeader={hideTableHeader}
          isFleet={true}
        />
      ),
    },
  ];

  return (
    <Box>
      {navigationTabs && <NavigationTabsLayout config={tabsConfig} />}
      {!navigationTabs && <BaseTabsLayout config={tabsConfig} />}
    </Box>
  );
};

export default AlarmsAndErrorsPage;
