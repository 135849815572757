import { Box, Typography, Divider, ListItem, List, Button, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import DeviceItem from './DeviceItem';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useEffect, useState } from 'react';
import { FLEET_GENERAL_QUERY_WIDTH } from '.';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import LoadingDevicesList from './LoadingDevicesList';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';

const renderDevicesList = (
  devices: Array<{ name: string; model: string; totDevices: number }>,
  limitDevices: boolean = true,
  t: any,
  handleDialog?: (openDialog: boolean) => void
) => {
  if (devices.length === 0) {
    return <></>;
  }

  if (limitDevices) {
    devices.length = 8;
  }

  return devices.map((device, i) => {
    if (devices.length > 8 && limitDevices && i === devices.length - 1) {
      return (
        <ListItem sx={{ p: 0 }} key={'deviceListButton'}>
          <Button
            onClick={() => handleDialog && handleDialog(true)}
            variant="outlined"
            color="primary"
            endIcon={<OpenInFullIcon />}
            fullWidth
            sx={{ height: '100%' }}
          >
            {t('seeXMore', { number: devices.length - 8 })}
          </Button>
        </ListItem>
      );
    }

    return (
      <ListItem sx={{ p: 0 }} key={device.name}>
        <DeviceItem
          numDevices={device.totDevices}
          totDevices={devices.reduce((prev, next) => prev + next.totDevices, 0)}
          name={device.name}
          model={device.model}
        />
      </ListItem>
    );
  });
};

export default function DevicesList({
  isDialog = false,
  handleDialog,
}: {
  isDialog: boolean;
  handleDialog: (openDialog: boolean) => void;
}) {
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isError } = useGetFleetQuery();

  const [sortBy, setSortBy] = useState<'name' | 'amount'>('amount');
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [sortedDevicesData, setSortedDevicesData] = useState<any>(null);
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);

  useEffect(() => {
    if (data?.data.devices) {
      setSortedDevicesData(
        [...data.data.devices].sort((firstDevice, secondDevice) => {
          if (sortBy === 'amount' && sortAsc) {
            return firstDevice.totDevices - secondDevice.totDevices;
          }

          if (sortBy === 'amount' && !sortAsc) {
            return secondDevice.totDevices - firstDevice.totDevices;
          }

          if (sortBy === 'name' && sortAsc) {
            return firstDevice.name < secondDevice.name ? -1 : firstDevice.name > secondDevice.name ? 1 : 0;
          }

          return secondDevice.name < firstDevice.name ? -1 : secondDevice.name > firstDevice.name ? 1 : 0;
        })
      );
    }
  }, [data?.data.devices, sortBy, sortAsc]);

  const theme = useTheme();

  const SafeDevicesList = withErrorLoadingManagement(() => (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        p: 2,
        gap: 1,
        alignItems: 'stretch',
        flexWrap: 'wrap',
      }}
    >
      <Box
        display="flex"
        gap={theme.spacing(1)}
        flexDirection={isDialog || matches || !(sortedDevicesData?.length > 0) ? 'row' : 'column'}
        {...(isDialog || matches || !(sortedDevicesData?.length > 0) ? { sx: { width: '100%' } } : {})}
      >
        <AreaContainer>
          <AreaBody>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              sx={{
                borderRadius: 1.5,
                backgroundColor: theme.palette.background.grayShades[0],
              }}
            >
              <Typography variant="body1" color="text.secondary">
                {t('totalNumberOfDevices')}
              </Typography>
              <Typography variant="h3" fontWeight={800} sx={{ alignSelf: 'flex-end' }}>
                {data.data.devices.reduce((prev: number, next: any) => prev + next.totDevices, 0)}
              </Typography>
            </Box>
          </AreaBody>
        </AreaContainer>

        <AreaContainer>
          <AreaBody>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              sx={{
                backgroundColor: theme.palette.background.grayShades[0],
                borderRadius: 1.5,
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
              }}
            >
              <Typography variant="body1" color="text.secondary">
                {t('sortBy')}
              </Typography>
              <Box display="flex" gap={theme.spacing(1)} alignSelf={'flex-end'}>
                <Button
                  color={sortBy === 'name' ? 'primary' : 'inherit'}
                  variant="outlined"
                  endIcon={sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  size="small"
                  onClick={() => {
                    setSortBy('name');
                    setSortAsc((p) => !p);
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{ mr: theme.spacing(1.5) }}
                    fontSize={theme.typography.button.fontSize}
                  >
                    {t('name')}
                  </Typography>
                  <Divider orientation="vertical" />
                </Button>
                <Button
                  color={sortBy === 'amount' ? 'primary' : 'inherit'}
                  variant="outlined"
                  endIcon={sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  size="small"
                  onClick={() => {
                    setSortBy('amount');
                    setSortAsc((p) => !p);
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{ mr: theme.spacing(1.5) }}
                    fontSize={theme.typography.button.fontSize}
                  >
                    {t('amount')}
                  </Typography>
                  <Divider orientation="vertical" />
                </Button>
              </Box>
            </Box>
          </AreaBody>
        </AreaContainer>
      </Box>
      {sortedDevicesData?.length > 0 && (
        <List
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: `repeat(${
              isDialog
                ? Math.ceil(sortedDevicesData.length / 2)
                : Math.ceil(sortedDevicesData.length / 2) > 4
                ? 4
                : Math.ceil(sortedDevicesData.length / 2)
            }, auto)`,
            gridAutoFlow: 'column',
            gap: theme.spacing(1),
            alignItems: 'stretch',
            flexGrow: 2,
            py: 0,
            color: theme.palette.text.primary,
          }}
        >
          {renderDevicesList(sortedDevicesData, !isDialog, t, handleDialog)}
        </List>
      )}
    </Box>
  ));

  return (
    <SafeDevicesList isError={isError} isLoading={isLoading || isFetching} LoadingComponent={<LoadingDevicesList />} />
  );
}
