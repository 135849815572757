import CulliganDialog from '@/components/Shared/CulliganDialog';
import { CulliganDialogProps } from '@/components/Shared/CulliganDialog/typings';

import { useState } from 'react';

export default function useCulliganDialog(props: Omit<CulliganDialogProps, 'activeTab' | 'setActiveTab'>) {
  const [activeTab, setActiveTab] = useState<number>(0);

  return {
    dialog: <CulliganDialog {...props} activeTab={activeTab} setActiveTab={setActiveTab} />,
    setActiveTab,
  };
}
