import ConfirmDialog from '@/components/Shared/ConfirmDialog';
import { Typography } from '@mui/material';
import { ConfirmDialogProps } from '@/components/Shared/ConfirmDialog/typings';
import { useState } from 'react';

export function useConfirmDialog(
  props: Omit<ConfirmDialogProps, 'isOpen' | 'onClose' | 'extra' | 'MessageComponent'> & {
    onClose?: Function;
    message: (e: any) => string | JSX.Element;
  }
) {
  const [msg, setMsg] = useState<JSX.Element | null>(null);
  const [extra, setExtra] = useState<any>(null);

  function handleOpenDialog(data: any) {
    let message = props.message(data);
    if (typeof message === 'string') {
      message = <Typography>{message}</Typography>;
    }
    setMsg(message);
    !!data && setExtra(data);
  }

  function handleOnClose() {
    setMsg(null);
  }

  return {
    ConfirmDialog: () => (
      <ConfirmDialog {...props} MessageComponent={msg} isOpen={!!msg} onClose={handleOnClose} extra={extra} />
    ),
    confirm: handleOpenDialog,
  };
}

export default useConfirmDialog;
