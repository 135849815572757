import { store } from '@/redux/store';
import { routes } from '../../router/routes';
import type { Location } from '@remix-run/router';
import { AppDrawerRoute, EnhancedRouteObject } from '@typings';
import { NonIndexRouteObject } from 'react-router';

function isRouteObjectWithChildren(route: any): route is NonIndexRouteObject {
  return !!(route as NonIndexRouteObject).children;
}

function createAppDrawerRoute(route: EnhancedRouteObject): AppDrawerRoute {
  const routePathWithouthSlashes = route?.path ? (route.path + '').replace('/', '') : '';
  return {
    id: route.id,
    key: routePathWithouthSlashes,
    path: route.path?.includes('/') ? route.path : `/${route.path}`,
    label: route.label
      ? route.label
      : routePathWithouthSlashes.charAt(0).toLocaleUpperCase() + routePathWithouthSlashes.slice(1),
    icon: route.icon,
    rolesAllowed: route.rolesAllowed,
  };
}

const getAppDrawerRoutes = (): AppDrawerRoute[] | undefined => {
  const {
    auth: { roles },
  } = store.getState();
  return routes[0].children
    ?.filter((route) => route.path && route.path !== '/*')
    .map((route) => {
      if (isRouteObjectWithChildren(route)) {
        return {
          ...createAppDrawerRoute(route),
          children: route.children?.map(createAppDrawerRoute),
        };
      }
      return createAppDrawerRoute(route);
    })
    .filter((route) => {
      const rolesAllowed = route.rolesAllowed;
      return !rolesAllowed || roles.filter((role) => rolesAllowed.includes(role)).length > 0;
    });
};

const getAppDrawerBaseRoute = (location: Location) =>
  getAppDrawerRoutes()?.filter((route) => route.path && location.pathname.includes('' + route.path))[0];

const getAppDrawerChildRoutes = (location: Location) => {
  const baseRoute = getAppDrawerBaseRoute(location);

  if (!baseRoute?.children) {
    return [];
  }

  return baseRoute.children.filter((route) => (route.path + '').replace('/', '') !== 'undefined');
};

export { getAppDrawerRoutes, getAppDrawerBaseRoute, getAppDrawerChildRoutes };
