import { Box, Snackbar as SnackbarMUI } from '@mui/material';
import { useState } from 'react';
import { Operation } from '@/redux/slices/operationSlice';
import { useDispatch } from 'react-redux';
import { toggleShowed } from '@/redux/slices/operationSlice';
import { OPERATION_STATUS } from '@/shared/constants';
import zIndex from '@mui/material/styles/zIndex';
import Snackbar from './Snackbar';

interface OperationProps {
  operation: Operation;
}

export default function SnackbarWrapper({ operation }: OperationProps) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { state } = operation;
  const { REJECTED } = OPERATION_STATUS;
  const hideable = state === REJECTED;
  const autoHideTimeout = hideable ? null : 3000;

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setTimeout(() => {
      dispatch(toggleShowed(operation.uniqueId));
    }, 1000);
  };

  return (
    <SnackbarMUI
      open={open}
      autoHideDuration={autoHideTimeout}
      onClose={(e, reason) => handleClose(reason)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{
        inset: 'unset',
      }}
      sx={{
        zIndex: zIndex.tooltip + 500,
        position: 'relative',

        mb: 1,
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        pointerEvents: 'auto',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Snackbar operation={operation} dismissAction={handleClose} />
      </Box>
    </SnackbarMUI>
  );
}
