import React, { useEffect, useRef, useState } from 'react';
import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryTheme,
  FlyoutProps,
} from 'victory';
import { Box, Typography, useTheme } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Flyout from '../Shared/Charts/Flyout';
import useSize, { Size } from '@/hooks/useSize';
import { getChipColor, stringToColor } from '@/shared/utils';
import { WATER_TYPES } from '@/shared/constants';
import RenderIf from '../Shared/RenderIf/RenderIf';
import MissingDataCard from '../Shared/MissingDataCard';
import AreaContainer, { AreaBody } from '../Shared/Card/Area';
import useStatisticsData from './useStatisticsData';

type CustomFlyoutProps = {
  colors: Map<string, string>;
  totalValue: number;
  size: Size;
};

const GOLD_MEDAL_COLOR = '#FFD700';
const SILVER_MEDAL_COLOR = '#C0C0C0';
const BRONZE_MEDAL_COLOR = '#CD7F32';

const HORIZONTAL_MARGIN = 10;
const ICON_SCALE = 0.8;
const MEDAL_BACKGROUND_OFFSET = 5;
const PERCENTAGE_VERTICAL_OFFSET = 10;
const PERCENTAGE_LABEL_VERTICAL_OFFSET = 10;
const TOOLTIP_CORNER_RADIUS = 5;
const TOOLTIP_POINTER_LENGTH = 10;
const TOOLTIP_POINTER_WIDTH = 20;

const CustomLabel = (props: FlyoutProps & CustomFlyoutProps) => {
  const theme = useTheme();
  const { x, y, datum, index, scale, active, id, colors, totalValue, size, ...rest } = props;
  const _datum = datum as any;
  const percentageValue = Math.round((_datum.value / totalValue) * 100);

  const isSmallScreen = size.width < 600;
  const iconSize = isSmallScreen ? 14 : 20;
  const backgroundSize = isSmallScreen ? 24 : 32;

  const labelStyle = {
    fill: theme.palette.text.primary,
    fontSize: isSmallScreen ? '0.625rem' : '0.875rem',
    fontWeight: 'bold',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  };

  const percentageLabelStyle = {
    ...labelStyle,
    fontSize: isSmallScreen ? '0.75rem' : '1rem',
  };

  const isHorizontal = size.width < size.height;

  let medalColor;
  if (index === 0) medalColor = GOLD_MEDAL_COLOR;
  else if (index === 1) medalColor = SILVER_MEDAL_COLOR;
  else if (index === 2) medalColor = BRONZE_MEDAL_COLOR;
  else medalColor = colors.get(_datum.label + _datum.waterTypeLabel);

  if (index! < 3) {
    const rectX = isHorizontal ? x! - backgroundSize - MEDAL_BACKGROUND_OFFSET : x! - backgroundSize / 2;
    const rectY = isHorizontal ? y! - backgroundSize / 2 : y! - backgroundSize - MEDAL_BACKGROUND_OFFSET;

    return (
      <g>
        <rect
          x={rectX}
          y={rectY}
          width={backgroundSize}
          height={backgroundSize}
          fill={medalColor}
          rx={backgroundSize / 2}
          ry={backgroundSize / 2}
        />
        <foreignObject
          x={rectX + (backgroundSize - iconSize) / 2}
          y={rectY + (backgroundSize - iconSize) / 2}
          width={iconSize}
          height={iconSize}
          style={{ overflow: 'visible' }}
        >
          <EmojiEventsIcon sx={{ fontSize: iconSize, transform: `scale(${ICON_SCALE})` }} />
        </foreignObject>

        <VictoryLabel
          x={isHorizontal ? size.width - HORIZONTAL_MARGIN : x}
          y={isHorizontal ? y : size.height - HORIZONTAL_MARGIN}
          style={percentageLabelStyle}
          textAnchor={isHorizontal ? 'end' : 'middle'}
          verticalAnchor={isHorizontal ? 'middle' : 'end'}
          text={`${percentageValue}%`}
        />
        <RenderIf condition={Boolean(_datum)}>
          <VictoryTooltip
            {...rest}
            x={x}
            y={y}
            active={active}
            pointerLength={TOOLTIP_POINTER_LENGTH}
            cornerRadius={TOOLTIP_CORNER_RADIUS}
            pointerWidth={TOOLTIP_POINTER_WIDTH}
            flyoutStyle={{ fill: theme.palette.text.primary }}
            style={{
              fill: 'transparent',
            }}
            flyoutComponent={
              <Flyout
                dy={PERCENTAGE_VERTICAL_OFFSET}
                parentSize={size}
                body={({ datum }) => {
                  return (
                    <Box
                      id="flyoutContent"
                      sx={{
                        background: 'black',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        padding: 1,
                        gap: 1,
                        width: 'fit-content',
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: colors.get(_datum.label + _datum.waterTypeLabel),
                          height: '1.5625rem',
                          width: '1.5625rem',
                          borderRadius: '0.25rem',
                        }}
                      />
                      <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>{_datum.label}</Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          marginLeft: 2,
                        }}
                      >
                        {_datum.percentageValue}%
                      </Typography>
                    </Box>
                  );
                }}
              />
            }
          />
        </RenderIf>
      </g>
    );
  } else {
    return (
      <>
        <VictoryLabel
          {...props}
          text={`#${index! + 1}`}
          style={labelStyle}
          textAnchor={isHorizontal ? 'end' : 'middle'}
          verticalAnchor={isHorizontal ? 'middle' : 'end'}
          x={isHorizontal ? size.width - HORIZONTAL_MARGIN : x}
          y={isHorizontal ? y : size.height}
        />
        <RenderIf condition={Boolean(_datum)}>
          <VictoryTooltip
            {...rest}
            x={x}
            y={y}
            active={active}
            pointerLength={TOOLTIP_POINTER_LENGTH}
            cornerRadius={TOOLTIP_CORNER_RADIUS}
            pointerWidth={TOOLTIP_POINTER_WIDTH}
            flyoutStyle={{ fill: theme.palette.text.primary }}
            style={{
              fill: 'transparent',
            }}
            flyoutComponent={
              <Flyout
                dy={PERCENTAGE_LABEL_VERTICAL_OFFSET}
                parentSize={size}
                body={({ datum }) => {
                  return (
                    <Box
                      id="flyoutContent"
                      sx={{
                        background: 'black',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        padding: 1,
                        gap: 1,
                        width: 'fit-content',
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: colors.get(_datum.label + _datum.waterTypeLabel),
                          height: '1.5625rem',
                          width: '1.5625rem',
                          borderRadius: '0.25rem',
                        }}
                      />
                      <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>{_datum.label}</Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          wordBreak: 'keep-all',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          marginLeft: 2,
                        }}
                      >
                        {_datum.percentageValue}%
                      </Typography>
                    </Box>
                  );
                }}
              />
            }
          />
        </RenderIf>
      </>
    );
  }
};

export default function PreferredWaterTypeAndFlavorCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filteredData } = useStatisticsData();
  const chartContainerRef = useRef<HTMLBaseElement | null>(null);
  const size = useSize(chartContainerRef?.current);
  const theme = useTheme();
  const colors = useRef<Map<string, string>>(new Map());
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    setKey((k) => k + 1);
  }, [filteredData]);

  const totalValue = filteredData?.preferredDrinkCombinations?.value.reduce((acc, item) => acc + item.value, 0) || 0;

  const sortedData = [...(filteredData?.preferredDrinkCombinations?.value ?? [])]
    .sort((a, b) => b.value - a.value)
    .slice(0, 15)
    .map((item, index) => {
      const color = stringToColor(item.label + WATER_TYPES[item.waterType as keyof typeof WATER_TYPES]);
      colors.current.set(item.label + WATER_TYPES[item.waterType as keyof typeof WATER_TYPES], color);
      return {
        ...item,
        column: index + 1,
        percentageValue: Math.round((item.value / totalValue) * 100),
        waterTypeLabel: WATER_TYPES[item.waterType as keyof typeof WATER_TYPES],
      };
    });

  const highestPercentageDrink = sortedData.length > 0 ? sortedData[0].percentageValue : 0;
  const lowestPercentageDrink = sortedData.length > 0 ? sortedData[sortedData.length - 1].percentageValue : 0;

  const hasData = !!(
    filteredData &&
    filteredData.preferredDrinkCombinations &&
    filteredData.preferredDrinkCombinations.value.length > 0
  );

  return (
    <StatisticsCard
      variant={'bigBoxed'}
      title={t('preferredWaterTypeAndFlavor')}
      action={{
        label: t('details'),
        fn: () => navigate('/'),
      }}
    >
      {hasData ? (
        <Box key={key}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr',
                md: '1fr 1fr',
                lg: '1fr 1fr 1fr',
              },
              gap: '0.5rem',
              mb: '0.5rem',
            }}
          >
            {sortedData.map((drink, index) => {
              const percentageValue = Math.round((drink.value / totalValue) * 100);
              const chipColor = (
                index < 3 ? getChipColor(index, theme) : colors.current.get(drink.label + drink.waterTypeLabel)
              ) as string;
              return (
                <AreaContainer key={index}>
                  <AreaBody>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: theme.spacing(1),
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: chipColor,
                          borderRadius: 8,
                          px: theme.spacing(1),
                          py: theme.spacing(0.5),
                          minWidth: '2.5rem',
                        }}
                      >
                        {index < 3 ? (
                          <>
                            <Typography
                              variant="body2"
                              fontWeight={'bold'}
                              sx={{ color: (theme) => theme.palette.getContrastText(chipColor) }}
                            >
                              #{index + 1}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="body2"
                            fontWeight={'bold'}
                            sx={{ color: (theme) => theme.palette.getContrastText(chipColor) }}
                          >
                            #{index + 1}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 0.5,
                          flexGrow: 1,
                          flexWrap: 'wrap',
                        }}
                      >
                        {drink.waterTypeLabel && (
                          <Box
                            sx={{
                              display: 'flex',
                              backgroundColor: theme.palette.background.grayShades[0],
                              borderRadius: '0.75rem 0 0 0.75rem',
                              px: theme.spacing(1.5),
                              py: theme.spacing(1),
                            }}
                          >
                            <Typography variant="body1" sx={{ lineHeight: 1.1 }}>
                              {drink.waterTypeLabel}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            backgroundColor: theme.palette.background.grayShades[0],
                            borderRadius: drink.waterTypeLabel ? '0 0.75rem 0.75rem 0' : '0.75rem',
                            px: theme.spacing(1.5),
                            py: theme.spacing(1),
                          }}
                        >
                          <Typography variant="body2" sx={{ lineHeight: 1.1 }}>
                            {drink.label}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography variant="body2">{percentageValue}%</Typography>
                    </Box>
                  </AreaBody>
                </AreaContainer>
              );
            })}
          </Box>
          <Box
            ref={chartContainerRef}
            style={{ backgroundColor: theme.palette.background.grayShades[0], borderRadius: '0.75rem' }}
            sx={{
              height: {
                xs: '25rem',
                sm: '31.25',
                md: '25rem',
                lg: '18.75rem',
              },
              width: '100%',
              '& .VictoryContainer': { top: '0' },
              '@media print': {
                marginTop: '-10mm',
              },
              overflow: 'hidden',
            }}
          >
            <RenderIf condition={Boolean(size.width && size.height)}>
              <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={{ x: size.width < 600 ? 10 : 20, y: size.width < 600 ? 10 : 20 }}
                padding={{
                  left: size.width < 600 ? 30 : 50,
                  right: size.width < 600 ? 30 : 50,
                  top: size.width < 600 ? 30 : 50,
                  bottom: size.width < 600 ? 30 : 50,
                }}
                horizontal={size.width < 500}
                domain={{ y: [lowestPercentageDrink, highestPercentageDrink] }}
                height={size.height}
                width={size.width}
              >
                <VictoryAxis
                  style={{
                    axis: { stroke: 'transparent' },
                    ticks: { stroke: 'transparent' },
                    tickLabels: { fill: 'transparent' },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  style={{
                    axis: { stroke: 'transparent' },
                    ticks: { stroke: 'transparent' },
                    tickLabels: { fill: 'transparent' },
                  }}
                  tickFormat={() => ''}
                />
                <VictoryBar
                  data={sortedData}
                  x="column"
                  y="percentageValue"
                  style={{
                    data: {
                      fill: ({ index }) => {
                        const idx = index as number;
                        if (idx === 0) return GOLD_MEDAL_COLOR;
                        if (idx === 1) return SILVER_MEDAL_COLOR;
                        if (idx === 2) return BRONZE_MEDAL_COLOR;
                        return (
                          colors.current.get(sortedData[idx]?.label + sortedData[idx]?.waterTypeLabel) ||
                          theme.palette.background.grayShades[2]
                        );
                      },
                    },
                  }}
                  labels={({ datum, index }) => `#${(index as number) + 1}`}
                  labelComponent={<CustomLabel totalValue={totalValue} size={size} colors={colors.current} />}
                  barRatio={size.width < 600 ? 0.9 : 0.8}
                  alignment="middle"
                  cornerRadius={{ top: 10, bottom: 0 }}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onMouseOver: () => {
                          return [{ target: 'labels', mutation: () => ({ active: true }) }];
                        },
                        onMouseOut: () => {
                          return [{ target: 'labels', mutation: () => ({ active: false }) }];
                        },
                      },
                    },
                  ]}
                />
              </VictoryChart>
            </RenderIf>
          </Box>
          <Typography variant="body2" fontWeight={400} fontStyle={'italic'} color={'grey'} mt={1}>
            {t('preferredWaterTypeAndFlavorCard')}
          </Typography>
        </Box>
      ) : (
        <MissingDataCard />
      )}
    </StatisticsCard>
  );
}
