import { Avatar, SxProps, Tooltip } from '@mui/material';
import { MouseEventHandler } from 'react';
import CulliganChip from './CulliganChip';

export default function ColorChip({
  hex,
  label,
  avatarSx,
  onClick,
  tooltipDescription,
}: {
  hex: string;
  label: string;
  sx?: SxProps;
  avatarSx?: SxProps;
  onClick?: () => void;
  tooltipDescription?: string | null;
}) {
  const chip = (
    <CulliganChip
      avatar={<Avatar sx={{ backgroundColor: hex, ...avatarSx }} children={<></>} />}
      label={label}
      {...(onClick && {
        onClick: ((e) => {
          onClick();
        }) satisfies MouseEventHandler<HTMLDivElement>,
      })}
    />
  );

  if (!tooltipDescription) {
    return chip;
  }

  return (
    <Tooltip title={tooltipDescription} placement="top" arrow>
      {chip}
    </Tooltip>
  );
}
