import useSize from '@/hooks/useSize';
import { Box, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';

import { Fragment, RefObject } from 'react';
import {
  VictoryLine,
  VictoryArea,
  VictoryScatter,
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryAxis,
  VictoryTooltip,
} from 'victory';
import { VictoryTheme } from 'victory';
import Flyout from './Flyout';
import { LineChartDatum } from './typings';
import { SCATTER_PROPS } from './constants';

const calcDomain = (data: number[], treshold: number = 10): [number, number] => {
  const min = Math.min(...data);
  const max = Math.max(...data);
  const diff = max - min;
  const tresholdValue = (diff * treshold) / 100;

  return [min - (diff * treshold) / 100, max + tresholdValue];
};

export default function LineChartVictory({
  data,
  chartContainerRef,
}: {
  data: LineChartDatum[];
  chartContainerRef: RefObject<HTMLBaseElement | null>;
}) {
  const checkIsGrowing = (data: LineChartDatum[]) => {
    const previousPeriod = data.slice(0, data.length / 2);
    const currentPeriod = data.slice(data.length / 2);
    return (
      previousPeriod.reduce((current, next) => current + next.y, 0) <
      currentPeriod.reduce((current, next) => current + next.y, 0)
    );
  };
  const size = useSize(chartContainerRef?.current);
  const theme = useTheme();
  const lightGreen = theme.palette.success.light;
  const lightRed = theme.palette.error.light;
  const isGrowing = checkIsGrowing(data);
  const dataWithDates = data
    .map((item) => ({ ...item, x: dayjs(item.x).toDate(), label: '' }))
    .filter(({ x, y }) => x && typeof y === 'number' && !isNaN(y));

  if (size.height === 0 && size.width === 0) {
    return <></>;
  }

  return (
    <Fragment>
      <svg style={{ height: 0, position: 'absolute' }}>
        <defs>
          <linearGradient id="decliningGradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={lightRed} stopOpacity="0.6" />
            <stop offset="50%" stopColor={lightRed} stopOpacity="0.2" />
            <stop offset="100%" stopColor={lightRed} stopOpacity="0" />
          </linearGradient>
          <linearGradient id="growingGradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor={lightGreen} stopOpacity="0.6" />
            <stop offset="50%" stopColor={lightGreen} stopOpacity="0.2" />
            <stop offset="100%" stopColor={lightGreen} stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            responsive={true}
            labels={() => ' '}
            labelComponent={
              <VictoryTooltip
                pointerWidth={20}
                pointerLength={10}
                flyoutComponent={
                  <Flyout
                    dy={10}
                    parentSize={size}
                    body={({ datum, flyoutProps }) => {
                      return (
                        <Box
                          id="flyoutContent"
                          sx={{
                            background: 'black',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            padding: 1,
                            gap: 1,
                            width: 'fit-content',
                            borderRadius: 1,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: flyoutProps.activePoints?.[0].style.data.stroke || 'white',
                              height: 20,
                              width: 20,
                              borderRadius: 10,
                            }}
                          />
                          <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                            {dayjs(datum.x).format('L')}
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap', fontWeight: 'bold', marginLeft: 2 }}
                          >
                            {datum.y}
                          </Typography>
                        </Box>
                      );
                    }}
                  />
                }
                flyoutStyle={{ fill: theme.palette.text.primary }}
              />
            }
          ></VictoryVoronoiContainer>
        }
        theme={VictoryTheme.material}
        {...size}
        domain={{
          y: calcDomain(
            data.map(({ y }) => y),
            5
          ),
        }}
        padding={0}
        domainPadding={{ y: 50, x: 16 }}
      >
        <VictoryAxis
          tickComponent={<></>}
          tickFormat={[]}
          tickValues={[]}
          style={{
            grid: { stroke: 'none' },
            axis: { stroke: 'none' },
          }}
        />
        <VictoryAxis
          tickComponent={<></>}
          tickFormat={[]}
          tickValues={[]}
          dependentAxis
          style={{
            grid: { stroke: 'none' },
            axis: { stroke: 'none' },
          }}
        />
        <VictoryLine
          style={{
            data: {
              stroke: isGrowing ? theme.palette.success.light : theme.palette.error.light,
              strokeWidth: 5,
              strokeLinecap: 'round',
            },
            labels: {
              opacity: 0,
            },
          }}
          interpolation="cardinal"
          labels={() => ' '}
          data={dataWithDates}
        />
        <VictoryArea
          name="area-1"
          labels={() => null}
          labelComponent={<></>}
          data={dataWithDates}
          style={{
            data: {
              fill: `${isGrowing ? 'url(#growingGradient)' : 'url(#decliningGradient)'}`,
              strokeWidth: 0,
            },
            labels: {
              opacity: 0,
            },
          }}
          interpolation="cardinal"
        />

        <VictoryScatter
          {...SCATTER_PROPS}
          data={dataWithDates}
          style={{
            ...SCATTER_PROPS.style,
            data: {
              ...SCATTER_PROPS.style?.data,
              fill: isGrowing ? theme.palette.success.light : theme.palette.error.light,
            },
          }}
        />
      </VictoryChart>
    </Fragment>
  );
}
