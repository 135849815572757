import { useContext } from 'react';
import { Box } from '@mui/material';
import { ITEM_TYPE, MergedTelemetry } from '../../typings';
import BaseItemPicker from './BaseItemPicker';
import GroupItemPicker from './GroupItemPicker';
import { TelemetryContext } from '../../TelemetryContext';

const renderTelemetryItem = (telemetry: MergedTelemetry) => {
  switch (telemetry.type) {
    case ITEM_TYPE.GROUP:
      return <GroupItemPicker item={telemetry} key={telemetry.id} />;
    case ITEM_TYPE.SINGLE:
      return <BaseItemPicker item={telemetry} key={telemetry.id} />;
    default:
      return <></>;
  }
};

export default function ItemsPicker() {
  const { telemetries } = useContext(TelemetryContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '30rem',
        overflowY: 'auto',
      }}
    >
      {telemetries && telemetries.map(renderTelemetryItem)}
    </Box>
  );
}
