import { redirect } from 'react-router';
import ScrollTopOutlet from '../core/ScrollTopOutlet';
import Ecosystem from '../pages/OneTools/Ecosystem';
import { ROLES, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import ErrorBoundary from '../core/ErrorBoundary';
import { getPermissionCheckLoader } from '@/shared/utils';
import Channels from '../pages/OneTools/Channels';
import DeviceIdentity from '../pages/OneTools/DeviceIdentity';
import AdminPanelSettingsTwoTone from '@mui/icons-material/AdminPanelSettingsTwoTone';

const rolesAllowed = [ROLES.SYS_ADMIN];

const OneToolsRoutes = {
  path: ROUTE_SECTION.ONETOOL,
  label: 'One Tool',
  element: <ScrollTopOutlet />,
  icon: <AdminPanelSettingsTwoTone />,
  errorElement: <ErrorBoundary />,
  rolesAllowed,
  loader: getPermissionCheckLoader(rolesAllowed),
  children: [
    {
      index: true,
      loader: () => redirect(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}`),
    },
    {
      path: 'ecosystem',
      label: 'Ecosystem',
      children: [
        {
          index: true,
          element: <Ecosystem />,
        },
        {
          path: 'device-identity/:deviceIdentityId',
          element: <DeviceIdentity />,
        },
      ],
    },
    {
      path: 'channels',
      element: <Channels />,
      label: 'Channels',
    },
  ],
};

export default OneToolsRoutes;
