import { Chip, styled } from '@mui/material';

const CulliganChip = styled(Chip)(({ theme }) => ({
  border: '1px solid',
  borderRadius: '0.25rem',
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(1),
  borderColor: theme.palette.background.grayShades[2],
  backgroundColor: theme.palette.background.grayShades[0],
}));

export default CulliganChip;
