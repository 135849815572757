import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useDefaultLoading() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [dots, setDots] = useState<string>('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (dots.length === 3) {
        setDots('');
        return;
      }

      setDots((d) => d + '.');
    }, 200);

    return () => clearInterval(intervalId);
  });

  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.background.grayShades[0],
        p: 1,
        borderRadius: 1,
        color: theme.palette.text.primary,
        position: 'relative',
      }}
    >
      <Typography>
        {t('loadingData')}
        {dots}
      </Typography>
    </Box>
  );
}
