import { Box, Collapse, Typography } from '@mui/material';
import { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SlideDiv } from '@/components/Shared/SlidingDiv';
import ExpandIcon from '@/components/Shared/AnimatedExpandIcon';

export default function ErrorFeedback({ hasErrors, action }: { hasErrors: boolean; action?: string }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <SlideDiv
      slide={hasErrors}
      sx={{
        position: 'absolute',
        zIndex: -1,
        top: 100,
        left: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          cursor: action ? 'pointer' : 'default',
          position: 'relative',
          p: 1,
          width: '100%',
          transform: 'translateY(-75%)',
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: (theme) => theme.palette.error.light,
        }}
        {...(action ? { onClick: () => setExpanded((prev) => !prev) } : {})}
      >
        <Box
          display={'flex'}
          paddingBottom={1}
          sx={{ color: (theme) => theme.palette.getContrastText(theme.palette.error.light) }}
        >
          <Box display="flex" gap={1} justifyContent={'space-between'} width={'100%'} alignItems={'flex-start'}>
            <Box display="flex" gap={1} alignItems={'flex-start'}>
              <Box display="flex" alignItems="center" gap={1}>
                <ErrorOutlineIcon sx={{ fontSize: 12 }} />
                <Typography variant="caption" sx={{ lineHeight: 1, display: 'inline-block' }} color="inherit">
                  {t('error')}
                </Typography>
              </Box>
              {action && (
                <Box position="relative">
                  <Collapse in={expanded}>
                    <Typography
                      component="div"
                      variant="caption"
                      sx={{
                        flex: 1,
                        lineHeight: 1.3,
                      }}
                      color="inherit"
                    >
                      {t('somethingWentWrongWhileXXX', { action })}
                    </Typography>
                  </Collapse>
                </Box>
              )}
            </Box>
            {action && (
              <ExpandIcon rotate={expanded} color="inherit" size="small" sx={{ p: 0.1 }}>
                <ExpandMoreIcon sx={{ fontSize: 12 }} />
              </ExpandIcon>
            )}
          </Box>
        </Box>
      </Box>
    </SlideDiv>
  );
}
