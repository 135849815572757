import { PRIMITIVES } from '@/shared/constants';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CurrentDataSection from './CurrentDataSection';
import { FiltersTelemetryContext, TelemetryKey } from './FiltersTelemetryContext';
import HistoryDataSection from './HistoryDataSection';
import { RangesTelemetryContext, TelemetryRange } from './RangesTelemetryContext';
import { TelemetryContext } from './TelemetryContext';
import { DeviceTelemetries, DeviceTelemetryWithGroup, ITEM_TYPE } from './typings';
import useSearchParamsManager, { CustomURLSearchParams } from '@/hooks/useSearchParamsManager';
import Header from '@/components/Shared/Print/Header';

export const HISTORYDATASECTION_ID = 'historyTelemetryData';

const areAllItemsInGroupSelected = (keys: TelemetryKey[], group: DeviceTelemetryWithGroup) => {
  const activeGroup = keys.find((telemetry) => telemetry.id === group?.tag);
  return activeGroup && group?.items.length === activeGroup.items?.length;
};

export default function DeviceTelemetryPanel({ data }: { data?: DeviceTelemetries }) {
  const { t } = useTranslation();
  const [ranges, setRanges] = useState<TelemetryRange[]>([]);
  const telemetries = useMemo(() => data?.telemetries || [], [data?.telemetries]);
  const telemetriesWithGroup = useMemo(() => telemetries.filter((telemetry) => telemetry?.tags?.length), [telemetries]);
  const telemetriesWithoutGroupTag = useMemo(() => telemetries.filter((telemetry) => !telemetry?.tags), [telemetries]);
  const telemetriesTags = useMemo(
    () => [...new Set(telemetriesWithGroup.flatMap((telemetry) => telemetry?.tags))],
    [telemetriesWithGroup]
  );
  const telemetriesGroups = useMemo(
    () =>
      telemetriesTags
        .filter((tag) => tag)
        .map((tag) => ({
          tag: tag,
          items: telemetriesWithGroup.filter((telemetry) => tag && telemetry?.tags?.includes(tag)),
        }))
        .map((group) => {
          if (group?.items?.length === 1) {
            const telemetry = group.items[0];
            const isAlreadyShowedAsSingleTelemetry = telemetriesWithoutGroupTag.some((t) => t.id === telemetry?.id);
            if (!isAlreadyShowedAsSingleTelemetry) {
              telemetriesWithoutGroupTag.push(group.items[0]);
            }
            return null;
          }
          return group;
        })
        .filter((group) => group && group.items.length)
        .sort((curr, next) => (next?.items.length || 0) - (curr?.items.length || 0)),
    [telemetriesTags, telemetriesWithGroup, telemetriesWithoutGroupTag]
  );

  const [searchParams, setSearchParams] = useSearchParamsManager([
    {
      key: 'dateRangeEnd',
      initialValue: dayjs().endOf('day').valueOf(),
    },
    {
      key: 'dateRangeStart',
      initialValue: dayjs().subtract(1, 'week').startOf('day').valueOf(),
    },
    {
      key: 'keys',
      isList: true,
    },
  ]);
  const [keys, setKeys] = useState<TelemetryKey[]>([]);
  const [rangeEnd, setRangeEnd] = useState<number | undefined>(
    !isNaN(Number(searchParams?.get('dateRangeEnd')))
      ? Number(searchParams?.get('dateRangeEnd'))
      : dayjs().endOf('day').valueOf()
  );
  const [rangeStart, setRangeStart] = useState<number | undefined>(
    !isNaN(Number(searchParams?.get('dateRangeStart')))
      ? Number(searchParams?.get('dateRangeStart'))
      : dayjs().subtract(1, 'week').startOf('day').valueOf()
  );

  useLayoutEffect(() => {
    const beforePrintListener = () => {
      document.body.id = 'print';
    };
    const afterPrintListener = () => {
      document.body.id = '';
    };

    if (window) {
      window.addEventListener('beforeprint', beforePrintListener);
      window.addEventListener('afterprint', afterPrintListener);
    }

    return () => {
      window.removeEventListener('beforeprint', beforePrintListener);
      window.removeEventListener('afterprint', afterPrintListener);
    };
  }, []);

  useEffect(() => {
    if (!searchParams) {
      return;
    }

    const _keys = searchParams.get('keys');
    const _dateRangeStart = searchParams.get('dateRangeStart');
    const _dateRangeEnd = searchParams.get('dateRangeEnd');

    if (_keys && _keys.length > 0 && typeof _keys !== 'string') {
      const searchParamsKeys =
        (searchParams?.get('keys') as string[])?.reduce<TelemetryKey[]>((acc, key) => {
          const keys = key.split('~~');

          if (keys.length > 1) {
            const group = acc.find((g) => g.id === keys[0]);
            if (group && group.items) {
              group.items?.push({ id: keys[1] });
              return acc;
            }

            return [...acc, { id: keys[0], items: [{ id: keys[1] }] }];
          }

          return [...acc, { id: keys[0] }];
        }, []) || [];
      keys.forEach((key) => {
        const searchParamKey = searchParamsKeys.find((k) => k.id === key.id);
        if (
          key.items &&
          key.items.length > 0 &&
          searchParamKey &&
          searchParamKey.items &&
          searchParamKey.items.length > 0
        ) {
          searchParamKey.items = [...searchParamKey.items, ...key.items].reduce<TelemetryKey[]>((acc, key) => {
            if (acc.some((k) => k.id === key.id)) {
              return acc;
            }
            return [...acc, key];
          }, []);
        }
      });
      setKeys([...keys, ...searchParamsKeys.filter((key) => !keys.some((k) => k.id === key.id))]);
    }

    if (_dateRangeStart && !isNaN(Number(_dateRangeStart))) {
      setRangeStart(Number(_dateRangeStart));
    }

    if (_dateRangeEnd && !isNaN(Number(_dateRangeEnd))) {
      setRangeEnd(Number(_dateRangeEnd));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const mergedTelemetries = useMemo(() => {
    let merged = [];
    let ranges: Map<string, TelemetryRange> = new Map();
    const activeGroups = keys.filter((k) => k.items && k.items.length > 0);
    merged.push(
      ...(telemetriesWithoutGroupTag?.map((telemetry) => {
        if (telemetry.primitive === PRIMITIVES.NUMBER) {
          ranges.set(telemetry.id, { id: telemetry.id, max: telemetry.max, min: telemetry.min });
        }

        return {
          id: telemetry.id,
          name: telemetry.name,
          selected: keys.some((key) => key.id === telemetry.id),
          active: keys.some((key) => key.id === telemetry.id),
          type: ITEM_TYPE.SINGLE,
          items: undefined,
        };
      }) || []),
      ...(telemetriesGroups?.map((_group) => ({
        id: _group?.tag,
        name: _group?.tag,
        active: Boolean(activeGroups.length > 0 && _group?.tag && activeGroups.some(({ id }) => id === _group?.tag)),
        selected: Boolean(
          activeGroups.length > 0 &&
            _group?.tag &&
            activeGroups.some(({ id }) => id === _group?.tag) &&
            areAllItemsInGroupSelected(keys, _group)
        ),
        type: ITEM_TYPE.GROUP,
        items: _group?.items.map((telemetry) => {
          if (telemetry.primitive === PRIMITIVES.NUMBER) {
            ranges.set(telemetry.id, { id: telemetry.id, max: telemetry.max, min: telemetry.min });
          }

          return {
            id: telemetry.id,
            name: telemetry.name,
            selected: Boolean(
              activeGroups.length > 0 &&
                _group?.tag &&
                activeGroups.some(({ id }) => id === _group?.tag) &&
                activeGroups?.find((g) => g.id === _group?.tag)?.items?.some((key) => key.id === telemetry.id)
            ),
            active: Boolean(
              activeGroups.length > 0 &&
                _group?.tag &&
                activeGroups.some(({ id }) => id === _group?.tag) &&
                activeGroups?.find((g) => g.id === _group?.tag)?.items?.some((key) => key.id === telemetry.id)
            ),
            type: ITEM_TYPE.SINGLE,
            items: undefined,
          };
        }),
      })) || [])
    );

    setRanges([...ranges.values()]);
    return merged;
  }, [telemetriesWithoutGroupTag, telemetriesGroups, keys]);

  const _setKeys = useCallback(
    (keys: TelemetryKey[]) => {
      setKeys(keys);
      setSearchParams(
        new CustomURLSearchParams(
          searchParams?.upsert(
            keys.reduce<{ [key in string]: string[] }>(
              (acc, key) => ({
                keys: [
                  ...new Set([
                    ...acc.keys,
                    ...(key.items?.length && key.items?.length > 0
                      ? key.items.map((k) => `${key.id}~~${k.id}`)
                      : [key.id]),
                  ]),
                ],
              }),
              { keys: [] }
            )
          )
        )
      );
    },
    [searchParams, setSearchParams]
  );

  const _setRangeEnd = useCallback(
    (rangeEnd: number) => {
      setRangeEnd(rangeEnd);
      setSearchParams(() => {
        const updatedSearchParams = new CustomURLSearchParams(searchParams?.upsert({ dateRangeEnd: String(rangeEnd) }));
        return updatedSearchParams;
      });
    },
    [searchParams, setSearchParams]
  );

  const _setRangeStart = useCallback(
    (rangeStart: number) => {
      setRangeStart(rangeStart);
      setSearchParams(new CustomURLSearchParams(searchParams?.upsert({ dateRangeStart: String(rangeStart) })));
    },
    [searchParams, setSearchParams]
  );

  const value = useMemo(
    () => ({
      keys,
      rangeEnd,
      rangeStart,
      setKeys: _setKeys,
      setRangeEnd: _setRangeEnd,
      setRangeStart: _setRangeStart,
    }),
    [_setKeys, _setRangeEnd, _setRangeStart, keys, rangeEnd, rangeStart]
  );

  if (!telemetries.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Typography variant="h6">{t('noTelemetryAvailable')}</Typography>
      </Box>
    );
  }

  return (
    <Fragment>
      <Header />
      <TelemetryContext.Provider
        value={{
          telemetries: mergedTelemetries,
        }}
      >
        <FiltersTelemetryContext.Provider value={value}>
          <CurrentDataSection
            telemetriesWithoutGroupTag={telemetriesWithoutGroupTag}
            telemetriesGroups={telemetriesGroups}
          />
          <RangesTelemetryContext.Provider value={ranges}>
            <HistoryDataSection />
          </RangesTelemetryContext.Provider>
        </FiltersTelemetryContext.Provider>
      </TelemetryContext.Provider>
    </Fragment>
  );
}
