import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { TabsConfig } from '@/components/Shared/TabsLayout/typings';
import BaseTabsLayout from '@/components/Shared/TabsLayout/BaseTabsLayout';
import Section from '@/components/Shared/Section';
import { useGetConsumablesMetricsQuery } from '@/redux/api/fleet/consumablesMetricsApiSlice';
import { ConsumableMetric } from './typings';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import ConsumableGraph from './ConsumableGraph';
import { CHANNELS } from '@/shared/constants';

const ConsumablesCard = () => {
  const { t } = useTranslation();
  const { data, isError, isLoading, isFetching } = useGetConsumablesMetricsQuery(['flavors', 'co2tanks', 'filters']);

  const SafeConsumableGraph = withErrorLoadingManagement(({ consumable }: { consumable: ConsumableMetric }) => (
    <ConsumableGraph consumable={consumable} />
  ));

  const generateTabsConfig = (data: ConsumableMetric[]): TabsConfig[] =>
    data.map((consumable) => ({
      id: consumable.id,
      label: consumable.label,
      ContentComponent: (
        <SafeConsumableGraph
          isError={isError || !(consumable?.ranges?.length > 0)}
          isLoading={isLoading || isFetching}
          consumable={consumable}
        />
      ),
    }));

  if (!data?.data?.items) {
    return null;
  }

  return (
    <Section variant="color">
      <Typography variant="h5" gutterBottom>
        {t(CHANNELS.CONSUMABLES)}
      </Typography>
      <Box>{data?.data && <BaseTabsLayout config={generateTabsConfig(data.data.items)} mt={0} />}</Box>
    </Section>
  );
};

export default ConsumablesCard;
