import React from 'react';
import { HistoryEvent } from '../typings';
import { Box, CSSObject, Chip, Paper, PaperProps, Theme, Typography, styled, useTheme } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import dayjs from 'dayjs';

const opacityInMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
    delay,
  }),
  opacity: 1,
});

const opacityOutMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
    delay,
  }),
  opacity: 0,
});

const renderParam = (key: string, value: any | Array<any> | string, index: number) => {
  if (!value) {
    return <React.Fragment key={`${key}-${index}`}></React.Fragment>;
  }

  if (typeof value === 'string') {
    return <Chip key={`${key}-${index}`} label={`${key}: ${value}`} size="small" />;
  }

  if (typeof value === 'object' && (value as Array<any>).length > 0) {
    return (value as Array<any>).flatMap((value, i) =>
      Object.keys(value).map((k) => (
        <Chip key={`${key}-${index}-${k}-${i}`} label={`${key} - ${k}: ${value?.[k]}`} size="small" />
      ))
    );
  }

  return Object.keys(value).map((k, i) => (
    <Chip key={`${key}-${index}-${k}-${i}`} label={`${key} - ${k}: ${value[k]}`} size="small" />
  ));
};

const PARAMS_BLACKLIST = ['session'];

interface OpacityPaperProps extends PaperProps {
  animate?: boolean;
  delayIn?: number;
  delayOut?: number;
}

export const OpacityPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'animate' && prop !== 'delayIn' && prop !== 'delayOut',
})<OpacityPaperProps>(({ theme, animate, delayIn, delayOut }) => ({
  ...(animate && {
    '&': opacityInMixin(theme, delayIn),
  }),
  ...(!animate && {
    '&': opacityOutMixin(theme, delayOut),
  }),
}));

export default function EventCard({ event, color, animate }: { event: HistoryEvent; color: string; animate: boolean }) {
  const theme = useTheme();
  return (
    <OpacityPaper sx={{ p: 1, height: 'calc(100% - 10px)' }} animate={animate}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          gap: 1,
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              pb: 1,
              gap: 2,
            }}
          >
            <Chip icon={<TagIcon />} label={event.name} size="small" />
            <Box sx={{ display: 'flex' }}>
              <WatchLaterIcon style={{ color: theme.palette.primary.main }} />
              <Typography fontWeight="600">{dayjs(event.time).format('HH:mm')}</Typography>
            </Box>
          </Box>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              whiteSpace: 'nowrap',
              wordBreak: 'keep-all',
              fontWeight: '600',
            }}
          >
            <Box
              component={'span'}
              sx={{
                display: 'inline-block',
                backgroundColor: color,
                height: '10px',
                width: '10px',
                borderRadius: '50%',
              }}
            />
            {event.event?.name}
          </Typography>
          <Typography>{event.event?.description}</Typography>
        </Box>
        <Box display={'flex'} gap={1} width="100%" flexWrap="wrap">
          {Object.keys(event.params ?? {})
            .filter((k) => !PARAMS_BLACKLIST.includes(k))
            .map((key, index) => renderParam(key, event.params?.[key], index))}
        </Box>
      </Box>
    </OpacityPaper>
  );
}
