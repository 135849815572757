import { Column } from '@material-table/core';
import { Box, Button, Typography, capitalize } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConfigRes } from './typings';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { ROLES, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { isValidNumber } from '@/shared/utils';
import Area from '@/components/Shared/Card/Area';

export default function useConfigColumns(
  onEditClick?: Function,
  onDeleteClick?: Function,
  itemsInDeviceIdentity?: ConfigRes[]
): Array<Column<ConfigRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns_array: Array<Column<ConfigRes>> = [];

  return columns_array.concat([
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('editable'),
      field: 'editable',
      render: (config) => <Typography>{config.editable ? t('yes') : t('no')}</Typography>,
    },
    {
      title: t('type'),
      field: 'type',
      render: (config) => capitalize(config.type),
    },
    {
      title: t('string'),
      width: '15%',
      field: 'string',
      render: (config) =>
        config.string?.map((r, index) => (
          <Typography sx={{ width: 'max-content' }} key={`${r}-${index}`}>
            {t(r)}
          </Typography>
        )),
    },
    {
      title: t('fullRange'),
      render: (config) => {
        return (
          <Box>
            <RenderIf condition={config.fullRangeMin != null && isValidNumber(Number(config.fullRangeMin))}>
              <Typography sx={{ width: 'max-content' }}>Min: {config.fullRangeMin}</Typography>
            </RenderIf>
            <RenderIf condition={config.fullRangeMax != null && isValidNumber(Number(config.fullRangeMax))}>
              <Typography sx={{ width: 'max-content' }}>Max: {config.fullRangeMax}</Typography>
            </RenderIf>
          </Box>
        );
      },
    },
    {
      title: t('safeRange'),
      render: (config) => {
        if (!config?.safeRangeMax && !config?.safeRangeMin) {
          return <></>;
        }

        return (
          <Area
            sx={{ backgroundColor: (theme) => theme.palette.background.grayShades[0], p: 1, borderRadius: 1, gap: 0 }}
          >
            <RenderIf condition={config?.safeRangeMin != null && isValidNumber(Number(config.safeRangeMin))}>
              <Typography sx={{ width: 'max-content' }}>Min: {config.safeRangeMin}</Typography>
            </RenderIf>
            <RenderIf condition={config?.safeRangeMax != null && isValidNumber(Number(config.safeRangeMax))}>
              <Typography sx={{ width: 'max-content' }}>Max: {config.safeRangeMax}</Typography>
            </RenderIf>
          </Area>
        );
      },
    },
    {
      title: t('roles'),
      field: 'role',
      customFilterAndSearch: (filter, configs) => {
        const roles = configs?.role || [];
        const roleFilter = roles.some((role) => Object.values(ROLES).includes(role));

        return roleFilter && roles.some((role) => role.startsWith(filter));
      },
      render: (config) =>
        config.role?.map((r, index) => (
          <Typography sx={{ width: 'max-content' }} key={`${r}-${index}`}>
            {t(r)}
          </Typography>
        )),
      exportTransformer: (config) =>
        (config.role as string[])?.join ? (config.role as string[])?.join(', ') : config.role,
      sorting: false,
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (config) => dayjs(config.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (config) => dayjs(config.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '10%',
      render: (config) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          {!itemsInDeviceIdentity ? (
            <>
              <Button variant="outlined" color="primary" onClick={onEditClick && (() => onEditClick(config.id))}>
                {t('edit')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={onDeleteClick && (() => onDeleteClick({ id: config.id, name: config.name }))}
              >
                {t('delete')}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#config`, {
                  state: {
                    searchText: config.name,
                    highlight: {
                      refField: 'id',
                      matchValue: config.id,
                    },
                  },
                })
              }
            >
              {t('view')}
            </Button>
          )}
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ]);
}
