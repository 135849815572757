import { Box, ButtonBase, Paper, lighten, useTheme } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Operation } from '@/redux/slices/operationSlice';

type Props = {
  children: ReactNode;
  read: Operation['read'];
  status: Operation['status'];
  onClick: () => void;
  style: any;
};

const BaseOperation = forwardRef<HTMLDivElement, Props>(({ children, read, status, onClick, style }, ref) => {
  const theme = useTheme();
  const color = read ? theme.palette.background.grayShades[0] : lighten(theme.palette[status || 'primary'].light, 0.9);

  return (
    <Box style={style}>
      <ButtonBase
        ref={ref}
        component="div"
        sx={{
          display: 'block',
          textAlign: 'unset',
          '&:focus': { outline: 'none' },
          fontSize: '1rem',
        }}
      >
        <Paper elevation={0} sx={{ backgroundColor: color, height: '100%', cursor: 'pointer' }} onClick={onClick}>
          {children}
        </Paper>
      </ButtonBase>
    </Box>
  );
});

BaseOperation.displayName = 'BaseOperation';

export default BaseOperation;
