import React from 'react';
import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Podium from '../Shared/Podium';
import useStatisticsData from './useStatisticsData';
import withMissingDataManagement from './withMissingDataManagement';

const SafePodium = withMissingDataManagement(Podium);

export default function TopFlavorsCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filteredData } = useStatisticsData();

  const hasData = !!(
    filteredData &&
    filteredData.topThreeFlavorsResult &&
    filteredData.topThreeFlavorsResult.value.length > 0
  );

  return (
    <StatisticsCard
      variant={hasData ? 'unBoxed' : 'boxed'}
      title={t('topFlavors')}
      action={{
        label: t('details'),
        fn: () => navigate('/'),
      }}
    >
      <SafePodium
        hasData={hasData}
        data={filteredData?.topThreeFlavorsResult?.value.map((flavor) => ({
          label: flavor.label,
          value: flavor.value,
        }))}
        label={t('flavors')}
      />
    </StatisticsCard>
  );
}
