import { RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import router from './router/routes';
import { store, persistedStore } from './redux/store';
import './App.css';
import { useTranslation } from 'react-i18next';
import { setDayJsLocale } from './shared/utils';
import '@fontsource/roboto/400.css'; // font-weight: regular
import '@fontsource/roboto/500.css'; // font-weight: medium
import '@fontsource/roboto/700.css'; // font-weight: bold
import '@fontsource/roboto/400-italic.css'; // font-style: italic
import '@fontsource/roboto/700-italic.css'; // font-style: italic

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function App() {
  const { i18n } = useTranslation();
  setDayJsLocale(i18n.language);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale()}>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistedStore}>
          <RouterProvider router={router} />
        </PersistGate>
      </ReduxProvider>
    </LocalizationProvider>
  );
}

export default App;
