import { Box, BoxProps, CSSObject, Paper, PaperProps, Theme, alpha, styled } from '@mui/material';
import { ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { OpacityPaper } from './AnimatedPaper';
import { HistoryEventGroupedByDay } from '../typings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AnimatedControl from './AnimatedControl';
import useDebounce from '@/hooks/useDebounce';

const slideInMixin = (theme: Theme, delay?: number, index?: number): CSSObject => ({
  transition: theme.transitions.create(['transform', 'height', 'top', 'right', 'opacity', 'box-shadow'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
    delay,
  }),
  height: '100%',
  top: 0,
  right: 0,
  boxShadow: theme.shadows[0],
});

const slideOutMixin = (theme: Theme, delay?: number, index?: number): CSSObject => ({
  transition: theme.transitions.create(['transform', 'height', 'top', 'right', 'opacity', 'box-shadow'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.short,
    delay,
  }),
  height: `calc(100% - ${20 * ((index || 0) + 1)}px)`,
  top: `${10 * ((index || 0) + 1)}px`,
  right: `-${5 * ((index || 0) + 1)}px`,
  boxShadow: theme.shadows[1],
});

interface SlidingPaperProps extends PaperProps {
  animate?: boolean;
  delayIn?: number;
  delayOut?: number;
  index: number;
}

export const SlidingPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'animate' && prop !== 'delayIn' && prop !== 'delayOut' && prop !== 'index',
})<SlidingPaperProps>(({ theme, animate, delayIn, delayOut, index }) => ({
  position: 'absolute',
  width: '50px',
  ...(animate && {
    '&': slideInMixin(theme, delayIn, index),
  }),
  ...(!animate && {
    '&': slideOutMixin(theme, delayOut, index),
  }),
}));

const calculateMaximumScroll = (elm: HTMLElement) => {
  const rect = elm.getBoundingClientRect();

  return Math.floor(elm.scrollWidth - rect.width);
};

const DEFAULT_OPACITY_PAPER_WIDTH = 300;
const DELAY_UNIT = 60;

export default function AnimatedStack({
  children,
  animate,
  lockIn,
  totItems,
  event,
  events,
}: {
  children: ReactNode;
  animate: boolean;
  lockIn: boolean;
  totItems: number;
  event: HistoryEventGroupedByDay;
  events: ReactNode;
} & BoxProps) {
  const [showControls, setShowControls] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<'both' | 'left' | 'right' | 'none'>('none');
  const [maximumScroll, setMaximumScroll] = useState<number>(0);
  const [scrolledAmount, setScrolledAmount] = useState<number>(0);
  const [numberOfOpacityPapers, setNumberOfOpacityPapers] = useState<number>(4);

  const debounceSetScrolledAmount = useDebounce<number>(setScrolledAmount, 200);
  const scrollableRef = useRef<HTMLElement>();

  const handleScrollEvent = useCallback(() => {
    if (scrollableRef.current) {
      debounceSetScrolledAmount(scrollableRef.current.scrollLeft);
    }
  }, [debounceSetScrolledAmount]);

  const scrollUnit = useMemo(() => maximumScroll / 10, [maximumScroll]);

  const handleScrollClickRequest = useCallback(
    (direction: 'left' | 'right') => {
      setScrolledAmount(() => {
        if (!scrollableRef.current) {
          return 0;
        }

        if (direction === 'left') {
          return scrollableRef.current.scrollLeft - scrollUnit < 0 ? 0 : scrollableRef.current.scrollLeft - scrollUnit;
        }

        return scrollableRef.current.scrollLeft + scrollUnit > maximumScroll
          ? maximumScroll
          : scrollableRef.current.scrollLeft + scrollUnit;
      });
    },
    [maximumScroll, scrollUnit]
  );

  useLayoutEffect(() => {
    if (maximumScroll < 0) {
      setScrollDirection('none');
      return;
    }

    if (scrollableRef.current) {
      if (scrolledAmount !== scrollableRef.current?.scrollLeft) {
        scrollableRef.current.scroll({ left: scrolledAmount, behavior: 'smooth' });
      }

      if (scrolledAmount > 0 && scrolledAmount < maximumScroll) {
        setScrollDirection('both');
        return;
      }
      if (scrolledAmount < maximumScroll) {
        setScrollDirection('right');
        return;
      }

      if (scrolledAmount > 0) {
        setScrollDirection('left');
        return;
      }
    }
  }, [maximumScroll, scrollableRef, scrolledAmount]);

  useLayoutEffect(() => {
    if (scrollableRef.current) {
      setMaximumScroll(calculateMaximumScroll(scrollableRef.current));
    }
  }, []);

  useEffect(() => {
    if (scrollableRef.current) {
      const { width } = scrollableRef.current.getBoundingClientRect();
      const items = Math.round(width / (DEFAULT_OPACITY_PAPER_WIDTH + 16) + 1);
      setNumberOfOpacityPapers(items);
    }
  }, []);

  return (
    <Box sx={{ display: 'grid', width: '100%', gridTemplateColumns: 'auto 1fr', gridTemplateRows: 'auto' }}>
      <Box sx={{ position: 'relative', width: 'fit-content' }}>
        {new Array(totItems > 2 ? 2 : totItems).fill('').map((_, i, { length }) => (
          <SlidingPaper
            key={`stack-${i}`}
            animate={animate || lockIn}
            delayIn={(DELAY_UNIT / 2) * (i + 1)}
            delayOut={
              (numberOfOpacityPapers < totItems ? DELAY_UNIT * numberOfOpacityPapers : DELAY_UNIT * totItems) +
              ((DELAY_UNIT / 2) * length - (DELAY_UNIT / 2) * i)
            }
            index={length - i - 1}
          />
        ))}

        <Box sx={{ position: 'relative', zIndex: 2, height: '100%' }}>{children}</Box>
      </Box>
      <Box
        sx={{ width: '100%', overflow: 'hidden', height: 'calc(100% + 10px)', position: 'relative' }}
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            ref={scrollableRef}
            sx={{
              ml: 2,
              pl: 0.1,
              pt: 0.1,
              pr: 2,
              position: 'relative',
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              gap: 1,
              overflow: lockIn ? 'scroll' : 'hidden',
            }}
            {...(scrollDirection !== 'none' ? { onScroll: () => handleScrollEvent() } : {})}
          >
            {events}
          </Box>
          {lockIn && scrollDirection !== 'none' && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: 'calc(100% - 5px)',
                top: 0,
                left: 0,
                pl: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  pl: 2,
                  pr: 2,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundImage: (theme) =>
                    `linear-gradient(90deg, ${theme.palette.background.paper} 0%, ${alpha(
                      theme.palette.background.paper,
                      0
                    )} ${scrollDirection === 'both' || scrollDirection === 'left' ? 100 : 0}%);`,
                }}
              >
                <AnimatedControl
                  animate={showControls}
                  color="primary"
                  size="small"
                  sx={{
                    boxShadow: 2,
                    pointerEvents: 'all',
                  }}
                  disabled={scrollDirection !== 'left' && scrollDirection !== 'both'}
                  onClick={() => handleScrollClickRequest('left')}
                >
                  <ChevronLeftIcon />
                </AnimatedControl>
              </Box>
              <Box
                sx={{
                  height: '100%',
                  pl: 2,
                  pr: 2,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundImage: (theme) =>
                    `linear-gradient(90deg, ${alpha(theme.palette.background.paper, 0)} ${
                      scrollDirection === 'both' || scrollDirection === 'right' ? 0 : 100
                    }%, ${theme.palette.background.paper} 100%);`,
                }}
              >
                <AnimatedControl
                  animate={showControls}
                  color="primary"
                  size="small"
                  sx={{ boxShadow: 2, pointerEvents: 'all' }}
                  disabled={scrollDirection !== 'right' && scrollDirection !== 'both'}
                  onClick={() => handleScrollClickRequest('right')}
                >
                  <ChevronRightIcon />
                </AnimatedControl>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            pl: 2,
            display: 'flex',
            gap: 1,
            height: 'calc(100% - 10px)',
            flexWrap: 'nowrap',
            width: 'fit-content',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
            pointerEvents: 'none',
          }}
        >
          {new Array(numberOfOpacityPapers < totItems ? numberOfOpacityPapers : totItems).fill('').map((_, i, arr) => (
            <OpacityPaper
              sx={{
                backgroundColor: (theme) => theme.palette.background.grayShades[0],
                width: `${DEFAULT_OPACITY_PAPER_WIDTH}px`,
                height: '100%',
              }}
              animate={animate && !lockIn}
              lockIn={lockIn}
              delayIn={DELAY_UNIT * i}
              delayOut={DELAY_UNIT * (arr.length - 1) - DELAY_UNIT * i}
              key={'shadow' + i}
              elevation={0}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
