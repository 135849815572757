import { Box, Typography, useTheme, Switch, FormControlLabel, Chip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LineChartVictory from '../Shared/Charts/LineChartVictory';

type LineChardCardProps = {
  id: string;
  data: { x: string; y: number }[];
  isPercentage: boolean;
  bigNumberLabel: string | string[];
  isValueTogglable: boolean;
  smallLabel: string;
  handleToggleClick?: () => void;
  bigNumber: number;
  hasSmallBottomCard: boolean;
  smallNumber: number;
};

export default function LineChartCard({
  id,
  data,
  isPercentage,
  bigNumberLabel,
  isValueTogglable,
  smallLabel,
  handleToggleClick,
  bigNumber,
  smallNumber,
  hasSmallBottomCard = true,
}: LineChardCardProps) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const theme = useTheme();

  const currentRange = [...data].slice(data.length / 2);
  const previousRange = [...data].slice(0, data.length / 2);
  const currentRangeTotal = currentRange.reduce((prev, next) => next.y + prev, 0);
  const previousRangeTotal = previousRange.reduce((prev, next) => next.y + prev, 0);

  // percentuale media del periodo corrente meno media periodo precedente
  const trendNumber = currentRangeTotal / currentRange.length - previousRangeTotal / previousRange.length;
  const chartContainerRef = useRef<HTMLBaseElement | null>(null);

  return (
    <Box sx={{ height: '100%', display: 'flex', position: 'relative', gap: '0.5rem' }}>
      <Box
        sx={{
          width: '40%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          gap: '0.5rem',
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.grey[200],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            borderRadius: theme.shape.cardBorderRadius,
            p: 1,
            height: '100%',
            '@media print': {
              printColorAdjust: 'exact',
              WebkitPrintColorAdjust: 'exact',
              flexGrow: 1,
            },
          }}
        >
          <Typography variant="h4" fontWeight={900} sx={{ wordBreak: 'break-all' }}>
            {!isPercentage ? bigNumber.toFixed(1) : bigNumber.toFixed(0) + (isPercentage ? '%' : '')}
          </Typography>
          <Typography variant="h5" fontWeight={400}>
            {!isChecked ? bigNumberLabel[0] : bigNumberLabel[1]}
          </Typography>
          {!hasSmallBottomCard && (
            <Typography variant="body1" sx={{ fontSize: 13, textAlign: 'center', marginTop: '10px' }}>
              {smallNumber + ' ' + smallLabel}
            </Typography>
          )}
        </Box>
        {hasSmallBottomCard && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.grey[200],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '20%',
              width: '100%',
              borderRadius: theme.shape.cardBorderRadius,
              '@media print': {
                display: 'none',
              },
            }}
          >
            {isValueTogglable ? (
              <FormControlLabel
                value="bottom"
                control={
                  <Switch
                    checked={isChecked}
                    onChange={() => {
                      if (handleToggleClick) {
                        handleToggleClick();
                      }
                      setIsChecked((prev) => !prev);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    color="primary"
                  />
                }
                label={smallLabel}
                labelPlacement="start"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '0 0 0 10px',
                  width: '100%',
                  '& .MuiFormControlLabel-label': {
                    fontSize: 13,
                  },
                }}
              />
            ) : (
              <Typography variant="body1" sx={{ fontSize: 13, marginLeft: '16px', marginRight: '16px' }}>
                {`${100 - bigNumber}% ${smallLabel}`}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '60%',
          position: 'relative',
          backgroundColor: (theme) => theme.palette.grey[200],
          borderRadius: 1,
          '@media print': {
            printColorAdjust: 'exact',
            WebkitPrintColorAdjust: 'exact',
          },
        }}
      >
        <Chip
          sx={{
            position: 'absolute',
            left: 0,
            backgroundColor: `${trendNumber > 0 ? 'rgb(76 175 80 / 15%)' : 'rgb(239 83 80 / 15%)'}`,
            color: `${trendNumber > 0 ? theme.palette.success.light : theme.palette.error.light}`,
            borderRadius: 0,
            borderTopLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            fontSize: '14px',
            fontWeight: 900,
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            padding: '12px 0 12px 0',
            width: '90px',
            justifyContent: 'space-between',
            '& .MuiChip-label': {
              padding: 0,
              marginRight: '10px',
            },
          }}
          label={`${trendNumber.toFixed(1)}%`}
          icon={
            trendNumber > 0 ? (
              <ArrowDropUpIcon
                sx={{
                  marginRight: '10px',
                  marginLeft: '6px',
                  order: 0,
                  fill: theme.palette.success.light,
                  fontSize: '30px',
                }}
              />
            ) : (
              <ArrowDropDownIcon
                sx={{
                  marginRight: '10px',
                  marginLeft: '6px',
                  order: 0,
                  fill: theme.palette.error.light,
                  fontSize: '30px',
                }}
              />
            )
          }
        />
        <Box
          sx={{
            position: 'absolute',
            width: '50%',
            height: '100%',
            bgcolor: (theme) => theme.palette.grey[300],
            left: '50%',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            '@media print': {
              printColorAdjust: 'exact',
              WebkitPrintColorAdjust: 'exact',
            },
          }}
        >
          <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
            <Typography
              variant="body1"
              sx={{ mt: 1, textTransform: 'uppercase', fontSize: '11px', color: '#999' }}
              fontWeight={900}
            >
              {t('currentPeriod')}
            </Typography>
          </Box>
        </Box>
        <Box ref={chartContainerRef} sx={{ height: '100%', width: '100%' }}>
          <LineChartVictory data={data} chartContainerRef={chartContainerRef} />
        </Box>
      </Box>
    </Box>
  );
}
