import { Fragment, useState } from 'react';
import {
  FiltersBarProps,
  LocalAutocompleteFilterConfig,
  LocalDateRangeFilterConfig,
  LocalDateRangeWithFixedRangeFilterConfig,
  LocalRadioFilterConfig,
  StoreAsyncAutocompleteFilterConfig,
  StoreAutocompleteFilterConfig,
  StoreDateRangeFilterConfig,
  StoreDateRangeWithFixedRangeFilterConfig,
  StoreRadioFilterConfig,
} from './typings';
import Box from '@mui/material/Box';
import AutocompleteFilter from './AutocompleteFilter';
import RadioFilter from './RadioFilter';
import DateRangeFilter from './DateRangeFilter';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import DateRangeWithFixedRangesFilter from './DateRangeWithFixedRangesFilter';
import AutocompleteAsyncFilter from './AutocompleteAsyncFilter';

export default function FilterBar<T extends object>({
  filterConfigs,
  onFilterChange,
  kind,
  flexDirection = 'row',
  handleApplyFilters,
  handleClearAll,
  ...props
}: FiltersBarProps<T>) {
  const { t } = useTranslation();
  const [r, clear] = useState<number>(Math.random());

  const handleClearClick = () => {
    clear(Math.random());
    handleClearAll();
  };

  const renderFilters = () =>
    filterConfigs.map((config, index) => {
      switch (config.kind) {
        case 'autocomplete':
          return kind === 'local' ? (
            <AutocompleteFilter<T>
              kind="local"
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as LocalAutocompleteFilterConfig<T>}
              onFilterChange={onFilterChange}
            />
          ) : (
            <AutocompleteFilter<T>
              kind="store"
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as StoreAutocompleteFilterConfig}
              onFilterChange={onFilterChange}
            />
          );
        case 'radio':
          return kind === 'local' ? (
            <RadioFilter<T>
              kind={'local'}
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as LocalRadioFilterConfig<T>}
              onFilterChange={onFilterChange}
            />
          ) : (
            <RadioFilter<T>
              kind={'store'}
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as StoreRadioFilterConfig}
              onFilterChange={onFilterChange}
            />
          );
        case 'dateRange':
          return kind === 'local' ? (
            <DateRangeFilter<T>
              kind={'local'}
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as LocalDateRangeFilterConfig<T>}
              onFilterChange={onFilterChange}
            />
          ) : (
            <DateRangeFilter<T>
              kind={'store'}
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as StoreDateRangeFilterConfig}
              onFilterChange={onFilterChange}
            />
          );
        case 'dateRangeWithFixedRanges':
          return kind === 'local' ? (
            <DateRangeWithFixedRangesFilter<T>
              kind={'local'}
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as LocalDateRangeWithFixedRangeFilterConfig<T>}
              onFilterChange={onFilterChange}
            />
          ) : (
            <DateRangeWithFixedRangesFilter<T>
              kind={'store'}
              key={`${config.kind}${config.label}${index}${r}`}
              config={config as StoreDateRangeWithFixedRangeFilterConfig}
              onFilterChange={onFilterChange}
            />
          );
        case 'asyncAutocomplete': {
          return kind === 'local' ? (
            <Fragment />
          ) : (
            <AutocompleteAsyncFilter
              config={config as StoreAsyncAutocompleteFilterConfig}
              kind={'store'}
              onFilterChange={onFilterChange}
              key={`${config.kind}${config.label}${index}${r}`}
            />
          );
        }

        default:
          return <Typography key={`filterUnknown${index}`}>{t('unknownFilter')}</Typography>;
      }
    });

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      p={flexDirection === 'column' ? '0.5rem' : '1.25rem'}
      width={1}
      boxShadow={2}
      borderRadius={1}
      marginBottom={'2rem'}
      {...props}
    >
      <Box
        flex={1}
        display={'grid'}
        flexDirection={flexDirection}
        gridTemplateColumns="repeat(auto-fit, minmax(18rem, 1fr))"
        alignItems="end"
        gap="1em"
        pt={flexDirection === 'column' ? '0.5rem' : '0'}
      >
        {renderFilters()}
      </Box>
      <Box display="flex" justifyContent="flex-end" flexDirection="row" mt={2}>
        <Button id="filterClearButton" sx={{ alignSelf: 'center' }} variant="outlined" onClick={handleClearClick}>
          {t('clearAll')}
        </Button>
        <Button
          id="filterDataButton"
          sx={{ alignSelf: 'center', minWidth: '6rem', ml: '1rem' }}
          variant="contained"
          onClick={handleApplyFilters}
        >
          {t('filter')}
        </Button>
      </Box>
    </Box>
  );
}
