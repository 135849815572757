import DevicesWithoutCoordinatesList from '@/components/Shared/Lists/DevicesWithoutCoordinatesList';
import { Alert, AlertTitle, Box, Button, Collapse, Pagination, Paper, Stack, Typography } from '@mui/material';
import { ChangeEvent, Fragment, useState } from 'react';
import { Device } from './typings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

export type DeviceWithoutCoordinate = Omit<Device, 'lat' | 'lon'>;

const ExpandableWarningMessage = ({ missingDevicesCount }: { missingDevicesCount: number }) => {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();
  return (
    <Alert
      severity="warning"
      sx={{ marginTop: 4, position: 'relative', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      elevation={0}
      action={
        <Button
          variant="outlined"
          color="warning"
          onClick={() => setExpand(!expand)}
          endIcon={
            <ExpandMoreIcon
              sx={{
                transition: 'transform 0.18s ease-in-out',
                transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          }
        >
          {t('learnMore')}
        </Button>
      }
    >
      <AlertTitle>{t('whyAmIseeingThis')}</AlertTitle>
      <Collapse in={expand}>
        <Typography>
          {t('looksLike')}{' '}
          <Typography component={'span'} fontWeight={'bold'}>
            {t('devicesCount', { count: missingDevicesCount })}{' '}
          </Typography>
          {t('devicesMissingLocation')}
        </Typography>
      </Collapse>
    </Alert>
  );
};

const PanelPagination = ({
  totalPages,
  handlePageChange,
}: {
  totalPages: number;
  handlePageChange: (e: ChangeEvent<unknown>, page: number) => void;
}) => {
  return (
    <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} pb={2} pr={2} pl={2}>
      <Pagination onChange={handlePageChange} defaultPage={1} count={totalPages} showFirstButton showLastButton />
    </Stack>
  );
};

export default function DevicesWithoutLocationPanel({ devices }: { devices: DeviceWithoutCoordinate[] }) {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const itemsPerPage = 20;
  const totalPages = Math.ceil(devices.length / itemsPerPage);
  const handlePageChange = (e: ChangeEvent<unknown>, page: number) => setPage(page);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedDevices = devices?.slice(startIndex, endIndex);
  return (
    <Fragment>
      <Typography variant="h4" pt={4}>
        {t('devicesWithoutLocation')}
      </Typography>

      <Box>
        <Paper>
          <ExpandableWarningMessage missingDevicesCount={devices.length} />
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              padding: 2,
            }}
          >
            <DevicesWithoutCoordinatesList devices={paginatedDevices} />
          </Box>
          <PanelPagination totalPages={totalPages} handlePageChange={handlePageChange} />
        </Paper>
      </Box>
    </Fragment>
  );
}
