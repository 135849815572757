import { Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRequestResetPasswordMutation } from '../../../redux/api/authApiSlice';
import { selectAccessToken } from '../../../redux/slices/authSlice';
import useInputValidations from '@/hooks/useFormValidations';
import authErrorHandler from './authErrorHandler';

const ResetPasswordRequest = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const [emailSent, setEmailSent] = useState(false);
  const [requestResetPassword, { isLoading }] = useRequestResetPasswordMutation();

  const { t } = useTranslation();
  const validations = useInputValidations();
  const navigate = useNavigate();

  useEffect(() => watch((v) => clearErrors()).unsubscribe, [watch, clearErrors]);

  // Preventing logged in users from accessing the login page
  const accessToken = useSelector(selectAccessToken);
  if (accessToken) {
    return <Navigate to="/" />;
  }

  const onSubmit = (formData: { email: string }) => {
    requestResetPassword(formData.email)
      .unwrap()
      .then(() => setEmailSent(true))
      .catch((err) => authErrorHandler(err.status, setError, t));
  };

  return (
    <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Card sx={{ minWidth: 400, maxWidth: 600, py: 2 }}>
        <Box px={2}>
          <Typography variant="h4">Culligan One</Typography>
          {!emailSent && <Typography variant="subtitle1">{t('resetPasswordRequest')}</Typography>}
        </Box>
        {emailSent ? (
          <>
            <CardContent>
              <Typography whiteSpace="pre">{t('resetPasswordEmailSent')}</Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button onClick={() => navigate('/')}>{t('goBack')}</Button>
            </CardActions>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Box display="flex" flexDirection="column">
                <TextField
                  variant="outlined"
                  label="Email"
                  {...register('email', validations.email)}
                  {...(!!errors.email && { error: true, helperText: errors.email.message })}
                />
              </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
              <Box display="flex" flexDirection="column">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={isLoading ? <CircularProgress size={20} sx={{ color: 'gray' }} /> : null}
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  {t('resetPassword')}
                </Button>
                {!!errors.root && (
                  <Typography variant="caption" color="red">
                    {errors.root.message}
                  </Typography>
                )}
              </Box>
            </CardActions>
          </form>
        )}
      </Card>
    </Box>
  );
};

export default ResetPasswordRequest;
