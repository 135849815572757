import { OverviewFiltersResponse } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import { List, ListItem, ListItemText } from '@mui/material';
import dayjs from 'dayjs';

export default function PouringsPrintResult({
  pourings,
}: {
  pourings: OverviewFiltersResponse['pourings'] | undefined;
}) {
  return (
    <List
      sx={{
        display: 'none',
        '@media print': {
          display: 'flex',
          flexWrap: 'wrap',
        },
      }}
    >
      {pourings?.value.map((data) => (
        <ListItem dense key={data.id} style={{ width: 'auto' }}>
          <ListItemText
            primaryTypographyProps={{ fontSize: '8px' }}
            secondaryTypographyProps={{ fontSize: '8px' }}
            primary={dayjs(data.day).format('L LTS')}
            secondary={`${data.value} pourings`}
          />
        </ListItem>
      ))}
    </List>
  );
}
