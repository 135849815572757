import { Box, InputAdornment } from '@mui/material';
import { REGEX_COLOR } from './DialogInput';

export default function ColorInputAdornement({ color }: { color: string }) {
  const fallbackColor = '#FFFFFF';
  const _color = REGEX_COLOR.test(color) ? color : fallbackColor;

  return (
    <InputAdornment position="start">
      <Box width="1.25rem" height="1.25rem" bgcolor={_color} borderRadius="0.25rem" />
    </InputAdornment>
  );
}
