import { OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/one/device';
import { useTheme } from '@mui/material';

type ExtendedOperatingMode = OperatingMode | 'Reboot' | PowerProfile;

export const useOperatingModeColors: () => Record<ExtendedOperatingMode, string> = () => {
  const theme = useTheme();
  return {
    Standard: theme.palette.primary.dark,
    Factory: theme.palette.secondary.dark,
    Ota: theme.palette.warning.dark,
    Fault: theme.palette.error.dark,
    Disabled: theme.palette.error.dark,
    Startup: theme.palette.info.dark,
    Reboot: theme.palette.success.dark,
    Sleep: theme.palette.secondary.dark,
    Eco: theme.palette.primary.light,
    Installation: theme.palette.culliganBlue.dark,
    Service: theme.palette.culliganBlue.light,
  };
};
