import { Cached, Cancel, Delete } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { styled, useTheme } from '@mui/material/styles';
import { FileInputProps } from './typings';
import AddIcon from '@mui/icons-material/Add';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import useDownloadFile from '@/hooks/useDownloadFile';
import { useDropzone } from 'react-dropzone';

const ActionButton = styled(Button)({
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  borderRadius: 4,
});

const FileInput = ({ placeholder, configItem, value, onChange, getValues, ...props }: FileInputProps) => {
  const { t } = useTranslation();
  const [fileLoaded, setFileLoaded] = useState<File | string | null>(value);
  const [inputUntouched, setInputUntouched] = useState<boolean>(!!value || true);
  const [wrongFileType, setWrongFileType] = useState<Boolean | null>(null);

  const fileURL = useMemo(() => {
    if (fileLoaded instanceof File) {
      return URL.createObjectURL(fileLoaded);
    }

    try {
      if (fileLoaded !== null) {
        return new URL(fileLoaded as string, window.location.href).toString();
      }

      throw new Error();
    } catch {
      return fileLoaded;
    }
  }, [fileLoaded]);

  useEffect(() => {
    (wrongFileType || wrongFileType == null) &&
      setTimeout(() => {
        setWrongFileType(false);
      }, 1500);
  }, [wrongFileType]);

  const theme = useTheme();

  const { downloadFile } = useDownloadFile();

  const fileConfig = useMemo(() => configItem.fileConfig, [configItem.fileConfig]);
  const acceptedTypes = useMemo(
    () => configItem?.fileConfig?.accept?.split(',').map((type) => type.trim()) || [],
    [configItem?.fileConfig?.accept]
  );
  const getFileExtension = (url: string) => {
    const match = url.match(/\.([a-zA-Z0-9]+)(\?.*)?$/);
    return match?.[1] || '';
  };
  const fileExtension = fileLoaded instanceof File ? fileLoaded.type : fileURL ? getFileExtension(fileURL) : '';
  const acceptedType = acceptedTypes.find((type) => type.includes(fileExtension));

  const handleFileUplaod = useCallback(
    (ev: any) => {
      const file = ev.size ? ev : ev.target.files[0];
      if (acceptedTypes) {
        const correctType = acceptedTypes.some((type) => {
          const typeRgx = type.replace('*', '.*').replace('+', '\\+').replace('.', '\\.').replace('-', '\\-');

          return file.type.match(typeRgx);
        });
        if (!correctType) {
          setWrongFileType(true);
          return;
        }
      }
      setInputUntouched(false);
      setFileLoaded(file);
      onChange(file);
    },
    [acceptedTypes, onChange]
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      handleFileUplaod(acceptedFiles[0]);
    },
    [handleFileUplaod]
  );

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    multiple: fileConfig?.multiple,
  });

  return (
    <Box>
      <fieldset
        style={{
          borderWidth: 1,
          borderRadius: 4,
          borderColor: wrongFileType ? 'red' : 'rgba(0, 0, 0, 0.3)',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <legend
          style={{
            fontSize: '0.75rem',
            lineHeight: '1.4375em',
            color: wrongFileType ? 'red' : 'rgba(0, 0, 0, 0.6)',
            padding: '0 4px',
          }}
        >
          {placeholder}
        </legend>
        <Box
          id="dropZone"
          sx={{
            border: `1px dashed ${theme.palette.background.grayShades[0]}`,
            padding: 2,
            ...((!fileURL && { paddingTop: 4 }) || {}),
            borderRadius: 1,
            display: 'flex',
            ...((!inputUntouched && { display: 'none' }) || {}),
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5,
            transition: 'all ease-in-out .3s',
            ...((!fileURL && {
              '&:hover': {
                cursor: 'pointer',
              },
            }) ||
              {}),
            ...((!fileURL &&
              isDragActive && {
                borderColor: theme.palette.primary.main,
              }) ||
              {}),
          }}
          {...(!fileURL && {
            onClick: () => {
              inputRef.current?.click();
            },
          })}
          {...getRootProps()}
        >
          {fileConfig?.preview && fileURL && (
            <>
              {!fileConfig.preview.default && (
                <fileConfig.preview.Component
                  {...fileConfig.preview.props}
                  fileUrl={fileURL + (!fileURL?.startsWith('blob') ? '?dummy=' + Date.now() : '')}
                />
              )}
              {fileConfig.preview.default && (
                <img
                  alt={props.alt || 'fileInputPreview' + Date.now()}
                  src={fileURL + (!fileURL?.startsWith('blob') ? '?dummy=' + Date.now() : '')}
                  style={{ borderRadius: 4, maxWidth: '50%' }}
                />
              )}
            </>
          )}
          {inputUntouched && (
            <>
              <Box
                sx={{
                  borderRadius: '50%',
                  fontSize: theme.typography.h3,
                  border: '2px solid black',
                  height: 'fit-content',
                  width: 'fit-content',
                  display: 'flex',
                  pointerEvents: 'none',
                  '& *': {
                    pointerEvents: 'none',
                  },
                }}
              >
                <AddIcon />
              </Box>
              <Typography>Drag & drop or click to choose files</Typography>
              <Box sx={{ backgroundColor: theme.palette.background.grayShades[0], p: 1, borderRadius: 1 }}>
                <Typography variant="subtitle2" color="gray" fontSize="0.75rem">
                  {configItem?.helperText}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <input
          type="file"
          accept={configItem?.fileConfig?.accept || '*'}
          hidden
          {...getInputProps()}
          onChange={handleFileUplaod}
        />

        {wrongFileType ? (
          <Box display="flex" width="50%" justifyContent="space-around" p={2}>
            <Cancel color="error" />
            <Typography color="red">{t('wrongFileType')}</Typography>
          </Box>
        ) : (
          <>
            {fileURL && (
              <Box>
                {fileLoaded && (
                  <>
                    {fileURL.match(/\/([^/?#]+(?:\.[^/?#]+)?)(\?.*)?$/)?.[1] && (
                      <Box
                        display="flex"
                        sx={{
                          backgroundColor: theme.palette.background.grayShades[0],
                          padding: 2,
                          borderRadius: 1,
                          border: `1px solid ${theme.palette.background.grayShades[2]}`,
                          gap: 1,
                          alignItems: 'center',
                        }}
                      >
                        <Box>
                          <Box sx={{ position: 'relative', display: 'flex', height: 'auto' }}>
                            <FilePresentOutlinedIcon sx={{ fontSize: 32 }} color="primary" />
                          </Box>
                        </Box>
                        <Box>
                          <Typography fontWeight={'bold'} lineHeight={'100%'}>
                            {fileURL?.match(/\/([^/?#]+(?:\.[^/?#]+)?)(\?.*)?$/)?.[1]}
                          </Typography>
                          <Typography variant="subtitle1" lineHeight={'100%'}>
                            {acceptedType || fileExtension}
                          </Typography>
                        </Box>
                        <Box marginLeft={'auto'}>
                          <IconButton
                            onClick={() => {
                              const downloadAction = configItem.fileConfig?.actions?.find(
                                (action) => action.role === 'download'
                              );
                              if (downloadAction && downloadAction.callback) {
                                return downloadAction.callback(getValues);
                              }

                              downloadFile(fileURL.match(/\/([^/?#]+)(\?.*)?$/)?.[1] || 'download', fileURL);
                            }}
                          >
                            <DownloadOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                    <Box display="flex" mt={1} flexDirection={'row-reverse'} gap={1}>
                      <ActionButton
                        startIcon={<Delete color="error" />}
                        onClick={() => {
                          setFileLoaded(null);
                          onChange(null);
                          setInputUntouched(true);
                          if (inputRef.current) {
                            inputRef.current.value = '';
                          }
                        }}
                        color="error"
                      >
                        {t('remove')}
                      </ActionButton>
                      <ActionButton startIcon={<Cached />} onClick={() => inputRef.current?.click()}>
                        {t('change')}
                      </ActionButton>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </fieldset>
    </Box>
  );
};

export default FileInput;
