import useTypedSelector from '@/hooks/useTypedSelector';
import { themeSelector } from '@/redux/slices/themeSlice';
import { ThemeProvider, createTheme, darken, lighten, colors } from '@mui/material';
import { ReactNode, useEffect, useMemo } from 'react';
// You can see the palette's defaults at this link:
// https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette

declare module '@mui/system' {
  interface Shape {
    cardBorderRadius: string;
  }
}

export default function ThemeColors({ children }: { children: ReactNode }) {
  const themeSliceValue = useTypedSelector(themeSelector);

  useEffect(() => {
    if (document) {
      document.documentElement.style.setProperty('--primary', themeSliceValue.primary);
    }
  }, [themeSliceValue.primary]);

  let theme = createTheme({
    palette: {
      mode: themeSliceValue.mode,
      primary: {
        main: themeSliceValue.primary,
      },
      secondary: {
        main: themeSliceValue.secondary,
      },
      background: {
        grayShades:
          themeSliceValue.mode === 'dark'
            ? [
                lighten(themeSliceValue.background, 0.2),
                lighten(themeSliceValue.background, 0.3),
                lighten(themeSliceValue.background, 0.4),
              ]
            : [
                darken(themeSliceValue.background, 0.05),
                darken(themeSliceValue.background, 0.1),
                darken(themeSliceValue.background, 0.2),
              ],
      },
    },
    transitions: {
      easing: {
        bounce: 'cubic-bezier(0.335, 0.000, 0.390, 2.000)',
      },
    },
    typography: {
      fontFamily: 'Manrope, Roboto, Arial, sans-serif',
    },
  });

  theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeSliceValue.mode,
          primary: {
            main: themeSliceValue.primary,
          },
          secondary: {
            main: themeSliceValue.secondary,
          },
          culliganBlue: {
            main: themeSliceValue.primary,
          },
          caution: {
            main: colors.yellow[500],
            light: colors.yellow[300],
            dark: colors.yellow[700],
            contrastText: theme.palette.getContrastText('#fff'),
          },
          background: {
            grayShades:
              themeSliceValue.mode === 'dark'
                ? [
                    lighten(themeSliceValue.background, 0.2),
                    lighten(themeSliceValue.background, 0.3),
                    lighten(themeSliceValue.background, 0.4),
                  ]
                : [
                    darken(themeSliceValue.background, 0.05),
                    darken(themeSliceValue.background, 0.1),
                    darken(themeSliceValue.background, 0.2),
                  ],
            podium: {
              gold: '#FFD700',
              silver: '#C0C0C0',
              bronze: '#CD7F32',
            },
          },
        },
        shape: {
          cardBorderRadius: '0.5rem',
        },
        typography: {
          fontFamily: 'Manrope, Roboto, Arial, sans-serif',
        },
      }),
    [
      themeSliceValue.background,
      themeSliceValue.mode,
      themeSliceValue.primary,
      themeSliceValue.secondary,
      theme.palette,
    ]
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
