import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import {
  DeviceHistoryTelemetryRequest,
  HistoryTelemetry,
} from '@/components/Fleet/Devices/DevicePanel/TelemetryPanel/typings';

const Commands = {
  telemetry: ['get', 'post'],
} as const;

type CommandKeys = keyof typeof Commands;
type CommandValues = (typeof Commands)[CommandKeys][number];
export type CommandType = `${CommandKeys}.${CommandValues}`;

export type CommandResponse<CommandType> = {
  status: number;
  body: {
    success: boolean;
    data: {
      payload: {
        pVer: string;
        channel: 'command';
        command: CommandType;
        fingerprint: string;
      };
      result: {
        status: number;
        payload: {
          success: boolean;
        } & {
          [key in string]: boolean | string | number;
        };
      };
    };
  };
};

export type LatestTelemetryUpdate = {
  lastUpdate: number;
};

const URL_PREFIX = `${API_VERSION.v2}/admin/device/telemetry`;

export const FleetTelemetryApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetTelemetry', 'Fleet'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getFilteredTelemetry: builder.query<ApiResult<{ items: HistoryTelemetry[] }>, DeviceHistoryTelemetryRequest>({
        query: (params) => {
          const queryParams = new URLSearchParams();
          Object.keys(params).forEach((key) => {
            if (key !== 'keys') {
              queryParams.append(key, String(params[key as keyof DeviceHistoryTelemetryRequest]));
              return;
            }
          });
          return {
            url: `${API_VERSION.v2}/fleet/telemetry/history?${
              queryParams.toString() + params.keys.reduce((acc, key) => `${acc}&keys[]=${key}`, '')
            }`,
          };
        },
        providesTags: ['FleetTelemetry'],
      }),
      requestTelemetryUpdate: builder.mutation<ApiResult<CommandResponse<CommandType>>, string>({
        query: (deviceId) => ({
          url: `${URL_PREFIX}/sendUpdateRequest`,
          method: 'POST',
          body: { serialNumber: deviceId },
        }),
        invalidatesTags: ['FleetTelemetry'],
      }),
      getLatestTelemetryUpdate: builder.query<ApiResult<LatestTelemetryUpdate>, string>({
        query: (serialNumber) => {
          return {
            url: `${URL_PREFIX}/lastUpdate?serialNumber=${serialNumber}`,
          };
        },
      }),
    }),
  });

export const {
  useLazyGetFilteredTelemetryQuery,
  useRequestTelemetryUpdateMutation,
  useLazyGetLatestTelemetryUpdateQuery,
} = FleetTelemetryApiSlice;
