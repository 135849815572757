import { CSSObject, Paper, PaperProps, Theme, styled } from '@mui/material';

const zoomInMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['transform'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.short,
  }),
  transform: ['scale(1)'],
});

const zoomOutMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['transform'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.short,
    delay,
  }),
  transform: ['scale(1)'],
});

interface ZoomPaperProps extends PaperProps {
  animate?: boolean;
  delay?: number;
}

export const ZoomingPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'animate' && prop !== 'delay',
})<ZoomPaperProps>(({ theme, animate, delay }) => ({
  transformOrigin: 'center left',
  ...(animate && {
    '&': zoomInMixin(theme),
  }),
  ...(!animate && {
    '&': zoomOutMixin(theme, delay),
  }),
}));

const opacityInMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity', 'transform'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.short,
    delay,
  }),
  opacity: 1,
  transform: ['translateX(0)'],
});

const opacityOutMixin = (theme: Theme, delay?: number, lockIn?: boolean): CSSObject => ({
  transition: theme.transitions.create(['opacity', 'transform'], {
    easing: theme.transitions.easing.bounce,
    duration: !lockIn ? theme.transitions.duration.short : 0,
    delay: !lockIn ? delay : 0,
  }),
  opacity: 0,
  transform: [`translateX(${!lockIn ? '-10%' : '0'})`],
});

interface OpacityPaperProps extends PaperProps {
  animate?: boolean;
  delayIn?: number;
  delayOut?: number;
  lockIn?: boolean;
}

export const OpacityPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'animate' && prop !== 'delayIn' && prop !== 'delayOut' && prop !== 'lockIn',
})<OpacityPaperProps>(({ theme, animate, delayIn, delayOut, lockIn }) => ({
  ...(animate && {
    '&': opacityInMixin(theme, delayIn),
  }),
  ...(!animate && {
    '&': opacityOutMixin(theme, delayOut, lockIn),
  }),
}));
