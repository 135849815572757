import { OLDER_UNIQUE_ID, Operation, TODAY_UNIQUE_ID } from '@/redux/slices/operationSlice';
import { END_REACHED_UNIQUE_ID } from './EndReached';

export const NOTIFICATION_HEIGHT = 110;
export const NOTIFICATION_VERTICAL_GUTTER = 10;
export const COMPUTED_NOTIFICATION_HEIGHT = NOTIFICATION_HEIGHT + NOTIFICATION_VERTICAL_GUTTER;

export const TODAY_OPERATION: Operation = {
  uniqueId: TODAY_UNIQUE_ID,
  operationId: TODAY_UNIQUE_ID,
  state: 'fulfilled',
  status: 'info',
  timestamp: 0,
  showed: false,
  read: false,
  location: '',
  tag: 'ota-update',
  entity: '',
  subject: '',
};
export const OLDER_OPERATION: Operation = {
  uniqueId: OLDER_UNIQUE_ID,
  operationId: OLDER_UNIQUE_ID,
  state: 'fulfilled',
  status: 'info',
  timestamp: 0,
  showed: false,
  read: false,
  location: '',
  tag: 'ota-update',
  entity: '',
  subject: '',
};
export const END_REACHED_OPERATION: Operation = {
  uniqueId: END_REACHED_UNIQUE_ID,
  operationId: END_REACHED_UNIQUE_ID,
  state: 'fulfilled',
  status: 'info',
  timestamp: 0,
  showed: false,
  read: false,
  location: '',
  tag: 'ota-update',
  entity: '',
  subject: '',
};
