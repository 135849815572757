import { CSSObject, Fab, FabProps, Theme, styled } from '@mui/material';

const animateInMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity', 'transform', 'all'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.short,
    delay,
  }),
  opacity: 1,
  transform: ['scale(1)'],
});

const animateOutMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity', 'transform', 'all'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.short,
    delay,
  }),
  opacity: 0,
  transform: ['scale(0)'],
});

interface AnimatedFabProps extends FabProps {
  animate?: boolean;
}

const AnimatedControl = styled(Fab, {
  shouldForwardProp: (prop) => prop !== 'animate',
})<AnimatedFabProps>(({ theme, animate }) => ({
  ...(animate && {
    '&': animateInMixin(theme),
  }),
  ...(!animate && {
    '&': animateOutMixin(theme),
  }),
}));

export default AnimatedControl;
