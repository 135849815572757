import { Box, Divider, Theme, Typography, alpha } from '@mui/material';
import TelemetryHeaderCard from './TelemetryHeaderCard';
import { useTranslation } from 'react-i18next';
import { BaseDeviceTelemetry, DeviceTelemetryWithNumber } from '../typings';
import { isValidNumber } from '@/shared/utils';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';

function parseNumber(num: number) {
  function _parseNumber(absNum: number, maxAbsValue: number, sign: number) {
    const exceedDomain = absNum > maxAbsValue;
    const decimalsAvailable = maxAbsValue.toString().length - 2 - absNum.toString().split('.')[0].length;
    const num =
      // parse a floating number string to number
      parseFloat(
        // reduce number of decimals
        Math.abs(absNum).toFixed(
          // reduce the number of decimals to
          // "decimalsAvailable" if "decimalsAvailable"
          // greater or equal to 0
          // otherwise reduce the number of decimals to 0
          decimalsAvailable * Number(decimalsAvailable >= 0)
        )
        // keep the parsedFloat number only if it doesn't exceed
        // MAX_VALUE
      ) *
        Number(!exceedDomain) +
      // if num exceed MAX_VALUE then replace it with
      // MAX_VALUE (9_999_999)
      maxAbsValue * Number(exceedDomain);

    return `${sign === 1 && exceedDomain ? '>' : ''}${sign === -1 && exceedDomain ? '<' : ''} ${num * sign}`;
  }

  if (num < 0) {
    const maxAbsValue = 10 ** 6 - 1;
    return _parseNumber(Math.abs(num), maxAbsValue, -1);
  }

  const maxAbsValue = 10 ** 7 - 1;
  return _parseNumber(num, maxAbsValue, 1);
}

export default function TelemetryNumberCard({
  telemetry,
  double = false,
  group = false,
}: {
  telemetry: BaseDeviceTelemetry & DeviceTelemetryWithNumber;
  double?: boolean;
  group?: boolean;
}) {
  const { t } = useTranslation();

  const isTelemetryInRange = (
    telemetry: BaseDeviceTelemetry & DeviceTelemetryWithNumber,
    { hasMinThreshold, hasMaxThreshold }: { hasMinThreshold: boolean; hasMaxThreshold: boolean; hasValidValue: boolean }
  ) => {
    if (!hasValidValue) {
      return true;
    }

    if (!hasMinThreshold && !hasMaxThreshold) {
      return true;
    }

    if (hasMinThreshold && !hasMaxThreshold) {
      return Number(telemetry?.min) <= Number(telemetry?.value);
    }

    if (!hasMinThreshold && hasMaxThreshold) {
      return Number(telemetry?.value) <= Number(telemetry?.max);
    }

    return Number(telemetry?.min) <= Number(telemetry?.value) && Number(telemetry?.value) <= Number(telemetry?.max);
  };
  const _telemetry = telemetry as BaseDeviceTelemetry & DeviceTelemetryWithNumber;
  const hasValidValue = _telemetry?.value != null && isValidNumber(Number(_telemetry?.value));
  const hasMinThreshold = _telemetry?.min != null && isValidNumber(Number(_telemetry?.min));
  const hasMaxThreshold = _telemetry?.max != null && isValidNumber(Number(_telemetry?.max));
  const isInRange = isTelemetryInRange(_telemetry, {
    hasMinThreshold,
    hasMaxThreshold,
    hasValidValue,
  });

  const errorGradient = (theme: Theme) =>
    `radial-gradient(85.17% 85.17% at 0% 0%, ${alpha(theme.palette.error.light, 0.2)} 0%, ${alpha(
      theme.palette.background.grayShades[0],
      0.2
    )} 100%), ${theme.palette.background.grayShades[0]}`;
  return (
    <Box
      className="telemetry-card"
      sx={(theme) => ({
        gridColumn: double ? 'span 2' : 'span 1',
        aspectRatio: !double ? '1/1' : 'unset',
        padding: 2,
        width: '100%',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.shape.cardBorderRadius,
        background: !isInRange ? errorGradient : theme.palette.background.grayShades[0],
      })}
    >
      <TelemetryHeaderCard name={_telemetry.name} id={_telemetry.id} group={group} />

      <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} flexGrow={1}>
        <Box>
          <Box display={'flex'} alignItems={'end'}>
            <Typography
              variant="h3"
              fontWeight={'bold'}
              lineHeight={1}
              {...(!isInRange
                ? {
                    sx: {
                      background: (theme) =>
                        `linear-gradient(140deg, ${theme.palette.warning.light} 0%,` +
                        ` ${theme.palette.error.light} 100%);`,
                      backgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    },
                  }
                : {})}
            >
              {typeof _telemetry?.value === 'number' ? parseNumber(_telemetry?.value) : t('notAvailable')}
            </Typography>
            <Typography
              variant="body1"
              component="span"
              fontStyle={'italic'}
              fontWeight={'bold'}
              sx={(theme) => ({ color: theme.palette.grey[600] })}
            >
              {_telemetry?.unitOfMeasure}
            </Typography>
          </Box>
          <Typography variant="caption" fontStyle={'italic'} display={'block'} margin={'10px 0'}>
            {/* {t('lastUpdated')}: 
            {_telemetry?.lastUpdate ? dayjs(_telemetry?.lastUpdate).fromNow() : t('notAvailable')} */}
          </Typography>
        </Box>

        <RenderIf condition={hasMinThreshold || hasMaxThreshold}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              backgroundColor: 'white',
              fontSize: '12px',
              borderRadius: theme.shape.cardBorderRadius,
              padding: 1,
            })}
          >
            <RenderIf condition={hasMinThreshold}>
              <Box>
                {t('min')}{' '}
                <Typography fontWeight={'bold'} fontSize="12px" display={'inline'}>
                  {_telemetry?.min}
                </Typography>
              </Box>
            </RenderIf>
            <RenderIf condition={hasMinThreshold && hasMaxThreshold}>
              <Divider orientation="vertical" flexItem />
            </RenderIf>
            <RenderIf condition={hasMaxThreshold}>
              <Box>
                {t('max')}{' '}
                <Typography fontWeight={'bold'} fontSize="12px" display={'inline'}>
                  {_telemetry?.max}
                </Typography>
              </Box>
            </RenderIf>
          </Box>
        </RenderIf>
      </Box>
    </Box>
  );
}
