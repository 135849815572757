import { ApiResult } from '@typings';
import { TelemetryReq, TelemetryRes } from '@/components/OneTools/Channels/Telemetry/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { CHANNELS } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/telemetry`;

export const TelemetryApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Telemetry'] }).injectEndpoints({
  endpoints: (builder) => ({
    getTelemetryEntries: builder.query<ApiResult<{ items: TelemetryRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Telemetry'],
    }),
    getTelemetryEntry: builder.query<ApiResult<TelemetryRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      transformResponse: (response: ApiResult<TelemetryRes>) => {
        if (!response.data) {
          return response;
        }
        if (response.data?.primitive === 'boolean') {
          let newTriageValueKey = response.data.triageExpectedValue;
          delete response.data.triageExpectedValue;
          response.data.triageExpectedBooleanValue = newTriageValueKey;
        }
        if (response.data?.primitive === 'string') {
          let newTriageValueKey = response.data.triageExpectedValue;
          delete response.data.triageExpectedValue;
          response.data.triageExpectedStringValue = newTriageValueKey;
        }
        return response;
      },
      providesTags: ['Telemetry'],
    }),
    isTelemetryUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
    postTelemetry: builder.mutation<ApiResult<TelemetryRes>, TelemetryReq>({
      query: (body: TelemetryReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Telemetry'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: CHANNELS.TELEMETRY,
        });
      },
    }),
    putTelemetry: builder.mutation<ApiResult<TelemetryRes>, { id: string; body: TelemetryReq }>({
      query: ({ id, body }: { id: string; body: TelemetryReq }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Telemetry'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: CHANNELS.TELEMETRY,
        });
      },
    }),
    patchTelemetry: builder.mutation<
      ApiResult<TelemetryRes>,
      { id: string; body: Partial<TelemetryReq>; name: string }
    >({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Telemetry'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: CHANNELS.TELEMETRY,
        });
      },
    }),
    deleteTelemetry: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['Telemetry'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: CHANNELS.TELEMETRY,
        });
      },
    }),
  }),
});

export const {
  useLazyGetTelemetryEntriesQuery,
  useGetTelemetryEntriesQuery,
  useLazyGetTelemetryEntryQuery,
  useLazyIsTelemetryUniqueQuery,
  usePostTelemetryMutation,
  usePutTelemetryMutation,
  usePatchTelemetryMutation,
  useDeleteTelemetryMutation,
} = TelemetryApiSlice;
