import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { DeviceCommandsApiSlice } from '../api/admin/deviceCommandsApiSlice';
import { OTAApiSlice } from '../api/admin/otaApiSlice';
import { FleetTelemetryApiSlice } from '../api/fleet/telemetryApiSlice';
import { OTAReq } from '@/components/OneTools/Ecosystem/OTA/typings';
import { getPowerProfileFromCommand } from '@/shared/validations';
import { LogLevel, OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/one/device';
import {
  Command,
  Reboot,
  OTA,
  GetTelemetry,
  SetLogLevel,
  SetPowerProfileSleep,
  SetPowerProfileStandard,
  SetOperatingMode,
} from '@culligan-iot/domain/culligan/one/command';

type RequestedOTACommand = {
  command: OTA['command'];
  payload: OTAReq['version'];
};
type RequestedOperatingModeCommand = {
  command: SetOperatingMode['command'];
  payload: Extract<OperatingMode, 'Disabled' | 'Standard'>;
};
type RequestedPowerProfileCommand = {
  command: SetPowerProfileSleep['command'] | SetPowerProfileStandard['command'];
  payload: PowerProfile;
};
type RequestedLogLevelCommand = {
  command: SetLogLevel['command'];
  payload: LogLevel;
};
type RequestedTelemetryUpdateCommand = {
  command: GetTelemetry['command'];
};
type RequestedRebootCommand = {
  command: Reboot['command'];
};

type RequestedCommmand =
  | RequestedOTACommand
  | RequestedOperatingModeCommand
  | RequestedPowerProfileCommand
  | RequestedLogLevelCommand
  | RequestedTelemetryUpdateCommand
  | RequestedRebootCommand;

type DeviceState = {
  online: boolean;
  pendingCommands: Array<RequestedCommmand>;
  operatingMode: OperatingMode;
};

type DevicesState = {
  [serialNumber: Device['serialNumber']]: DeviceState;
};
const reboot = 'reboot';
const initialState = {};

const deviceSlice = createSlice({
  name: 'devicesSlice',
  initialState: initialState,
  reducers: {
    addDevice: (
      state: DevicesState,
      action: PayloadAction<{ serialNumber: Device['serialNumber']; payload: DeviceState }>
    ) => {
      const serialNumber = action.payload.serialNumber;
      return { ...state, [serialNumber]: action.payload.payload };
    },
    upsertDevice: (
      state: DevicesState,
      action: PayloadAction<{ serialNumber: Device['serialNumber']; payload: DeviceState }>
    ) => {
      const hasDevice = state[action.payload.serialNumber] != null;
      const serialNumber = action.payload.serialNumber;
      return hasDevice
        ? {
            ...state,
            [serialNumber]: { ...state[serialNumber], ...action.payload.payload },
          }
        : { ...state, [serialNumber]: action.payload.payload };
    },
    addPendingCommand: (
      state: DevicesState,
      action: PayloadAction<{ serialNumber: Device['serialNumber']; command: RequestedCommmand }>
    ) => {
      const serialNumber = action.payload.serialNumber;
      const command = action.payload.command;
      const device = state[serialNumber];
      return {
        ...state,
        [serialNumber]: {
          ...device,
          pendingCommands: [command, ...device.pendingCommands],
        },
      };
    },
    removePendingCommand: (
      state: DevicesState,
      action: PayloadAction<{
        serialNumber: Device['serialNumber'];
        command: Command['command'];
      }>
    ) => {
      const serialNumber = action.payload.serialNumber;
      const command = action.payload.command;
      const device = state[serialNumber];
      return {
        ...state,
        [serialNumber]: {
          ...device,
          pendingCommands: device.pendingCommands.filter((c) => c.command !== command),
        },
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(OTAApiSlice.endpoints.postOTAJob.matchPending, (state: DevicesState, action) => {
      const { targetFilterParams, version } = action.meta.arg.originalArgs;
      const serialNumber = targetFilterParams.serialNumbers[0];
      const requestedCommand: RequestedOTACommand = { command: 'ota.update', payload: version };
      state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
    });
    builder.addMatcher(OTAApiSlice.endpoints.postOTAJob.matchRejected, (state: DevicesState, action) => {
      const { targetFilterParams } = action.meta.arg.originalArgs;
      const serialNumber = targetFilterParams.serialNumbers[0];
      state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
        (c) => c.command !== 'ota.update'
      );
    });
    builder.addMatcher(
      FleetTelemetryApiSlice.endpoints.requestTelemetryUpdate.matchPending,
      (state: DevicesState, action) => {
        const serialNumber: Device['serialNumber'] = action.meta.arg.originalArgs;
        const requestedCommand: RequestedTelemetryUpdateCommand = { command: 'telemetry.get' };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      FleetTelemetryApiSlice.endpoints.requestTelemetryUpdate.matchRejected,
      (state: DevicesState, action) => {
        const serialNumber: Device['serialNumber'] = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== 'telemetry.get'
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postLogLevelCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber, logLevel } = action.meta.arg.originalArgs;
        const requestedCommand: RequestedLogLevelCommand = { command: 'log.set', payload: logLevel };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postLogLevelCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== 'log.set'
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postOperatingModeCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber, operatingMode } = action.meta.arg.originalArgs;
        const requestedCommand = {
          command: 'operating_mode.set',
          payload: operatingMode,
        } as RequestedOperatingModeCommand;
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postOperatingModeCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== 'operating_mode.set'
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postPowerProfileCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        const command =
          action.meta.arg.originalArgs.powerProfile === 'Standard' ? 'power.profile.standard' : 'power.profile.sleep';
        const requestedCommand: RequestedPowerProfileCommand = {
          command: command,
          payload: getPowerProfileFromCommand(command),
        };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postPowerProfileCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter(
          (c) => c.command !== 'power.profile.sleep' && c.command !== 'power.profile.standard'
        );
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postRebootCommand.matchPending,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        const requestedCommand: RequestedRebootCommand = {
          command: 'reboot',
        };
        state[serialNumber].pendingCommands = [...new Set([requestedCommand, ...state[serialNumber].pendingCommands])];
      }
    );
    builder.addMatcher(
      DeviceCommandsApiSlice.endpoints.postRebootCommand.matchRejected,
      (state: DevicesState, action) => {
        const { serialNumber } = action.meta.arg.originalArgs;
        state[serialNumber].pendingCommands = state[serialNumber].pendingCommands.filter((c) => c.command !== reboot);
      }
    );
  },
});

export const selectDevice = createSelector(
  (state: AppState) => state.devices,
  (_: AppState, serialNumber: Device['serialNumber']) => serialNumber,
  (devices: DevicesState, serialNumber: Device['serialNumber']) => devices[serialNumber] || null
);
export const selectPendingCommands = createSelector(
  (state: AppState) => state.devices,
  (_: AppState, serialNumber: Device['serialNumber']) => serialNumber,
  (devices: DevicesState, serialNumber: Device['serialNumber']) => devices[serialNumber]?.pendingCommands || []
);
export const selectPendingOperatingMode = createSelector(selectPendingCommands, (commands) =>
  commands.find((c) => c.command === 'operating_mode.set' || c.command === 'reboot')
);
export const selectPendingOTAUpdate = createSelector(selectPendingCommands, (commands) =>
  commands.find((c) => c.command === 'ota.update')
);
export const selectHasPendingOperatingMode = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === 'operating_mode.set' || c.command === 'reboot')
);
export const selectHasPendingOTAUpdate = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === 'ota.update')
);
export const selectHasPendingPowerProfile = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === 'power.profile.standard' || c.command === 'power.profile.sleep')
);
export const selectHasPendingLogLevel = createSelector(selectPendingCommands, (commands) =>
  commands.some((c) => c.command === 'log.set')
);
export const { addDevice, upsertDevice, addPendingCommand, removePendingCommand } = deviceSlice.actions;
export default deviceSlice.reducer;
