import { Button, Typography } from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ExpandableField({
  content,
  maxItems = 20,
  ItemsContainer = Typography,
}: {
  content: string | ReactNode[];
  maxItems?: number;
  ItemsContainer?: React.FC<{ children: ReactNode }>;
}) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { t } = useTranslation();

  if (content.length <= maxItems) {
    return <ItemsContainer>{content}</ItemsContainer>;
  }

  return (
    <ItemsContainer>
      {isCollapsed && content.slice(0, maxItems)}
      {!isCollapsed && content}
      {isCollapsed && (
        <Button variant="text" size="small" onClick={() => setIsCollapsed(false)}>
          {t('expand')}
        </Button>
      )}
      {!isCollapsed && (
        <Button variant="text" size="small" onClick={() => setIsCollapsed(true)}>
          {t('collapse')}
        </Button>
      )}
    </ItemsContainer>
  );
}
