import React, { useState } from 'react';
import { Slice, SliceProps } from 'victory';
import ExtendendVictoryAnimation from './TypedVictoryAnimation';

export default function AnimatedSlice(initialProps: SliceProps) {
  const { radius: _radius } = initialProps;
  const initialRadiusValue = typeof _radius === 'number' ? _radius : (_radius && _radius(initialProps)) || 100;
  const [radius, setRadius] = useState(initialRadiusValue);
  return (
    <ExtendendVictoryAnimation duration={100} data={{ radius }}>
      {(style) => {
        return (
          <Slice
            {...initialProps}
            radius={style.radius as number}
            events={{
              onMouseOver: () => {
                setRadius(initialRadiusValue * 1.1);
              },
              onMouseOut: () => {
                setRadius(initialRadiusValue);
              },
            }}
          />
        );
      }}
    </ExtendendVictoryAnimation>
  );
}
