import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { SlideDiv } from '@/components/Shared/SlidingDiv';

export default function SuccessFeedback({ success }: { success: boolean }) {
  const { t } = useTranslation();
  const [hideSuccess, setHideSuccess] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    setHideSuccess(false);

    if (success) {
      timeoutId = setTimeout(() => setHideSuccess(true), 3000);
    }

    return () => clearTimeout(timeoutId);
  }, [success]);
  return (
    <SlideDiv
      slide={success && !hideSuccess}
      sx={{
        position: 'absolute',
        zIndex: -1,
        top: 100,
        left: 0,
        right: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          p: 1,
          transform: 'translateY(-80%)',
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: (theme) => theme.palette.success.light,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          paddingBottom={1}
          gap={1}
          sx={{ color: (theme) => theme.palette.getContrastText(theme.palette.error.light) }}
        >
          <ThumbUpIcon sx={{ fontSize: 12 }} />
          <Typography variant="caption" sx={{ lineHeight: 1, display: 'inline-block' }} color="inherit">
            {t('success')}
          </Typography>
        </Box>
      </Box>
    </SlideDiv>
  );
}
