import { useLazyGetPasswordPolicyQuery } from '@/redux/api/authApiSlice';
import { useEffect, useState } from 'react';

export default function usePasswordPolicy() {
  const [getPasswordPolicy] = useLazyGetPasswordPolicyQuery();
  const [policyUpdated, setPolicyUpdated] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('passwordPolicy')) {
      getPasswordPolicy().then(({ data }) => {
        if (data) {
          sessionStorage.setItem('passwordPolicy', JSON.stringify(data.data));
          setPolicyUpdated(true);
        }
      });
    }
  }, [getPasswordPolicy, setPolicyUpdated]);

  return policyUpdated;
}
