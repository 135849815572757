import { ActionReducerMapBuilder, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlarmsApiSlice } from '../api/system/alarmsApiSlice';
import { AlarmsApiSlice as FleetAlarmsApiSlice } from '../api/fleet/alarmsApiSlice';
import { DeviceIdentitiesApiSlice } from '../api/system/deviceIdentitiesApiSlice';
import { AppState } from '../store';
import { CHANNELS, ECOSYSTEM, OPERATION_STATUS } from '@/shared/constants';
import { AnyAction } from 'redux';
import dayjs from 'dayjs';
import { BrandsApiSlice } from '../api/system/brandsApiSlice';
import { OTAApiSlice } from '../api/admin/otaApiSlice';
import { commandsApiSlice } from '../api/system/commandsApiSlice';
import { TelemetryApiSlice } from '../api/system/telemetryApiSlice';
import { eventsApiSlice } from '../api/system/eventsApiSlice';
import { ConsumablesApiSlice } from '../api/system/consumablesApiSlice';
import { ConsumableSubsetsApiSlice } from '../api/system/consumableSubsetsApiSlice';
import { reactionsApiSlice } from '../api/system/reactionsApiSlice';
import { ConfigsApiSlice } from '../api/system/configsApiSlice';
import { ErrorsApiSlice } from '../api/fleet/errorsApiSlice';
import consumablesApiSlice from '../api/fleet/consumablesApiSlice';
import i18next from 'i18next';
import { getNumberTh } from '../utils';
import { LoyaltyProgramsApiSlice } from '../api/system/loyaltyProgramsApiSlice';

const { REJECTED, FULFILLED } = OPERATION_STATUS;
export const TODAY_UNIQUE_ID = 'today';
export const OLDER_UNIQUE_ID = 'older';

interface ErrorType {
  status?: number;
  data?: {
    error?: {
      message?: string;
    };
  };
  error?: string;
}

export const TAGS = {
  OTA_UPDATE: 'ota-update',
  ONE_TOOL_CREATE: 'one-tool-create',
  ONE_TOOL_UPDATE: 'one-tool-update',
  ONE_TOOL_DELETE: 'one-tool-delete',
  DEVICE_CONSUMABLE_DEPLETE: 'device-consumable-deplete',
  DEVICE_CONSUMABLE_REMOVE: 'device-consumable-remove',
  DEVICE_CONSUMABLE_SET: 'device-consumable-set',
  DEVICE_TELEMETRY_UPDATE: 'device-telemetry-update',
  DEVICE_COMMAND_SEND: 'device-command-send',
  FALLBACK: 'fallback',
} as const;

export const STATUS = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
} as const;

export type Status = (typeof STATUS)[keyof typeof STATUS];

export type Tag = (typeof TAGS)[keyof typeof TAGS];

export interface Operation {
  uniqueId: string;
  operationId: string;
  state: OperationStatus;
  status: Status;
  timestamp: number;
  showed: boolean;
  read: boolean;
  location: string;
  tag: Tag;
  error?: ErrorType;
  entity: string;
  subject: string;
  jobStatus?: string;
}

export type OperationStatus = (typeof OPERATION_STATUS)[keyof typeof OPERATION_STATUS];

interface OperationsQueueState {
  operations: Operation[];
}

const initialState: OperationsQueueState = {
  operations: [],
};

type EndpointConfig = {
  name: string;
  retrieveEntity: (args?: any) => string;
  retrieveSubject: (args: any) => string;
};

const addApiMatchers = (
  builder: ActionReducerMapBuilder<OperationsQueueState>,
  apiSlice: { endpoints: { [key: string]: any } },
  endpointConfig: EndpointConfig[]
) => {
  endpointConfig.forEach(({ name, ...rest }) => {
    const endpoint = apiSlice.endpoints[name];
    if (endpoint) {
      addEndpointMatchers(builder, { ...endpoint, ...rest });
    }
  });
};

const addEndpointMatchers = (
  builder: ActionReducerMapBuilder<OperationsQueueState>,
  endpoint: {
    matchRejected: (action: AnyAction) => boolean;
    matchFulfilled: (action: AnyAction) => boolean;
    matchPending: (action: AnyAction) => boolean;
  } & Omit<EndpointConfig, 'name'>
) => {
  builder
    .addMatcher(endpoint.matchRejected, (state, action) => {
      const { payload, error, meta } = action;
      const { rejectedWithValue } = meta;
      const errorObject = rejectedWithValue ? payload : error;

      _addOperation(state, action, REJECTED, endpoint.retrieveEntity, endpoint.retrieveSubject, errorObject || {});
    })
    .addMatcher(endpoint.matchFulfilled, (state, action) => {
      _addOperation(state, action, FULFILLED, endpoint.retrieveEntity, endpoint.retrieveSubject);
    });
};

export const getStatus = (state: OperationStatus) => {
  switch (state) {
    case 'fulfilled':
      return STATUS.SUCCESS;

    case 'pending':
      return STATUS.INFO;

    case 'rejected':
      return STATUS.ERROR;
  }
};

export const getTag = (location: string, method: string) => {
  if ((location.includes('system') || location.includes('/admin/device/ota/build/create')) && method === 'POST') {
    return TAGS.ONE_TOOL_CREATE;
  } else if ((location.includes('system') || location.includes('/admin/device/ota/build/put')) && method === 'PUT') {
    return TAGS.ONE_TOOL_UPDATE;
  } else if ((location.includes('system') || location.includes('/admin/device/ota/build')) && method === 'DELETE') {
    return TAGS.ONE_TOOL_DELETE;
  } else if (location.includes('admin/device/consumable/deplete')) {
    return TAGS.DEVICE_CONSUMABLE_DEPLETE;
  } else if (location.includes('admin/device/consumable/remove')) {
    return TAGS.DEVICE_CONSUMABLE_REMOVE;
  } else if (location.includes('admin/device/consumable/set')) {
    return TAGS.DEVICE_CONSUMABLE_SET;
  } else if (location.includes('admin/device/command')) {
    return TAGS.DEVICE_COMMAND_SEND;
  } else return TAGS.FALLBACK;
};

const _addOperation = (
  state: OperationsQueueState,
  action: AnyAction,
  operationState: OperationStatus,
  retrieveEntity: EndpointConfig['retrieveEntity'],
  retrieveSubject: EndpointConfig['retrieveSubject'],
  errorObject = {}
) => {
  const { requestId, startedTimeStamp, arg, baseQueryMeta } = action.meta;
  const operationIndex = state.operations.findIndex((operation: Operation) => operation.operationId === requestId);

  const operation = {
    uniqueId: requestId + operationState,
    operationId: requestId,
    state: operationState,
    showed: false,
    location: baseQueryMeta?.request?.url,
    tag: getTag(baseQueryMeta?.request?.url, baseQueryMeta?.request?.method),
    timestamp: operationIndex === -1 ? startedTimeStamp : dayjs().valueOf(),
    error: errorObject,
    read: false,
    status: getStatus(operationState),
    subject: retrieveSubject(arg),
    entity: retrieveEntity(arg),
  } satisfies Operation;

  if (operationIndex !== -1) {
    state.operations[operationIndex] = {
      ...state.operations[operationIndex],
      showed: true,
    };
  }

  state.operations.unshift(operation);
};

const operationSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    upsertOperation: (state, action: PayloadAction<Operation>) => {
      const prevIndex = state.operations.findIndex((operation) => operation.uniqueId === action.payload.uniqueId);
      if (prevIndex !== -1) {
        state.operations[prevIndex] = action.payload;
        return;
      }

      state.operations.unshift(action.payload);
    },
    addOperation: (state, action: PayloadAction<Operation>) => {
      state.operations.unshift(action.payload);
    },
    removeOperation: (state, action: PayloadAction<string>) => {
      state.operations = state.operations.filter((operation) => operation.uniqueId !== action.payload);
    },
    toggleShowed: (state, action: PayloadAction<string>) => {
      state.operations = state.operations.map((operation) => {
        if (operation.uniqueId === action.payload) {
          operation.showed = !operation.showed;
        }
        return operation;
      });
    },
    toggleRead: (state, action: PayloadAction<string>) => {
      state.operations = state.operations.map((operation) => {
        if (operation.uniqueId === action.payload) {
          operation.read = !operation.read;
        }
        return operation;
      });
    },
    markAllAsRead: (state, action: PayloadAction<typeof TODAY_UNIQUE_ID | typeof OLDER_UNIQUE_ID>) => {
      const timestamp =
        action.payload === TODAY_UNIQUE_ID ? dayjs().valueOf() : dayjs().subtract(1, 'day').endOf('day').valueOf();
      state.operations = state.operations.map(({ read, showed, ...operation }) => {
        const isRead = operation.timestamp < timestamp;
        return {
          ...operation,
          read: isRead || read,
          showed: true,
        };
      });
    },
    clearOperations: (state) => {
      state.operations = [];
    },
  },
  extraReducers: (builder) => {
    addApiMatchers(builder, AlarmsApiSlice, [
      {
        name: 'postAlarm',
        retrieveEntity: () => CHANNELS.ALARMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.ALARMS) })
          ),
      },
      {
        name: 'putAlarm',
        retrieveEntity: () => CHANNELS.ALARMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.ALARMS) })
          ),
      },
      {
        name: 'deleteAlarm',
        retrieveEntity: () => CHANNELS.ALARMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.ALARMS) })
          ),
      },
    ]);
    addApiMatchers(builder, commandsApiSlice, [
      {
        name: 'postCommand',
        retrieveEntity: () => CHANNELS.COMMANDS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.COMMANDS) })
          ),
      },
      {
        name: 'putCommand',
        retrieveEntity: () => CHANNELS.COMMANDS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.COMMANDS) })
          ),
      },
      {
        name: 'deleteCommand',
        retrieveEntity: () => CHANNELS.COMMANDS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.COMMANDS) })
          ),
      },
    ]);
    addApiMatchers(builder, TelemetryApiSlice, [
      {
        name: 'postTelemetry',
        retrieveEntity: () => CHANNELS.TELEMETRY,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.TELEMETRY) })
          ),
      },
      {
        name: 'putTelemetry',
        retrieveEntity: () => CHANNELS.TELEMETRY,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.TELEMETRY) })
          ),
      },
      {
        name: 'deleteTelemetry',
        retrieveEntity: () => CHANNELS.TELEMETRY,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.TELEMETRY) })
          ),
      },
    ]);
    addApiMatchers(builder, eventsApiSlice, [
      {
        name: 'postEvent',
        retrieveEntity: () => CHANNELS.EVENTS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.EVENTS) })
          ),
      },
      {
        name: 'putEvent',
        retrieveEntity: () => CHANNELS.EVENTS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.EVENTS) })
          ),
      },
      {
        name: 'deleteEvent',
        retrieveEntity: () => CHANNELS.EVENTS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.EVENTS) })
          ),
      },
    ]);
    addApiMatchers(builder, ConsumablesApiSlice, [
      {
        name: 'postConsumable',
        retrieveEntity: () => CHANNELS.CONSUMABLES,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONSUMABLES) })
          ),
      },
      {
        name: 'putConsumable',
        retrieveEntity: () => CHANNELS.CONSUMABLES,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONSUMABLES) })
          ),
      },
      {
        name: 'deleteConsumable',
        retrieveEntity: () => CHANNELS.CONSUMABLES,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONSUMABLES) })
          ),
      },
    ]);
    addApiMatchers(builder, ConsumableSubsetsApiSlice, [
      {
        name: 'postConsumableSubset',
        retrieveEntity: () => CHANNELS.CONSUMABLE_SUBSETS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONSUMABLE_SUBSETS) })
          ),
      },
      {
        name: 'putConsumableSubset',
        retrieveEntity: () => CHANNELS.CONSUMABLE_SUBSETS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONSUMABLE_SUBSETS) })
          ),
      },
      {
        name: 'deleteConsumableSubset',
        retrieveEntity: () => CHANNELS.CONSUMABLE_SUBSETS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONSUMABLE_SUBSETS) })
          ),
      },
    ]);
    addApiMatchers(builder, reactionsApiSlice, [
      {
        name: 'postReaction',
        retrieveEntity: () => CHANNELS.REACTIONS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(args, i18next.t('unknownEntityXXX', { entity: i18next.t('reaction') })),
      },
      {
        name: 'putReaction',
        retrieveEntity: () => CHANNELS.REACTIONS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(args, i18next.t('unknownEntityXXX', { entity: i18next.t('reaction') })),
      },
      {
        name: 'deleteReaction',
        retrieveEntity: () => CHANNELS.REACTIONS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(args, i18next.t('unknownEntityXXX', { entity: i18next.t('reaction') })),
      },
    ]);
    addApiMatchers(builder, ConfigsApiSlice, [
      {
        name: 'postConfig',
        retrieveEntity: () => CHANNELS.CONFIGS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONFIGS) })
          ),
      },
      {
        name: 'putConfig',
        retrieveEntity: () => CHANNELS.CONFIGS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONFIGS) })
          ),
      },
      {
        name: 'deleteConfig',
        retrieveEntity: () => CHANNELS.CONFIGS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.CONFIGS) })
          ),
      },
    ]);

    addApiMatchers(builder, BrandsApiSlice, [
      {
        name: 'postBrand',
        retrieveEntity: () => ECOSYSTEM.BRANDS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.BRANDS) })
          ),
      },
      {
        name: 'putBrand',
        retrieveEntity: () => ECOSYSTEM.BRANDS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.BRANDS) })
          ),
      },
      {
        name: 'deleteBrand',
        retrieveEntity: () => ECOSYSTEM.BRANDS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.BRANDS) })
          ),
      },
    ]);
    addApiMatchers(builder, OTAApiSlice, [
      {
        name: 'postOTA',
        retrieveEntity: () => ECOSYSTEM.OTA,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(args, i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.OTA) })),
      },
      {
        name: 'putOTA',
        retrieveEntity: () => ECOSYSTEM.OTA,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(args, i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.OTA) })),
      },
      {
        name: 'deleteOTA',
        retrieveEntity: () => ECOSYSTEM.OTA,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(args, i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.OTA) })),
      },
    ]);
    addApiMatchers(builder, DeviceIdentitiesApiSlice, [
      {
        name: 'postDeviceIdentity',
        retrieveEntity: () => ECOSYSTEM.DEVICE_IDENTITIES,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.DEVICE_IDENTITIES) })
          ),
      },
      {
        name: 'putDeviceIdentity',
        retrieveEntity: () => ECOSYSTEM.DEVICE_IDENTITIES,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.DEVICE_IDENTITIES) })
          ),
      },
      {
        name: 'deleteDeviceIdentity',
        retrieveEntity: () => ECOSYSTEM.DEVICE_IDENTITIES,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(ECOSYSTEM.DEVICE_IDENTITIES) })
          ),
      },
    ]);
    addApiMatchers(builder, FleetAlarmsApiSlice, [
      {
        name: 'dismissAlarm',
        retrieveEntity: () => CHANNELS.ALARMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.ALARMS) })
          ),
      }, // TODO
    ]);
    addApiMatchers(builder, ErrorsApiSlice, [
      {
        name: 'dismissAlarm',
        retrieveEntity: () => 'error',
        retrieveSubject: () => 'error',
      }, // TODO
    ]);
    addApiMatchers(builder, consumablesApiSlice, [
      {
        name: 'patchSetConsumable',
        retrieveEntity: (args) => args?.originalArgs?.serialNumber,
        retrieveSubject: (args) =>
          i18next.t('consumableSlotN', { number: getNumberTh(Number(args.originalArgs?.body?.index) + 1) }),
      },
      {
        name: 'patchRemoveConsumable',
        retrieveEntity: (args) => args?.originalArgs?.serialNumber,
        retrieveSubject: (args) =>
          i18next.t('consumableSlotN', { number: getNumberTh(Number(args.originalArgs?.body?.index) + 1) }),
      },
      {
        name: 'patchDepleteConsumable',
        retrieveEntity: (args) => args?.originalArgs?.serialNumber,
        retrieveSubject: (args) =>
          i18next.t('consumableSlotN', { number: getNumberTh(Number(args.originalArgs?.body?.index) + 1) }),
      },
    ]);
    addApiMatchers(builder, LoyaltyProgramsApiSlice, [
      {
        name: 'postLoyaltyProgram',
        retrieveEntity: () => CHANNELS.LOYALTY_PROGRAMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.LOYALTY_PROGRAMS) })
          ),
      },
      {
        name: 'putLoyaltyProgram',
        retrieveEntity: () => CHANNELS.LOYALTY_PROGRAMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.LOYALTY_PROGRAMS) })
          ),
      },
      {
        name: 'deleteLoyaltyProgram',
        retrieveEntity: () => CHANNELS.LOYALTY_PROGRAMS,
        retrieveSubject: (args) =>
          defaultGetSubjectFromOneToolEntity(
            args,
            i18next.t('unknownEntityXXX', { entity: i18next.t(CHANNELS.LOYALTY_PROGRAMS) })
          ),
      },
    ]);
  },
});

export const {
  addOperation,
  removeOperation,
  clearOperations,
  markAllAsRead,
  toggleShowed,
  toggleRead,
  upsertOperation,
} = operationSlice.actions;
export const operationsSelector = (state: AppState) => state.operations.operations;
export const operationsUnreadSelector = createSelector(
  [operationsSelector, (_: AppState, onlyUnread: boolean) => onlyUnread],
  (operations: AppState['operations']['operations'], onlyUnread) =>
    operations.filter((operation) => !onlyUnread || operation.read === false)
);

export default operationSlice.reducer;

const defaultGetSubjectFromOneToolEntity = (arg: any, fallback: string) => {
  return arg?.originalArgs?.body?.name || arg?.originalArgs?.name || arg?.originalArgs?.id || fallback;
};
