import Section from '@/components/Shared/Section';
import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import DevicesList from './DevicesList';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import LoadingAlarm from './LoadingAlarm';
import CircularWithBasePath from '@/components/Shared/ProgressWithLabel/CircularWithBasePath';
import { CHANNELS } from '@/shared/constants';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';

export const FLEET_GENERAL_QUERY_WIDTH = 1340;

export default function FleetGeneral() {
  const [openDialog, setOpenDialog] = useState(false);
  const { data, isLoading, isFetching, isError } = useGetFleetQuery();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);

  const totalDevices =
    data?.data?.devices.reduce((sum: number, device: { name: string; totDevices: number }) => {
      return sum + device.totDevices;
    }, 0) || 0;

  const SafeBody = withErrorLoadingManagement(() => (
    <>
      {[
        {
          number: data.data.alerts.priority || 0,
          label: t('devicesHave') + ' ' + t('errors').toLowerCase(),
          color: theme.palette.error.dark,
        },
        {
          number: data.data.alerts.postponable || 0,
          label: t('devicesHave') + ' ' + t(CHANNELS.ALARMS).toLowerCase(),
          color: theme.palette.warning.dark,
        },
        {
          number: data.data.alerts.none || 0,
          label: t('devicesWithoutAlarmsOrErrors'),
          color: theme.palette.success.dark,
        },
        {
          number: data.data.devicesWithoutTelemetry,
          label: 'No telemetry system',
          color: 'inherit',
        },
      ].map((v: { label: string; number: number; color: string }) => {
        return (
          <AreaContainer key={v.label}>
            <AreaBody>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  textAlign: 'center',
                  gap: 2,
                }}
              >
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularWithBasePath
                    key={v.label}
                    size={90}
                    color="inherit"
                    sx={{
                      '& .MuiCircularProgress-circle': {
                        stroke: v.color,
                      },
                    }}
                    circleContainerStyle={{ padding: 0 }}
                    thickness={4}
                    value={(v.number * 100) / totalDevices}
                    variant="determinate"
                    innerLabel={
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{
                            fontSize: 28,
                            fontWeight: 'bold',
                            color: v.color,
                            letterSpacing: -1.3,
                            lineHeight: '70%',
                            paddingTop: 0.625,
                          }}
                        >
                          {v.number}
                        </Typography>
                        <Typography
                          variant="caption"
                          component="span"
                          sx={{
                            fontSize: 12,
                            color: v.color,
                          }}
                        >
                          of {totalDevices}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    maxWidth: '120px',
                    wordWrap: 'break-word',
                    textAlign: 'left',
                  }}
                >
                  {v.label}
                </Typography>
              </Box>
            </AreaBody>
          </AreaContainer>
        );
      })}
    </>
  ));

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: () => setOpenDialog(false),
    tabs: [
      {
        id: 'numberAndKindOfDevices',
        label: t('numberAndKindOfDevices'),
        body: <DevicesList isDialog={true} handleDialog={setOpenDialog} />,
      },
    ],
    styles: {
      bodyContainer: {
        p: 0,
      },
    },
  });

  return (
    <Section>
      {dialog}
      <Typography variant="h5">{t('fleetGeneral')}</Typography>
      <Box display="grid" gridTemplateColumns={'1.3fr 0.8fr'} gridTemplateRows={'auto auto'} gap={2}>
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="subtitle1" sx={{ lineHeight: 'inherit' }}>
            {t('numberAndKindOfDevices')}
          </Typography>
          <Button variant="text" onClick={() => navigate('/fleet/devices')}>
            {t('seeAll')}
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="subtitle1" sx={{ lineHeight: 'inherit' }}>
            {t('alarmsAndErrors')}
          </Typography>
          <Button variant="text" onClick={() => navigate('/fleet/alarms-errors')}>
            {t('seeAll')}
          </Button>
        </Box>
        <Card sx={{ flexGrow: 1 }}>
          <DevicesList isDialog={false} handleDialog={setOpenDialog} />
        </Card>
        <Card sx={{ p: 1.5, bgcolor: 'white' }}>
          <Box
            display="grid"
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateRows={'auto auto'}
            gap={'0.5rem'}
            sx={{ height: '100%' }}
            {...(matches ? { height: 'fit-content' } : {})}
          >
            <SafeBody isError={isError} isLoading={isLoading || isFetching} LoadingComponent={<LoadingAlarm />} />
          </Box>
        </Card>
      </Box>
    </Section>
  );
}
