import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { HistoryTelemetry } from '../typings';

export default function PrintItem({ item }: { item: [number, HistoryTelemetry[]]; index: number }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplate: '1fr',
        marginBottom: '3mm',
        printColorAdjust: 'exact',
        WebkitPrintColorAdjust: 'exact',
        gap: 2,
        '& *': {
          printColorAdjust: 'exact',
          WebkitPrintColorAdjust: 'exact',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplate: 'auto / min-content auto',
          backgroundColor: (theme) => theme.palette.background.grayShades[0],
          borderRadius: 1,
          padding: 1,
          width: '100%',
        }}
      >
        {/* Counter */}
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: 2,
            p: 1,
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography
            color="#fff"
            sx={{ opacity: 0.8 }}
            paddingBottom={0}
            lineHeight={'normal'}
            component={'span'}
            fontSize={15}
            variant="h6"
          >
            {t('date')}
          </Typography>
          <Typography
            color="#fff"
            paddingBottom={0}
            lineHeight={'normal'}
            component={'span'}
            fontSize={15}
            variant="h6"
            fontWeight={'600'}
          >
            {dayjs(item[0]).format('L')}
          </Typography>
        </Box>
        {/* Info about item */}
        <Box
          sx={{
            display: 'grid',
            maxWidth: '100%',
            gridTemplate: 'auto',
            gap: 1,
          }}
        >
          {/* General info about item */}
          <Box display="flex" flexWrap="wrap" gap={2} padding={1}>
            <Box
              sx={{
                overflowWrap: 'break-word',
              }}
            ></Box>
            <Box
              sx={{
                overflowWrap: 'break-word',
              }}
            >
              <Typography
                component="pre"
                variant="body2"
                sx={{ paddingBottom: 0, fontSize: '10pt', width: '100%', whiteSpace: 'pre-wrap' }}
              >
                {item[1]
                  .map((historyTelemetry, index) => {
                    const { lastUpdate, ...values } = historyTelemetry;
                    const hasHourChanged =
                      dayjs(lastUpdate).get('hours') !== dayjs(item[1][index - 1]?.lastUpdate || null).get('hours');
                    return `${index !== 0 && hasHourChanged ? '\n\n' : ''}${
                      hasHourChanged ? `— ${dayjs(lastUpdate).format('h A')} —\n` : ''
                    }${Object.keys(values)
                      .map(
                        (k, i, arr) =>
                          `${i === 0 ? `${dayjs(lastUpdate).format('h:mm:ss A [(]SSS[z)]')}` : ''}${
                            arr.length > 1 && i === 0 ? ` ${t('items', { number: arr.length })}` : ''
                          }   ${arr.length > 1 ? `\n    [${i + 1}] ` : ''}${k}: ${values[k]},${
                            arr.length > 1 && i + 1 !== arr.length ? '' : '\n'
                          }`
                      )
                      .join('')}`;
                  })
                  .join('')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
