import { Chip, SxProps } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiddenColumnsContext } from './CulliganTable';

export default function HiddenColumnChip({ sx }: { sx?: SxProps }) {
  const { t } = useTranslation();
  const hiddenColumns = useContext(HiddenColumnsContext);
  if (hiddenColumns === 0) {
    return <></>;
  }
  return (
    <Chip
      id="column-count"
      color="default"
      variant="outlined"
      sx={{
        padding: 1,
        ...sx,
      }}
      label={`${t('hiddenColumns')}: ${hiddenColumns}`}
    />
  );
}
