import { redirect } from 'react-router';
import ScrollTopOutlet from '../core/ScrollTopOutlet';
import { ROLES } from '@/shared/constants';
import ErrorBoundary from '../core/ErrorBoundary';
import { getPermissionCheckLoader } from '@/shared/utils';
import Overview from '@/components/BusinessAnalysis/Overview';
import AnalyticsTwoTone from '@mui/icons-material/AnalyticsTwoTone';

const rolesAllowed = [ROLES.USER];

const BusinessAnalisysRoutes = {
  path: '/business-analysis',
  label: 'Business Analysis',
  element: <ScrollTopOutlet />,
  icon: <AnalyticsTwoTone />,
  errorElement: <ErrorBoundary />,
  rolesAllowed,
  loader: getPermissionCheckLoader(rolesAllowed),
  children: [
    {
      index: true,
      loader: () => redirect('/business-analysis/overview'),
    },
    {
      path: 'overview',
      element: <Overview />,
      label: 'Overview',
    },
  ],
};

export default BusinessAnalisysRoutes;
