import apiSlice, { API_VERSION } from '../apiSlice';

const consumablesMetricsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConsumablesMetrics: builder.query<any, string[]>({
      query: (params: string[]) =>
        `${API_VERSION.v2}/fleet/consumables/metrics?` +
        new URLSearchParams(params.map((label) => ['label[]', label])).toString(),
    }),
  }),
});

export const { useGetConsumablesMetricsQuery } = consumablesMetricsApiSlice;
