import { redirect } from 'react-router';
import AlarmsAndErrorsPage from '../pages/Fleet/AlarmsAndErrors';
import Consumables from '../pages/Fleet/Consumables';
import Devices from '../pages/Fleet/Devices';
import Overview from '../pages/Fleet/Overview';
import ScrollTopOutlet from '../core/ScrollTopOutlet';
import DevicePage from '../pages/Fleet/Device';
import DevicesOtherTwoTone from '@mui/icons-material/DevicesOtherTwoTone';

export const FLEET_ROUTES = {
  FLEET: 'fleet',
  OVERVIEW: 'overview',
  CONSUMABLES: 'consumables',
  DEVICES: 'devices',
  ALARMS_ERRORS: 'alarms-errors',
};

const FleetRoutes = {
  path: `/${FLEET_ROUTES.FLEET}`,
  label: 'Fleet',
  element: <ScrollTopOutlet />,
  icon: <DevicesOtherTwoTone />,
  children: [
    {
      index: true,
      loader: () => redirect(`/${FLEET_ROUTES.FLEET}/${FLEET_ROUTES.OVERVIEW}`),
    },
    {
      path: `${FLEET_ROUTES.OVERVIEW}`,
      label: 'Overview',
      element: <Overview />,
    },
    {
      path: `${FLEET_ROUTES.CONSUMABLES}`,
      element: <Consumables />,
      label: 'Consumables',
    },
    {
      path: `${FLEET_ROUTES.DEVICES}`,
      label: 'Devices',
      children: [
        {
          index: true,
          element: <Devices />,
        },
        {
          path: ':deviceId',
          element: <DevicePage />,
        },
      ],
    },
    {
      path: `${FLEET_ROUTES.ALARMS_ERRORS}`,
      element: <AlarmsAndErrorsPage />,
      label: 'Alarms and errors',
    },
    // TODO
    // {
    //   path: 'interventions',
    //   element: <Interventions />,
    //   label: 'Interventions',
    // },
    // {
    //   path: 'glossary',
    //   element: <Glossary />,
    //   label: 'Glossary',
    // },
  ],
};

export default FleetRoutes;
