import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { Avatar, Collapse, Fade, ListItem, ListItemAvatar, alpha, darken, useTheme } from '@mui/material';
import Drawer from './Drawer';
import DrawerHeader from './DrawerHeader';
import { useTranslation } from 'react-i18next';
import { getAppDrawerRoutes } from '@/components/Core/utils';
import { WaterDrop } from '@mui/icons-material';
import { Fragment, useState } from 'react';
import DrawerMenuItem from './DrawerMenuItem';
import { useGetCurrentUserProfileQuery } from '@/redux/api/usersApiSlice';
import { User } from '@typings';

const drawerWidth = 240;

const AppDrawer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data,
    isLoading: isLoadingProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetCurrentUserProfileQuery();
  const profile: User = data?.data || ({} as User);
  const [open, setOpen] = useState<boolean>(false);
  const allRoutes = getAppDrawerRoutes();

  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
      PaperProps={{
        elevation: 4,
        sx: {
          zIndex: 0,
          backgroundColor: darken(theme.palette.primary.dark, 0.4),
        },
      }}
      drawerWidth={drawerWidth}
    >
      <DrawerHeader
        sx={{
          justifyContent: open ? 'normal' : 'center',
          alignItems: 'center',
          marginLeft: open ? '0.75rem' : 0,
        }}
      />
      <Divider />
      <Box
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <List sx={{ pt: 0 }}>
          {allRoutes
            ?.filter((route) => route.key !== 'settings' && route.key !== 'profile')
            ?.map((page) => (
              <DrawerMenuItem
                key={page.key}
                disableGutters
                dense
                // eslint-disable-next-line no-restricted-globals
                selected={location.pathname.includes(page.key)}
                onClick={() => navigate(`/${page.key}`)}
                id={`${page.key}MenuItem`}
                disableRipple
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    fontWeight: '800',
                    justifyContent: open ? 'initial' : 'center',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: theme.palette.text.primary,
                    }}
                  >
                    {page.icon ? page.icon : <WaterDrop />}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontWeight: 'bold' }}
                    primary={page.label}
                    sx={{ fontWeight: '800', opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List sx={{ padding: 0, position: 'relative' }}>
                    {page.children?.flatMap((childRoute, index) =>
                      childRoute.label ? (
                        <Fragment key={`${childRoute?.id}_${index}`}>
                          <Fade in={open} timeout={(index + 2) * 150}>
                            <ListItem
                              dense
                              sx={{
                                paddingLeft: '76px',
                                '&:first-of-type div::before': {
                                  height: '80%',
                                },
                              }}
                            >
                              <ListItemText
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/${page.key}/${childRoute.key}`);
                                }}
                                sx={{
                                  padding: '8px 6px 8px 12px',
                                  opacity: open ? 1 : 0,
                                  position: 'relative',
                                  '&:hover': {
                                    borderRadius: '8px',
                                    transition: 'all 0.2s ease-in-out',
                                    backgroundColor: alpha(theme.palette.primary.light, 0.5),
                                    width: 'calc(100% - 16px)',
                                  },
                                  '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    height: 'calc(100% + 8px + 5px);',
                                    left: '-42px',
                                    width: '37px',
                                    display: 'block',
                                    border: `1px solid ${alpha(theme.palette.primary.main, 0.7)}`,
                                    bottom: '50%',
                                    borderTop: '0',
                                    borderRight: '0',
                                    borderBottomLeftRadius: '5px',
                                  },
                                }}
                              >
                                {childRoute.label}
                              </ListItemText>
                            </ListItem>
                          </Fade>
                          <Box
                            component="li"
                            sx={{ backgroundColor: '#ffffff40', width: '86%', position: 'relative', right: '-14%' }}
                          />
                        </Fragment>
                      ) : (
                        []
                      )
                    )}
                  </List>
                </Collapse>
              </DrawerMenuItem>
            ))}
        </List>
        <List>
          <DrawerMenuItem dense disableGutters id="settingsMenuItem" onClick={() => navigate('/settings')}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: theme.palette.text.primary,
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t('settings')} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </DrawerMenuItem>
          {!isLoadingProfile && !isFetchingProfile && !isErrorProfile && (
            <DrawerMenuItem dense disableGutters id="profileMenuItem" onClick={() => navigate('/profile')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <ListItemAvatar
                  sx={{
                    minWidth: '2.25rem',
                    display: 'flex',
                    justifyContent: 'center',
                    mr: open ? 3 : 'auto',
                  }}
                >
                  <Avatar
                    alt="my profile"
                    src="https://placekitten.com/200/200"
                    sx={{ width: '1.5rem', height: '1.5rem' }}
                  />
                </ListItemAvatar>
                <ListItemText primary={`${profile.firstName} ${profile.lastName}`} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </DrawerMenuItem>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default AppDrawer;
