import React from 'react';
import { VictoryScatterProps } from 'victory';

const SCATTER_PROPS: VictoryScatterProps = {
  symbol: 'circle',
  size: 4,
  labels: () => ' ',
  labelComponent: <></>,
  style: {
    data: {
      fill: '#000',
      stroke: '#fff',
      strokeWidth: 4,
      pointerEvents: 'none',
      strokeLinecap: 'round',
    },
  },
};

export { SCATTER_PROPS };
