import { useMemo } from 'react';
import { Device } from '../components/Fleet/Devices/DevicesPanel/typings';

type DefaultLabel = `${string} (${Device['model']})`;
type FallbackLabel = `${Device['model']}`;

type DeviceOption = { label: DefaultLabel | FallbackLabel; value: Device['model'] };

export const useDevicesOptions = (devices: Device[]) => {
  const labelBuilder = (device: Device) =>
    device?.name ? device.name.concat(' ').concat(`(${device.model})`) : device.model;
  const uniqueOptions = useMemo(
    () =>
      devices?.reduce<Array<DeviceOption>>((options, device) => {
        if (options.findIndex((d) => d.value === device.model) === -1) {
          options.push({
            label: labelBuilder(device),
            value: device.model,
          });
        }
        return options;
      }, []),
    [devices]
  );
  return uniqueOptions || [];
};
