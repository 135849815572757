export const COMMAND_TYPES = {
  NO_PARAMS: 'noParams',
  PARAMS_WITHOUT_CONFIG: 'paramsWithoutConfig',
  PARAMS_WITH_CONFIG: 'paramsWithConfig',
} as const;

export const CONNECTIVITIES = {
  '2G': '2G',
  '4G': '4G',
  '5G': '5G',
  BLE: 'BLE',
  LTECAT14: 'LTE cat 1-4',
  LTECATM1: 'LTE cat M1',
  NBIOT2: 'Nb-IoT 2',
  NBIOT: 'NB-IoT',
  SIDEWALK: 'Sidewalk',
  WIFI: 'Wi-Fi',
} as const;

export const CONSUMABLE_KIND = {
  ENHANCEMENTS: 'enhancements',
  CO2: 'CO2',
  CHEMICALS: 'chemicals',
  FILTER: 'filter',
  MEMBRANES: 'membranes',
  OZONE: 'ozone',
  SANITIZATIONKIT: 'sanitizationKit',
  UVCLIGHT: 'uvcLight',
  WATERBOTTLES: 'waterBottles',
} as const;

export const DIRECTIONS = {
  C2D: 'C2D',
  D2C: 'D2C',
} as const;

export const ERROR_CAUSES = {
  UNAUTHORIZED_ROLE: 'UNAUTHORIZED_ROLE',
} as const;

export const EXPIRATION_METHOD = {
  EXPIRATION_DATE: 'expirationDate',
  REPLACE_AFTER: 'replaceAfter',
} as const;

export const ISSUE_STATUS = {
  ACTIVE: 'active',
  DISMISSED: 'dismissed',
} as const;

export const LOYALTY_PROGRAMS_CONFIGURATION_TYPE = {
  POINTS_PER_DRINK: 'pointsPerDrink',
  POINTS_PER_VOLUME: 'pointsPerVolume',
} as const;

export const MARKER_TYPE = {
  DEVICE: 'device',
} as const;

export const PRIMITIVES = {
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  STRING: 'string',
} as const;

export const REACTION_KIND = {
  EMAIL: 'email',
  PUSHNOTIFICATION: 'pushNotification',
  SMS: 'sms',
  WEBHOOK: 'webhook',
} as const;

export const ROLES = {
  ADMIN: 'admin',
  BRAND_MANAGER: 'brandManager',
  BRAND_SUPPORT_OPERATOR: 'brandSupportOperator',
  BRAND_TECH_OPERATOR: 'brandTechOperator',
  SYS_ADMIN: 'sysAdmin',
  USER: 'user',
} as const;

export const ROLES_PRIORITIES = [
  ROLES.SYS_ADMIN,
  ROLES.ADMIN,
  ROLES.BRAND_MANAGER,
  ROLES.BRAND_TECH_OPERATOR,
  ROLES.BRAND_SUPPORT_OPERATOR,
  ROLES.USER,
];

export const SENDING_RULE = {
  LESS: 'less',
  GREATER: 'greater',
} as const;

export const SENDING_STYLE = {
  IMMEDIATE: 'immediate',
  STORED: 'stored',
} as const;

export const SEVERITY = {
  CRITICAL: 'critical',
  NON_CRITICAL: 'non-critical',
} as const;

export const TELEMETRY_SENDING_STYLE = {
  ALWAYS: 'always',
  ON_CHANGE: 'onchange',
  ON_DELTA: 'ondelta',
  ON_THRESHOLD: 'onthreshold',
} as const;

export const TELEMETRY_TYPE = {
  INFERRED: 'inferred',
  NATIVE: 'native',
} as const;

export const TIERS = {
  '1': 1,
  '2': 2,
  '3': 3,
} as const;

export const ROUTE_SECTION = {
  FLEET: 'fleet',
  ONETOOL: 'one-tool',
  BUSINESSANALYSIS: 'business-analysis',
} as const;

export const ROUTE_FOLDER = {
  ECOSYSTEM: 'ecosystem',
  CHANNELS: 'channels',
  DEVICES: 'devices',
  OVERVIEW: 'overview',
} as const;

export const ECOSYSTEM = {
  DEVICE_IDENTITIES: 'device_identities',
  BRANDS: 'brands',
  OTA: 'ota',
} as const;

export const CHANNELS = {
  TELEMETRY: 'telemetry',
  EVENTS: 'events',
  ALARMS: 'alarms',
  COMMANDS: 'commands',
  CONSUMABLES: 'consumables',
  CONSUMABLE_SUBSETS: 'consumable_subsets',
  REACTIONS: 'reactions',
  CONFIGS: 'configs',
  LOYALTY_PROGRAMS: 'loyalty_programs',
} as const;

export const OPERATION_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled',
} as const;

export const WATER_TYPES = {
  0: 'Fresh Water',
  1: 'Sparkling Water',
  2: 'Flat Water',
  3: 'Hot Water',
  4: 'Tasty Water',
} as const;

export const JOB_STATUS = {
  LOADING: 'loading',
  QUEUED: 'queued',
  RUNNING: 'running',
  COOL_DOWN: 'coolDown',
  WAITING: 'waiting',
  PAUSED: 'paused',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
} as const;

export const COMMANDS = {
  DISABLE: 'disable',
  ENABLE: 'enable',
  REBOOT: 'reboot',
  SLEEP: 'power.profile.sleep',
  STANDARD: 'power.profile.standard',
  LOG_SET: 'log.set',
  OTA_UPDATE: 'ota.update',
  TELEMETRY_GET: 'telemetry.get',
} as const;

export const REQUESTED_LOCATION = 'requestedLocation';
