import ConsumablesCard from '@/components/Fleet/Overview/ConsumablesCard';
import FleetLocationsCard from '@/components/Fleet/Overview/FleetLocationsCard';
import FleetGeneral from '@/components/Fleet/Overview/FleetGeneral';
import AlarmsAndErrorCard from '@/components/Fleet/Overview/AlertsAndErrorCard';

export const Overview = () => {
  return (
    <>
      <FleetGeneral />
      <FleetLocationsCard />
      <ConsumablesCard />
      <AlarmsAndErrorCard />
    </>
  );
};

export default Overview;
