import { Box, Typography, alpha, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { Operation } from '@/redux/slices/operationSlice';

export type OperationMessageAction = { label: string; callback: () => void };

type BaseOperationMessageProps = {
  main: ReactNode;
  date: Dayjs;
  action?: OperationMessageAction;
  status: Operation['status'];
  read: Operation['read'];
};

const ms = 1000;
const oneMinute = 60 * ms;

const calculateTimeoutMs = (diffInSeconds: number) => {
  if (diffInSeconds <= 900) {
    // <= 15 minutes
    return oneMinute;
  }

  if (diffInSeconds <= 1800) {
    // <= 30 minutes
    return oneMinute * 5;
  }

  if (diffInSeconds <= 3600) {
    // <= 1 hour
    return oneMinute * 10;
  }

  if (diffInSeconds <= 3600 * 24) {
    // <= 24 hours
    return oneMinute * 60;
  }

  return -1;
};

const handleUpdateTimeFromNow = (
  date: Dayjs,
  timeoutIdRef: MutableRefObject<number | undefined>,
  setTimeFromNow: (fromNow: string) => void
) => {
  const timeoutMs = calculateTimeoutMs(date.diff(dayjs(), 's'));
  if (timeoutMs === -1) {
    return;
  }

  timeoutIdRef.current = setTimeout(() => {
    setTimeFromNow(date.fromNow());
    handleUpdateTimeFromNow(date, timeoutIdRef, setTimeFromNow);
  }, timeoutMs) as unknown as number;
};

export default function BaseOperationMessage({ main, date, action, status, read }: BaseOperationMessageProps) {
  const [timeFromNow, setTimeFromNow] = useState(date.fromNow());
  const timeoutIdRef = useRef<undefined | number>();

  useEffect(() => {
    if (!timeoutIdRef.current) {
      handleUpdateTimeFromNow(date, timeoutIdRef, setTimeFromNow);
      return;
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = undefined;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFromNow]);

  const theme = useTheme();
  const color = read ? theme.palette.grey[800] : theme.palette[status || 'primary'].light;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ fontSize: '0.875rem', lineHeight: '100%' }}>
        {main}{' '}
        {action && (
          <Typography
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              action.callback();
            }}
            sx={{
              fontSize: 'inherit',
              color: (theme) => theme.palette[status || 'primary'].light,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            component="span"
          >
            {action.label}
          </Typography>
        )}
      </Box>
      <Typography sx={{ fontSize: '0.625rem', color: alpha(color, 0.8) }}>{timeFromNow}</Typography>
    </Box>
  );
}
