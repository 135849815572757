import { selectPollingById, startPolling } from '@/redux/slices/pollingSlice';
import { useEffect, useLayoutEffect } from 'react';
import { Device } from '../../../DevicesPanel/typings';
import { STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { usePostOTAJobMutation } from '@/redux/api/admin/otaApiSlice';
import {
  OperatingModeCommandPayload,
  usePostLogLevelCommandMutation,
  usePostOperatingModeCommandMutation,
  usePostPowerProfileCommandMutation,
  usePostRebootCommandMutation,
} from '@/redux/api/admin/deviceCommandsApiSlice';

export default function useDeviceInfoPollings({
  flags,
  serialNumber,
}: {
  serialNumber: Device['serialNumber'];
  flags: {
    postOperatingModeCommand: ReturnType<typeof usePostOperatingModeCommandMutation>[1];
    postPowerProfileCommand: ReturnType<typeof usePostPowerProfileCommandMutation>[1];
    postLogLevel: ReturnType<typeof usePostLogLevelCommandMutation>[1];
    postOTAJob: ReturnType<typeof usePostOTAJobMutation>[1];
    postRebootCommand: ReturnType<typeof usePostRebootCommandMutation>[1];
  };
}) {
  const dispatch = useDispatch();
  const operatingModeFlags = flags.postOperatingModeCommand;
  const powerProfileFlags = flags.postPowerProfileCommand;
  const logLevelFlags = flags.postLogLevel;
  const otaUpdateFlags = flags.postOTAJob;
  const rebootFlags = flags.postRebootCommand;

  const isPollingOperatingMode =
    useSelector((state: AppState) =>
      selectPollingById(state, operatingModeFlags.requestId || '', 'postOperatingModeCommand')
    )?.isPolling || false;

  const isPollingPowerProfile =
    useSelector((state: AppState) =>
      selectPollingById(state, powerProfileFlags.requestId || '', 'postPowerProfileCommand')
    )?.isPolling || false;

  const isPollingOTAUpdate = useSelector(
    (state: AppState) => selectPollingById(state, otaUpdateFlags?.requestId || '', 'postOTAJob')?.isPolling || false
  );
  const otaPollingRequestedAt = useSelector(
    (state: AppState) => selectPollingById(state, otaUpdateFlags?.requestId || '', 'postOTAJob')?.pollingRequestedAt
  );
  const isPollingLogLevel = useSelector(
    (state: AppState) => selectPollingById(state, logLevelFlags?.requestId || '', 'postLogLevel')?.isPolling || false
  );
  const isPollingReboot = useSelector(
    (state: AppState) => selectPollingById(state, rebootFlags?.requestId || '', 'postRebootCommand')?.isPolling || false
  );

  useEffect(() => {
    if (!operatingModeFlags) {
      return;
    }
    if (!operatingModeFlags.isError && !operatingModeFlags.isLoading && operatingModeFlags?.requestId) {
      dispatch(
        startPolling({
          endpoint: 'postOperatingModeCommand',
          args: {
            serialNumber,
            operatingMode: (operatingModeFlags.originalArgs as OperatingModeCommandPayload).operatingMode,
          },
          id: operatingModeFlags?.requestId,
          interval: 3000,
        })
      );
    }

    if (operatingModeFlags.isError) {
      dispatch(
        upsertOperation({
          entity: serialNumber,
          location: ' ',
          operationId: operatingModeFlags?.requestId || '',
          read: false,
          showed: false,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.ERROR,
          subject: serialNumber,
          timestamp: dayjs().valueOf(),
          uniqueId: `${operatingModeFlags?.requestId}-${STATUS.ERROR}`,
          tag: TAGS.DEVICE_COMMAND_SEND,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatingModeFlags.isError, operatingModeFlags.isLoading, operatingModeFlags?.requestId]);

  useEffect(() => {
    if (!powerProfileFlags) {
      return;
    }
    if (
      !powerProfileFlags.isError &&
      !powerProfileFlags.isLoading &&
      powerProfileFlags?.requestId &&
      powerProfileFlags?.originalArgs?.powerProfile
    ) {
      dispatch(
        startPolling({
          endpoint: 'postPowerProfileCommand',
          args: { serialNumber, requestedPowerProfile: powerProfileFlags.originalArgs?.powerProfile },
          id: powerProfileFlags?.requestId,
          interval: 3000,
        })
      );
    }

    if (powerProfileFlags?.isError) {
      dispatch(
        upsertOperation({
          entity: 'PostPowerProfileCommand',
          location: ' ',
          operationId: powerProfileFlags?.requestId || '',
          read: false,
          showed: false,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.ERROR,
          subject: serialNumber,
          timestamp: dayjs().valueOf(),
          uniqueId: `${powerProfileFlags?.requestId}-${STATUS.ERROR}`,
          tag: TAGS.DEVICE_COMMAND_SEND,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerProfileFlags.isError, powerProfileFlags.isLoading, powerProfileFlags?.requestId]);

  useEffect(() => {
    if (!logLevelFlags) {
      return;
    }
    if (
      !logLevelFlags.isError &&
      !logLevelFlags.isLoading &&
      logLevelFlags.isSuccess &&
      logLevelFlags?.requestId &&
      logLevelFlags?.originalArgs?.logLevel
    ) {
      dispatch(
        startPolling({
          endpoint: 'postLogLevel',
          args: { serialNumber: serialNumber, requestedLogLevel: logLevelFlags?.originalArgs?.logLevel },
          id: logLevelFlags?.requestId,
          interval: 3000,
        })
      );
    }

    if (logLevelFlags.isError) {
      dispatch(
        upsertOperation({
          entity: serialNumber,
          location: ' ',
          operationId: logLevelFlags?.requestId || '',
          read: false,
          showed: false,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.ERROR,
          subject: serialNumber,
          timestamp: dayjs().valueOf(),
          uniqueId: `${logLevelFlags?.requestId}-${STATUS.ERROR}`,
          tag: TAGS.DEVICE_COMMAND_SEND,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logLevelFlags.isError, logLevelFlags.isLoading, logLevelFlags?.requestId]);

  useEffect(() => {
    if (!rebootFlags) {
      return;
    }
    if (!rebootFlags.isError && !rebootFlags.isLoading && rebootFlags.isSuccess && rebootFlags?.requestId) {
      dispatch(
        startPolling({
          endpoint: 'postRebootCommand',
          args: serialNumber,
          id: rebootFlags?.requestId,
          interval: 3000,
        })
      );
    }

    if (rebootFlags.isError) {
      dispatch(
        upsertOperation({
          entity: serialNumber,
          location: ' ',
          operationId: rebootFlags?.requestId || '',
          read: false,
          showed: false,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.ERROR,
          subject: serialNumber,
          timestamp: dayjs().valueOf(),
          uniqueId: `${rebootFlags?.requestId}-${STATUS.ERROR}`,
          tag: TAGS.DEVICE_COMMAND_SEND,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rebootFlags.isError, rebootFlags.isLoading, rebootFlags?.requestId]);

  useLayoutEffect(() => {
    if (!otaUpdateFlags) {
      return;
    }
    if (!otaUpdateFlags.isError && otaUpdateFlags.isSuccess && otaUpdateFlags?.requestId && otaUpdateFlags?.data) {
      const jobId = otaUpdateFlags?.data?.data?.item.id as string;
      dispatch(
        startPolling({
          endpoint: 'postOTAJob',
          args: { jobId: jobId, serialNumber: serialNumber },
          id: otaUpdateFlags?.requestId,
        })
      );

      dispatch(
        upsertOperation({
          entity: 'OTAUpdate',
          location: ' ',
          operationId: otaUpdateFlags?.requestId,
          read: false,
          showed: false,
          state: OPERATION_STATUS.PENDING,
          status: STATUS.INFO,
          subject: serialNumber,
          timestamp: dayjs().valueOf(),
          uniqueId: `${otaUpdateFlags?.requestId}-${STATUS.INFO}`,
          tag: TAGS.OTA_UPDATE,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otaUpdateFlags.isError, otaUpdateFlags.isSuccess, otaUpdateFlags?.requestId]);

  return {
    isPollingOperatingMode,
    isPollingPowerProfile,
    isPollingOTAUpdate,
    isPollingLogLevel,
    isPollingReboot,
    otaPollingRequestedAt,
  };
}
