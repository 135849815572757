import Button from '@mui/material/Button/Button';
import { useTranslation } from 'react-i18next';

export default function DownloadButton({
  onClick,
  id,
  flags: { isLoading, isFetching },
}: {
  onClick: Function;
  id: string;
  flags: { isLoading: boolean; isFetching: boolean };
}) {
  const { t } = useTranslation();
  return (
    <Button variant="outlined" onClick={() => onClick(id)} disabled={isLoading || isFetching}>
      {t('download')}
    </Button>
  );
}
