import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FilterErrorRequest, FleetError } from '@/components/Fleet/AlarmsAndErrors/typings';

export const ErrorsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetErrors', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    getFilteredErrors: builder.query<ApiResult<{ items: FleetError[] }>, FilterErrorRequest>({
      query: (params) => ({
        url: `${API_VERSION.v2}/fleet/errors/filteredList`,
        params,
      }),
      providesTags: ['FleetErrors', 'Fleet'],
    }),
  }),
});

export const { useLazyGetFilteredErrorsQuery } = ErrorsApiSlice;
