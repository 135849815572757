import { Typography, TypographyProps, darken, useTheme } from '@mui/material';
import { ElementType, ReactNode } from 'react';
import { Operation } from '@/redux/slices/operationSlice';
import React from 'react';

function BaseOperationTypography({
  children,
  fontWeight,
  component,
  status,
  read,
}: {
  children: ReactNode;
  status: Operation['status'];
  read: Operation['read'];
  component?: ElementType<any>;
} & TypographyProps) {
  const theme = useTheme();
  const color = read ? theme.palette.grey[800] : darken(theme.palette[status || 'primary'].dark, 0.3);
  return (
    <Typography
      sx={{
        fontSize: 'inherit',
        color,
        fontWeight: fontWeight || 'inherit',
        lineHeight: 'inherit',
      }}
      component={component || 'span'}
    >
      {children}
    </Typography>
  );
}

export function BoldOperationTypography({
  children,
  status,
  read,
}: {
  children: ReactNode;
  status: Operation['status'];
  read: Operation['read'];
}) {
  return (
    <BaseOperationTypography status={status} read={read} fontWeight="600">
      {children}
    </BaseOperationTypography>
  );
}

export default React.memo(BaseOperationTypography);
