import { Box, Typography, darken, lighten, useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import CulliganTooltipChart from '@/components/Shared/Charts/ChartTooltip';
import { PerformanceData } from './typings';

export default function DoughnutChartWithCustomTooltip({ data }: { data: PerformanceData[] }) {
  const theme = useTheme();

  const total = data.reduce((prev: number, next: any) => prev + next.value, 0);

  return (
    <ResponsivePie
      colors={[
        ...[...data].map((_: any, i: number, flavors: any[]) =>
          lighten(theme.palette.primary.dark, i * (1 / flavors.length))
        ),
      ]}
      data={[...data].map((flavor: any, i: number, flavors: any[]) => ({
        ...flavor,
        color: darken(theme.palette.primary.light, i * (1 / flavors.length)),
      }))}
      margin={{ bottom: 20, top: 20, left: 80, right: 80 }}
      innerRadius={0.5}
      padAngle={2}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={theme.palette.text.primary}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={(d) => theme.palette.getContrastText(d.color)}
      arcLinkLabel={(d) => `${d.label}`}
      valueFormat={(value) => `${((value * 100) / total).toFixed(1)}%`}
      tooltip={(d) => {
        setTimeout(() => {
          if (!document) {
            return;
          }

          const tooltip = document.getElementById(`${d.datum.id}DoughnutChartTooltip`);

          if (!tooltip) {
            return;
          }

          tooltip.style.opacity = '1';
        }, 150);

        const tooltipBody = (
          <Box
            p={1}
            width={'fit-content'}
            display="flex"
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={2}
          >
            <Box display="flex" alignItems={'center'} gap={1} width={'fit-content'}>
              <Box width={20} height={20} bgcolor={d.datum.color} borderRadius={4} />
              <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                {d.datum.label}
              </Typography>
            </Box>
            <Typography variant="body1" fontWeight={600}>
              {((d.datum.value * 100) / total).toFixed(1)}%
            </Typography>
          </Box>
        );

        return (
          <CulliganTooltipChart
            id={`${d.datum.id}DoughnutChartTooltip`}
            body={tooltipBody}
            position="managed"
            coordinates={{ top: -20, left: 0 }}
          />
        );
      }}
    />
  );
}
