import { Box, Typography, Chip, IconButton } from '@mui/material';
import { copyToClipboard } from '@/shared/utils';
import KeyIcon from '@mui/icons-material/Key';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { dispatchToast } from '@/shared/utils';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { FiltersTelemetryContext } from '../FiltersTelemetryContext';
import { TelemetryGroupContext } from './TelemetryGroupContext';
import useScrollWithOffset from '@/hooks/useScrollWithOffset';
import { HISTORYDATASECTION_ID } from '..';

export default function TelemetryHeaderCard({ name, id, group }: { name: string; id: string; group: boolean }) {
  const { t } = useTranslation();
  const { setKeys } = useContext(FiltersTelemetryContext);
  const { setKeys: setGroupKeys } = useContext(TelemetryGroupContext);
  const scrollElementIntoViewWithOffset = useScrollWithOffset(HISTORYDATASECTION_ID);

  const _setKeys = useCallback(
    (key: string) => {
      if (group) {
        setGroupKeys(key);
        return;
      }

      setKeys([{ id: key }]);
    },
    [group, setGroupKeys, setKeys]
  );

  return (
    <Box display={'flex'} flexDirection={'row'} flexWrap="wrap" justifyContent={'space-between'}>
      <Typography variant="subtitle2" marginBottom={'1rem'} mr={'4px'}>
        {name || 'N/A'}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', flexGrow: 0, alignItems: 'baseline' }}>
        <Chip
          label={id.toUpperCase()}
          onClick={(e) => {
            copyToClipboard({
              text: id,
              onSucces: () => {
                dispatchToast({
                  message: t('copyToClipboardSuccess'),
                  severity: 'success',
                  position: { x: e.clientX, y: e.clientY },
                });
              },
              onFail: () => {
                dispatchToast({
                  message: t('copyToClipboardError'),
                  severity: 'error',
                  position: { x: e.clientX, y: e.clientY },
                });
              },
            });
          }}
          variant="outlined"
          size="small"
          icon={<KeyIcon />}
          sx={(theme) => ({
            height: '22px',
            borderColor: theme.palette.grey[500],
            '.MuiChip-label': { fontSize: '10px', fontWeight: 'bold', color: theme.palette.grey[800] },
            '.MuiSvgIcon-root': { transform: 'rotate(270deg)', fontSize: '12px', ml: '4px' },
          })}
        />

        <IconButton
          size="small"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.grey[500]}`,
            fontSize: '10px',
            borderRadius: '16px',
            padding: 0,
            color: theme.palette.grey[800],
            height: '22px',
            ...(group ? { width: '22px' } : {}),
            marginLeft: '5px',
            '&:hover': {
              border: `1px solid ${theme.palette.grey[800]}`,
            },
          })}
          onClick={() => {
            _setKeys(id);
            scrollElementIntoViewWithOffset();
          }}
        >
          <OpenInNewIcon sx={{ fontSize: '10px', marginInline: '4px' }} />
          {!group && (
            <Typography fontWeight="bold" fontSize={'10px'} lineHeight={1} mr="4px">
              {t('history')}
            </Typography>
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
