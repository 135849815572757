import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import apiSlice from '@/redux/api/apiSlice';
import { useGetCurrentUserProfileQuery } from '@/redux/api/usersApiSlice';
import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { User } from '@typings';
import { useTranslation } from 'react-i18next';
import { logout } from '@/redux/slices/authSlice';
import { useDispatch } from 'react-redux';

const Profile = () => {
  const { data, isLoading, isFetching, isError } = useGetCurrentUserProfileQuery();

  return <ProfileContent data={data?.data} isLoading={isLoading || isFetching} isError={isError} />;
};

const ProfileContent = withErrorLoadingManagement(({ data }: { data: User }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
  };

  return (
    <Box>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          sx={{ height: 140, backgroundColor: '#e3e3e3' }}
          // image="/static/images/whatever.jpg"
          // title="a_title"
        >
          <></>
        </CardMedia>
        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {data.firstName} {data.lastName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {data.email}
            </Typography>
          </CardContent>
          <CardContent sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button variant="outlined" color="error" sx={{ alignSelf: 'flex-end' }} onClick={handleLogout}>
              {t('logout')}
            </Button>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
});

export default Profile;
