import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useGetLoyaltyProgramsQuery,
  usePostLoyaltyProgramMutation,
  usePutLoyaltyProgramMutation,
  useDeleteLoyaltyProgramMutation,
  useLazyGetLoyaltyProgramQuery,
  useLazyIsLoyaltyProgramUniqueQuery,
  LoyaltyProgramWithStringifiedDates,
  LoyaltyProgramWithoutDates,
} from '@/redux/api/system/loyaltyProgramsApiSlice';
import { useTranslation } from 'react-i18next';
import { LoyaltyProgramRes } from './typings';
import useLoyaltyProgramsColumns from './useLoyaltyProgramsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { CHANNELS, LOYALTY_PROGRAMS_CONFIGURATION_TYPE } from '@/shared/constants';
import useUniqueness from '@/hooks/useUniqueness';
import { FieldsUndefined } from '@typings';

export const LoyaltyProgramsTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<LoyaltyProgramRes>)
);

export default function LoyaltyProgramsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetLoyaltyProgramsQuery();

  const [triggerGetLoyaltyProgram] = useLazyGetLoyaltyProgramQuery();
  const [postLoyaltyProgramTrigger] = usePostLoyaltyProgramMutation();
  const [putLoyaltyProgramTrigger] = usePutLoyaltyProgramMutation();
  const [deleteLoyaltyProgramTrigger] = useDeleteLoyaltyProgramMutation();
  const [isLoyaltyProgramUnique] = useLazyIsLoyaltyProgramUniqueQuery();

  const validateUniqueness = useUniqueness<
    FieldsUndefined<typeof LoyaltyProgramWithStringifiedDates.Type, 'id' | 'name'>,
    'id' | 'name'
  >({
    isUniqueTrigger: isLoyaltyProgramUnique,
  });

  const { AddEditDialog, openDialog } = useAddEditDialog<
    Omit<typeof LoyaltyProgramWithoutDates.Type, 'config'> & (typeof LoyaltyProgramWithoutDates.Type)['config']
  >({
    title: t(CHANNELS.LOYALTY_PROGRAMS),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => async (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }
            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'enabled',
        placeholder: t('enabled'),
        type: 'checkbox',
        initialValue: false,
      },
      {
        type: 'autocomplete',
        group: 'configuration',
        name: 'type',
        placeholder: t('type'),
        options: { required: t('fieldRequiredError') as string },
        selectConfig: {
          options: Object.values(LOYALTY_PROGRAMS_CONFIGURATION_TYPE).map((loyaltyProgram) => ({
            label: t(loyaltyProgram),
            value: loyaltyProgram,
          })),
        },
      },
      {
        type: 'number',
        group: 'configuration',
        helperText: t('fieldNumberError') as string,
        name: 'value',
        placeholder: t('value'),
        options: { required: t('fieldRequiredError') as string },
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      if (typeof dialogId === 'boolean') {
        return;
      }
      const { data } = await triggerGetLoyaltyProgram(dialogId);
      if (!data || data._tag === 'Left' || data.right.success === false) {
        console.error('Error while trying to edit data', (data as any)?.right?.error || (data as any)?.left || data);
        return;
      }
      const item = data.right.data;
      if (!item) {
        return;
      }
      const { config, ...rest } = item;
      return {
        ...rest,
        type: config.type,
        value: config.value,
      };
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';
      const { type, value, ...rest } = data;
      const config = {
        type,
        value,
      } as const;
      rest.enabled = rest.enabled || false;
      const submitData = { ...rest, config };
      if (isEditing) {
        await putLoyaltyProgramTrigger({ id: dialogId, body: submitData });
      } else {
        await postLoyaltyProgramTrigger(submitData);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('loyaltyProgram'),
    message: (extra: any) => t('deleteMessage', { entity: t('loyaltyProgram'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteLoyaltyProgramTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useLoyaltyProgramsColumns(openDialog, confirm);
  if (data?._tag === 'Left' || !data || data.right.success === false) {
    return <></>;
  }
  return (
    <>
      <LoyaltyProgramsTable
        title={t(CHANNELS.LOYALTY_PROGRAMS)}
        data={data?.right.data.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('loyaltyProgram')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
