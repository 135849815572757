import { useTranslation } from 'react-i18next';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import Brands from '@/components/OneTools/Ecosystem/Brands';
import DeviceIdentities from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentitiesPanel';
import OTA from './OTA';
import { ECOSYSTEM } from '@/shared/constants';

function Ecosystem() {
  const { t } = useTranslation();

  const tabsConfig = [
    {
      id: ECOSYSTEM.DEVICE_IDENTITIES,
      label: t(ECOSYSTEM.DEVICE_IDENTITIES),
      ariaControls: true,
      ContentComponent: <DeviceIdentities />,
    },
    {
      id: ECOSYSTEM.BRANDS,
      label: t(ECOSYSTEM.BRANDS),
      ariaControls: true,
      ContentComponent: <Brands />,
    },
    {
      id: 'ota',
      label: t('ota'),
      ariaControls: true,
      ContentComponent: <OTA />,
    },
  ];

  return <NavigationTabsLayout config={tabsConfig} />;
}

export default Ecosystem;
