import { ApiResult } from '@typings';
import { ConfigReq, ConfigRes } from '@/components/OneTools/Channels/Configs/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { CHANNELS } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/configs`;

export const ConfigsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Configs'] }).injectEndpoints({
  endpoints: (builder) => ({
    getConfigs: builder.query<ApiResult<{ items: ConfigRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Configs'],
    }),
    getConfig: builder.query<ApiResult<ConfigRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Configs'],
    }),
    isConfigUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
    postConfig: builder.mutation<ApiResult<ConfigRes>, ConfigReq>({
      query: (body: ConfigReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: CHANNELS.CONFIGS,
        });
      },
      invalidatesTags: ['Configs'],
    }),
    putConfig: builder.mutation<ApiResult<ConfigRes>, { id: string; body: ConfigReq }>({
      query: ({ id, body }: { id: string; body: ConfigReq }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: CHANNELS.CONFIGS,
        });
      },
      invalidatesTags: ['Configs'],
    }),
    patchConfig: builder.mutation<ApiResult<ConfigRes>, { id: string; body: Partial<ConfigReq>; name: string }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: CHANNELS.CONFIGS,
        });
      },
      invalidatesTags: ['Configs'],
    }),
    deleteConfig: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: CHANNELS.CONFIGS,
        });
      },
      invalidatesTags: ['Configs'],
    }),
  }),
});

export const {
  useLazyGetConfigsQuery,
  useGetConfigsQuery,
  useLazyGetConfigQuery,
  useLazyIsConfigUniqueQuery,
  usePostConfigMutation,
  usePutConfigMutation,
  usePatchConfigMutation,
  useDeleteConfigMutation,
} = ConfigsApiSlice;
