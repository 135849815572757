import { Box, Fab, Zoom, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export default function CulliganFAB({ label, onClick }: { label: string; onClick: any }) {
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Box
      onClick={onClick}
      sx={(theme) => ({
        position: 'fixed',
        top: '100dvh',
        left: '100dvw',
        transform: 'translate(-120%, -150%)',
        zIndex: theme.zIndex.mobileStepper - 1,
        '@media print': {
          display: 'none',
        },
      })}
    >
      <Zoom
        timeout={transitionDuration}
        in={true}
        style={{
          transitionDelay: '200ms',
        }}
        unmountOnExit
      >
        <Fab variant="extended" color="primary">
          <DownloadIcon sx={{ mr: 1 }} />
          {label}
        </Fab>
      </Zoom>
    </Box>
  );
}
