import { OLDER_UNIQUE_ID, Operation, TODAY_UNIQUE_ID } from '@/redux/slices/operationSlice';
import dayjs from 'dayjs';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { END_REACHED_OPERATION, NOTIFICATION_VERTICAL_GUTTER, OLDER_OPERATION, TODAY_OPERATION } from './consts';
import EndReached, { END_REACHED_UNIQUE_ID } from './EndReached';
import { VariableSizeList } from 'react-window';
import Today from './Today';
import Older from './Older';
import Notification from '.';
import { Box } from '@mui/material';

export default function List({ operations }: { operations: Operation[] }) {
  const measures = useRef<Map<Operation['uniqueId'], number>>(new Map());
  const listRef = useRef<VariableSizeList>(null);
  const [_operationsWithDividers, setOperationsWithToday] = useState<Operation[]>([]);

  useLayoutEffect(() => {
    const TODAY_TIMESTAMP = dayjs().startOf('day').valueOf();
    const [todayOperations, olderOperations] = operations.reduce<[Operation[], Operation[]]>(
      (acc, curr) => {
        if (curr.timestamp >= TODAY_TIMESTAMP) {
          return [[...acc[0], curr], acc[1]];
        }
        return [acc[0], [...acc[1], curr]];
      },
      [[], []]
    );

    setOperationsWithToday([
      ...(todayOperations.length > 0 ? [TODAY_OPERATION, ...todayOperations] : []),
      ...(olderOperations.length > 0 ? [OLDER_OPERATION, ...olderOperations] : []),
      END_REACHED_OPERATION,
    ]);
  }, [operations]);

  const getItemSize = useCallback(
    (index: number) => {
      const endReached = index === _operationsWithDividers?.length - 1;
      const operation = !endReached ? _operationsWithDividers[index] : { uniqueId: END_REACHED_UNIQUE_ID };
      return measures.current.get(operation.uniqueId) || NOTIFICATION_VERTICAL_GUTTER;
    },
    [_operationsWithDividers]
  );

  const setNotificationHeight = useCallback((id: Operation['uniqueId'], size: number) => {
    listRef?.current?.resetAfterIndex(0);
    measures.current.set(id, size + NOTIFICATION_VERTICAL_GUTTER);
    return;
  }, []);

  const NotificationItems = useCallback(
    ({
      index,
      data,
      style: { height, ...style },
    }: {
      index: number;
      data: Operation[];
      style: React.CSSProperties;
    }) => {
      const props = {
        ...data[index],
        index,
        measures,
        setNotificationHeight,
      };

      if (data[index]?.uniqueId === TODAY_UNIQUE_ID) {
        return <Today {...props} style={style} />;
      }

      if (data[index]?.uniqueId === OLDER_UNIQUE_ID) {
        return <Older {...props} style={style} />;
      }

      if (data[index]?.uniqueId === END_REACHED_UNIQUE_ID) {
        return <EndReached {...props} style={style} />;
      }

      return <Notification {...props} style={style} />;
    },
    [setNotificationHeight]
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        overflow: 'auto',
        borderRadius: 1,
      }}
    >
      <VariableSizeList
        height={400}
        ref={listRef}
        itemData={_operationsWithDividers}
        width={'100%'}
        itemCount={_operationsWithDividers?.length || 0}
        itemSize={getItemSize}
      >
        {NotificationItems}
      </VariableSizeList>
    </Box>
  );
}
