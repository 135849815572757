import { useEffect, useState } from 'react';
import { BoxProps } from '@mui/material';
import { LocalFilterOption, FilterFx, LocalFiltersProps } from './typings';
import FilterBar from './FilterBar';

const LocalFilters = <T extends object>({
  filterConfigs,
  onFiltersApplied,
  flexDirection = 'row',
  ...props
}: BoxProps & LocalFiltersProps<T>) => {
  const [appliedFiltersMap, setAppliedFiltersMap] = useState<Map<string, ((arg: T) => boolean) | null>>(new Map());

  const handleOnFilterChange = (filterId: string, selectedValue: null | FilterFx<T> | LocalFilterOption<T>) => {
    if (selectedValue == null) {
      appliedFiltersMap.delete(filterId);
    } else if (typeof selectedValue === 'function') {
      appliedFiltersMap.set(filterId, selectedValue);
    } else if (selectedValue) {
      appliedFiltersMap.set(filterId, selectedValue.filterFx);
    }
    setAppliedFiltersMap(new Map(appliedFiltersMap));
  };

  const handleClearAll = () => {
    const filtersCleared = new Map();
    onFiltersApplied(filtersCleared);
    setAppliedFiltersMap(filtersCleared);
  };

  const handleApplyFilters = () => onFiltersApplied(appliedFiltersMap);

  return (
    <FilterBar<T>
      kind="local"
      filterConfigs={filterConfigs}
      onFilterChange={handleOnFilterChange}
      handleApplyFilters={handleApplyFilters}
      handleClearAll={handleClearAll}
      {...props}
    />
  );
};

export default LocalFilters;

export function useFilters<T>(data: T[] = []) {
  const [filteredData, setFilteredData] = useState<T[]>([]);
  const [filters, setFilters] = useState<Map<string, Function> | null>(null);

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      let resData: Array<T>;

      if (filters && filters.size) {
        resData = [...data];
        [...filters].forEach(([_, filterFx]: [any, any]) => {
          resData = resData.filter(filterFx);
        });
      } else {
        resData = data;
      }

      setFilteredData(resData);
    }
  }, [data, filters]);

  return {
    filteredData,
    applyFilters: setFilters,
  };
}
