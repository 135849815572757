import { Box, Button, Card, Typography } from '@mui/material';
import { StatisticsCardProps } from './typings';
const StatisticsCard = ({ title, variant, action, children }: StatisticsCardProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        '@media print': {
          breakInside: 'avoid',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems={'flex-start'} mb="1rem">
        <Typography variant="body1">{title}</Typography>
        {action && (
          <Button variant="text" size="small" onClick={() => action.fn()}>
            {action.label}
          </Button>
        )}
      </Box>
      {variant === 'boxed' ? (
        <Card sx={{ p: 2, overflow: 'visible', height: 400, position: 'relative' }}>{children}</Card>
      ) : variant === 'bigBoxed' ? (
        <Card sx={{ p: 2, overflow: 'visible', position: 'relative' }}>{children}</Card>
      ) : (
        children
      )}
    </Box>
  );
};
export default StatisticsCard;
