import useLoyaltyProgramsColumns from '@/components/OneTools/Channels/LoyaltyPrograms/useLoyaltyProgramsColumns';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { useUpdateDeviceLoyaltyProgramMutation } from '@/redux/api/fleet/devicesApiSlice';

import { LoyaltyProgram } from '@culligan-iot/domain/culligan/one/loyalty';
import { MaterialTableProps } from '@material-table/core';
import { t } from 'i18next';

export default function LoyaltyProgramTable({
  items,
  isFleet,
  excludeColumns,
  serialNumber,
  ...props
}: { items: LoyaltyProgram[]; isFleet: boolean; excludeColumns?: string[]; serialNumber: string } & Omit<
  MaterialTableProps<any>,
  'columns' | 'data'
>) {
  const [updateLoyaltyProgram] = useUpdateDeviceLoyaltyProgramMutation();

  const handleDeleteLoyaltyProgram = (id: string) => {
    const updatedLoyaltyPrograms = items.reduce<string[]>((acc, cur) => {
      if (cur.id !== id) {
        acc.push(cur.id);
      }
      return acc;
    }, []);
    updateLoyaltyProgram({ serialNumber, loyaltyPrograms: updatedLoyaltyPrograms });
  };
  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('loyaltyProgram'),
    message: (extra: any) => t('deleteMessage', { entity: t('loyaltyProgram'), name: extra.name }) as string,
    onConfirm: (extra: any) => handleDeleteLoyaltyProgram(extra.id),
  });

  const column = useLoyaltyProgramsColumns(undefined, confirm, excludeColumns);

  return (
    <>
      <GenericExportTable<LoyaltyProgram>
        {...props}
        title={t('loyalty_programs')}
        data={items}
        columns={column}
        exportData={false}
        selection={false}
      />
      <ConfirmDialog />
    </>
  );
}
