import { createContext } from 'react';

export type TelemetryKey = {
  id: string;
  items?: Array<{
    id: string;
  }>;
};

export const FiltersTelemetryContext = createContext<{
  keys: TelemetryKey[];
  rangeEnd: number | undefined;
  rangeStart: number | undefined;
  setKeys: (keys: TelemetryKey[]) => void;
  setRangeEnd: (rangeEnd: number) => void;
  setRangeStart: (rangeStart: number) => void;
}>({
  keys: [],
  rangeEnd: undefined,
  rangeStart: undefined,
  setKeys: () => {},
  setRangeEnd: () => {},
  setRangeStart: () => {},
});
