/* eslint-disable react-hooks/exhaustive-deps */
import {
  alpha,
  Box,
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { ConsumableMetric } from './typings';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import DoughnutChartWithCustomTooltip from '@/components/Shared/Charts/DoughnutChartWithCustomTooltip';
import BarChartWithCustomTooltip from '@/components/Shared/Charts/BarChartWithCustomTooltip';
import Podium from '@/components/Shared/Podium';
import { useNavigate } from 'react-router';
import useIsTablet from '@/hooks/useIsTablet';

const getGridLayout = (isTablet: boolean, hasPerformance: boolean) => {
  if (isTablet && hasPerformance) {
    return 'auto auto / 1fr';
  }

  if (hasPerformance) {
    return 'auto / 0.5fr 1fr';
  }

  return 'auto / 1fr';
};

export default function ConsumableGraph({ consumable }: { consumable: ConsumableMetric }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<'doughnutChart' | 'podium'>('doughnutChart');

  const handleChange = (event: SelectChangeEvent) => {
    setViewType(event.target.value as 'doughnutChart' | 'podium');
  };

  const navigate = useNavigate();
  const isTablet = useIsTablet();

  const section = useMemo(
    () => (
      <Box display={'grid'} gap={2} sx={{ gridTemplate: getGridLayout(isTablet, !!consumable.performance) }}>
        {consumable.performance && (
          <Box display="grid" sx={{ gridTemplate: 'auto 1fr / auto', position: 'relative' }} gap={1}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">{t('consumablePerformance', { label: consumable.label })}</Typography>
              <FormControl>
                <Select
                  labelId="viewTypeLabel"
                  id="viewTypeSelect"
                  value={viewType}
                  onChange={handleChange}
                  size="small"
                  variant="standard"
                  sx={{
                    '&.MuiInputBase-root': {
                      textTransform: 'uppercase',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                      color: theme.palette.primary.main,
                      marginRight: -1,
                    },
                    '&.Mui-focused': {
                      backgroundColor: 'transparent',
                    },
                    '& .MuiSelect-select': {
                      pb: 0,
                    },
                    '&.MuiInputBase-root::after': {
                      display: 'none',
                    },
                    '&.MuiInputBase-root::before': {
                      display: 'none',
                    },
                    '& .MuiSvgIcon-root': {
                      color: theme.palette.primary.main,
                    },
                  }}
                  color="primary"
                >
                  <MenuItem value={'doughnutChart'}>{t('doughnutChart')}</MenuItem>
                  <MenuItem value={'podium'}>{t('podium')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {viewType === 'doughnutChart' ? (
              <Card sx={{ height: '400px', width: '100%', overflow: 'visible', position: 'relative' }}>
                <Box sx={{ position: 'absolute', height: '100%', width: '100%', p: 2 }}>
                  <DoughnutChartWithCustomTooltip data={consumable.performance} />
                </Box>
              </Card>
            ) : (
              <Box sx={{ height: '400px' }}>
                <Podium data={consumable.performance} label={consumable.label} />
              </Box>
            )}
          </Box>
        )}
        <Box display={'grid'} height={'100%'} sx={{ gridTemplate: 'auto 1fr / auto' }}>
          <Box display="flex" justifyContent="space-between" sx={{ mb: '1px' }}>
            <Typography variant="body1">{t('consumableLevels', { label: consumable.label })}</Typography>
            <Button variant="text" size="small" onClick={() => navigate(`/fleet/consumables#${consumable.id}`)}>
              {t('seeAll')}
            </Button>
          </Box>
          <Card sx={{ p: 2, overflow: 'visible', height: '400px', position: 'relative' }}>
            <Box sx={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: 0 }}>
              <BarChartWithCustomTooltip
                data={{
                  datasets: [{ data: consumable.values, backgroundColor: alpha(theme.palette.primary.main, 0.8) }],
                  labels: consumable.ranges,
                }}
                id={consumable.id}
                label={consumable.label}
              />
            </Box>
          </Card>
        </Box>
      </Box>
    ),
    [
      consumable.id,
      consumable.label,
      consumable.performance,
      consumable.ranges,
      consumable.values,
      isTablet,
      navigate,
      t,
      theme.palette.primary.main,
      viewType,
    ]
  );

  return section;
}
