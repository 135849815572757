import { Button, Card, CardActions, CardContent, CircularProgress, Divider, Typography, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useConfirmResetPasswordMutation } from '../../../redux/api/authApiSlice';
import { selectAccessToken } from '../../../redux/slices/authSlice';
import { Check } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import authErrorHandler from './authErrorHandler';
import PasswordInput from '@/components/Shared/PasswordInput';
import PasswordPolicyList from './PasswordPolicyList';

const ResetPasswordConfirmation = () => {
  const methods = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
  });
  const {
    handleSubmit,
    formState: { errors, isSubmitted },
    setError,
    watch,
    clearErrors,
  } = methods;

  const [passwordReset, setPasswordReset] = useState(false);
  const [confirmResetPassword, { isLoading }] = useConfirmResetPasswordMutation();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(
    () =>
      watch((v) => {
        clearErrors();
        if (isSubmitted && v.password !== v.repeatPassword) {
          setError('root', { message: t('passwordsNotMatchError') as string });
        }
      }).unsubscribe,
    [watch, clearErrors, isSubmitted, setError, t]
  );

  // Preventing logged in users from accessing the login page
  const accessToken = useSelector(selectAccessToken);
  if (accessToken) {
    return <Navigate to="/" />;
  }

  if (!params.get('oobCode')) {
    setError('root', { message: t('invalidLink') as string });
  }

  const onSubmit = ({ password, repeatPassword }: { password: string; repeatPassword: string }) => {
    if (password !== repeatPassword) {
      setError('root', { message: t('passwordsNotMatchError') as string });
      return;
    }

    confirmResetPassword({
      password,
      oobCode: params.get('oobCode')!,
    })
      .unwrap()
      .then(() => setPasswordReset(true))
      .catch((err) => authErrorHandler(err.status, setError, t));
  };

  return (
    <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Card sx={{ minWidth: 400, maxWidth: 600, py: 2 }}>
        <Box px={2}>
          <Typography variant="h4">Culligan One</Typography>
          {!passwordReset && <Typography variant="subtitle1">{t('resetPasswordConfirmation')}</Typography>}
        </Box>
        {passwordReset ? (
          <>
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              {<Typography>{t('passwordResetSuccesful')}</Typography>}
              <Zoom in={true}>
                <Check sx={{ fontSize: 100, color: 'green', alignSelf: 'center' }} />
              </Zoom>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button onClick={() => navigate('/login')} variant="contained" color="primary">
                {t('goLogin')}
              </Button>
            </CardActions>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <FormProvider {...methods}>
                <Box display="flex" flexDirection="column">
                  <PasswordInput />
                  <PasswordInput sx={{ mt: 2 }} name="repeatPassword" label={t('repeatPassword') as string} />
                </Box>
              </FormProvider>

              <Divider sx={{ mb: 2, mt: 3 }} />

              <Typography variant="subtitle1">{t('passwordRequirements')}</Typography>
              <PasswordPolicyList />
            </CardContent>
            <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
              <Box display="flex" flexDirection="column">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  startIcon={isLoading ? <CircularProgress size={20} sx={{ color: 'gray' }} /> : null}
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  {t('resetPassword')}
                </Button>
                {!!errors.root && (
                  <Typography variant="caption" color="red" alignSelf="center">
                    {errors.root.message}
                  </Typography>
                )}
              </Box>
            </CardActions>
          </form>
        )}
      </Card>
    </Box>
  );
};

export default ResetPasswordConfirmation;
