import { OTARes } from '@/components/OneTools/Ecosystem/OTA/typings';
import { SELECT_CARD_ROLES } from '@/components/Shared/AddEditDialog/SelectCardInput';
import Area, { AREA_DESIGN_TOKENS, AreaRow } from '@/components/Shared/Card/Area';
import { Body, Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import ConfirmDialog from '@/components/Shared/ConfirmDialog';
import Map from '@/components/Shared/Map';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { usePostRebootCommandMutation } from '@/redux/api/admin/deviceCommandsApiSlice';
import { useLazyGetOTAListByDeviceIdQuery, usePostOTAJobMutation } from '@/redux/api/admin/otaApiSlice';
import { MARKER_TYPE, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import { isGreaterVersion } from '@/shared/utils';
import { getSupportedOperatingModeActions } from '@/shared/validations';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ParsedInfo } from '..';
import { Device } from '../../DevicesPanel/typings';
import OverviewCard from './DeviceCardInfo';
import Address from './InfoCard/Address';
import Firmware from './InfoCard/Firmware';
import Identity from './InfoCard/Identity';
import Status from './InfoCard/Status';

import {
  selectHasPendingOperatingMode,
  selectPendingCommands,
  selectHasPendingOTAUpdate,
} from '@/redux/slices/deviceSlice';
import { AppState } from '@/redux/store';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import CommandButton from './InfoCard/CommandButton';
import { useOperatingModeColors } from './InfoCard/useOperatingModeColors';

function DeviceInfoCard({
  device,
  info,
  postOTAJob,
  postReboot,
  otaPollingRequestAt,
}: {
  device: Device;
  info: ParsedInfo;
  postOTAJob: ReturnType<typeof usePostOTAJobMutation>[0];
  postReboot: ReturnType<typeof usePostRebootCommandMutation>[0];
  otaPollingRequestAt?: number;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [triggerGetOTAListByDeviceId, result] = useLazyGetOTAListByDeviceIdQuery();
  const pendingCommands = useSelector((state: AppState) => selectPendingCommands(state, device.serialNumber));
  const hasPendingCommmands = pendingCommands.length > 0;
  const hasPendingOTAUpdate = useSelector((state: AppState) => selectHasPendingOTAUpdate(state, device.serialNumber));
  const hasPendingOperatingModeUpdate = useSelector((state: AppState) =>
    selectHasPendingOperatingMode(state, device.serialNumber)
  );
  const isLoadingReboot = pendingCommands.some((command) => command.command === 'reboot');
  const operatingModeColors = useOperatingModeColors();
  const isRebootSupported =
    device?.supportedOperatingModes?.length && device?.supportedOperatingModes.includes('Startup');
  const hasCoordinates = typeof device?.lat === 'number' && typeof device.lon === 'number';
  const stringAddress = Object.values(device?.installationAddress || {}).join(', ');
  const locationNotAvailableMessage = useMemo(() => t('locationNotAvailable'), [t]);

  const MemoizedMap = useMemo(
    () => (
      <Map
        noMarkersMessage={locationNotAvailableMessage}
        markers={
          hasCoordinates
            ? [
                {
                  position: { lat: device.lat, lng: device.lon },
                  title: device.name || '',
                  description: device.serialNumber,
                  type: MARKER_TYPE.DEVICE,
                  extra: device,
                },
              ]
            : []
        }
      />
    ),
    [device, hasCoordinates, locationNotAvailableMessage]
  );

  useEffect(() => {
    triggerGetOTAListByDeviceId(device.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentOTAVersion = useMemo(() => {
    return result.data?.data?.items.find((OTA) => device.swVersion === OTA.version);
  }, [device.swVersion, result.data?.data?.items]);

  const isDeviceOnline = device?.status?.connection?.online;
  const supportedActions = getSupportedOperatingModeActions(device.operatingMode);
  const operatingModeSupportsOTA = supportedActions.includes('OTA');
  const canOTAUpdate =
    supportedActions.length > 0 &&
    operatingModeSupportsOTA &&
    isDeviceOnline &&
    !hasPendingCommmands &&
    currentOTAVersion != null &&
    !hasPendingOTAUpdate;

  const getOTATooltipMessage = useCallback(
    (isPolling: boolean, isOnline: boolean, isStandardOperatingMode: boolean) => {
      if (isPolling) {
        return t('firmwareUpdateAlreadyRequested');
      }
      if (!isOnline) {
        return t('deviceIsntConnectedRetryLater');
      }
      if (!operatingModeSupportsOTA) {
        return t('cantPerformOTAinCurrentOperatingMode');
      }
    },
    [operatingModeSupportsOTA, t]
  );
  const otaTooltipMessage = useMemo(
    () => getOTATooltipMessage(hasPendingOTAUpdate || false, isDeviceOnline, device.operatingMode === 'Standard'),
    [getOTATooltipMessage, hasPendingOTAUpdate, isDeviceOnline, device.operatingMode]
  );

  const confirmDialogMessage = useMemo(() => {
    return (
      <Fragment>
        <DialogTitle>
          {t('reboot')} {device.name}
        </DialogTitle>
        <DialogContent>
          <Typography>{`Are you sure you want to reboot ${device.serialNumber}?`}</Typography>
        </DialogContent>
      </Fragment>
    );
  }, [device.name, device.serialNumber, t]);

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: t('firmware'),
    baseConfig: [
      {
        name: 'firmwareVersion',
        type: 'selectCard',
        options: { required: t('fieldRequiredError') as string },
        selectCardConfig: {
          options: () => triggerGetOTAListByDeviceId(device.id).then((res) => res.data?.data?.items || []),
          labels: {
            current: t('artifact'),
            selected: t('artifact'),
            options: t('artifacts'),
          },
          getItemIdentifier: (item) => item.id,
          mapItemToHeaderElements: (item) => [
            <Box key={item.id + 'name'}>
              <Subtitle sx={{ fontWeight: '600' }}>{item.id}</Subtitle>
            </Box>,
          ],
          mapItemToBodyElements: (item, _, role) => {
            const _item = item as OTARes;
            return (
              <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: AREA_DESIGN_TOKENS.gap }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                  }}
                >
                  <Box>
                    <Subtitle>{t('version')}</Subtitle>
                    <Title>
                      {_item.version}
                      {role !== SELECT_CARD_ROLES.CURRENT &&
                        (_item?.minVersion ||
                          (!isGreaterVersion(_item.version, currentOTAVersion?.version as unknown as string) && (
                            <WarningIcon color="warning" sx={{ fontSize: 12 }} />
                          )))}
                    </Title>
                  </Box>
                  <RenderIf condition={_item?.minVersion != null && _item?.minVersion !== ''}>
                    <Box>
                      <Subtitle sx={{ fontSize: 10 }}>{t('minVersion')}</Subtitle>
                      <Body sx={{ fontWeight: '600' }}>
                        {_item.minVersion}
                        {role !== SELECT_CARD_ROLES.CURRENT &&
                          _item?.minVersion &&
                          !isGreaterVersion(currentOTAVersion?.version as unknown as string, _item?.minVersion) && (
                            <WarningIcon color="warning" sx={{ fontSize: 12 }} />
                          )}
                      </Body>
                    </Box>
                  </RenderIf>
                </Box>
                <Box>
                  <Subtitle sx={{ fontSize: 10 }}>{t('createdAt')}</Subtitle>
                  <Body>{dayjs(_item.createdAt).format('DD/MM/YYYY')}</Body>
                </Box>
                <Box>
                  <Subtitle sx={{ fontSize: 10 }}>{t('updatedAt')}</Subtitle>
                  <Body>{dayjs(_item.updatedAt).format('DD/MM/YYYY')}</Body>
                </Box>
              </Box>
            );
          },
          isCurrentItem: (item, currentValue) => item.id === currentValue,
          isWarningItem: (item) =>
            item?.minVersion && !isGreaterVersion(currentOTAVersion?.version as unknown as string, item.minVersion),
          isDisabledItem: (item, currentValue) => {
            return (
              item.id === currentValue ||
              !isGreaterVersion(item.version, currentOTAVersion?.version as unknown as string)
            );
          },
          currentValue: currentOTAVersion?.id as unknown as string,
        },
      },
    ],
    onSubmit: async (_, data: { firmwareVersion: string }) => {
      const ota = result.data?.data?.items.find((_ota) => _ota.id === data.firmwareVersion);
      if (!ota) {
        return;
      }

      postOTAJob({
        buildFile: ota.id,
        name: ota.id,
        version: ota.version,
        enabled: true,
        targetFilterParams: {
          serialNumbers: [device.id],
        },
        targetSelectionMode: 'filter',
      });
    },
  });

  const handleFirmwareAreaNavigation = () =>
    navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${device.id}#ota_jobs`);

  const handleFirmwareAreaClick = () => {
    if (!isDeviceOnline || hasPendingOTAUpdate || currentOTAVersion == null || !canOTAUpdate) {
      handleFirmwareAreaNavigation();
      return;
    }

    currentOTAVersion && canOTAUpdate && openDialog(' ');
  };

  return (
    <Fragment>
      <OverviewCard id="deviceInfoCard" title={t('deviceInfo')} sx={{ position: 'relative' }}>
        <AddEditDialog />
        <ConfirmDialog
          isOpen={confirmDialogOpen}
          onConfirm={() =>
            postReboot({
              serialNumber: device.serialNumber,
            })
          }
          onClose={() => setConfirmDialogOpen(false)}
          MessageComponent={confirmDialogMessage}
        />

        <AreaRow sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Identity
            sx={{ gridColumnEnd: 'span 2' }}
            serialNumber={device.serialNumber}
            model={device.model}
            name={device.name}
            metaData={device?.metaData}
            brandName={device.brandName}
          />
          <Firmware
            serialNumber={device.serialNumber}
            firmwareVersion={device.swVersion}
            tooltipMessage={otaTooltipMessage || ''}
            hasPendingOTAUpdate={hasPendingOTAUpdate}
            otaPollingRequestedAt={otaPollingRequestAt}
            openOTADialog={openDialog}
            canOTAUpdate={canOTAUpdate}
            currentOTAVersion={currentOTAVersion}
            isDeviceOnline={isDeviceOnline}
            handleFirmwareAreaClick={handleFirmwareAreaClick}
            handleFirmwareAreaNavigation={handleFirmwareAreaNavigation}
            info={info}
            sx={isRebootSupported ? { gridRowEnd: 'span 2' } : {}}
          />
          <Status
            device={device}
            isDeviceOnline={isDeviceOnline}
            info={info}
            updatedAt={device.updatedAt}
            sx={{ display: 'flex', flex: 1 }}
          />
          <RenderIf condition={isRebootSupported || false}>
            <CommandButton
              disabledReasons={[
                { disabled: !isDeviceOnline, reason: t('cantRebootDeviceOffline') as string },
                { disabled: isLoadingReboot, reason: t('rebootInProgress') as string },
                { disabled: device.operatingMode === 'Disabled', reason: t('cantRebootWhileDisabled') as string },
                { disabled: hasPendingCommmands, reason: t('pendingCommands') as string },
                { disabled: hasPendingOperatingModeUpdate, reason: t('pendingOperatingModeUpdate') as string },
              ]}
              color={operatingModeColors.Reboot}
              handler={() => setConfirmDialogOpen(true)}
              text={t('reboot')}
              Icon={RestartAltOutlinedIcon}
              isLoading={isLoadingReboot}
            />
          </RenderIf>
        </AreaRow>

        <AreaRow sx={{ flexBasis: 0 }}>
          <Area paddingRenderer="section" sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', height: '100%' }}>
              <Address stringAddress={stringAddress} device={device} />
              <Box sx={{ borderRadius: 1, overflow: 'hidden', height: '350px', flexGrow: 2, position: 'relative' }}>
                {MemoizedMap}
              </Box>
            </Box>
          </Area>
        </AreaRow>
      </OverviewCard>
    </Fragment>
  );
}

export default React.memo(DeviceInfoCard);
