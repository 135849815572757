import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ThemeProvider,
  Typography,
  createTheme,
  darken,
  lighten,
  useTheme,
} from '@mui/material';
import AppBarButton from './AppBarButton';
import AppBarIconButton from './AppBarIconButton';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { getAppDrawerBaseRoute, getAppDrawerChildRoutes, getAppDrawerRoutes } from '@/components/Core/utils';
import useIsTablet from '@/hooks/useIsTablet';
import MuiAppBar from '@mui/material/AppBar';
import LogoExtended from '@/assets/logo-extended.svg';
import { Link } from 'react-router-dom';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useGetCurrentUserProfileQuery } from '@/redux/api/usersApiSlice';
import { User } from '@typings';
import NotificationsManager from '../Notification/Manager';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { operationsUnreadSelector } from '@/redux/slices/operationSlice';
import SnackbarsManager from '../Snackbar/Manager';
import SlidingDiv from '@/components/Shared/Animated/SlidingDiv';

function AppHeader() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const appDrawerBaseRoute = getAppDrawerBaseRoute(location);
  const navigate = useNavigate();
  const [currentPopperPath, setCurrentPopperPath] = useState<any | null>(null);
  const dropDownButtonRef = useRef<HTMLButtonElement>(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [navigateTarget, setNavigateTarget] = useState<string | null>(null);
  const [renderPopper, setRenderPopper] = useState<boolean>(false);
  const isHome = location.pathname === '/';
  const routePathComplexity = location?.pathname?.split('/').flatMap((path) => path || []).length || 1;
  const childRoutes = getAppDrawerChildRoutes(location);
  const unreadNotifications = useSelector((state: AppState) => operationsUnreadSelector(state, true));

  useEffect(() => {
    setRenderPopper(false);
    setOpenDropDown(false);
  }, [location]);

  const {
    data,
    isLoading: isLoadingProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetCurrentUserProfileQuery();
  const profile: User = data?.data || ({} as User);

  const isTablet = useIsTablet();

  const handleDropDownToggle = () => {
    setRenderPopper(true);
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const handleDropDownClose = (event: Event | React.SyntheticEvent) => {
    if (dropDownButtonRef.current && dropDownButtonRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenDropDown(false);
  };

  function handleDropDownListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenDropDown(false);
    } else if (event.key === 'Escape') {
      setOpenDropDown(false);
    }
  }

  const getCurrentPopperPath = useCallback(
    () =>
      [
        ...[...childRoutes].filter((childRoute) =>
          location.pathname.split('/').some((path) => path === childRoute.key)
        ),
      ].filter((currentPopperItem) => currentPopperItem && currentPopperItem !== undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  useLayoutEffect(() => {
    setCurrentPopperPath(getCurrentPopperPath()[0] || '');
  }, [getCurrentPopperPath, location.pathname]);

  const renderCurrentPopperItem = () =>
    currentPopperPath &&
    currentPopperPath.key && (
      <AppBarIconButton
        key={currentPopperPath.key}
        ref={dropDownButtonRef}
        id={`${currentPopperPath.key}AppBarItem`}
        aria-controls={openDropDown ? 'composition-menu' : undefined}
        aria-expanded={openDropDown ? 'true' : undefined}
        aria-haspopup="true"
        onClick={() => (childRoutes.length > 1 ? handleDropDownToggle() : null)}
        openDropDown={openDropDown}
        sx={{
          minWidth: '11rem',
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.body1.fontSize,
          height: 'min-content',
          alignSelf: 'center',
          borderRadius: '8px',
          padding: '1.35rem 1.5rem',
          justifyContent: 'space-between',

          background: !openDropDown
            ? lighten(theme.palette.primary.main, 0.1)
            : darken(theme.palette.primary.main, 0.4),

          maxHeight: '1.8rem',
          ...(openDropDown && {
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
          }),
        }}
      >
        <Typography fontWeight={800} component={'p'} sx={{ marginLeft: '0' }}>
          {currentPopperPath.label}
        </Typography>
        {childRoutes.length > 1 && (
          <Box sx={{ position: 'relative', width: '24px', height: '21px' }}>
            <KeyboardArrowDownIcon sx={{ position: 'absolute', left: '6px' }} />
          </Box>
        )}
      </AppBarIconButton>
    );

  const renderPopperItems = useCallback(
    () =>
      [...getAppDrawerChildRoutes(location)].map((route) => (
        <MenuItem
          id={`${route.key}AppBarPopperItem`}
          key={route.key}
          // eslint-disable-next-line no-sequences
          onClick={(e) => (handleDropDownClose(e), setNavigateTarget(`/${appDrawerBaseRoute?.key}/${route.key}`))}
          sx={{
            paddingX: '1.5rem',
            paddingY: '0.625rem',
            background: darken(theme.palette.primary.main, 0.4),
            color: theme.palette.grey['300'],
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
              background: darken(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.contrastText,
              transition: 'background 0.2s',
            },
          }}
        >
          {route.label}
        </MenuItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appDrawerBaseRoute?.key, currentPopperPath, location, theme]
  );

  const renderAppBarButtons = useCallback(() => {
    const appDrawerBaseRoute = getAppDrawerBaseRoute(location);
    const iconComponent =
      appDrawerBaseRoute?.key && getAppDrawerRoutes()?.find((route) => route.key === appDrawerBaseRoute?.key)?.icon;
    return (
      appDrawerBaseRoute && (
        <AppBarButton
          id={`${appDrawerBaseRoute.key}AppBarItem`}
          key={appDrawerBaseRoute.key}
          onClick={() => navigate(`/${appDrawerBaseRoute.key}`)}
        >
          {iconComponent ? React.cloneElement(iconComponent, { sx: { marginRight: '0.5rem' } }) : null}
          {appDrawerBaseRoute.label}
        </AppBarButton>
      )
    );
  }, [navigate, location]);

  const DEFAULT_APP_BAR_DROP_DOWN_POPPER_WIDTH = 'auto';
  const [appBarDropDownPopperWidth, setAppBarDropDownPopperWidth] = useState<string | undefined>(
    DEFAULT_APP_BAR_DROP_DOWN_POPPER_WIDTH
  );

  useLayoutEffect(() => {
    if (currentPopperPath?.key) {
      const width = document.getElementById(`${currentPopperPath.key}AppBarItem`)?.offsetWidth || 100;
      setAppBarDropDownPopperWidth('' + width / 16);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPopperPath?.key, document.getElementById(`${currentPopperPath?.key}AppBarItem`)?.offsetWidth]);

  const popperAnchorRef = useRef<HTMLDivElement>(null);
  const buttonNotificationRef = useRef<HTMLButtonElement>(null);
  const [openNotification, setOpenNotification] = useState(false);
  const [isNotificationPopperHidden, setIsNotificationPopperHidden] = useState(true);
  const [notificationTarget, setNotificationTarget] = useState<string | null>(null);
  const handleNavigationToggle = () => {
    setOpenNotification((prevOpen) => !prevOpen);
    !openNotification && setIsNotificationPopperHidden(false);
  };

  const handleNotificationClose = (event: Event | React.SyntheticEvent) => {
    if (buttonNotificationRef.current && buttonNotificationRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenNotification(false);
  };

  const drawerTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      text: {
        ...theme.palette.text,
        primary: theme.palette.grey['900'],
      },
    },
  });

  return (
    <MuiAppBar sx={{ position: 'fixed', bgcolor: darken(theme.palette.primary.main, 0.2), gap: 2 }}>
      <Toolbar sx={{ alignItems: 'stretch', gap: 2, px: 2 }} disableGutters>
        <Link
          id="logoMenuItem"
          to="/"
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={LogoExtended} alt="Culligan Logo" style={{ height: '2rem' }} />
        </Link>
        <Box sx={{ flexGrow: 0, display: 'flex' }} id="paddingLeft">
          <>{renderAppBarButtons()}</>
          {!isHome && routePathComplexity > 1 && (
            <KeyboardArrowRight sx={{ alignSelf: 'center', color: theme.palette.grey['300'], opacity: 0.3 }} />
          )}
          {renderCurrentPopperItem()}

          {getAppDrawerChildRoutes(location).length > 1 && (
            <Popper
              open={openDropDown}
              anchorEl={dropDownButtonRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
              sx={{
                width: appBarDropDownPopperWidth + 'rem',
                display: renderPopper ? 'block' : 'none',
              }}
              onTransitionEnd={() => {
                if (!openDropDown) {
                  setRenderPopper(false);
                }
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                  onExited={() => {
                    if (navigateTarget) {
                      navigate(navigateTarget);
                      setNavigateTarget(null);
                    }
                  }}
                >
                  <Paper
                    square
                    sx={{
                      background: darken(theme.palette.primary.main, 0.4),
                      boxShadow: '0 0 0 0',
                      borderRadius: '0 0 8px 8px',
                      overflow: 'hidden',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleDropDownClose}>
                      <MenuList
                        autoFocusItem={openDropDown}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleDropDownListKeyDown}
                        sx={{ paddingY: 0 }}
                      >
                        {renderPopperItems()}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1.25,
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'flex-end',
          }}
        >
          {!isTablet && !isLoadingProfile && !isFetchingProfile && !isErrorProfile && (
            <Box>
              <Typography textAlign="right" lineHeight="1rem" fontWeight={200} fontSize="0.75rem" marginRight={1}>
                {t('greet', { user: profile.firstName + ' ' + profile.lastName })}
              </Typography>
            </Box>
          )}
          <Box sx={{ position: 'relative' }}>
            <IconButton
              ref={buttonNotificationRef}
              color="inherit"
              aria-label="open notifications"
              onClick={handleNavigationToggle}
              sx={{
                backgroundColor: lighten(theme.palette.primary.main, 0.1),
                borderRadius: '8px',
                '&:focus': {
                  outline: 'none',
                },
                ...(unreadNotifications.length > 0 && {
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '0.437rem',
                    height: '0.437rem',
                    background: theme.palette.error.main,
                    borderRadius: '50%',
                    top: '30%',
                    left: '55%',
                  },
                }),
              }}
            >
              {openNotification && <NotificationsIcon sx={{ pointerEvents: 'none' }} />}
              {!openNotification && <NotificationsTwoToneIcon sx={{ pointerEvents: 'none' }} />}
            </IconButton>
            <Box
              ref={popperAnchorRef}
              sx={{
                position: 'absolute',
                width: '0',
                height: '0',
                backgroundColor: 'blue',
                top: 'calc(100% + 30px)',
                right: 0,
              }}
            />
            <ThemeProvider theme={drawerTheme}>
              <Box sx={{ position: 'absolute', top: 'calc(100% + 30px)', right: 0 }}>
                <SlidingDiv trigger={isNotificationPopperHidden} direction="right" amount={416}>
                  <SnackbarsManager />
                </SlidingDiv>
                <Popper
                  open={openNotification}
                  anchorEl={popperAnchorRef.current}
                  role={undefined}
                  placement="bottom-end"
                  transition
                  disablePortal
                  sx={{
                    display: isNotificationPopperHidden ? 'none' : 'block',
                  }}
                  onTransitionEnd={() => !openNotification && setIsNotificationPopperHidden(true)}
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: 'right top',
                      }}
                      onExited={() => {
                        if (notificationTarget) {
                          navigate(notificationTarget);
                          setNotificationTarget(null);
                        }
                      }}
                    >
                      <Paper
                        sx={{
                          boxShadow: theme.shadows[10],
                          overflow: 'hidden',
                          width: '400px',
                          p: 2,
                          borderRadius: 2,
                        }}
                      >
                        <ClickAwayListener onClickAway={handleNotificationClose}>
                          <Box>
                            <NotificationsManager />
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            </ThemeProvider>
          </Box>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppHeader;
