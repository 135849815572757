import apiSlice, { API_VERSION } from './apiSlice';
import { ApiResult, User } from '@typings';

const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<ApiResult<User>, string>({
      query: (id) => ({
        url: `${API_VERSION.v1}/admin/user/account`,
        params: { userId: id },
      }),
    }),
    getCurrentUserProfile: builder.query<ApiResult<User>, void>({
      query: () => `${API_VERSION.v1}/user/profile`,
    }),
  }),
});

export const { useGetUserQuery, useGetCurrentUserProfileQuery } = usersApiSlice;
