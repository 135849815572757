import { ParseError } from '@effect/schema/ParseResult';
import { Either, pipe } from 'effect';
import { Either as EitherT } from 'effect/Either';
import { useCallback, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryStringFilters = <
  DecodedFilterState extends Record<string, unknown>,
  EncodedFilterState extends Record<string, string>
>({
  defaultFilterValues,
  encode,
  decode,
}: {
  defaultFilterValues: EncodedFilterState;
  encode: (decodedState: DecodedFilterState) => EitherT<EncodedFilterState, ParseError>;
  decode: (encodedState: EncodedFilterState) => EitherT<DecodedFilterState, ParseError>;
}) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams(new URLSearchParams(defaultFilterValues));
  const hasFilterHydrationCompleted = useRef(false);

  const notifyHydrationCompleted = useCallback(() => {
    hasFilterHydrationCompleted.current = true;
  }, []);

  const getQueryFilters = useCallback((): DecodedFilterState => {
    const currentEncodedState = Object.fromEntries(urlSearchParams.entries()) as EncodedFilterState;
    return pipe(
      decode(currentEncodedState),
      Either.map((filters) => {
        if (filters.page && typeof filters.page === 'number') {
          return { ...filters, page: filters.page - 1 };
        }
        return filters;
      }),
      Either.getOrElse((error) => {
        console.error('Error decoding filter values:', error);
        return {} as DecodedFilterState;
      })
    );
  }, [urlSearchParams, decode]);

  const setQueryFilters = useCallback(
    (toSet: Array<{ key: keyof DecodedFilterState; value: DecodedFilterState[keyof DecodedFilterState] }>) => {
      const maybePayload = toSet.reduce<DecodedFilterState>((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {} as DecodedFilterState);

      const result = encode(maybePayload);
      if (Either.isRight(result)) {
        const payload = result.right;
        for (const key in payload) {
          urlSearchParams.set(key, payload[key]);
        }
        return setUrlSearchParams(urlSearchParams, { replace: true });
      }
      throw result.left;
    },
    [encode, setUrlSearchParams, urlSearchParams]
  );

  const stabilizedReturnValues = useMemo(
    () => ({
      urlSearchParams,
      setUrlSearchParams,
      getQueryFilters,
      setQueryFilters,
      hasFilterHydrationCompleted,
      notifyHydrationCompleted,
    }),
    [getQueryFilters, notifyHydrationCompleted, setQueryFilters, setUrlSearchParams, urlSearchParams]
  );

  return stabilizedReturnValues;
};
