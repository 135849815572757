import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { styled, CSSObject, useTheme, darken, alpha } from '@mui/material/styles';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { CulliganDialogProps, TabButtonProps } from './typings';

const TabButtonCurrentStyle: CSSObject = {
  borderRadius: '0.25rem',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  '&:focus': {
    outline: 'none',
  },
};

const TabButton = styled(Button, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'mode' && prop !== 'singleTab',
})<TabButtonProps>(({ mode, singleTab, theme }) => ({
  backgroundColor: theme.palette.background.grayShades[0],
  padding: '0 1rem',
  outline: 'none',
  ...(singleTab ? { '&:hover': { cursor: 'default', backgroundColor: theme.palette.background.grayShades[0] } } : {}),
  ...(!singleTab
    ? {
        '&:hover': {
          background: darken(theme.palette.background.grayShades[0], 0.2),
          color: theme.palette.getContrastText(darken(theme.palette.background.grayShades[0], 0.2)),
          outline: 'none',
        },
      }
    : {}),
  '&:focus': {
    outline: 'none',
  },
  color: theme.palette.getContrastText(darken(theme.palette.background.grayShades[0], 0.2)),
  ...(mode === 'current' && !singleTab
    ? {
        ...TabButtonCurrentStyle,
        backgroundColor: theme.palette.background.paper,
        borderBottom: 0,
        color: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.background.paper,
          color: theme.palette.primary.main,
          outline: 'none',
        },
      }
    : {}),
}));

function CulliganDialog({
  activeTab,
  setActiveTab,
  id,
  open,
  handleClose,
  tabs,
  styles,
  ...props
}: CulliganDialogProps) {
  const theme = useTheme();

  useEffect(() => {
    if (document) {
      document.getElementById('currentDialogTab')?.scrollIntoView(false);
    }
  }, [activeTab]);

  const dialog = (
    <Dialog
      open={open}
      id={id}
      onClose={(_, reason) => handleClose(_, reason)}
      keepMounted={false}
      sx={{
        '& .MuiPaper-root': { backgroundColor: theme.palette.background.grayShades[0] },
        ...styles?.dialogContainer,
        '@media print': {
          display: 'none',
        },
      }}
      {...props}
    >
      <DialogContent
        sx={{
          p: 0,
          backgroundColor: theme.palette.background.grayShades[0],
          display: 'grid',
          gridTemplate: 'auto / 4fr auto',
          height: 'fit-content',
          overflowY: 'unset',
          zIndex: 1,
          '&::after': {
            content: '""',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            gridColumnStart: 1,
            gridColumnEnd: 3,
            borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Box
          sx={{
            // overflowX: 'scroll',
            display: 'flex',
            ...(tabs.length === 1 ? { backgroundColor: theme.palette.background.grayShades[0] } : {}),
          }}
        >
          {tabs.map((tab, i) => (
            <Box display={'inline-block'} key={tab.id}>
              <TabButton
                mode={activeTab === i ? 'current' : 'default'}
                onClick={() => setActiveTab(i)}
                singleTab={tabs.length === 1}
                {...(activeTab === i ? { id: 'currentDialogTab' } : {})}
                sx={{
                  borderRadius: 0,
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  height: '100%',
                }}
              >
                {tab.label}
              </TabButton>
            </Box>
          ))}
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="flex-end"
          {...(tabs.length === 1 ? { sx: { backgroundColor: theme.palette.background.grayShades[0] } } : {})}
        >
          <IconButton
            onClick={() => handleClose({}, 'escapeKeyDown')}
            sx={{
              borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
              borderRadius: 0,
              backgroundColor: theme.palette.background.grayShades[1],
              color: theme.palette.getContrastText(theme.palette.background.grayShades[0]),
              ...(tabs.length === 1 ? { backgroundColor: theme.palette.background.grayShades[0] } : {}),
              p: 1.5,
              '&:hover': {
                borderLeft: '0.0625rem solid rgba(0, 0, 0, 0.1)',
                borderBottom: 'none',
                backgroundColor: darken(theme.palette.background.grayShades[0], 0.2),
                ...(tabs.length === 1 ? { backgroundColor: theme.palette.background.grayShades[1] } : {}),
              },
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogContent
        sx={{ p: 0, backgroundColor: 'background.default', ...styles?.dialogContentContainer }}
        dividers={false}
      >
        <Box sx={{ p: 1, ...styles?.bodyContainer }}>
          <Box>{tabs[activeTab].body}</Box>
        </Box>
      </DialogContent>
    </Dialog>
  );

  return dialog;
}

export default CulliganDialog;
