import { Box, BoxProps, useTheme, alpha } from '@mui/material';
import React, { ReactNode } from 'react';

export default function Section({
  children,
  variant = 'default',
  ...props
}: { children: ReactNode; variant?: 'color' | 'default' } & BoxProps) {
  const theme = useTheme();
  return (
    <Box
      {...props}
      {...(variant === 'default' ? { mb: '2rem', sx: props.sx } : {})}
      {...(variant === 'color'
        ? {
            sx: {
              ...props.sx,
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              outline: `${alpha(theme.palette.primary.light, 0.1)} 2rem solid`,
            },
            mt: '4rem',
            mb: '4rem',
          }
        : {})}
    >
      {children}
    </Box>
  );
}
