import { useTranslation } from 'react-i18next';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import Telemetry from './Telemetry';
import Alarms from './Alarms';
import Commands from './Commands';
import Consumables from './Consumables';
import Reactions from './Reactions';
import Configs from './Configs';
import Events from './Events';
import ConsumableSubsets from './ConsumableSubsets';
import { CHANNELS } from '@/shared/constants';
import LoyaltyPrograms from './LoyaltyPrograms';

function Channels() {
  const { t } = useTranslation();

  const tabsConfig = [
    {
      id: CHANNELS.TELEMETRY,
      label: t(CHANNELS.TELEMETRY),
      ariaControls: true,
      ContentComponent: <Telemetry />,
    },
    {
      id: CHANNELS.EVENTS,
      label: t(CHANNELS.EVENTS),
      ariaControls: true,
      ContentComponent: <Events />,
    },
    {
      id: CHANNELS.ALARMS,
      label: t(CHANNELS.ALARMS),
      ariaControls: true,
      ContentComponent: <Alarms />,
    },
    {
      id: CHANNELS.COMMANDS,
      label: t(CHANNELS.COMMANDS),
      ariaControls: true,
      ContentComponent: <Commands />,
    },
    {
      id: CHANNELS.CONSUMABLES,
      label: t(CHANNELS.CONSUMABLES),
      ariaControls: true,
      ContentComponent: <Consumables />,
    },
    {
      id: CHANNELS.CONSUMABLE_SUBSETS,
      label: t(CHANNELS.CONSUMABLE_SUBSETS),
      ariaControls: true,
      ContentComponent: <ConsumableSubsets />,
    },
    {
      id: CHANNELS.REACTIONS,
      label: t(CHANNELS.REACTIONS),
      ariaControls: true,
      ContentComponent: <Reactions />,
    },
    {
      id: CHANNELS.CONFIGS,
      label: t(CHANNELS.CONFIGS),
      ariaControls: true,
      ContentComponent: <Configs />,
    },
    {
      id: CHANNELS.LOYALTY_PROGRAMS,
      label: t(CHANNELS.LOYALTY_PROGRAMS),
      ariaControls: true,
      ContentComponent: <LoyaltyPrograms />,
    },
  ];

  return <NavigationTabsLayout config={tabsConfig} />;
}

export default Channels;
