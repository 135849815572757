import StoreFilters from '@/components/Shared/Filters/StoreFilters';
import { StoreFilterConfig } from '@/components/Shared/Filters/typings';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ISSUE_STATUS } from '@/shared/constants';
import { DateRangeDefaultValue } from '@/shared/utils';
import { IssueStatus } from '@typings';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrorColumns from './useErrorsColumn';
import { useLazyGetFilteredErrorsQuery } from '@/redux/api/fleet/errorsApiSlice';
import { FleetError } from './typings';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { Box } from '@mui/material';

export const ErrorsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<FleetError>));

export default function ErrorsPanel({
  filters = true,
  pageSize = 10,
  selection = true,
  search = true,
  exportData = true,
  hideTableHeader = false,
  defaultRange = {
    end: dayjs().endOf('day'),
    start: dayjs().subtract(1, 'month').startOf('day'),
  },
  isFleet,
}: {
  navigationTabs?: boolean;
  filters?: boolean;
  pageSize?: number;
  selection?: boolean;
  search?: boolean;
  exportData?: boolean;
  hideTableHeader?: boolean;
  defaultRange?: {
    end: Dayjs;
    start: Dayjs;
  };
  isFleet: boolean;
}) {
  const [getFilteredErrors, { isError, isFetching, isLoading, data }] = useLazyGetFilteredErrorsQuery();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedError, setSelectedError] = useState<FleetError | null>(null);

  const { t } = useTranslation();

  const dateRangeDefaultValue = useMemo(
    () => new DateRangeDefaultValue(defaultRange.start, defaultRange.end),
    [defaultRange.end, defaultRange.start]
  );

  useLayoutEffect(() => {
    getFilteredErrors({
      dateRangeEnd: +dateRangeDefaultValue.end,
      dateRangeStart: +dateRangeDefaultValue.start,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterConfig: StoreFilterConfig[] = useMemo(
    () => [
      {
        id: 'dateRange',
        label: t('dateRange'),
        kind: 'dateRangeWithFixedRanges',
        defaultValue: dateRangeDefaultValue.unix(),
      },
      {
        id: 'status',
        label: t('status'),
        kind: 'autocomplete',
        options: [
          {
            label: t('both'),
            optionId: 'both',
          },
          {
            label: t(ISSUE_STATUS.ACTIVE),
            optionId: ISSUE_STATUS.ACTIVE,
          },
          {
            label: t('resolved'),
            optionId: ISSUE_STATUS.DISMISSED,
          },
        ],
        defaultValue: {
          label: t('both'),
          optionId: 'both',
        },
      },
    ],
    [dateRangeDefaultValue, t]
  );

  const handleFiltersApplied = useCallback(
    (filtersApplied: Map<string, string>) => {
      const filterParams = Object.fromEntries(filtersApplied);

      getFilteredErrors({
        ...(filterParams.dateRange
          ? {
              dateRangeEnd: dayjs(JSON.parse(filterParams.dateRange)?.end).valueOf() as number,
              dateRangeStart: dayjs(JSON.parse(filterParams.dateRange)?.start).valueOf() as number,
            }
          : {
              dateRangeEnd: +dateRangeDefaultValue.end,
              dateRangeStart: +dateRangeDefaultValue.start,
            }),
        ...(filterParams.status && filterParams.status !== 'both'
          ? { status: filterParams.status as IssueStatus }
          : {}),
      });
    },
    [dateRangeDefaultValue.end, dateRangeDefaultValue.start, getFilteredErrors]
  );

  const handleFiltersCleared = () => {
    getFilteredErrors({
      dateRangeEnd: +dateRangeDefaultValue.end,
      dateRangeStart: +dateRangeDefaultValue.start,
    });
  };

  const handleOpenDialog = (id: string) => {
    const error = data?.data?.items.find((error) => error.id === id) as FleetError;
    setSelectedError(error);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedError(null);
    setOpenDialog(false);
  };

  const columns = useErrorColumns({
    onDetailClick: handleOpenDialog,
  });

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'error',
        label: t('error'),
        body: selectedError && <IssueDetails data={selectedError} isFleet={isFleet} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  return (
    <Box>
      {filters && (
        <StoreFilters
          filterConfigs={filterConfig as StoreFilterConfig[]}
          onFiltersApplied={handleFiltersApplied}
          onFiltersCleared={handleFiltersCleared}
        />
      )}

      <ErrorsTable
        title={t('errors')}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading || isFetching}
        isError={isError}
        resetStateButtonVisible={false}
        exportData={exportData}
        selection={selection}
        options={{
          pageSize,
        }}
        search={search}
        toolbar={!hideTableHeader}
      />

      {openDialog && dialog}
    </Box>
  );
}
