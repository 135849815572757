import { PRIMITIVES } from '@/shared/constants';

export type DeviceTelemetries = {
  lastUpdate?: number;
  telemetries?: DeviceTelemetry[];
};

export type DeviceTelemetry = BaseDeviceTelemetry &
  (DeviceTelemetryWithNumber | DeviceTelemetryWithString | DeviceTelemetryWithBoolean);

export type DeviceTelemetryWithGroup = {
  tag: string | undefined;
  items: DeviceTelemetry[];
} | null;

export type BaseDeviceTelemetry = {
  id: string;
  name: string;
  unitOfMeasure: string;
  description?: string;
  tags?: string[];
  lastUpdate?: number;
};

export type DeviceTelemetryWithNumber = {
  primitive: typeof PRIMITIVES.NUMBER;
  value?: number | string;
  min?: number;
  max?: number;
};

export type DeviceTelemetryWithString = {
  primitive: typeof PRIMITIVES.STRING;
  value?: string;
  expectedValue?: string;
};

export type DeviceTelemetryWithBoolean = {
  primitive: typeof PRIMITIVES.BOOLEAN;
  value?: boolean | string;
  expectedValue?: boolean | string;
};

export type DeviceHistoryTelemetryRequest = {
  dateRangeEnd: number;
  dateRangeStart: number;
  deviceId: string;
  keys: string[];
};

export type HistoryTelemetry = {
  lastUpdate: number;
} & {
  [key in string]?: number | boolean | string;
};

export const ITEM_TYPE = {
  SINGLE: 'single',
  GROUP: 'group',
} as const;

export type SingleMergedTelemetry = {
  id: string;
  name: string;
  selected: boolean;
  active: boolean;
  type: 'single';
  items: undefined;
};

export type MergedTelemetry =
  | SingleMergedTelemetry
  | {
      id: string | undefined;
      name: string | undefined;
      active: boolean;
      selected: boolean;
      type: 'group';
      items: SingleMergedTelemetry[] | undefined;
    };
