import { AppState } from '@/redux/store';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import Poll from './Poll';
import { pollingInitialState } from '@/redux/slices/pollingSlice';

export default function PollingsPool() {
  const pollings = useSelector((state: AppState) => state.pollings.polls);

  const polls = useMemo(() => {
    return Object.entries(pollings).map(([endpoint, pollings]) => {
      return Object.entries(pollings).map(([id, polling]) => (
        <Poll
          endpoint={endpoint as keyof typeof pollingInitialState.polls}
          polling={polling}
          id={id}
          key={endpoint + id + polling.pollingRequestedAt}
        />
      ));
    });
  }, [pollings]);

  return <>{polls}</>;
}
