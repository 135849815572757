import { Alert, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import LocalFilters from '@/components/Shared/Filters/LocalFilters';
import DevicesPanel from '@/components/Fleet/Devices/DevicesPanel';
import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';
import Map from '@/components/Shared/Map/index';
import { MarkerInfo } from '@/components/Shared/Map/typings';
import { Fragment, useMemo } from 'react';
import { MARKER_TYPE } from '@/shared/constants';
import { isValidNumber } from '@/shared/utils';
import DevicesWithoutLocationPanel from '@/components/Fleet/Devices/DevicesPanel/DevicesWithoutLocationPanel';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import useDevices from '@/hooks/useDevices';

const Devices = () => {
  const { t } = useTranslation();
  const { remoteFetchState, filterConfigs, applyFilters, filteredData } = useDevices();
  const { devicesWithCoordinates, devicesWithoutCoordinates } = useMemo(
    () =>
      filteredData?.reduce(
        (acc: { devicesWithCoordinates: Device[]; devicesWithoutCoordinates: Device[] }, device: Device) => {
          isValidNumber(device.lat) && isValidNumber(device.lon)
            ? acc.devicesWithCoordinates.push(device)
            : acc.devicesWithoutCoordinates.push(device);
          return acc;
        },
        {
          devicesWithCoordinates: [],
          devicesWithoutCoordinates: [],
        }
      ),
    [filteredData]
  );

  const markers: MarkerInfo[] = useMemo(() => {
    return devicesWithCoordinates?.map((fd) => ({
      position: { lat: fd.lat, lng: fd.lon },
      title: fd.name || '',
      description: fd.serialNumber,
      type: MARKER_TYPE.DEVICE,
      extra: fd,
    }));
  }, [devicesWithCoordinates]);

  const tabsConfig = [
    {
      id: 'devices',
      label: t('devicesList'),
      ariaControls: true,
      ContentComponent: <DevicesPanel data={filteredData} title={t('devices')} {...remoteFetchState} />,
    },
    {
      id: 'map',
      label: t('devicesMap'),
      ContentComponent: (
        <Fragment>
          <Typography variant="h4" pb={2}>
            {t('devicesMap')}
          </Typography>
          <Alert
            severity="info"
            sx={{
              margin: '12px 0',
              paddingTop: 0,
              paddingBottom: 0,
              backgroundColor: '#f6f6f6',
              position: 'relative',
              height: 'min-content',
              '.MuiAlert-icon': { fontSize: '18px' },
            }}
          >
            {t('markerInfo')}
          </Alert>
          <Box border={0} position="relative" height="800px">
            <Map markers={markers} noMarkersMessage={t('noDevicesFound') as string} />
          </Box>

          <RenderIf condition={devicesWithoutCoordinates.length > 0}>
            <DevicesWithoutLocationPanel devices={devicesWithoutCoordinates} />
          </RenderIf>
        </Fragment>
      ),
    },
  ];

  return (
    <Box>
      <LocalFilters<Device> filterConfigs={filterConfigs} onFiltersApplied={applyFilters} />
      <NavigationTabsLayout config={tabsConfig} />
    </Box>
  );
};

export default Devices;
