import { Box, Button, Dialog, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocalFilters, { useFilters } from '@/components/Shared/Filters/LocalFilters';
import ConsumablesPanel from '@/components/Fleet/Consumables/ConsumablesPanel';
import { LocalFilterConfig } from '@/components/Shared/Filters/typings';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CHANNELS, CONSUMABLE_KIND } from '@/shared/constants';
import { autocompleteFilterDefaultAssert } from '@/components/Shared/Filters/AutocompleteFilter';
import { ShoppingCartCheckout } from '@mui/icons-material';
import ConsumablesShoppingDialog from '@/components/Fleet/Consumables/ConsumableShoppingDialog';
import { ConsumableKind, Device, ShoppingDialogDetails } from '@/components/Fleet/Devices/DevicesPanel/typings';
import { useConsumablesDeviceTableColumns } from '@/components/Fleet/Consumables/useConsumablesDeviceTableColumns';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { useGetDevicesWithConsumablesQuery } from '@/redux/api/fleet/devicesWithConsumablesApiSlice';
import { useDevicesOptions } from '@/hooks/useDevicesOptions';

const Consumables = () => {
  const { t } = useTranslation();

  const [noDeviceWithLowConsumables, setNoDeviceWithLowConsumables] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConsumableKind>(CONSUMABLE_KIND.ENHANCEMENTS);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>();

  const { data, isLoading, isError } = useGetDevicesWithConsumablesQuery();
  const normalise = (value: any, min: any, max: any) => Math.round(((value - min) * 100) / (max - min));
  const devicesAutocompleteOptions = useDevicesOptions(data?.data?.items || []);

  const { applyFilters, filteredData } = useFilters<Device>(data?.data?.items || []);

  const devicesWithConsumables = filteredData.filter((data) => data?.consumables.length > 0);
  /*
   * Disabled until further notice.
   * const locations = useMemo(() => {
   *   const filteredDevices = data?.data?.items;
   *  return filteredDevices ? [...new Set(filteredDevices.map((device) => device.region?.code))] : [];
   * }, [data]);
   */

  const [shoppingDialogDetails, setShoppingDialogDetails] = useState<ShoppingDialogDetails>({
    devices: [],
    consumableType: 'all',
  });

  const handleFillLowItemsByAllTypes = () => {
    const devicesWithLowConsumables = devicesWithConsumables.filter((deviceWithConsumables) =>
      deviceWithConsumables.consumables.some(
        (consumable: any) =>
          normalise(consumable.current, consumable.subset?.rangeExhaust || 0, consumable.subset?.rangeFullCapacity) <=
          20
      )
    );

    if (selectedDevices) {
      const devicesSelectedWithLowConsumables = selectedDevices.filter((device: Device) =>
        device.consumables.some(
          (consumable: any) =>
            normalise(consumable.current, consumable.subset?.rangeExhaust || 0, consumable.subset?.rangeFullCapacity) <=
            20
        )
      );
      setShoppingDialogDetails({
        devices: devicesSelectedWithLowConsumables,
        consumableType: activeTab,
      });
    } else {
      setShoppingDialogDetails({
        devices: devicesWithLowConsumables,
        consumableType: 'all',
      });
    }

    if (!devicesWithLowConsumables || devicesWithLowConsumables.length <= 0) {
      setNoDeviceWithLowConsumables(true);
    }
  };

  const selectedRowsID = useRef<Array<string> | undefined>([]);

  const handleSelection = useCallback((data: Device[]) => {
    if (selectedRowsID?.current?.length === 0) {
      selectedRowsID.current = undefined;
    } else {
      selectedRowsID.current = data.map((row) => row.id);
    }
    setSelectedDevices(selectedRowsID?.current?.length !== 0 ? data : undefined);
  }, []);

  function useDynamicConsumableColumns(activeTabType: ConsumableKind) {
    const columns = useConsumablesDeviceTableColumns(activeTabType);
    return columns;
  }

  const tabsConfig = useMemo(
    () =>
      Object.entries(CONSUMABLE_KIND).map(([key, value]) => ({
        id: key,
        label: t(value),
        ariaControl: true,
        ContentComponent: () => {
          const columns = useDynamicConsumableColumns(value);
          return (
            <ConsumablesPanel
              data={filteredData.filter(
                (data) =>
                  data?.consumables.length > 0 &&
                  data.consumables.some((consumable) => consumable.consumable.kind === value) &&
                  data.consumables.some(
                    // eslint-disable-next-line eqeqeq
                    (consumable) => consumable.current != undefined && consumable.current >= 0
                  )
              )}
              title={t(value)}
              columns={columns}
              isError={isError}
              isLoading={isLoading}
              exportData={false}
              handleSelection={handleSelection}
              selectedRowsID={selectedRowsID}
            />
          );
        },
      })),
    [t, isError, isLoading, filteredData, handleSelection]
  );

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (hash) {
      setActiveTab(hash as ConsumableKind);
      setSelectedDevices(undefined);
    }
  }, [activeTab]);

  const fillingFilterAssert = (device: Device, lower: number | undefined, upper: number | undefined): boolean => {
    const levels = device.consumables.map((consumable: any) =>
      normalise(consumable.current, consumable.subset?.rangeExhaust || 0, consumable.subset?.rangeFullCapacity)
    );

    if (lower !== undefined && upper === undefined) {
      return levels.some((level) => level <= lower);
    } else if (lower === undefined && upper !== undefined) {
      return levels.some((level) => level >= upper);
    } else if (lower !== undefined && upper !== undefined) {
      return levels.some((level) => level >= lower && level <= upper);
    }
    return false;
  };

  const filterConfigs: LocalFilterConfig<Device>[] = [
    {
      kind: 'autocomplete',
      id: 'consumablesAutocompleteDeviceType',
      label: t('deviceName'),
      options:
        devicesAutocompleteOptions?.map((dt) => ({
          label: dt.label,
          optionId: dt.value,
          filterFx: (d) => autocompleteFilterDefaultAssert(d.model, dt.value),
        })) || [],
    },
    /*
     * Disabled until further notice.
     * {
     *   kind: 'autocomplete',
     *   id: 'consumablesAutocompleteLocationArea',
     *   label: t('locationArea'),
     *   options: locations.map((l) => ({
     *     label: l,
     *     optionId: l,
     *     filterFx: (d) => autocompleteFilterDefaultAssert(d.region.code, l),
     *   })),
     * },
     */
    {
      kind: 'autocomplete',
      id: 'consumablesAutocompleteConsumablesFilling',
      label: 'Consumables Filling',
      options: [
        {
          label: '< 20%',
          optionId: 'lessThan20',
          filterFx: (d) => fillingFilterAssert(d, 20, undefined),
        },
        {
          label: '20% - 40%',
          optionId: '20To40',
          filterFx: (d) => fillingFilterAssert(d, 20, 40),
        },
        {
          label: '40% - 60%',
          optionId: '40To60',
          filterFx: (d) => fillingFilterAssert(d, 40, 60),
        },
        {
          label: '60% - 80%',
          optionId: '60To80',
          filterFx: (d) => fillingFilterAssert(d, 60, 80),
        },
        {
          label: '> 80%',
          optionId: 'moreThan80',
          filterFx: (d) => fillingFilterAssert(d, undefined, 80),
        },
      ],
    },
  ];

  return (
    <Box>
      <LocalFilters<Device> filterConfigs={filterConfigs} onFiltersApplied={applyFilters} />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Typography variant="h4" marginBottom={'1rem'}>
          {t(CHANNELS.CONSUMABLES)}
        </Typography>
        <Button
          sx={{ alignSelf: 'center' }}
          variant="contained"
          startIcon={<ShoppingCartCheckout />}
          onClick={() => handleFillLowItemsByAllTypes()}
        >
          {t('fillLowItems')}
        </Button>
      </Box>
      <NavigationTabsLayout config={tabsConfig} />
      {!!shoppingDialogDetails.devices.length && (
        <ConsumablesShoppingDialog
          devices={shoppingDialogDetails.devices}
          onClose={() => setShoppingDialogDetails({ devices: [], consumableType: 'all' })}
          consumableType={shoppingDialogDetails.consumableType}
        />
      )}
      <Dialog open={noDeviceWithLowConsumables} onClose={() => setNoDeviceWithLowConsumables(false)}>
        <DialogTitle>No devices with consumables levels lower than 20 </DialogTitle>
      </Dialog>
    </Box>
  );
};

export default Consumables;
