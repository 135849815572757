import { MutableRefObject, useCallback, useRef } from 'react';
import { Device } from './typings';
import { useDevicesTableColumns } from './useDevicesTableColumns';
import { Table } from '@/components/Shared/Tables/typings';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import ExportTable from '@/components/Shared/Tables/ExportTable';

const filterRowsBySelected = (data: Device[], selectedRowsID: MutableRefObject<string[]>) =>
  data.filter((row) => {
    if (!selectedRowsID.current || selectedRowsID.current.length === 0) {
      return true;
    }
    return selectedRowsID.current.includes(row.serialNumber);
  });

function DevicesPanel({ data, title }: Table<Device>) {
  const selectedRowsID = useRef<Array<string>>([]);

  const devicesTableColumns = useDevicesTableColumns();

  const handleSelection = useCallback((data: Device[]) => {
    selectedRowsID.current = [...data.map((row) => row.serialNumber)];
  }, []);

  const sortedData = [...data].sort((a, b) => {
    return a.status.connection.online === b.status.connection.online ? 0 : a.status.connection.online ? -1 : 1;
  });

  return (
    <ExportTable
      title={title}
      data={sortedData}
      columns={devicesTableColumns}
      handleSelection={handleSelection}
      filterRowsBySelected={filterRowsBySelected}
      selectedRowsID={selectedRowsID}
      options={{
        pageSize: 10,
        columnsButton: true,
      }}
    />
  );
}

export default withErrorLoadingManagement(DevicesPanel);
