import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CommandRes } from './typings';
import dayjs from 'dayjs';
import ExpandableField from '@/components/Shared/ExpandableField';
import { useNavigate } from 'react-router';
import { ROLES, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';

export function useCommandsColumns(
  onEditClick?: Function,
  onDeleteClick?: Function,
  itemsInDeviceIdentity?: CommandRes[]
): Array<Column<CommandRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns_array: Array<Column<CommandRes>> = [];

  return columns_array.concat([
    {
      title: t('key'),
      width: '15%',
      field: 'id',
    },
    {
      title: t('name'),
      width: '15%',
      field: 'name',
    },
    {
      title: t('description'),
      width: '15%',
      field: 'description',
    },
    {
      title: t('mandatory'),
      width: '15%',
      field: 'mandatory',
      render: (command) => (command.mandatory ? t('yes') : t('no')),
    },
    {
      title: t('parameters'),
      width: '15%',
      field: 'parameters',
      render: (command) => (command.parameters ? t('yes') : t('no')),
    },
    {
      title: t('direction'),
      width: '15%',
      field: 'direction',
    },
    {
      title: t('roles'),
      width: '20%',
      field: 'role',
      customFilterAndSearch: (filter, commands) => {
        const roles = commands?.role || [];
        const roleFilter = roles.some((role) => Object.values(ROLES).includes(role));

        return roleFilter && roles.some((role) => role.startsWith(filter));
      },
      render: (command) =>
        command.role?.map((r, index) => (
          <Typography sx={{ width: 'max-content' }} key={`${r}-${index}`}>
            {t(r)}
          </Typography>
        )),
      exportTransformer: (command) =>
        (command.role as string[])?.join ? (command.role as string[])?.join(', ') : command.role,
      sorting: false,
    },
    {
      title: t('type'),
      width: '20%',
      field: 'type',
      render: (command) => t(command.type),
    },
    {
      title: t('payloadExample'),
      width: '15%',
      field: 'payloadExample',
      render: (command) => command.payloadExample && <ExpandableField content={command.payloadExample} />,
      sorting: false,
    },
    {
      title: t('JSONSchema'),
      width: '15%',
      field: 'jsonSchema',
      render: (command) => command.jsonSchema && <ExpandableField content={command.jsonSchema} />,
      sorting: false,
    },
    {
      title: t('creationDate'),
      width: '15%',
      field: 'createdAt',
      render: (command) => dayjs(command.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      width: '15%',
      field: 'updatedAt',
      render: (command) => dayjs(command.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '11%',
      render: (command) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          {!itemsInDeviceIdentity ? (
            <>
              <Button variant="outlined" color="primary" onClick={onEditClick && (() => onEditClick(command.id))}>
                {t('edit')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={onDeleteClick && (() => onDeleteClick({ id: command.id, name: command.name }))}
              >
                {t('delete')}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#commands`, {
                  state: {
                    searchText: command.name,
                    highlight: {
                      refField: 'id',
                      matchValue: command.id,
                    },
                  },
                })
              }
            >
              {t('view')}
            </Button>
          )}
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ]);
}
