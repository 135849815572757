import { Alert, AlertColor, Box, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import zIndex from '@mui/material/styles/zIndex';

export default function LocalSnackBar() {
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  useEffect(() => {
    const handleToastEvent = (e: CustomEvent) => {
      setOpen(true);
      setMessage(e.detail.message);
      setSeverity(e.detail.severity);
      setPosition(e.detail.position);
    };
    window.addEventListener('toast', handleToastEvent as EventListener);

    return () => {
      window.removeEventListener('toast', handleToastEvent as EventListener);
    };
  }, []);
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => setOpen(false)}
      autoHideDuration={1000}
      sx={{
        zIndex: zIndex.tooltip + 500,

        width: 'max-content',
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
        pointerEvents: 'auto',
        '&.MuiSnackbar-root': {
          position: 'absolute',
          top: `${position.y}px`,
          left: `${position.x}px`,
        },
      }}
    >
      <Alert
        severity={severity as AlertColor}
        sx={{
          alignItems: 'center',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          border: '1px',
          '& .MuiAlert-icon': { fontSize: '16px' },
          '& .MuiAlert-action > * *': { pointerEvents: 'none' },
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography fontSize={'12px'}>{message}</Typography>
        </Box>
      </Alert>
    </Snackbar>
  );
}
