import useTypedSelector from '@/hooks/useTypedSelector';
import { useLazyGetOverviewQuery } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import {
  BusinessAnalisysFlatFilterState,
  BusinessAnalysisFiltersState,
  businessAnalisysSelectFlatValue,
  setFilters,
} from '@/redux/slices/BusinessAnalysis/businessAnalysisFiltersSlice';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useSearchParams } from 'react-router-dom';

type AggregatedData = {
  id: string;
  label: string;
  value: number;
};

type ExtendendAggregatedData = AggregatedData & {
  totalAmount: number;
  waterType: number;
};

type StatisticsData = {
  brands: { name: string; id: string }[];
  waterTypes: AggregatedData[];
  flavors: AggregatedData[];
  pourings: AggregatedData[];
  preferredDrinkCombinations: ExtendendAggregatedData[];
};

type CustomerResponse = {
  id: string;
  name: string;
} & StatisticsData;

export type StatisticsDataResponse = {
  customers: CustomerResponse[];
};

export default function useStatisticsData() {
  const businessAnalysisState = useTypedSelector(businessAnalisysSelectFlatValue);
  const dispatch = useDispatch();
  const [getOverviewData, resultOverviewData] = useLazyGetOverviewQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const valuesFromQueryString: Partial<BusinessAnalysisFiltersState> = {};

    searchParams.forEach((value, key) => {
      if (key !== 'dateRangeStart' && key !== 'dateRangeEnd' && !Object.keys(businessAnalysisState).includes(key)) {
        return;
      }
      if (key !== 'dateRangeStart' && key !== 'dateRangeEnd') {
        valuesFromQueryString[key as keyof Omit<BusinessAnalysisFiltersState, 'dateRange'>] = value;
        return;
      }
      const dateRangeEnd = searchParams.get('dateRangeEnd');
      if (key === 'dateRangeStart' && dateRangeEnd) {
        valuesFromQueryString.dateRange = {
          start: value,
          end: dateRangeEnd,
        };
      }
    });
    if (Object.keys(valuesFromQueryString).length > 0) {
      dispatch(setFilters(valuesFromQueryString));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const definedFilters = Object.keys(businessAnalysisState).filter(
      (key) => businessAnalysisState[key as keyof BusinessAnalisysFlatFilterState]
    );

    const nextSearchParams: Partial<BusinessAnalisysFlatFilterState> = {};
    definedFilters.forEach(
      (key) =>
        (nextSearchParams[key as keyof BusinessAnalisysFlatFilterState] =
          businessAnalysisState[key as keyof BusinessAnalisysFlatFilterState])
    );
    setSearchParams(nextSearchParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessAnalysisState]);

  useEffect(() => {
    if (
      Object.keys(businessAnalysisState).every(
        (key) => businessAnalysisState[key as keyof BusinessAnalisysFlatFilterState]
      )
    ) {
      const dateRangeStart = dayjs(Number(businessAnalysisState.dateRangeStart));
      const dateRangeEnd = dayjs(Number(businessAnalysisState.dateRangeEnd));
      const diffInDays = dateRangeEnd.diff(dateRangeStart, 'd');

      const filters = {
        ...businessAnalysisState,
        dateRangeStart: String(dateRangeEnd.subtract(diffInDays * 2, 'd').valueOf()),
      };
      getOverviewData(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessAnalysisState]);

  const filteredData = useMemo(() => {
    if (
      resultOverviewData.isError ||
      resultOverviewData.isFetching ||
      resultOverviewData.isLoading ||
      !resultOverviewData.data
    ) {
      return;
    }

    return resultOverviewData.data.data.items;
  }, [resultOverviewData]);

  const hasQueryParams = useMemo(() => {
    return [...searchParams.keys()].some((key) => Object.keys(businessAnalysisState).includes(key));
  }, [businessAnalysisState, searchParams]);

  return {
    filteredData,
    hasQueryParams,
  };
}
