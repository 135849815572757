import { SyntheticEvent } from 'react';
import { DateRange, DateRangeWithFixedRangeFilterProps, LocalOnFilterChange, StoreOnFilterChange } from './typings';
import DateRangeWithFixedRanges from './DateRangeWithFixedRanges';
import dayjs from 'dayjs';

export default function DateRangeWithFixedRangesFilter<T>({
  config,
  kind,
  onFilterChange,
  ...props
}: DateRangeWithFixedRangeFilterProps<T>) {
  return kind === 'local' ? (
    <DateRangeWithFixedRanges
      key={config.id}
      id={config.id}
      onChange={(_: SyntheticEvent<Element, Event>, dateRange: DateRange<number>) =>
        (onFilterChange as LocalOnFilterChange<T>)(config.id, config.filterFxHof(dateRange))
      }
      defaultValue={{
        start: dayjs().subtract(1, 'month').startOf('day').unix(),
        end: dayjs().endOf('day').unix(),
      }}
      sx={{ props }}
    />
  ) : (
    <DateRangeWithFixedRanges
      key={config.id}
      id={config.id}
      onChange={(_: SyntheticEvent<Element, Event>, dateRange: DateRange<number>) =>
        (onFilterChange as StoreOnFilterChange)(config.id, {
          label: 'dateRangeWithFixedRanges',
          optionId: JSON.stringify(dateRange),
        })
      }
      defaultValue={
        config.defaultValue || {
          start: dayjs().subtract(1, 'month').startOf('day').unix(),
          end: dayjs().endOf('day').unix(),
        }
      }
      sx={{ props }}
    />
  );
}
