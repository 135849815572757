import { TranslateFunction } from '@typings';
import { UseFormSetError } from 'react-hook-form';

export default function authErrorHandler(status: number, setError: UseFormSetError<any>, t: TranslateFunction) {
  switch (status) {
    case 422:
      setError('root', { message: t('unauthorizedError') as string });
      break;
    case 400:
      setError('root', { message: t('bodyValidationError') as string });
      break;
    default:
      setError('root', { message: t('serverError') as string });
  }
}
