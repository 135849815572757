import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

export default function withResetNavigationState(Component: React.FC | ((args: any) => JSX.Element | null)) {
  const WithResetNavigationState = ({
    resetStateButtonVisible = true,
    resetStateButtonLabel,
    onResetStateButtonClick,
    ...props
  }: {
    resetStateButtonVisible?: boolean;
    resetStateButtonLabel?: string;
    onResetStateButtonClick?: Function;
  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();

    const _onClick = () => {
      if (state) {
        navigate(state.from, { replace: true, state: null });
      }
      onResetStateButtonClick && onResetStateButtonClick();
    };

    return (
      <>
        <Component key={JSON.stringify(state)} {...props} />
        {resetStateButtonVisible && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
            <Button variant="contained" color="primary" onClick={_onClick}>
              {resetStateButtonLabel || t('resetNavigation')}
            </Button>
          </Box>
        )}
      </>
    );
  };

  return WithResetNavigationState;
}
