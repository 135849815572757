import { Avatar, Chip, ChipProps, useTheme } from '@mui/material';
import BlipBox from '../BlipBox';

export default function BlipChip(props: ChipProps) {
  const theme = useTheme();
  return (
    <Chip
      {...props}
      avatar={
        <Avatar sx={{ bgcolor: 'transparent' }}>
          <BlipBox {...(props.color && props.color !== 'default' ? { color: theme.palette[props.color].light } : {})} />
        </Avatar>
      }
    />
  );
}
