import { Card, Skeleton, useMediaQuery } from '@mui/material';
import { FLEET_GENERAL_QUERY_WIDTH } from '.';

export default function LoadingAlarm() {
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);

  return (
    <>
      {new Array(4).fill('').map(() => (
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            color: 'text.primary',
          }}
          key={Math.random()}
        >
          <Skeleton variant="rounded" width="100%" height={matches ? '70px' : '100%'} animation="pulse" />
        </Card>
      ))}
    </>
  );
}
