import { useEffect, useMemo } from 'react';
import { PollProps } from './typings';
import { useDispatch } from 'react-redux';
import { Job } from '@typings';
import usePolling from '@/hooks/usePolling';
import { Polling, removePolling, sendPollingUpdate } from '@/redux/slices/pollingSlice';
import { Operation, STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { JOB_STATUS, OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLazyGetOTAJobProgressQuery } from '@/redux/api/admin/otaApiSlice';
import { removePendingCommand } from '@/redux/slices/deviceSlice';
import apiSlice from '@/redux/api/apiSlice';
import { Id } from '@culligan-iot/domain/culligan/one/device';

export default function PollPostOTAJob({ polling, id }: PollProps) {
  const { t } = useTranslation();
  const { lastArgs } = polling as Omit<Polling, 'lastArgs'> & { lastArgs?: { serialNumber: Id; jobId: string } };

  const { result, isPolling, stop, remainingAttempts } = usePolling({
    useQuery: useLazyGetOTAJobProgressQuery,
    interval: polling?.interval,
    maxAttempts: 10000,
    pollingState: polling,
    queryArgs: lastArgs?.jobId,
  });

  const dispatch = useDispatch();

  const partialOperation = useMemo(
    () =>
      ({
        entity: 'OTAUpdate',
        location: ' ',
        operationId: id,
        read: false,
        showed: false,
        subject: lastArgs?.serialNumber || (t('unknownEntityXXX', { entity: t('device') }) as string),
        timestamp: dayjs().valueOf(),
        tag: TAGS.OTA_UPDATE,
      } satisfies Partial<Operation>),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, lastArgs]
  );

  useEffect(() => {
    dispatch(sendPollingUpdate({ endpoint: 'postOTAJob', result, isPolling, id, remainingAttempts }));
    if (!isPolling && result.error) {
      dispatch(
        upsertOperation({
          ...partialOperation,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.ERROR,
          uniqueId: `${id}-${STATUS.ERROR}`,
          error: result.error,
          jobStatus: result.data?.data.items[0]?.status,
        })
      );
      dispatch(removePolling({ endpoint: 'postOTAJob', id }));
      if (lastArgs?.serialNumber) {
        dispatch(removePendingCommand({ serialNumber: lastArgs.serialNumber, command: 'ota.update' }));
      }
      return;
    }
    const hasPollings = result.data?.data.items.length > 0;

    if (
      polling.remainingAttempts &&
      polling.remainingAttempts > 0 &&
      isPolling &&
      hasPollings &&
      result.data?.data.items.every((job: Job) => {
        return job.status === JOB_STATUS.COMPLETED;
      })
    ) {
      stop();
      dispatch(
        upsertOperation({
          ...partialOperation,
          state: OPERATION_STATUS.FULFILLED,
          status: STATUS.SUCCESS,
          uniqueId: `${id}-${STATUS.SUCCESS}`,
          jobStatus: result.data?.data.items[0]?.status,
        })
      );
      dispatch(removePolling({ endpoint: 'postOTAJob', id }));
      if (lastArgs?.serialNumber) {
        dispatch(removePendingCommand({ serialNumber: lastArgs.serialNumber, command: 'ota.update' }));
        dispatch({
          type: `${apiSlice.reducerPath}/invalidateTags`,
          payload: ['Device'],
        });
      }
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, isPolling, dispatch, stop]);

  useEffect(() => {
    dispatch({
      type: `${apiSlice.reducerPath}/invalidateTags`,
      payload: ['OTAJob'],
    });
  }, [result.data?.data.items, dispatch]);

  useEffect(() => {
    if (polling.remainingAttempts && polling.remainingAttempts > 0 && remainingAttempts === 0 && !isPolling) {
      dispatch(
        upsertOperation({
          ...partialOperation,
          state: OPERATION_STATUS.REJECTED,
          status: STATUS.WARNING,
          uniqueId: `${id}-${dayjs().valueOf()}-${STATUS.WARNING}`,
          error: { error: t('firmwareUpdateDidntFinishInTime') as string },
          jobStatus: result.data?.data.items[0]?.status,
        })
      );
      dispatch(removePolling({ endpoint: 'postOTAJob', id }));
      if (lastArgs?.serialNumber) {
        dispatch(removePendingCommand({ serialNumber: lastArgs.serialNumber, command: 'ota.update' }));
      }
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingAttempts, isPolling, partialOperation]);

  return <></>;
}
