import { useTranslation } from 'react-i18next';
import BaseOperationTypography from '../../../Operation/BaseOperationTypography';
import { Operation } from '@/redux/slices/operationSlice';

export type OTAUpdateMessageProps = {
  actionStatement: string;
  subject: string;
  versionsTuple?: [string, string];
  read: Operation['read'];
  status: Operation['status'];
};

export default function OTAUpdateMessage({
  actionStatement,
  subject,
  versionsTuple,
  read,
  status,
}: OTAUpdateMessageProps) {
  const { t } = useTranslation();
  return (
    <BaseOperationTypography read={read} status={status}>
      <BaseOperationTypography read={read} status={status}>
        {t('device')}{' '}
      </BaseOperationTypography>
      <BaseOperationTypography read={read} status={status} fontWeight={'600'}>
        {subject}{' '}
      </BaseOperationTypography>
      <BaseOperationTypography read={read} status={status}>
        {t(actionStatement)}{' '}
      </BaseOperationTypography>
      {versionsTuple && versionsTuple.length > 0 && (
        <>
          <BaseOperationTypography read={read} status={status}>
            {t('from')}{' '}
          </BaseOperationTypography>
          <BaseOperationTypography read={read} status={status} fontWeight={'600'}>
            {versionsTuple[0]}{' '}
          </BaseOperationTypography>
          <BaseOperationTypography read={read} status={status}>
            {t('to')}{' '}
          </BaseOperationTypography>
          <BaseOperationTypography read={read} status={status} fontWeight={'600'}>
            {versionsTuple[1]}{' '}
          </BaseOperationTypography>
        </>
      )}
    </BaseOperationTypography>
  );
}
