import { STATUS, Tag, TAGS } from '@/redux/slices/operationSlice';
import BaseNotification, { StatusNotification } from '../../BaseNotification';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useDefaultErrorCallback from '../../hooks/useDefaultErrorCallback';
import BaseOperationAvatar from '../../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../../Operation/BaseOperationIcon';
import { getDefaultStatusIcon } from '../utils';
import BaseOperationIconButton from '../../../Operation/BaseOperationIconButton';
import DefaultNotificationRightAction from '../../DefaultNotificationRightAction';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import DeviceTelemetryUpdateMessage from './Message';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Operations } from '../../../Operation/types';
import { NotificationVirtualizationProps } from '../../types';
import { useNotificationHeight } from '../../hooks/useNotificationHeight';

type TelemetryUpdateTag = Extract<Tag, typeof TAGS.DEVICE_TELEMETRY_UPDATE>;
type Props = Operations.Props & { tag: TelemetryUpdateTag } & NotificationVirtualizationProps;

export default function TelemetryUpdate(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uniqueId, status, read, entity, setNotificationHeight } = props;
  const notificationRef = useNotificationHeight({ id: uniqueId, setNotificationHeight });
  const defaultErrorCallback = useDefaultErrorCallback({ uniqueId });
  const path = `/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${entity}#telemetry`;

  return (
    <StatusNotification
      {...props}
      ref={notificationRef}
      baseComponent={{ Component: BaseNotification }}
      avatar={
        <BaseOperationAvatar
          status={status}
          tagIcon={<BaseOperationIcon Icon={QueryStatsIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: (
            <DeviceTelemetryUpdateMessage
              read={read}
              status={status}
              entity={entity}
              opening={t('telemetryUpdateRequestError')}
            />
          ),
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={read}
                status={status}
                icon={<BaseOperationIcon Icon={VisibilityIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction read={read} status={status} uniqueId={uniqueId} />,
          },
        },
        [STATUS.INFO]: {
          message: (
            <DeviceTelemetryUpdateMessage
              read={read}
              status={status}
              entity={entity}
              opening={t('telemetryUpdateRequestPending')}
            />
          ),
          onClick: () => navigate(path),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={read}
                status={status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction read={read} status={status} uniqueId={uniqueId} />,
          },
        },
        [STATUS.SUCCESS]: {
          message: (
            <DeviceTelemetryUpdateMessage
              read={read}
              status={status}
              entity={entity}
              opening={t('telemetryUpdateRequestSuccess')}
            />
          ),
          onClick: () => navigate(path),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={read}
                status={status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction read={read} status={status} uniqueId={uniqueId} />,
          },
        },
      }}
    />
  );
}
