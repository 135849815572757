import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Nullable } from '@typings';
import { Fragment } from 'react';

const withRenderFragment = <P = {},>(Component: React.FC<P> | ((args: any) => JSX.Element | null)) => {
  const WithRenderFragment = ({ renderFragment, ...props }: { renderFragment: boolean } & Nullable<P>) => {
    if (renderFragment) {
      return <Fragment />;
    }

    return (Component && <Component {...(props as unknown as P & EmotionJSX.IntrinsicAttributes)} />) || null;
  };

  return WithRenderFragment;
};

export default withRenderFragment;
