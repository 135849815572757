import { useEffect, useRef, useState } from 'react';
import { DeviceConnectionHistoryItem } from '../DevicesPanel/typings';
import { useTranslation } from 'react-i18next';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import Flyout from '@/components/Shared/Charts/Flyout';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { LineChartDatum } from '@/components/Shared/Charts/typings';
import useSize from '@/hooks/useSize';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';
import { SCATTER_PROPS } from '@/components/Shared/Charts/constants';
import React from 'react';

const ConnectionsGraph = ({ history }: { history: DeviceConnectionHistoryItem[] }) => {
  const { t } = useTranslation();

  const [online, setOnline] = useState<LineChartDatum[]>();
  const [offline, setOffline] = useState<LineChartDatum[]>();

  useEffect(() => {
    const events = history.reduce<{ online: Map<string, number>; offline: Map<string, number> }>(
      (acc, evt) => {
        const isoDate = dayjs(evt.createdAt).startOf('day').toISOString();

        if (evt.online) {
          acc.online.set(isoDate, (acc.online.get(isoDate) || 0) + 1);
          return acc;
        }

        acc.offline.set(isoDate, (acc.offline.get(isoDate) || 0) + 1);
        return acc;
      },
      {
        online: new Map<string, number>(),
        offline: new Map<string, number>(),
      }
    );

    setOnline([...events.online.entries()].map<LineChartDatum>(([x, y]) => ({ x, y })));
    setOffline([...events.offline.entries()].map<LineChartDatum>(([x, y]) => ({ x, y })));
  }, [history, t]);

  const theme = useTheme();

  const chartContainerRef = useRef<HTMLBaseElement | null>(null);
  const size = useSize(chartContainerRef?.current);

  return (
    <AreaContainer>
      <AreaBody>
        <Box>
          <Box key={'Legend'} display="flex" alignItems="center" justifyContent="center" gap={1} width="100%">
            {[t('connected'), t('disconnected')].map((label) => (
              <Chip
                key={label}
                icon={
                  <Box
                    width={15}
                    height={15}
                    bgcolor={(theme) =>
                      label === t('connected') ? theme.palette.success.light : theme.palette.error.light
                    }
                    sx={{
                      borderRadius: 2,
                      border: '1px solid white',
                    }}
                  ></Box>
                }
                size="small"
                label={label}
              />
            ))}
          </Box>
        </Box>
        <Box ref={chartContainerRef} sx={{ height: '400px' }}>
          <VictoryChart
            containerComponent={
              <VictoryVoronoiContainer
                responsive={true}
                labels={() => ' '}
                labelComponent={
                  <VictoryTooltip
                    pointerWidth={20}
                    pointerLength={10}
                    flyoutComponent={
                      <Flyout
                        dy={10}
                        parentSize={size}
                        body={({ datum, flyoutProps }) => {
                          return (
                            <Box
                              id="flyoutContent"
                              sx={{
                                background: 'black',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                padding: 1,
                                gap: 1,
                                width: 'fit-content',
                                borderRadius: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: flyoutProps.activePoints?.[0].style.data.stroke || 'white',
                                  height: 20,
                                  width: 20,
                                  borderRadius: 10,
                                }}
                              />
                              <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                {dayjs(datum.x).format('L')}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap', fontWeight: 'bold', marginLeft: 2 }}
                              >
                                {datum.y}
                              </Typography>
                            </Box>
                          );
                        }}
                      />
                    }
                    flyoutStyle={{ fill: theme.palette.text.primary }}
                  />
                }
              ></VictoryVoronoiContainer>
            }
            theme={VictoryTheme.material}
            {...size}
            domainPadding={{ y: 100, x: 16 }}
            padding={40}
          >
            <VictoryAxis
              style={{
                grid: { stroke: 'none' },
              }}
              dependentAxis
            />
            <VictoryAxis
              tickFormat={(tick) => dayjs(tick).format('L')}
              style={{
                grid: { stroke: 'none' },
              }}
            />
            <VictoryLine
              style={{
                data: {
                  stroke: theme.palette.success.light,
                  strokeWidth: 5,
                  strokeLinecap: 'round',
                },
                labels: {
                  opacity: 0,
                },
              }}
              interpolation="cardinal"
              labels={() => ' '}
              data={online}
            />
            <VictoryLine
              style={{
                data: {
                  stroke: theme.palette.error.light,
                  strokeWidth: 5,
                  strokeLinecap: 'round',
                },
                labels: {
                  opacity: 0,
                },
              }}
              interpolation="cardinal"
              labels={() => ' '}
              data={offline}
            />

            <VictoryScatter
              {...SCATTER_PROPS}
              data={online}
              style={{
                ...SCATTER_PROPS.style,
                data: {
                  ...SCATTER_PROPS.style?.data,
                  fill: theme.palette.success.light,
                },
              }}
            />
            <VictoryScatter
              {...SCATTER_PROPS}
              data={offline}
              style={{
                ...SCATTER_PROPS.style,
                data: {
                  ...SCATTER_PROPS.style?.data,
                  fill: theme.palette.error.light,
                },
              }}
            />
          </VictoryChart>
        </Box>
      </AreaBody>
    </AreaContainer>
  );
};

export default React.memo(ConnectionsGraph);
