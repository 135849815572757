import { ReactNode, useState } from 'react';
import { Operation } from '@/redux/slices/operationSlice';
import { Box, IconButton, useTheme } from '@mui/material';
import SlidingDiv from '@/components/Shared/Animated/SlidingDiv';
import { DIRECTION } from '@/components/Shared/Animated/typings';
import { BaseOperationHoverIconButtons } from './BaseOperationIconButton';
import OpacityDiv from '@/components/Shared/Animated/OpacityDiv';

import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';

export type BaseOperationBodyProps = {
  avatar: ReactNode;
  message: ReactNode;
  hoverIconButtons?: BaseOperationHoverIconButtons;
  status: Operation['status'];
  read: Operation['read'];
  style?: React.CSSProperties;
  ref?: React.Ref<any>;
};

export default function BaseOperationBody({ avatar, message, hoverIconButtons, status, read }: BaseOperationBodyProps) {
  const [mouseHoverContainer, setMouseHoverContainer] = useState(false);
  const [mouseHoverRead, setMouseHoverRead] = useState(false);
  const theme = useTheme();
  const color = read ? theme.palette.grey[800] : theme.palette[status || 'primary'].main;

  return (
    <Box
      onMouseEnter={() => !mouseHoverContainer && setMouseHoverContainer(true)}
      onMouseLeave={() => mouseHoverContainer && setMouseHoverContainer(false)}
      sx={{ display: 'grid', gap: 2, gridTemplate: 'auto / auto 1fr auto', p: 2 }}
    >
      <Box>{avatar}</Box>
      <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>{message}</Box>
      {(hoverIconButtons?.main || hoverIconButtons?.rightAction) && (
        <Box
          {...(hoverIconButtons?.rightAction && {
            onMouseEnter: () => !mouseHoverRead && setMouseHoverRead(true),
            onMouseLeave: () => mouseHoverRead && setMouseHoverRead(false),
          })}
          sx={{ display: 'flex', height: '100%', alignItems: 'center', position: 'relative', overflow: 'hidden' }}
        >
          <IconButton sx={{ fontSize: '1rem', opacity: 0, pointerEvents: 'none' }}>
            <MarkChatReadIcon sx={{ fontSize: 'inherit' }} />
          </IconButton>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              color,
            }}
          >
            <SlidingDiv trigger={!mouseHoverRead && mouseHoverContainer} direction={DIRECTION.LEFT}>
              <OpacityDiv trigger={!mouseHoverRead && mouseHoverContainer}>{hoverIconButtons?.main}</OpacityDiv>
            </SlidingDiv>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              color,
            }}
          >
            <SlidingDiv trigger={mouseHoverRead} direction={DIRECTION.LEFT}>
              <OpacityDiv trigger={mouseHoverRead}>{hoverIconButtons?.rightAction}</OpacityDiv>
            </SlidingDiv>
          </Box>
        </Box>
      )}
    </Box>
  );
}
