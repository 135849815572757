import { ChartType } from 'chart.js';
import { MutableRefObject } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

const generateCustomTooltipHandler = <TType extends ChartType>(
  tooltipModel: any,
  tooltipData: {
    top: number;
    left: number;
    dataPoint: any;
    totalDevices: number;
  } | null,
  setTooltipData: (
    tooltipData: {
      top: number;
      left: number;
      dataPoint: any;
      totalDevices: number;
    } | null
  ) => void,
  chartRef: MutableRefObject<ChartJSOrUndefined<TType, (number | [number, number] | null)[], unknown> | null>
) => {
  if (tooltipModel.tooltip.opacity === 0) {
    setTooltipData(null);
    return;
  }

  const totalDevices = tooltipModel.chart.config._config.data.datasets[0].data.reduce(
    (p: number, n: number) => p + n,
    0
  );

  const chart = chartRef.current;

  if (chart === null || chart === undefined) {
    return;
  }

  const canvas = chart.canvas;

  if (canvas) {
    // set position of tooltip
    const left = tooltipModel.tooltip.dataPoints[0].element.x;
    const top = tooltipModel.tooltip.dataPoints[0].element.y;

    tooltipData?.top !== top &&
      setTooltipData({ top: top, left: left, dataPoint: tooltipModel.tooltip.dataPoints[0], totalDevices });
  }
};

export default function useGenerateCustomTooltipHandler<TType extends ChartType>() {
  return generateCustomTooltipHandler<TType>;
}
