import React, { useLayoutEffect, useMemo, useState } from 'react';
import { POINTER_DEFAULT } from '.';

export const POINTER_SHAPE = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
} as const;

type PointerProps = {
  flyoutRect: {
    x?: number;
    y?: number;
    height: number;
    width: number;
  };
  pointerWidth?: number;
  pointerLength?: number;
  overflow: boolean;
  overflowDelta?: number;
};

export default function Pointer({
  pointerLength = POINTER_DEFAULT.LENGTH,
  pointerWidth = POINTER_DEFAULT.WIDTH,
  flyoutRect: { x = 0, y = 0, width, height },
  overflow,
  overflowDelta,
}: PointerProps) {
  const shape = useMemo(() => {
    if (!overflow) {
      return `0,0 ${pointerWidth},0 ${pointerWidth / 2},${pointerLength}`;
    }

    if (overflow && overflowDelta && overflowDelta >= 0) {
      return `0,-1 ${(pointerWidth || 0) / 2},-1 0,${pointerLength || 0}`;
    }

    if (overflow && overflowDelta && overflowDelta < 0) {
      return `0,-1 ${(pointerWidth || 0) / 2},-1 ${(pointerWidth || 0) / 2},${pointerLength || 0}`;
    }
  }, [overflow, overflowDelta, pointerLength, pointerWidth]);

  const [translateX, setTranslateX] = useState<number>(x + width / 2 - pointerWidth / 2);
  const [translateY, setTranslateY] = useState<number>(y + height);

  useLayoutEffect(() => {
    let translateX = x + width / 2 - pointerWidth / 2;
    let translateY = y + height;

    if (overflow && overflowDelta) {
      const isOverflowingToTheRight = overflowDelta && overflowDelta >= 0 ? false : true;
      translateX = x + width / 2 - overflowDelta - (isOverflowingToTheRight ? pointerWidth / 2 : 0);
    }

    setTranslateX(translateX);
    setTranslateY(translateY);
  }, [height, overflow, overflowDelta, pointerWidth, width, x, y]);

  return (
    <g id="flyoutPointer" transform={`translate(${translateX} ${translateY})`}>
      <polygon id="flyoutPointerPolygon" points={shape} fill="black" />
    </g>
  );
}
