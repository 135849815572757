import { Column } from '@material-table/core';
import { useTranslation } from 'react-i18next';
import { OtaJobsRes } from './typings';
import dayjs from 'dayjs';

export default function useAlarmColumns(): Array<Column<OtaJobsRes>> {
  const { t } = useTranslation();

  const columns_array: Array<Column<OtaJobsRes>> = [];

  return columns_array.concat([
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('minVersion'),
      field: 'minVersion',
    },
    {
      title: t('version'),
      field: 'version',
    },
    {
      title: t('status'),
      field: 'status',
    },
    {
      title: t('attempts'),
      field: 'attempts',
    },
    {
      title: t('lastAttemptAt'),
      field: 'lastAttemptAt',
      render: (otaJobs) => dayjs(otaJobs.lastAttemptAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('deliveredAt'),
      field: 'deliveredAt',
      render: (otaJobs) => dayjs(otaJobs.deliveredAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('completedAt'),
      field: 'completedAt',
      render: (otaJobs) => dayjs(otaJobs.completedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (otaJobs) => dayjs(otaJobs.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (otaJobs) => dayjs(otaJobs.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
  ]);
}
