import OneToolCUDVariant from './OneToolCUD';
import { CHANNELS, ECOSYSTEM, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import { CommonSnackbarProps } from '../typings';

export default function OneToolVariant({ operation, dismissAction }: CommonSnackbarProps) {
  switch (operation.entity) {
    case ECOSYSTEM.DEVICE_IDENTITIES:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ECOSYSTEM.BRANDS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ECOSYSTEM.OTA:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.TELEMETRY:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.EVENTS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.ALARMS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.COMMANDS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.CONSUMABLES:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.CONSUMABLE_SUBSETS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.REACTIONS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${operation.entity}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.CONFIGS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${CHANNELS.CONFIGS}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case CHANNELS.LOYALTY_PROGRAMS:
      return (
        <OneToolCUDVariant
          path={`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${CHANNELS.LOYALTY_PROGRAMS}`}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    default:
      return <></>;
  }
}
