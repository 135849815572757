import { Schema as S } from '@effect/schema';
import { ParseError } from '@effect/schema/ParseResult';
import { _ApiResult } from '@typings';
import { Either } from 'effect';

export type EntityWithParameters = {
  parameters: true;
  payloadExample: string;
};

export type EntityWithoutParameters = {
  parameters: false;
  payloadExample: undefined;
};

export interface ResCommonFields {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export type CommonIsEntityUniqueBase = {
  id?: string;
  name?: string;
};

export type CommonIsEntityUniqueRes = Record<keyof CommonIsEntityUniqueBase, boolean | undefined>;

export type CommonIsEntityUniqueRes_Type = S.Schema.Type<typeof CommonIsEntityUniqueRes_Struct>;
export const CommonIsEntityUniqueRes_Struct = S.Struct({
  id: S.UndefinedOr(S.Boolean),
  name: S.UndefinedOr(S.Boolean),
});
export type PartialCommonIsEntityUniqueRes_Type = S.Schema.Type<typeof PartialCommonIsEntityUniqueRes_Struct>;
export const PartialCommonIsEntityUniqueRes_Struct = CommonIsEntityUniqueRes_Struct.pipe(
  S.pick('id', 'name'),
  S.partial()
).annotations({
  identifier: 'PartialCommonIsEntityUniqueRes_Struct',
});
export const CommonIsIdUniqueRes_Struct = CommonIsEntityUniqueRes_Struct.pipe(S.pick('id'));

export const IsEntityUniqueApiResult = _ApiResult(CommonIsEntityUniqueRes_Struct).annotations({
  identifier: 'IsEntityUniqueApiResult',
});
export const IsIdUniqueApiResult = _ApiResult(
  S.Struct({
    id: S.Boolean,
  })
).annotations({
  identifier: 'IsIdUniqueApiResult',
});

export const decodeCommonIsEntityUniqueResponse = S.decodeUnknownEither(IsEntityUniqueApiResult, {
  errors: 'all',
});

export const decodeCommonIsIdUniqueResponse = S.decodeUnknownEither(IsIdUniqueApiResult, {
  errors: 'all',
});

export function eitherCommonIsIdUnique(response: unknown) {
  return decodeEither<typeof IsIdUniqueApiResult.Type, ParseError>(response, decodeCommonIsIdUniqueResponse);
}

export function eitherCommonIsEntityUnique(response: unknown) {
  return decodeEither<typeof IsEntityUniqueApiResult.Type, ParseError>(response, decodeCommonIsEntityUniqueResponse);
}

export function decodeEither<R, L>(raw: unknown, decoder: (raw: unknown) => Either.Either<R, L>) {
  const decoded = decoder(raw);
  if (decoded && decoded._tag === 'Left') {
    return {
      _tag: decoded._tag,
      left: decoded.left,
    };
  }

  return {
    _tag: decoded._tag,
    right: decoded.right,
  };
}
