import { Box, Theme, Typography, alpha } from '@mui/material';
import TelemetryHeaderCard from './TelemetryHeaderCard';
import { BaseDeviceTelemetry, DeviceTelemetry, DeviceTelemetryWithBoolean } from '../typings';
import { useTranslation } from 'react-i18next';

export default function TelemetryBooleanCard({
  telemetry,
  double = false,
  group = false,
}: {
  telemetry: DeviceTelemetry;
  double?: boolean;
  group?: boolean;
}) {
  const { t } = useTranslation();
  const _telemetry = telemetry as BaseDeviceTelemetry & DeviceTelemetryWithBoolean;
  const matchExpectedValue = String(_telemetry?.expectedValue) === String(_telemetry?.value);
  const errorGradient = (theme: Theme) =>
    `radial-gradient(85.17% 85.17% at 0% 0%, ${alpha(theme.palette.error.light, 0.2)} 0%, ${alpha(
      theme.palette.background.grayShades[0],
      0.2
    )} 100%), ${theme.palette.background.grayShades[0]}`;
  return (
    <Box
      className="telemetry-card"
      sx={(theme) => ({
        gridColumn: double ? 'span 2' : 'span 1',
        aspectRatio: !double ? '1/1' : 'unset',
        borderRadius: theme.shape.cardBorderRadius,
        padding: 2,
        height: '250px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: !matchExpectedValue ? errorGradient : theme.palette.background.grayShades[0],
      })}
    >
      <TelemetryHeaderCard name={_telemetry.name} id={_telemetry.id} group={group} />
      <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} flexGrow={1}>
        <Box>
          <Box
            sx={(theme) => ({
              backgroundColor: 'white',
              height: '60px',
              width: '60px',
              borderRadius: '50%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              '&::before': {
                content: '""',
                height: '70px',
                width: '70px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                transition: 'all 0.3s ease',
                position: 'absolute',
                border: `3px solid ${matchExpectedValue ? theme.palette.grey[300] : '#ef5350'}`,
              },
            })}
          >
            <Typography
              fontWeight={'bold'}
              {...(!matchExpectedValue
                ? {
                    sx: {
                      color: (theme) => theme.palette.warning.light,
                      background: (theme) =>
                        `linear-gradient(140deg, ${theme.palette.warning.light} 0%,` +
                        ` ${theme.palette.error.light} 100%);`,
                      backgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    },
                  }
                : {})}
            >
              {['true', 'false'].includes(String(_telemetry?.value))
                ? String(_telemetry.value)?.toUpperCase()
                : t('notAvailable')}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            fontStyle={'italic'}
            display={'block'}
            marginTop={'20px'}
            textAlign={'center'}
            marginBottom={'2px'}
          >
            {/* {t('lastUpdated')}: 
            {_telemetry?.lastUpdate ? dayjs(_telemetry?.lastUpdate).fromNow() : t('notAvailable')} */}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            backgroundColor: 'white',
            fontSize: '12px',
            borderRadius: theme.shape.cardBorderRadius,
            maxWidth: '80%',
            padding: 1,
            margin: '0 auto',
          })}
        >
          {t('expectedValue')}{' '}
          <Typography fontWeight={'bold'} fontSize="12px" display={'inline'} ml="10px">
            {String(_telemetry?.expectedValue)?.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
