import { styled, Theme, CSSObject, darken } from '@mui/material/styles';
import { AppBarIconButtonProps } from './typings';
import IconButton from '@mui/material/IconButton';

const rotateIconMixin180 = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  transform: 'rotate(180deg)',
});

const rotateIconMixin0 = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  transform: 'rotate(0deg)',
});

const AppBarIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'openDropDown',
})<AppBarIconButtonProps>(({ theme, openDropDown }) => ({
  height: '100%',
  color: theme.palette.grey[100],
  backgroundColor: darken(theme.palette.primary.main, 0.2),
  fontWeight: 'bold',
  textTransform: 'none',
  minWidth: '10%',
  justifyContent: 'flex-start',
  paddingLeft: '1.875rem',
  paddingRight: '1.875rem',
  borderRadius: 0,
  fontSize: '1.5rem',
  outline: 'none',

  '&:hover': {
    background: darken(theme.palette.primary.main, 0.4),
  },
  ...(openDropDown && {
    background: darken(theme.palette.primary.main, 0.4),
    '& .MuiSvgIcon-root': rotateIconMixin180(theme),
  }),
  ...(!openDropDown && { '& .MuiSvgIcon-root': rotateIconMixin0(theme) }),
}));

export default AppBarIconButton;
