import { Box, CircularProgress, Typography } from '@mui/material';
import { ProgressWithLabelProps } from './typings';

const __CircularProgressWithLabel = ({
  containerStyle,
  circleContainerStyle,
  externalLabel,
  innerLabel,
  externalLabelStyle,
  innerLabelStyle,
  size = 100,
  value = 100,
  ...props
}: ProgressWithLabelProps) => (
  <Box sx={{ display: 'inline-flex', flexDirection: 'column', ...containerStyle }}>
    {externalLabel && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          whiteSpace: 'pre-line',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={'0.7rem'}
          fontWeight="bold"
          sx={externalLabelStyle}
        >
          {externalLabel}
        </Typography>
      </Box>
    )}
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        p: '0.62rem',
        ...(circleContainerStyle ? circleContainerStyle : {}),
      }}
    >
      <CircularProgress {...props} variant="determinate" value={value} size={size} />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" fontSize={'0.55rem'} sx={innerLabelStyle}>
          {innerLabel}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default __CircularProgressWithLabel;
