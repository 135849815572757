import { copyToClipboard, dispatchToast } from '@/shared/utils';
import { Chip, ChipProps, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material/styles';

export default function CopyChip({
  valueToCopy,
  sx,
  ...rest
}: ChipProps & { valueToCopy?: string; sx?: SxProps<Theme> }) {
  const { t } = useTranslation();

  const defaultSx: SxProps<Theme> = {
    height: '22px',
    borderColor: (theme) => theme.palette.grey[500],
    '.MuiChip-label': {
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  };

  const combinedSx = sx ? { ...defaultSx, ...sx } : defaultSx;

  return (
    <Chip
      {...rest}
      onClick={(e) => {
        copyToClipboard({
          text: String(valueToCopy || rest.label),
          onSucces: () => {
            dispatchToast({
              message: t('copyToClipboardSuccess'),
              severity: 'success',
              position: { x: e.clientX, y: e.clientY },
            });
          },
          onFail: () => {
            dispatchToast({
              message: t('copyToClipboardError'),
              severity: 'error',
              position: { x: e.clientX, y: e.clientY },
            });
          },
        });

        rest.onClick && rest.onClick(e);
      }}
      variant="outlined"
      size="small"
      sx={combinedSx}
    />
  );
}
