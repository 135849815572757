import { createBrowserRouter, redirect } from 'react-router-dom';
import Login from '../pages/Auth/Login';
import AppOutlet from '../core/AppOutlet';
import Page404 from '../core/404';
import FleetRoutes, { FLEET_ROUTES } from './FleetRoutes';
import Settings from '../pages/Settings';
import Profile from '../pages/Profile';
import OneToolRoutes from './OneToolRoutes';
import ResetPasswordRequest from '../pages/Auth/ResetPasswordRequest';
import ResetPasswordConfirmation from '../pages/Auth/ResetPasswordConfirmation';
import BusinessAnalisysRoutes from './BusinessAnalisysRoutes';

const routes = [
  {
    path: '/',
    element: <AppOutlet />,
    children: [
      {
        index: true,
        loader: () => redirect(`/${FLEET_ROUTES.FLEET}/${FLEET_ROUTES.OVERVIEW}`),
      },
      FleetRoutes,
      OneToolRoutes,
      BusinessAnalisysRoutes,
      {
        path: 'settings',
        element: <Settings />,
        label: 'Settings',
      },
      {
        path: 'profile',
        element: <Profile />,
        label: 'Profile',
      },
      {
        path: '/*',
        element: <Page404 />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordRequest />,
  },
  {
    path: '/reset-password-confirmation',
    element: <ResetPasswordConfirmation />,
  },
];
const router = createBrowserRouter(routes);

export { router as default, routes };
