import { alpha, darken, lighten, styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const AppBarButton = styled(Button)(({ theme }) => ({
  color: alpha(theme.palette.primary.contrastText, 0.8),
  textTransform: 'none',
  minWidth: '10%',
  justifyContent: 'flex-start',
  paddingLeft: '1.875rem',
  paddingRight: '1.875rem',
  fontSize: theme.typography.body1.fontSize,
  outline: 'none',
  backgroundColor: 'transparent',
  height: 'min-content',
  alignSelf: 'center',
  padding: '1.35rem 1.5rem',
  borderRadius: '8px',
  background: alpha(lighten(theme.palette.primary.main, 0.1), 0.5),
  fontWeight: 'bold',
  maxHeight: '1.8rem',

  '&:hover': {
    background: darken(theme.palette.primary.main, 0.4),
  },
}));

export default AppBarButton;
