import { Device } from '../../../DevicesPanel/typings';
import OverviewCard from '../DeviceCardInfo';
import { useTranslation } from 'react-i18next';
import { AREA_DESIGN_TOKENS, AreaRow } from '@/components/Shared/Card/Area';
import PowerProfile from '../InfoCard/PowerProfile';
import { alpha, Box, Typography } from '@mui/material';
import OperatingMode from '../InfoCard/OperatingMode';
import LogLevel from '../InfoCard/LogLevel';
import { useCallback, useMemo } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import {
  usePostLogLevelCommandMutation,
  usePostOperatingModeCommandMutation,
  usePostPowerProfileCommandMutation,
} from '@/redux/api/admin/deviceCommandsApiSlice';
import { useSelector } from 'react-redux';
import { selectRoles } from '@/redux/slices/authSlice';
import { ROLES } from '@/shared/constants';
import { AppState } from '@/redux/store';
import React from 'react';
import {
  selectHasPendingLogLevel,
  selectHasPendingOperatingMode,
  selectHasPendingPowerProfile,
  selectPendingCommands,
} from '@/redux/slices/deviceSlice';

function ProfileCard({
  device,
  postPowerProfile,
  postOperatingMode,
  postLogLevel,
}: {
  device: Device;
  postPowerProfile: ReturnType<typeof usePostPowerProfileCommandMutation>[0];
  postOperatingMode: ReturnType<typeof usePostOperatingModeCommandMutation>[0];
  postLogLevel: ReturnType<typeof usePostLogLevelCommandMutation>[0];
}) {
  const { t } = useTranslation();
  const isAdmin = useSelector((state: AppState) => selectRoles(state)).some(
    (role) => role === ROLES.SYS_ADMIN || role === ROLES.ADMIN
  );
  const pendingCommands = useSelector((state: AppState) => selectPendingCommands(state, device.serialNumber));
  const isInLoadingState = pendingCommands.length > 0;
  const hasPendingOperatingMode = useSelector((state: AppState) =>
    selectHasPendingOperatingMode(state, device.serialNumber)
  );
  const hasPendingPowerProfile = useSelector((state: AppState) =>
    selectHasPendingPowerProfile(state, device.serialNumber)
  );
  const hasPendingLogLevel = useSelector((state: AppState) => selectHasPendingLogLevel(state, device.serialNumber));
  const requestedLogLevel = useMemo(
    () => pendingCommands.find((command) => command.command === 'log.set')?.payload,
    [pendingCommands]
  );

  const { internalActionGap } = AREA_DESIGN_TOKENS;

  const renderCardSubtitle = useCallback(
    () => (
      <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <InfoOutlined sx={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)', mr: 1 }} />
        <Typography
          variant="subtitle2"
          sx={(theme) => ({ pt: 0, color: alpha(theme.palette.common.black, 0.6) })}
          fontWeight={400}
        >
          {/* {t('lastUpdated')} {device?.updatedAt ? dayjs(device.updatedAt).fromNow().toString() : '--'} */}
        </Typography>
      </Box>
    ),
    []
  );
  return (
    <OverviewCard
      title={t('profile')}
      subtitle={renderCardSubtitle}
      cardContentProps={{ sx: { width: '100%', justifyContent: 'stretch', display: 'grid' } }}
      cardContainerProps={{ sx: { flexShrink: 1, flex: 'unset', display: 'flex', overflow: 'unset' } }}
      sx={{ position: 'relative' }}
    >
      <AreaRow sx={{ flexWrap: 'nowrap', flexBasis: '50%' }}>
        <Box sx={{ display: 'grid', width: '50%', gap: internalActionGap }}>
          <OperatingMode
            device={device}
            isAdmin={isAdmin}
            isInLoadingState={isInLoadingState}
            isLoading={hasPendingOperatingMode}
            isDeviceOnline={device?.status.connection.online}
            postOperatingMode={postOperatingMode}
          />
        </Box>
        <Box
          sx={{
            gap: internalActionGap,
            width: '50%',
            display: 'grid',
            ...(Number(device?.supportedPowerProfiles?.length || 0) > 0 ? { gridTemplateColumns: '2fr 1fr' } : {}),
          }}
        >
          <PowerProfile
            isAdmin={isAdmin}
            serialNumber={device.serialNumber}
            operatingMode={device.operatingMode}
            powerProfile={device?.powerProfile}
            isInLoadingState={isInLoadingState}
            isDeviceOnline={device?.status.connection.online}
            postPowerProfile={postPowerProfile}
            isLoading={hasPendingPowerProfile}
            supportedPowerProfiles={device?.supportedPowerProfiles || []}
          />
        </Box>
      </AreaRow>
      <AreaRow sx={{ display: 'grid', gridTemplate: 'auto / repeat(6, 1fr)', gap: internalActionGap }}>
        <LogLevel
          device={device}
          postLogLevel={postLogLevel}
          isInLoadingState={isInLoadingState}
          isLoadingLogLevel={hasPendingLogLevel}
          isPollingLogLevel={false}
          requestLogLevel={requestedLogLevel}
        />
      </AreaRow>
    </OverviewCard>
  );
}
export default React.memo(ProfileCard);
