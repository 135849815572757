import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { LocalFilterOption, RadioFilterProps, StoreFilterOption, StoreOnFilterChange } from './typings';

const RadioFilter = <T,>({ config, kind, onFilterChange }: RadioFilterProps<T>) => {
  const handleOnChange = (e: any, value: string) => {
    const el = config.options.find((o) => o.optionId === value) || null;
    if (kind === 'local') {
      onFilterChange(config.id, (el as LocalFilterOption<T>)?.filterFx ? (el as LocalFilterOption<T>) : null);
    } else if (!el || kind === 'store') {
      (onFilterChange as StoreOnFilterChange)(config.id, (el as StoreFilterOption) || null);
    }
  };

  return (
    <fieldset
      key={config.id}
      id={config.id}
      style={{
        boxSizing: 'border-box',
        padding: '0 0.25rem',
        border: '1px solid rgba(0,0,0,0.23)',
        borderRadius: '0.25rem',
      }}
    >
      <legend style={{ fontSize: '0.75rem', padding: '0 0.25rem', margin: '0 0.33rem' }}>{config.label}</legend>
      <RadioGroup
        row
        defaultValue={
          kind === 'store' && config.defaultValue ? config.defaultValue.optionId : config.options[0].optionId
        }
        onChange={handleOnChange}
        style={config?.style}
        sx={{
          padding: '0.33rem',
          display: 'block',
          whiteSpace: 'nowrap',
        }}
      >
        {config.options.map((option) => (
          <FormControlLabel
            key={option.label}
            value={option.optionId}
            control={<Radio sx={{ p: 0, mr: '0.33rem' }} />}
            sx={{ mx: 0 }}
            style={{ padding: '0.33rem' }}
            label={<Typography variant="subtitle2">{option.label}</Typography>}
          />
        ))}
      </RadioGroup>
    </fieldset>
  );
};

export const radioFilterDefaultAssert = <T,>(param: T, value: T) => param === value;

export default RadioFilter;
