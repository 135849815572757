import { Box, Checkbox, Collapse, FormControlLabel } from '@mui/material';
import { useContext, useState } from 'react';
import ExpandIcon from '@/components/Shared/AnimatedExpandIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITEM_TYPE, MergedTelemetry } from '../../typings';
import { FiltersTelemetryContext, TelemetryKey } from '../../FiltersTelemetryContext';

export default function BaseItemPicker({
  item,
  setGroupKeys,
  groupActive,
  GroupComponent,
}: {
  item: MergedTelemetry;
  setGroupKeys?: (keys: TelemetryKey, checked: boolean) => void;
  groupActive?: boolean;
  GroupComponent?: React.ReactNode;
}) {
  const { keys, setKeys } = useContext(FiltersTelemetryContext);
  const [collapsed, setCollapsed] = useState(true);
  const handleChange = (checked: boolean) => {
    if (checked) {
      if (setGroupKeys) {
        item.id && setGroupKeys({ id: item.id }, checked);
        return;
      }

      item.id &&
        setKeys([
          ...keys.filter((k) => k.id !== item.id),
          ...(item.type === ITEM_TYPE.GROUP
            ? [{ id: item.id, items: item.items?.map((i) => ({ id: i.id })) }] || []
            : [{ id: item.id }]),
        ]);
    }

    if (!checked) {
      if (setGroupKeys) {
        item.id && setGroupKeys({ id: item.id }, checked);
        return;
      }
      item.id && setKeys([...keys.filter((k) => k.id !== item.id)]);
    }
  };
  return (
    <Box
      sx={{
        p: 1,
        pl: 2,
        borderRadius: 1,
        color: (theme) => (item.active || groupActive ? theme.palette.primary.contrastText : 'inherit'),
        background: (theme) =>
          item.active || groupActive ? theme.palette.primary.main : theme.palette.background.grayShades[0],
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControlLabel
          checked={item.selected}
          sx={{ color: (theme) => (item.active ? theme.palette.primary.contrastText : 'inherit') }}
          onChange={(event, checked) => handleChange(checked)}
          control={
            <Checkbox
              sx={{
                color: 'inherit',
                '& .MuiSvgIcon-root': {
                  fontSize: 28,
                  ...(item.selected ? { fill: (theme) => theme.palette.background.grayShades[0] } : {}),
                },
              }}
            />
          }
          label={item.name}
        />
        {item.type === ITEM_TYPE.GROUP && (
          <ExpandIcon rotate={!collapsed} color="inherit" sx={{ p: 2 }} onClick={() => setCollapsed((prev) => !prev)}>
            <ExpandMoreIcon />
          </ExpandIcon>
        )}
      </Box>
      {item.type === ITEM_TYPE.GROUP && <Collapse in={!collapsed}>{GroupComponent}</Collapse>}
    </Box>
  );
}
