import { useTranslation } from 'react-i18next';
import usePasswordPolicy from '@/hooks/usePasswordPolicy';
import { useMemo } from 'react';
import { PasswordPolicy } from './typings';

export default function PasswordPolicyList() {
  const { t } = useTranslation();

  const passwordPolicyUpdated = usePasswordPolicy();

  return useMemo(() => {
    const _pwdPolicy: string | null = sessionStorage.getItem('passwordPolicy');
    if (!_pwdPolicy) return null;
    const passwordPolicy: PasswordPolicy = JSON.parse(_pwdPolicy);
    return (
      passwordPolicy && (
        <ul>
          <li>{t('minLength', { value: passwordPolicy.minLength })}</li>
          <li>{t('minLowerCase', { value: passwordPolicy.minLowercase })}</li>
          <li>{t('minUpperCase', { value: passwordPolicy.minUppercase })}</li>
          <li>{t('minNumericCharacters', { value: passwordPolicy.minNumbers })}</li>
          <li>{t('minSpecialCharacters', { value: passwordPolicy.minSymbols })}</li>
        </ul>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordPolicyUpdated, t]);
}
