import { Autocomplete, TextField } from '@mui/material';
import {
  AutocompleteFilterProps,
  LocalFilterOption,
  StoreAutocompleteFilterConfig,
  StoreFilterOption,
  StoreOnFilterChange,
} from './typings';
import { useEffect } from 'react';

const AutocompleteFilter = <T,>({ config, kind, onFilterChange, ...props }: AutocompleteFilterProps<T>) => {
  useEffect(() => {
    if (kind === 'local') {
      return;
    }

    if (!config.defaultValue) {
      return;
    }
    (onFilterChange as StoreOnFilterChange)(config.id, config.defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(config as StoreAutocompleteFilterConfig).defaultValue]);

  return kind === 'local' ? (
    <Autocomplete<LocalFilterOption<T>>
      disablePortal
      key={config.id}
      id={config.id}
      options={config.options}
      getOptionLabel={(o) => o.label}
      isOptionEqualToValue={(o, v) => o.optionId === v.optionId}
      renderInput={(params) => <TextField {...params} label={config.label} />}
      onChange={(_, val: LocalFilterOption<T> | null) => onFilterChange(config.id, val)}
      style={config?.style}
      sx={{ props }}
    />
  ) : (
    <Autocomplete<StoreFilterOption>
      disablePortal
      id={config.id}
      {...(config.defaultValue
        ? { defaultValue: config.defaultValue, key: config.id + config.defaultValue?.optionId }
        : { key: config.id })}
      options={config.options}
      getOptionLabel={(o) => o.label}
      isOptionEqualToValue={(o, v) => o.optionId === v.optionId}
      renderInput={(params) => <TextField {...params} label={config.label} />}
      onChange={(_, val: StoreFilterOption | null) => (onFilterChange as StoreOnFilterChange)(config.id, val)}
      style={config?.style}
      sx={{ props }}
    />
  );
};

export const autocompleteFilterDefaultAssert = <T,>(param: T, value: T) => param === value;

export default AutocompleteFilter;
