import React from 'react';
import { PerformanceData } from '@/components/Shared/Charts/typings';
import { Box, Card, Chip, Typography, useTheme } from '@mui/material';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getChipColor } from '@/shared/utils';
import AreaContainer, { AreaBody } from '../Card/Area';

export default function Podium({ data, label }: { data: PerformanceData[]; label: string }) {
  const podiumItems = [...data].sort((current, next) => next.value - current.value);
  podiumItems.length = 3;
  const total = data.reduce((prev, next) => prev + next.value, 0);
  const theme = useTheme();

  return (
    <AreaContainer>
      <AreaBody>
        <Box display={'grid'} gap={1} sx={{ gridTemplate: '1fr 1fr 1fr / auto' }} height={'100%'}>
          {podiumItems.map((value, index) => (
            <Card
              key={value.label + 'PodiumItem'}
              sx={{
                flexGrow: 1,
                display: 'flex',
                p: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box position="absolute" height={'100%'} overflow={'hidden'} ml={'80%'} sx={{ opacity: 0 }}>
                <Typography fontWeight={800} fontSize={160} lineHeight={1} sx={{ mt: -4, color: 'primary.main' }}>
                  {index + 1}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h6">{value.label}</Typography>
                <Box display={'flex'} gap={1}>
                  <Chip
                    label={`#${index + 1}`}
                    size="small"
                    sx={{
                      bgcolor: getChipColor(index, theme),
                      color: theme.palette.getContrastText(getChipColor(index, theme)),
                    }}
                    icon={<EmojiEventsIcon sx={{ fill: theme.palette.getContrastText(getChipColor(index, theme)) }} />}
                  />
                  {index === 0 && <Chip label="Most performant" size="small" color="error" icon={<WhatshotIcon />} />}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Box display="flex" alignItems={'baseline'}>
                  <Typography variant="h4" fontWeight={600}>
                    {((value.value * 100) / total).toFixed(1)}
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    %
                  </Typography>
                </Box>
                <Typography variant="caption">
                  of the total {data.length} {label}{' '}
                </Typography>
              </Box>
            </Card>
          ))}
        </Box>
      </AreaBody>
    </AreaContainer>
  );
}
