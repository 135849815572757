import { ReactNode } from 'react';
import ErrorMessage from './ErrorMessage';
import LoadingMessage from './LoadingMessage';

const withErrorLoadingManagement = (Component: React.FC | ((args: any) => JSX.Element | null)) => {
  const WithErrorLoadingManagement = ({
    isLoading,
    isError,
    LoadingComponent,
    ...props
  }: {
    isLoading: boolean;
    isError: boolean;
    LoadingComponent?: ReactNode;
    [k: string]: any;
  }) => {
    if (isLoading) {
      return LoadingComponent ? <LoadingMessage>{LoadingComponent}</LoadingMessage> : <LoadingMessage />;
    }

    if (isError) {
      return <ErrorMessage />;
    }

    return (Component && <Component {...props} />) || null;
  };

  return WithErrorLoadingManagement;
};

export default withErrorLoadingManagement;
