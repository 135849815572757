import { useLayoutEffect, useRef } from 'react';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import './github.theme.css';
import { Box } from '@mui/material';

const indentJson = (jsonString: string) => {
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch {
    return `${jsonString}\n\n this JSON had errors and couldn't be indented correctly`;
  }
};

export default function CodeHighlighter({ code, language }: { code: string; language: string }) {
  const codeRef = useRef<HTMLElement | null>(null);
  hljs.registerLanguage('json', json);

  useLayoutEffect(() => {
    if (codeRef.current && codeRef.current.dataset.highlighted !== 'yes') {
      hljs.highlightElement(codeRef.current);
    }
  }, [code, language]);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        borderRadius: 2,
      }}
    >
      <pre>
        <code ref={codeRef}>
          {language === 'json' ? indentJson(code) : code.split(',').join(',\n').split('[{').join('\n[{')}
        </code>
      </pre>
    </Box>
  );
}
