import { Box, IconButton, SvgIconProps, Tooltip, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { RequireAtLeastOne } from '@typings';
import { Operation } from '@/redux/slices/operationSlice';

export type BaseOperationHoverIconButtons = RequireAtLeastOne<
  {
    main?: ReactNode;
    rightAction?: ReactNode;
  },
  'main' | 'rightAction'
>;

export function BaseOperationConditionalIcon({
  condition,
  WhenFalse,
  WhenTrue,
}: {
  condition: boolean;
  WhenTrue: React.ComponentType<SvgIconProps>;
  WhenFalse: React.ComponentType<SvgIconProps>;
}) {
  return (
    <>
      {!condition && <WhenFalse sx={{ fontSize: 'inherit' }} />}
      {condition && <WhenTrue sx={{ fontSize: 'inherit' }} />}
    </>
  );
}

export default function BaseOperationIconButton({
  icon,
  onClick,
  status,
  read,
}: {
  icon: ReactNode;
  onClick?: () => void;
  status: Operation['status'];
  read: Operation['read'];
}) {
  const theme = useTheme();
  const color = read ? theme.palette.grey[800] : theme.palette[status || 'primary'].main;

  return (
    <IconButton
      sx={{ fontSize: '1rem', color, '&:focus': { outline: 'none' } }}
      onClick={(e) => {
        if (typeof onClick === 'function') {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {icon}
    </IconButton>
  );
}

export function BaseOperationIconButtonWithTooltip({
  tooltip,
  icon,
  status,
  read,
  onClick,
}: {
  tooltip: { title: string };
  icon: ReactNode;
  onClick: () => void;
  status: Operation['status'];
  read: Operation['read'];
}) {
  return (
    <Tooltip
      title={tooltip.title}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -7],
              },
            },
          ],
        },
      }}
    >
      <Box>
        <BaseOperationIconButton read={read} status={status} icon={icon} onClick={onClick} />
      </Box>
    </Tooltip>
  );
}
