import React, { useContext } from 'react';
import BaseItemPicker from './BaseItemPicker';
import { Box } from '@mui/material';
import { MergedTelemetry } from '../../typings';
import { FiltersTelemetryContext, TelemetryKey } from '../../FiltersTelemetryContext';

export default function GroupItemPicker({ item }: { item: MergedTelemetry }) {
  const { keys, setKeys } = useContext(FiltersTelemetryContext);
  const groupComponent = item.items?.map((telemetry) => (
    <BaseItemPicker
      key={telemetry.id}
      item={telemetry}
      groupActive={keys.some((k) => k.id === item.id)}
      setGroupKeys={(key, checked) => {
        if (!checked) {
          let group = keys.find((k) => k.id === item.id);

          if (!group) {
            return;
          }

          group = { ...group, items: group?.items?.filter((i) => i.id !== key.id) };
          const _keys = [
            ...keys.filter((k) => k.id !== item.id),
            group.items && group.items?.length > 0 ? group : undefined,
          ].filter((v) => typeof v !== 'undefined') as TelemetryKey[];

          if (_keys && _keys?.length > 0) {
            setKeys(_keys);
            return;
          }
          setKeys([]);
          return;
        }

        const group = (keys.find((k) => k.id === item.id) || { id: item.id }) as TelemetryKey;
        setKeys([
          ...keys.filter((k) => k.id !== item.id),
          { id: group.id, items: [...(group.items || []), { id: key.id }] },
        ]);
      }}
    />
  ));
  return <BaseItemPicker item={item} GroupComponent={<Box>{groupComponent}</Box>} />;
}
