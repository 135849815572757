import apiSlice, { API_VERSION } from '../apiSlice';

type CommonResponse<T> = {
  success: boolean;
  data: {
    items: T;
  };
};

type OverviewFiltersRequest = {
  brand?: string;
  type?: string;
  customer?: string;
  dateRangeStart: string;
  dateRangeEnd: string;
};

export type UsersFilterRequest = {
  query: string;
};

export type UsersFilterResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type TimeSeriesValue = {
  value: number;
  date: string;
  totalVolume: number;
  day: string;
  id: string;
  type: string;
};

export type AggregatedValue = {
  value: number;
  label: string;
};

type ExtendedAggregatedValue = AggregatedValue & {
  totalAmount: number;
  waterType: number;
};

type StatisticDataRes<T extends AggregatedValue | TimeSeriesValue> = {
  status: 'fullfilled' | 'rejected';
  total?: number;
  value: T[];
};

export type OverviewFiltersResponse = {
  waterDispensed?: StatisticDataRes<TimeSeriesValue>;
  pourings?: StatisticDataRes<TimeSeriesValue>;
  filtersStatus?: StatisticDataRes<TimeSeriesValue>;
  waterTypes?: StatisticDataRes<AggregatedValue>;
  topThreeFlavorsResult?: StatisticDataRes<AggregatedValue>;
  waterFlavorMix?: StatisticDataRes<AggregatedValue>;
  preferredDrinkCombinations?: StatisticDataRes<ExtendedAggregatedValue>;
};

const dispensingStatisticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOverview: builder.query<CommonResponse<OverviewFiltersResponse>, OverviewFiltersRequest>({
      query: (filters: OverviewFiltersRequest) =>
        `${API_VERSION.v2}/business-analysis/dispensing-statistics/overview?` + new URLSearchParams(filters),
    }),
    getUsers: builder.query<CommonResponse<UsersFilterResponse[]>, UsersFilterRequest>({
      query: (filter: UsersFilterRequest) =>
        `${API_VERSION.v2}/business-analysis/dispensing-statistics/users?` + new URLSearchParams(filter),
    }),
  }),
});

export const { useLazyGetOverviewQuery, useLazyGetUsersQuery } = dispensingStatisticsApiSlice;
