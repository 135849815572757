import { Box, Card, Chip, Collapse, Fab, Typography, Zoom } from '@mui/material';
import ItemsPicker from './ItemsPicker';
import CalendarPicker from './CalendarPicker';
import { useContext, useState } from 'react';
import { FiltersTelemetryContext } from '../FiltersTelemetryContext';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import KeyIcon from '@mui/icons-material/Key';

export default function Filters() {
  const { keys, rangeEnd, rangeStart } = useContext(FiltersTelemetryContext);
  const [open, setOpen] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        p: 2,
        position: 'relative',
        overflow: 'visible',
        '@media print': { display: 'none' },
      }}
      onMouseOver={() => setShowLabel(true)}
      onMouseOut={() => setShowLabel(false)}
    >
      <Collapse in={!open}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
          <Typography>{t('filters')}</Typography>
          {rangeEnd && rangeStart && (
            <Chip
              label={`${dayjs(rangeStart).format('L')} - ${dayjs(rangeEnd).format('L')}`}
              size="small"
              icon={<DateRangeIcon />}
            />
          )}
          {keys.length === 0 && (
            <Chip
              variant="outlined"
              size="small"
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
              label={t('noItemsSelected')}
            />
          )}
          {keys &&
            keys.flatMap((key) => {
              if (!key.items) {
                return <Chip key={key.id} label={key.id} size="small" icon={<KeyIcon sx={{ rotate: '-45deg' }} />} />;
              }

              return key.items.map((k) => (
                <Chip
                  key={key.id + k.id}
                  label={`${key.id} - ${k.id}`}
                  size="small"
                  icon={<KeyIcon sx={{ rotate: '-45deg' }} />}
                />
              ));
            })}
        </Box>
      </Collapse>
      <Collapse in={open}>
        <Box sx={{ display: 'grid', gap: 2, gridTemplate: 'auto / 1fr 1fr' }}>
          <CalendarPicker />
          <ItemsPicker />
        </Box>
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {open && (
          <Fab
            color="primary"
            aria-label="add"
            variant={'extended'}
            sx={{ position: 'absolute', bottom: 0, transform: 'translateY(50%)', zIndex: 2 }}
            onClick={() => setOpen((prev) => !prev)}
          >
            {open ? (
              <>
                <UnfoldLessIcon />
                <Collapse orientation="horizontal" in={showLabel}>
                  {t('collapse')}
                </Collapse>
              </>
            ) : (
              <>
                <UnfoldMoreIcon sx={{ rotate: '45deg' }} />
                <Collapse orientation="horizontal" in={showLabel}>
                  {t('expand')}
                </Collapse>
              </>
            )}
          </Fab>
        )}
        {!open && (
          <Box sx={{ position: 'absolute', bottom: 0, transform: 'translateY(50%)' }}>
            <Zoom in={showLabel}>
              <Fab color="primary" aria-label="add" variant={'extended'} onClick={() => setOpen((prev) => !prev)}>
                {open ? (
                  <>
                    <UnfoldLessIcon />
                    <Collapse orientation="horizontal" in={showLabel}>
                      {t('collapse')}
                    </Collapse>
                  </>
                ) : (
                  <>
                    <UnfoldMoreIcon sx={{ rotate: '45deg' }} />
                    <Collapse orientation="horizontal" in={showLabel}>
                      {t('expand')}
                    </Collapse>
                  </>
                )}
              </Fab>
            </Zoom>
          </Box>
        )}
      </Box>
    </Card>
  );
}
