import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Operation, STATUS, addOperation, getTag } from '../slices/operationSlice';
import { OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';

export const dispatchPendingToOperationsSlice = <QueryArg, BaseQuery extends BaseQueryFn, ResultType>(
  api: MutationLifecycleApi<QueryArg, BaseQuery, ResultType, 'api'>,
  meta: {
    url_prefix: string;
    method: string;
    subject: string;
    entity: string;
  }
) => {
  const { requestId } = api;
  const operation = {
    uniqueId: requestId + OPERATION_STATUS.PENDING,
    operationId: requestId,
    state: OPERATION_STATUS.PENDING,
    showed: false,
    location: meta.url_prefix,
    tag: getTag(meta.url_prefix, meta.method),
    timestamp: dayjs().valueOf(),
    read: false,
    status: STATUS.INFO,
    subject: meta.subject,
    entity: meta.entity,
  } satisfies Operation;

  api.dispatch(addOperation(operation));
};
