import { Box, BoxProps } from '@mui/material';
const StatisticsRow = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      {...rest}
      display="grid"
      sx={(theme) => ({
        ...(rest.sx && typeof rest.sx === 'function' ? rest.sx(theme) : {}),
        gap: 2,
        marginBottom: 2,
        '@media print': {
          gridTemplate: '1fr / 1fr',
          '.MuiButton-textPrimary[type="button"]': {
            display: 'none',
          },
        },
      })}
    >
      {children}
    </Box>
  );
};
export default StatisticsRow;
