import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import useUrlChangeListener from './useUrlChangeListener';

type Param = {
  key: string;
  transformer?: (value: any) => string | string[];
  initialValue?: any;
  isList?: boolean;
};

const isValidUrl = (url: any) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

export class CustomURLSearchParams extends Map<string, string[] | string> {
  constructor(iterable?: Iterable<readonly [string, string[] | string]> | null | undefined | URL) {
    let _iterable = iterable;
    if (_iterable && isValidUrl(_iterable)) {
      const queryParams = (_iterable as URL).hash.split('?')[1]?.split('&');
      if (!queryParams || queryParams.length === 0) {
        _iterable = null;
      }

      _iterable = [];

      queryParams?.forEach((p) => {
        (_iterable as Array<Array<string>>).push(p.split('='));
      });

      _iterable = (_iterable as Array<Array<string>>).reduce<Array<[string, string | string[]]>>((acc, p) => {
        const _p = acc.find((v) => v[0] === p[0]);

        if (_p) {
          const i = acc.indexOf(_p);
          acc[i] = [_p[0], [...(typeof _p[1] === 'string' ? [_p[1]] : _p[1]), p[1]]];
          return acc;
        }

        acc.push(p as [string, string]);
        return acc;
      }, []);
    }

    super(_iterable as Iterable<readonly [string, string[] | string]> | null | undefined);
  }

  toString() {
    let res = '';
    for (let [key, value] of this) {
      if (!Array.isArray(value)) {
        res += `${key}=${value}&`;
        continue;
      }

      for (let v of value) {
        res += `${key.replace('[]', '')}[]=${v}&`;
      }
    }

    return res.substring(0, res.length - 1);
  }

  upsert(entries: { [key in string]: string | string[] }) {
    for (const key of Object.keys(entries)) {
      this.set(key.replace('[]', ''), entries[key]);
    }

    return this;
  }
}

export default function useSearchParamsManager(
  params: Param[]
): [CustomURLSearchParams | undefined, Dispatch<SetStateAction<CustomURLSearchParams | undefined>>] {
  const url = useUrlChangeListener();
  const urlChangedRef = useRef<boolean>(false);

  // write initial values
  const [searchParams, setSearchParams] = useState<CustomURLSearchParams | undefined>(
    new CustomURLSearchParams(
      params.reduce<Array<[string, string | string[]]>>((acc, param) => {
        const initial: Array<[string, string | string[]]> = param.initialValue ? [[param.key, param.initialValue]] : [];
        return [...acc, ...initial];
      }, [])
    )
  );

  // read from url, write to searchParams state
  useEffect(() => {
    // urlChange will prevent any looping and unnecessary renders
    if (!urlChangedRef.current && document && url) {
      const _searchParams = new CustomURLSearchParams(searchParams);

      setSearchParams(
        _searchParams.upsert(
          [...new CustomURLSearchParams(new URL(url)).entries()].reduce<{
            [key in string]: string | string[];
          }>((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {})
        )
      );
      urlChangedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  // read from searchParams state, write to url
  useEffect(() => {
    const _searchParams = searchParams?.toString();
    if (_searchParams) {
      const hash = document.location.hash.split('?');
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, '', `${hash[0]}?${searchParams?.toString()}`);
    }
  }, [searchParams]);

  return [searchParams, setSearchParams];
}
