import { useCallback, useState } from 'react';
import { AddEditDialogConfigSelectBox, SelectBoxOption } from './typings';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { AREA_DESIGN_TOKENS } from '../Card/Area';

export const useSelect = <T,>(initialValue: T) => {
  const [value, setValue] = useState<T>(initialValue);

  return { value, setValue };
};

export const SelectBoxInput = ({
  configItem,
  value,
  onChange,
}: {
  configItem: AddEditDialogConfigSelectBox;
  value: NonNullable<SelectBoxOption>;
  onChange: (value: unknown) => void;
}) => {
  const { options, currentValue, skeletonOptionComponent } = configItem.selectBoxConfig;
  const isValidOption = useCallback(
    (x: SelectBoxOption | null | undefined): x is SelectBoxOption =>
      x != null && typeof x.component === 'function' && typeof x.label === 'string' && x.value != null,
    []
  );
  const hasOptions = options && options.length > 0;
  const validOptions = options.filter((option) => isValidOption(option)) as NonNullable<SelectBoxOption[]>;

  const { value: selectedOption, setValue } = useSelect<SelectBoxOption | null>(value ? value : null);

  const handleClick = useCallback(
    (option: SelectBoxOption) => {
      setValue(option);
      onChange(option.value);
    },
    [onChange, setValue]
  );
  const handleClear = useCallback(() => {
    setValue(null);
    onChange(null);
  }, [onChange, setValue]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: AREA_DESIGN_TOKENS.gap }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: AREA_DESIGN_TOKENS.gap }}>
        <Box sx={{ display: 'flex', flexGrow: 1, width: '50%' }}>
          {currentValue != null &&
            React.cloneElement(currentValue?.component(currentValue), {
              key: `key-${String(currentValue.value)}`,
              value: currentValue.value,
              selected: false,
              deselect: handleClear,
            })}
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1, width: '50%' }}>
          {selectedOption != null
            ? React.cloneElement(selectedOption?.component(selectedOption), {
                key: `key-${String(selectedOption.value)}`,
                value: selectedOption.value,
                selected: true,
              })
            : skeletonOptionComponent()}
        </Box>
      </Box>
      <Typography>Available options</Typography>
      <Box sx={{ display: 'flex', gap: AREA_DESIGN_TOKENS.gap }}>
        {hasOptions &&
          validOptions
            ?.filter((o) => o.value !== selectedOption?.value)
            .map((option) =>
              React.cloneElement(option.component(option), {
                key: `key-${String(option.value)}`,
                value: option.value,
                selected: false,
                onClick: () => handleClick(option),
              })
            )}
      </Box>
    </Box>
  );
};
