import useDownloadFile from '@/hooks/useDownloadFile';
import { useLazyGetBuildFileDownloadURLQuery } from '@/redux/api/admin/otaApiSlice';

export default function useDownloadOTABuildFile() {
  const [getDownloadBuildFileUrlTrigger, flags] = useLazyGetBuildFileDownloadURLQuery();

  const { downloadFile } = useDownloadFile();

  const _handleDownloadFile = async (id: string) => {
    const res = await getDownloadBuildFileUrlTrigger(id);
    if (res.data?.data?.item) {
      downloadFile(id, res.data?.data.item);
    }
  };

  return {
    downloadOTABuildFile: _handleDownloadFile,
    flags,
  };
}
