import ReactDOM from 'react-dom/client';
import { Box, Typography } from '@mui/material';
import { MarkerInfo } from '../typings';
import DeviceTooltipContent from './DeviceTooltipContent';
import './styles.css';

const MarkerTooltip = ({ title, content, children }: React.PropsWithChildren & { title: string; content: string }) => {
  return (
    <Box borderRadius={4} minWidth={'250px'}>
      <Box display="flex" flexDirection="column" alignItems={'center'} textAlign={'center'}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="body2">{content}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default function createMarkerTooltip(containerId: string, data: MarkerInfo) {
  const renderContent = () => {
    switch (data.type) {
      case 'device':
        return (
          <DeviceTooltipContent
            model={data.extra.model}
            name={data.extra.name}
            serialNumber={data.extra.serialNumber}
            coordinates={{ lat: data.extra.lat, lng: data.extra.lon }}
            errors={data.extra.status.errors}
            alarms={data.extra.status.alarms}
          />
        );
      default:
        return null;
    }
  };

  const container = ReactDOM.createRoot(document.getElementById(containerId)!);
  container.render(
    <MarkerTooltip title={data.title} content={data.description}>
      {renderContent()}
    </MarkerTooltip>
  );
}
