import { Box, BoxProps, ButtonBaseProps, CSSObject, Fab, Theme, alpha, styled } from '@mui/material';

const slideInMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity', 'transform'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.enteringScreen,
    delay,
  }),
  transform: ['translate(calc(100% + 2rem), -50%)'],
  opacity: 1,
});

const slideOutMixin = (theme: Theme, delay?: number): CSSObject => ({
  transition: theme.transitions.create(['opacity', 'transform'], {
    easing: theme.transitions.easing.bounce,
    duration: theme.transitions.duration.enteringScreen,
    delay,
  }),
  transform: ['translate(0%, -50%)'],
  opacity: 0,
});

interface SlideDivProps extends BoxProps {
  slide?: boolean;
  delay?: number;
}

export const SlideDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'slide' && prop !== 'delay',
})<SlideDivProps>(({ theme, slide, delay }) => ({
  position: 'absolute',
  ...(slide && {
    '&': slideInMixin(theme),
  }),
  ...(!slide && {
    '&': slideOutMixin(theme, delay),
  }),
}));

export default function SlidingInButton({
  slide,
  delay,
  body,
  ...props
}: { slide: boolean; delay: number; body: JSX.Element } & ButtonBaseProps) {
  return (
    <SlideDiv
      slide={slide}
      delay={delay}
      sx={{
        position: 'absolute',
        top: '50%',
        right: 0,
        opacity: 0,
      }}
    >
      <Fab
        {...props}
        color="inherit"
        size="small"
        sx={{ backgroundColor: (theme) => alpha(theme.palette.background.default, 0.9), boxShadow: 0 }}
        variant="extended"
      >
        {body}
      </Fab>
    </SlideDiv>
  );
}
