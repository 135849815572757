import { REQUESTED_LOCATION } from '@/shared/constants';
import i18next from 'i18next';

export const getNumberTh = (number: number) => {
  if (number === 1) {
    return i18next.t('first');
  }
  if (number === 2) {
    return i18next.t('second');
  }
  if (number === 3) {
    return i18next.t('third');
  }
  return i18next.t('numberTh', { number });
};

export const setRequestedLocation = (location: Location) => {
  localStorage.setItem(REQUESTED_LOCATION, location.href);
};

export const getRequestedLocation = () => localStorage.getItem(REQUESTED_LOCATION);

export const removeRequestLocation = () => localStorage.removeItem(REQUESTED_LOCATION);
