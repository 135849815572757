import useInputValidations from '@/hooks/useFormValidations';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PasswordInputProps } from './typings';

export default function PasswordInput({
  label,
  name = 'password',
  fullValidation = true,
  ...props
}: PasswordInputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [visible, setVisible] = useState(false);
  let { password: passwordValidations }: Record<string, any> = useInputValidations();
  const { t } = useTranslation();

  if (!fullValidation) {
    passwordValidations = {
      required: passwordValidations.required,
      minLength: passwordValidations.minLength,
    };
  }

  return (
    <TextField
      variant="outlined"
      label={label || t('password')}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setVisible((v) => !v)} edge="end">
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...register(name, passwordValidations)}
      {...(!!errors[name] && { error: true, helperText: errors[name]!.message as string })}
      {...props}
    />
  );
}
