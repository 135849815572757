import { SyntheticEvent, useState } from 'react';
import { CommonSnackbarProps } from './typings';
import { Box, Button, Collapse, Paper, alpha, darken } from '@mui/material';
import BaseOperationTypography from '../Operation/BaseOperationTypography';
import { useTranslation } from 'react-i18next';

const commonStatusSnackbarActions = (e: SyntheticEvent, action: Function) => {
  e.preventDefault();
  e.stopPropagation();
  action();
};

export default function StatusSnackbarActions({ operation, dismissAction }: CommonSnackbarProps) {
  const [expand, setExpand] = useState(false);
  const { t } = useTranslation();
  const toggleExpand = () => setExpand((prev) => !prev);

  return (
    <Box>
      {operation.error && Object.keys(operation.error).length > 0 && (
        <Box>
          <Collapse in={expand}>
            <Paper
              elevation={0}
              sx={{
                m: 2,
                mt: 0,
                p: 1,
                backgroundColor: (theme) => alpha(darken(theme.palette[operation.status].dark, 0.1), 0.1),
              }}
            >
              {operation.error?.status && (
                <BaseOperationTypography read={operation.read} status={operation.status} component="p">
                  {t('error')}:{' '}
                  <BaseOperationTypography read={operation.read} status={operation.status} fontWeight={'500'}>
                    {operation.error?.status}
                  </BaseOperationTypography>
                </BaseOperationTypography>
              )}
              {operation.error?.data?.error?.message && (
                <BaseOperationTypography read={operation.read} status={operation.status} component="p">
                  {t('message')}:{' '}
                  <BaseOperationTypography read={operation.read} status={operation.status} fontWeight={'500'}>
                    {operation.error?.data?.error?.message}
                  </BaseOperationTypography>
                </BaseOperationTypography>
              )}
              {operation.error?.error && (
                <BaseOperationTypography read={operation.read} status={operation.status} component="p">
                  {t('message')}:{' '}
                  <BaseOperationTypography read={operation.read} status={operation.status} fontWeight={'500'}>
                    {operation.error?.error}
                  </BaseOperationTypography>
                </BaseOperationTypography>
              )}
            </Paper>
          </Collapse>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 1, pt: 0 }}>
        {operation.error && Object.keys(operation.error).length > 0 && (
          <BaseOperationTypography read={operation.read} status={operation.status} component="div">
            <Button
              onClick={(e) => commonStatusSnackbarActions(e, toggleExpand)}
              variant="text"
              sx={{ color: 'inherit', '&:focus': { outline: 'none' } }}
              size="small"
            >
              {expand ? t('collapse') : t('expand')}
            </Button>
          </BaseOperationTypography>
        )}
        <BaseOperationTypography read={operation.read} status={operation.status} component="div">
          <Button
            onClick={(e) => commonStatusSnackbarActions(e, dismissAction)}
            variant="text"
            sx={{ color: 'inherit', '&:focus': { outline: 'none' } }}
            size="small"
          >
            {t('dismiss')}
          </Button>
        </BaseOperationTypography>
      </Box>
    </Box>
  );
}
