import { Autocomplete, TextField } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { StoreAsyncAutocompleteFilterConfig, StoreFilterOption, StoreOnFilterChange } from './typings';
import useDebounce from '@/hooks/useDebounce';
import { useTranslation } from 'react-i18next';

export default function AutocompleteAsync({
  id,
  defaultValue,
  lazyQueryHook,
  renderOption,
  transformFn,
  debounceTime = 400,
  onFilterChange,
  getQueryParam,
  shouldFetch,
  renderInput,
  label,
  ...props
}: StoreAsyncAutocompleteFilterConfig & { onFilterChange: StoreOnFilterChange }) {
  const [queryTrigger, { isFetching, isLoading, currentData, isUninitialized }] = lazyQueryHook();
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const queryParams = getQueryParam(query);
  const debouncedQueryTrigger = useDebounce(() => queryTrigger(queryParams), 800);
  const parsedData = (currentData as { data?: { items: any[] } })?.data?.items?.length
    ? transformFn((currentData as { data: { items: any } }).data)
    : [];
  const showLoader = isFetching || isLoading;
  const lastInputValue = useRef('*');
  const options = defaultValue && !parsedData?.length ? [defaultValue] : parsedData;

  useEffect(() => {
    shouldFetch(query) && lastInputValue.current !== query && debouncedQueryTrigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (defaultValue && typeof onFilterChange === 'function' && isUninitialized) {
      // Receiving the default value and set it as a prop to the autocomplete does not trigger
      // the onChange event, so we need to invoke it manually.
      onFilterChange(id, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, id, isUninitialized]);

  return (
    <Autocomplete
      {...props}
      {...(defaultValue ? { defaultValue: defaultValue, key: id + defaultValue?.optionId } : { key: id })}
      id={id}
      getOptionLabel={(option) => option?.label}
      filterOptions={(x) => x}
      options={options}
      isOptionEqualToValue={(o, v) => o.optionId === v.optionId}
      autoComplete
      disablePortal
      loading={showLoader}
      loadingText={`${t('loading')}...`}
      noOptionsText={isUninitialized ? t('search3Letters') : t('noResultsFound')}
      onChange={(_, val: StoreFilterOption | null) => {
        onFilterChange(id, val);
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason !== 'input') {
          return;
        }
        setQuery((prev) => {
          lastInputValue.current = prev;
          return newInputValue;
        });
      }}
      renderInput={
        typeof renderInput === 'function'
          ? renderInput
          : (params) => (
              <TextField
                {...params}
                label={label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                }}
              />
            )
      }
      renderOption={renderOption}
    />
  );
}
