import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import APP_CONFIG from '@/appConfig';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Culligan One Dashboard
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('appVersion', { version: APP_CONFIG.appVersion })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Settings;
