import { useCallback, useEffect, useMemo } from 'react';
import { PollProps } from './typings';
import { useDispatch } from 'react-redux';
import usePolling from '@/hooks/usePolling';
import { removePolling, sendPollingUpdate } from '@/redux/slices/pollingSlice';
import { Operation, STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { OPERATION_STATUS } from '@/shared/constants';
import dayjs from 'dayjs';
import { useLazyGetDeviceOperatingModeAndPowerProfileQuery } from '@/redux/api/admin/deviceCommandsApiSlice';
import apiSlice from '@/redux/api/apiSlice';
import { removePendingCommand } from '@/redux/slices/deviceSlice';
import { OperatingMode } from '@culligan-iot/domain/culligan/one/device';
import { Schema as S } from '@effect/schema';

export default function PollPostRebootCommand({ polling, id }: PollProps) {
  const lastArgs = polling?.lastArgs as string;
  const { result, isPolling, stop, remainingAttempts } = usePolling({
    useQuery: useLazyGetDeviceOperatingModeAndPowerProfileQuery,
    interval: polling?.interval,
    maxAttempts: polling.maxAttempts,
    pollingState: polling,
    queryArgs: lastArgs,
  });
  const latestOperatingMode = result.data?.data?.operatingMode;
  const expectedOperatingMode = OperatingMode.pipe(S.pickLiteral('Startup')).literals[0];
  const dispatch = useDispatch();

  const partialOperation = useMemo(
    () =>
      ({
        entity: lastArgs,
        location: ' ',
        operationId: id,
        read: false,
        showed: false,
        subject: 'reboot',
        timestamp: dayjs().valueOf(),
        tag: TAGS.DEVICE_COMMAND_SEND,
      } satisfies Partial<Operation>),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, lastArgs]
  );

  const handleRemovePolling = useCallback(() => {
    dispatch(removePolling({ endpoint: 'postRebootCommand', id }));
    dispatch(removePendingCommand({ serialNumber: lastArgs, command: 'reboot' }));
  }, [dispatch, id, lastArgs]);

  const handlePollingSuccess = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.FULFILLED,
        status: STATUS.SUCCESS,
        uniqueId: `${id}-${STATUS.SUCCESS}`,
      })
    );
    handleRemovePolling();
  }, [dispatch, handleRemovePolling, id, partialOperation]);

  const handlePollingError = useCallback(() => {
    dispatch(
      upsertOperation({
        ...partialOperation,
        state: OPERATION_STATUS.REJECTED,
        status: STATUS.ERROR,
        uniqueId: `${id}-${STATUS.ERROR}`,
        error: result.error,
      })
    );
    handleRemovePolling();
  }, [dispatch, handleRemovePolling, id, partialOperation, result.error]);

  useEffect(() => {
    dispatch(sendPollingUpdate({ endpoint: 'postRebootCommand', result, isPolling, id, remainingAttempts }));
    if (remainingAttempts <= 0 || result.isError) {
      handlePollingError();
      return;
    }

    if (
      polling.remainingAttempts &&
      polling.remainingAttempts > 0 &&
      isPolling &&
      result.data?.data &&
      expectedOperatingMode === latestOperatingMode
    ) {
      handlePollingSuccess();
      stop();
      dispatch({
        type: `${apiSlice.reducerPath}/invalidateTags`,
        payload: ['Device'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, isPolling, dispatch, stop]);

  return <></>;
}
