import { Theme } from '@mui/material';

export type Amount = {
  amount: number;
};

export type Duration = {
  duration: Theme['transitions']['duration'] | number;
};

export const DIRECTION = {
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up',
} as const;

export type Direction = {
  direction: (typeof DIRECTION)[keyof typeof DIRECTION];
};

export type Delay = {
  delay: number;
};

export type Trigger = {
  trigger: boolean;
};
