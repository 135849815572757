import { AreaBody, AreaHeader, StatusAreaContainer } from '@/components/Shared/Card/Area';
import { Body, Caption, Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import { Device } from '../../../DevicesPanel/typings';
import { Box, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ParsedInfo } from '../..';
import dayjs from 'dayjs';

export default function Status({
  device,
  isDeviceOnline,
  updatedAt,
  info,
  sx,
}: {
  device: Device;
  isDeviceOnline: boolean;
  updatedAt: Device['updatedAt'];
  info: ParsedInfo;
  sx?: SxProps;
}) {
  const { t } = useTranslation();
  return (
    <Box sx={sx ? sx : {}}>
      <StatusAreaContainer
        shade={(theme) => (isDeviceOnline ? theme.palette.success.light : theme.palette.error.light)}
      >
        <AreaHeader>
          <Subtitle>{t('status')}</Subtitle>
        </AreaHeader>
        <AreaBody>
          <Title sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Box
              width={15}
              height={15}
              sx={{
                backgroundColor: `${isDeviceOnline ? 'success' : 'error'}.light`,
                borderRadius: 2,
              }}
            />
            {`${isDeviceOnline ? t('connected') : t('disconnected')}`}
          </Title>
          <Body>{`${t('updated')} ${dayjs(updatedAt).from(dayjs()) || t('notAvailable')}`}</Body>
          <Caption>({dayjs(updatedAt).format('YYYY/MM/DD')})</Caption>
        </AreaBody>
      </StatusAreaContainer>
    </Box>
  );
}
