import { Column } from '@material-table/core';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BrandRes } from './typings';
import dayjs from 'dayjs';
import ColorChip from '@/components/Shared/Chips/ColorChip';
import CulliganChip from '@/components/Shared/Chips/CulliganChip';
import { Check, Clear } from '@mui/icons-material';
import UnsetColorChip from '@/components/Shared/Chips/UnsetColorChip';
import useTypedSelector from '@/hooks/useTypedSelector';
import { setTheme, themeSelector } from '@/redux/slices/themeSlice';
import { useDispatch } from 'react-redux';

export function useBrandColumns(onEditClick: Function, onDeleteClick: Function): Array<Column<BrandRes>> {
  const { t } = useTranslation();
  const columns_array: Array<Column<BrandRes>> = [];

  const themeSlice = useTypedSelector(themeSelector);
  const dispatch = useDispatch();

  return columns_array.concat([
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('countries'),
      field: 'countries',
      render: (brand) => (brand.countries as string[])?.join(', '),
      exportTransformer: (brand) => (brand.countries as string[])?.join(', '),
      sorting: false,
    },
    {
      title: t('address'),
      field: 'address',
    },
    {
      title: t('domain'),
      field: 'domain',
      render: (brand) => ((brand.domain as string[])?.join ? (brand.domain as string[])?.join(', ') : brand.domain),
      exportTransformer: (brand) =>
        (brand.domain as string[])?.join ? (brand.domain as string[])?.join(', ') : brand.domain,
      sorting: false,
    },
    {
      title: t('color'),
      field: 'color',
      render: (brand) => {
        if (brand.color) {
          return (
            <ColorChip
              key={brand.id + brand.color}
              hex={brand.color}
              label={brand.color}
              onClick={() => dispatch(setTheme({ ...themeSlice, primary: brand.color as `#${string}` }))}
              tooltipDescription={t('tryColorExplaination')}
            />
          );
        }

        return <UnsetColorChip label={t('unset')} key={brand.id + 'primary'} />;
      },
      exportTransformer: (rowData) => rowData.color,
    },
    {
      title: t('Logo'),
      field: 'logo',
      customFilterAndSearch: (filter, brand) => {
        const logo = brand.logo ? 'Uploaded' : 'Not Uploaded';
        return logo.indexOf(filter) !== -1;
      },
      render: (brand) =>
        brand.logo ? (
          <CulliganChip icon={<Check color="success" />} label={t('uploaded')} />
        ) : (
          <CulliganChip icon={<Clear color="error" />} label={t('notUploaded')} />
        ),
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (brand) => dayjs(brand.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (brand) => dayjs(brand.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      render: (brand) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          <Button variant="outlined" color="primary" onClick={() => onEditClick(brand.id)}>
            {t('edit')}
          </Button>
          <Button variant="outlined" color="error" onClick={() => onDeleteClick({ id: brand.id, name: brand.name })}>
            {t('delete')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ]);
}
