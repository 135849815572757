import { DatePicker, DatePickerSlotsComponentsProps } from '@mui/x-date-pickers/DatePicker';
import { DateRangeFilterProps, DateRange, LocalOnFilterChange, StoreOnFilterChange } from './typings';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

const datePickerSlotProps: DatePickerSlotsComponentsProps<Date> = {
  actionBar: {
    actions: ['clear'],
  },
  textField: {
    fullWidth: true,
    variant: 'standard',
    InputProps: { style: { marginTop: 0 } },
    InputLabelProps: { style: { display: 'none' }, shrink: true },
  },
};

const DateRangeFilter = <T,>({ config, onFilterChange, kind, ...props }: DateRangeFilterProps<T>) => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<DateRange<number>>({
    start: null,
    end: null,
  });

  const handleDateChange = (date: dayjs.Dayjs | null, type: keyof DateRange<number>) => {
    if (date == null || date.isValid()) {
      setDateRange((dr) => ({ ...dr, [type]: date ? date.valueOf() : null }));
    }
  };

  useEffect(() => {
    if (kind === 'local') {
      (onFilterChange as LocalOnFilterChange<T>)(
        config.id,
        dateRange.start == null && dateRange.end == null ? null : config.filterFxHof(dateRange)
      );
    } else if (kind === 'store') {
      (onFilterChange as StoreOnFilterChange)(
        config.id,
        dateRange.start == null && dateRange.end == null
          ? null
          : { label: 'dateRange', optionId: JSON.stringify(dateRange) }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.start, dateRange.end, onFilterChange, config]);

  return (
    <fieldset
      key={config.id}
      id={config.id}
      style={{
        boxSizing: 'border-box',
        padding: '0.425rem 0.25rem',
        border: '1px solid rgba(0,0,0,0.23)',
        borderRadius: '0.25rem',
      }}
    >
      <legend style={{ fontSize: '0.75rem', padding: '0 0.25rem', margin: '0 0.25rem' }}>{config.label}</legend>
      <Box {...props} display="flex" alignItems={'center'} px={'0.75rem'}>
        <DatePicker
          onChange={(date: any) => handleDateChange(date, 'start')}
          label={t('startDate')}
          slotProps={{
            ...datePickerSlotProps,
            textField: { ...datePickerSlotProps.textField, placeholder: t('startDate') as string },
          }}
          {...(kind === 'store' && config.defaultValue
            ? { defaultValue: dayjs(config.defaultValue.start).toDate() }
            : {})}
          {...(config.style ? { style: config.style } : {})}
        />
        <DatePicker
          onChange={(date: any) => handleDateChange(date, 'end')}
          label={t('endDate')}
          slotProps={{
            ...datePickerSlotProps,
            textField: { ...datePickerSlotProps.textField, placeholder: t('endDate') as string },
          }}
          sx={{ ml: '2rem' }}
          {...(kind === 'store' && config.defaultValue
            ? { defaultValue: dayjs(config.defaultValue.end).toDate() }
            : {})}
          {...(config.style ? { style: config.style } : {})}
        />
      </Box>
    </fieldset>
  );
};

export default DateRangeFilter;

export const dateRangeFilterDefaultAssert = (param: dayjs.ConfigType, dateRange: DateRange<number>) => {
  const paramDate = dayjs(param).unix();
  if (dateRange.start && !dateRange.end) {
    return paramDate >= dateRange.start;
  } else if (dateRange.end && !dateRange.start) {
    return paramDate <= dateRange.end;
  } else {
    return paramDate >= dateRange.start! && paramDate <= dateRange.end!;
  }
};
