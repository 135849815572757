import { Box, keyframes, useTheme } from '@mui/material';

const blipEffect = keyframes`
  100% {
    opacity: 0;
    transform: scale(100%);
  }
  0% {
    opacity: 1;
    transform: scale(0);
  }
`;

export default function BlipBox({ color }: { color?: string }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '80%',
        bgcolor: color || theme.palette.error.light,
        width: '80%',
        borderRadius: '50%',
        animation: `${blipEffect} 700ms infinite ${theme.transitions.easing.easeInOut}`,
        transformOrigin: 'center',
      }}
    />
  );
}
