import useAddEditDialog from '@/hooks/useAddEditDialog';
import {
  useDeleteCommandMutation,
  useGetCommandsQuery,
  usePostCommandMutation,
  usePutCommandMutation,
  useLazyIsCommandUniqueQuery,
} from '@/redux/api/system/commandsApiSlice';
import { useTranslation } from 'react-i18next';
import { FieldsUndefined } from '@typings';
import { CommandReq, CommandRes } from './typings';
import { ROLES, DIRECTIONS, COMMAND_TYPES, CHANNELS } from '@/shared/constants';
import { useCommandsColumns } from './useCommandsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import useUniqueness from '@/hooks/useUniqueness';

const isValidJSON = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch {
    return false;
  }
};

export const CommandsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<CommandRes>));

export default function CommandsPanel() {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useGetCommandsQuery();
  const [postCommandTrigger] = usePostCommandMutation();
  const [putCommandTrigger] = usePutCommandMutation();
  const [deleteCommandTrigger] = useDeleteCommandMutation();
  const [isCommandUnique] = useLazyIsCommandUniqueQuery();
  const validateUniqueness = useUniqueness<FieldsUndefined<CommandRes, 'id' | 'name'>, 'id' | 'name'>({
    isUniqueTrigger: isCommandUnique,
  });

  const getDialogOptions = () => {
    return {
      rolesOptions: Object.values(ROLES).map((role) => ({ label: t(role), value: role })),
      directionOptions: Object.values(DIRECTIONS).map((direction) => ({ label: t(direction), value: direction })),
      typeOptions: Object.values(COMMAND_TYPES).map((type) => ({ label: t(type), value: type })),
    };
  };

  const { rolesOptions, directionOptions, typeOptions } = getDialogOptions();

  const { AddEditDialog, openDialog } = useAddEditDialog<CommandReq>({
    title: t(CHANNELS.COMMANDS),
    baseConfig: [
      {
        name: 'id',
        placeholder: t('key'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('id', currValue);
          },
        },
      },
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'description',
        placeholder: t('description'),
      },
      {
        type: 'autocomplete',
        name: 'direction',
        placeholder: t('direction'),
        options: { required: true },
        selectConfig: {
          options: directionOptions,
        },
      },
      {
        type: 'autocomplete',
        name: 'role',
        placeholder: t('roles'),
        options: { required: true },
        selectConfig: {
          options: rolesOptions,
          multiple: true,
        },
      },
      {
        type: 'autocomplete',
        name: 'type',
        placeholder: t('type'),
        options: { required: true },
        selectConfig: {
          options: typeOptions,
        },
        mutations: (showFields, hideFields, toggleFields) => ({
          onChange: {
            impacts: {
              form: {
                handler: ({ getValues }) => {
                  const { type } = getValues();

                  if (type?.value === COMMAND_TYPES.PARAMS_WITH_CONFIG) {
                    showFields('payloadExample', 'jsonSchema');
                    return;
                  }

                  if (type?.value === COMMAND_TYPES.PARAMS_WITHOUT_CONFIG) {
                    toggleFields((name) => name !== 'jsonSchema');
                    return;
                  }

                  hideFields('payloadExample', 'jsonSchema');
                },
              },
            },
          },
        }),
      },
      {
        name: 'mandatory',
        type: 'checkbox',
        placeholder: t('mandatory'),
        initialValue: false,
      },
      {
        name: 'parameters',
        type: 'checkbox',
        placeholder: t('parameters'),
        initialValue: false,
      },
      {
        name: 'payloadExample',
        placeholder: t('payloadExample'),
        options: {
          required: true,
          deps: ['type'],
          validate: (value, formValues) => {
            if (!value && formValues?.type.value === COMMAND_TYPES.NO_PARAMS) {
              return true;
            }

            if (
              value &&
              [COMMAND_TYPES.PARAMS_WITHOUT_CONFIG, COMMAND_TYPES.PARAMS_WITH_CONFIG].includes(formValues?.type.value)
            ) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
          },
        },
        helperText: t('JSONHelperText') as string,
      },
      {
        name: 'jsonSchema',
        placeholder: t('JSONSchema'),
        options: {
          required: true,
          deps: ['parameters'],
          validate: (value, formValues) => {
            if (
              !value &&
              [COMMAND_TYPES.NO_PARAMS, COMMAND_TYPES.PARAMS_WITHOUT_CONFIG].includes(formValues?.type.value)
            ) {
              return true;
            }

            if (value && formValues?.type.value === COMMAND_TYPES.PARAMS_WITH_CONFIG) {
              return value && isValidJSON(value) ? true : (t('JSONInvalid') as string);
            }
          },
        },
        helperText: t('JSONHelperText') as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => data?.data?.items?.find((command) => command.id === dialogId),
    mutations: (showFields, hideFields) => ({
      onMount: {
        impacts: {
          form: {
            handler: ({ getValues }) => {
              const { type } = getValues();

              if (type?.value === COMMAND_TYPES.PARAMS_WITH_CONFIG) {
                showFields('payloadExample', 'jsonSchema');
                return;
              }

              if (type?.value === COMMAND_TYPES.PARAMS_WITHOUT_CONFIG) {
                showFields('payloadExample');
                return;
              }

              hideFields('payloadExample', 'jsonSchema');
            },
          },
        },
      },
    }),
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      if (isEditing) {
        await putCommandTrigger({ id: dialogId, body: data });
      } else {
        await postCommandTrigger(data);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onChange',
  });

  const { ConfirmDialog, confirm } = useConfirmDialog({
    title: t('delete') + ' ' + t('command'),
    message: (extra: any) => t('deleteMessage', { entity: t('command'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteCommandTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useCommandsColumns(openDialog, confirm);

  return (
    <>
      <CommandsTable
        title={t(CHANNELS.COMMANDS)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('command')}
        onResetStateButtonClick={openDialog}
        exportData={true}
        selection={true}
      />

      <AddEditDialog />

      <ConfirmDialog />
    </>
  );
}
