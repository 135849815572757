import MaterialTable, { MaterialTableProps, MTableToolbar } from '@material-table/core';
import { Box, useTheme } from '@mui/material';
import { useState, createContext, useMemo } from 'react';
import HiddenColumnChip from './HiddenColumnChip';

const DEFAULT_PADDING = '0.5rem';
export const HiddenColumnsContext = createContext(0);

const CulliganTable = <T extends object>(props: MaterialTableProps<T>) => {
  const theme = useTheme();

  const [hiddenColumns, setHiddenColumns] = useState<number>(0);

  const table = useMemo(
    () => (
      <MaterialTable
        {...props}
        components={{
          ...(props.options?.paging && props.options?.pageSize ? { Pagination: () => <></> } : {}),
          Actions: () => {
            return (
              <Box px={1} height="100%" display="flex" alignItems="center">
                <HiddenColumnChip sx={{}} />
              </Box>
            );
          },
          Toolbar: (toolbarProps) => {
            return (
              <MTableToolbar
                {...{
                  ...toolbarProps,
                  onColumnsChanged: (column: any, hidden: boolean) => {
                    const calc = toolbarProps.columns
                      .map((c: any) => {
                        if (c.field === column.field) {
                          return { ...column, hidden };
                        }
                        return c;
                      })
                      .reduce((current: number, next: any) => (next.hidden ? current + 1 : current), 0);
                    setHiddenColumns(calc);
                    return toolbarProps.onColumnsChanged(column, hidden);
                  },
                }}
              />
            );
          },
        }}
        options={{
          headerStyle: {
            ...props.options?.headerStyle,
            backgroundColor: theme.palette.background.grayShades[0],
            paddingTop: DEFAULT_PADDING,
            paddingBottom: DEFAULT_PADDING,
            fontWeight: 500,
            fontSize: '1rem',
          },
          tableLayout: 'auto',
          pageSize: props.options?.pageSize || 10,
          ...props.options,
        }}
      />
    ),
    [props, theme.palette.background.grayShades]
  );

  return <HiddenColumnsContext.Provider value={hiddenColumns}>{table}</HiddenColumnsContext.Provider>;
};

export default CulliganTable;
