import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend, ChartData, ChartOptions } from 'chart.js';
import { CSSProperties, MutableRefObject, useMemo, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CulliganTooltipChart from '@/components/Shared/Charts/ChartTooltip';
import { Bar } from 'react-chartjs-2';
import useGenerateCustomTooltipHandler from '@/hooks/useGenerateCustomTooltip';
import { useNavigate } from 'react-router';
import { BarChartWithCustomTooltipProps } from './typings';

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend);

const getOptions = (customTooltip: any, options?: ChartOptions<'bar'>): ChartOptions<'bar'> => ({
  responsive: true,
  maintainAspectRatio: false,
  events: ['mousemove', 'click', 'touchstart', 'touchmove', ...[...(options?.events ? options.events : [])]],
  ...{ ...(options ? options : {}) },
  interaction: {
    mode: 'index',
    ...{ ...(options?.interaction ? options.interaction : {}) },
  },
  plugins: {
    legend: {
      display: false,
      ...{ ...(options?.plugins?.legend ? options.plugins?.legend : {}) },
    },
    tooltip: {
      enabled: false,
      external: customTooltip,
      ...{ ...(options?.plugins?.tooltip ? options.plugins?.tooltip : {}) },
    },
    ...{ ...(options?.plugins ? options.plugins : {}) },
  },
  scales: {
    y: {
      max: 100,
      ...{ ...(options?.scales?.y ? options.scales?.y : {}) },
    },
    ...{ ...(options?.scales ? options.scales : {}) },
  },
});

const BarGraph = ({
  data,
  style,
  forwardRef,
  options,
  tooltipData,
  label,
  navigationClickHandler,
}: {
  data: ChartData<'bar', (number | [number, number] | null)[], unknown>;
  style?: CSSProperties;
  forwardRef: MutableRefObject<ChartJSOrUndefined<'bar', (number | [number, number] | null)[], unknown> | null>;
  options: ChartOptions<'bar'>;
  tooltipData: {
    top: number;
    left: number;
    dataPoint: any;
    totalDevices: number;
  } | null;
  label: string;
  navigationClickHandler: () => void;
}) => {
  const { t } = useTranslation();

  const tooltipBody = useMemo(
    () =>
      tooltipData ? (
        <>
          <Box p={2}>
            <Box pb={1} display={'flex'} gap={2} alignItems={'center'} justifyContent={'space-between'} width="100%">
              <Typography variant="caption" lineHeight={1} height="fit-content">
                {label} <br /> {t('with')} {tooltipData.dataPoint.label}
              </Typography>
              <Typography variant="h6" fontSize={32} lineHeight={1}>
                {tooltipData.dataPoint.formattedValue}
              </Typography>
            </Box>
            <Box display={'flex'} gap={2} alignItems={'baseline'} justifyContent={'space-between'}>
              <Typography variant="body1" lineHeight={1} fontWeight={600}>
                {((tooltipData.dataPoint.raw * 100) / tooltipData.totalDevices).toFixed(2)}%
              </Typography>
              <Typography variant="caption" lineHeight={1}>
                {t('ofTheTotalLabel', { label: label.toLowerCase() })}
              </Typography>
            </Box>
          </Box>
          <Divider orientation="horizontal" sx={{ borderColor: 'background.default', opacity: 0.3 }} />
          <Box sx={{ color: 'background.default' }} display="flex" justifyContent="space-between" width="100%" p={1}>
            <Button variant="text" color="inherit" size="small" onClick={() => navigationClickHandler()}>
              {t('viewAll')}
            </Button>
            <IconButton color="inherit" size="small">
              <ShoppingCartIcon sx={{ fontSize: '1rem' }} />
            </IconButton>
          </Box>
        </>
      ) : (
        <></>
      ),
    [label, navigationClickHandler, t, tooltipData]
  );

  return (
    <Box sx={{ position: 'relative', height: '100%', py: 1 }}>
      <Bar options={options} data={data} style={style} width="100%" ref={forwardRef} />
      {tooltipData && (
        <CulliganTooltipChart
          position="managed"
          coordinates={{ top: tooltipData?.top || 0, left: tooltipData?.left || 0 }}
          body={tooltipBody}
        />
      )}
    </Box>
  );
};

export default function BarChartWithCustomTooltip({ data, options, label, id }: BarChartWithCustomTooltipProps) {
  const chartRef = useRef<ChartJSOrUndefined<'bar', (number | [number, number] | null)[], unknown> | null>();
  const [tooltipData, setTooltipData] = useState<{
    top: number;
    left: number;
    dataPoint: any;
    totalDevices: number;
  } | null>(null);
  const navigate = useNavigate();

  const generateCustomTooltipHandler = useGenerateCustomTooltipHandler<'bar'>();
  const customTooltip = (tooltipModel: any) =>
    generateCustomTooltipHandler(tooltipModel, tooltipData, setTooltipData, chartRef);

  return (
    <BarGraph
      forwardRef={chartRef}
      data={data}
      style={{ paddingLeft: '1rem', paddingRight: '1rem', height: '100%' }}
      options={getOptions(customTooltip, options)}
      tooltipData={tooltipData}
      label={label}
      navigationClickHandler={() => navigate(`/fleet/consumables#${id}`)}
    />
  );
}
