import { MutableRefObject, useCallback, useRef } from 'react';
import { GenericExportTableProps } from './typings';
import ExportTable from './ExportTable';

const GenericExportTable = <T extends object & { id: string }>({
  data,
  title,
  columns,
  exportData,
  selection,
  ...props
}: GenericExportTableProps<T>) => {
  const flattenedColumnsTitles = columns.map((column) => {
    if (!column.title) {
      return column;
    }

    if (typeof column.title === 'object') {
      const columnTitle = column.title as unknown as { props: { children: string } };
      return { ...column, title: columnTitle.props.children };
    }

    return column;
  });

  const selectedRowsID = useRef<Array<string>>([]);

  const handleSelection = useCallback(function <T extends object & { id: string }>(data: T[]) {
    selectedRowsID.current = [...data.map((row) => row.id)];
  }, []);

  const filterRowsBySelected = useCallback(
    <T extends object & { id: string }>(data: T[], selectedRowsID: MutableRefObject<string[]>): T[] => {
      return data.filter((row) => {
        if (!selectedRowsID.current || selectedRowsID.current.length === 0) {
          return true;
        }
        return selectedRowsID.current.includes(row.id);
      });
    },
    []
  );

  return (
    <ExportTable
      title={title}
      data={data}
      columns={flattenedColumnsTitles}
      handleSelection={handleSelection}
      filterRowsBySelected={filterRowsBySelected}
      selectedRowsID={selectedRowsID}
      exportData={exportData}
      selection={selection}
      {...props}
    />
  );
};

export default GenericExportTable;
