import { ApiFailureResult, Either, _ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import {
  CommonIsEntityUniqueBase,
  eitherCommonIsEntityUnique,
  IsEntityUniqueApiResult,
} from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { CHANNELS } from '@/shared/constants';
import { Culligan, Entity } from '@culligan-iot/domain';
import { Schema as S } from '@effect/schema';

const URL_PREFIX = `${API_VERSION.v2}/system/loyalty-programs`;

const StringifiedDates = S.Struct({
  createdAt: S.String,
  updatedAt: S.String,
});

export const LoyaltyProgramWithStringifiedDates = Culligan.One.Loyalty.LoyaltyProgram.pipe(
  S.omit('createdAt', 'updatedAt'),
  S.extend(StringifiedDates)
);

export const LoyaltyProgramWithoutDates = Entity.newEntity(Culligan.One.Loyalty.LoyaltyProgram).annotations({
  identifier: 'LoyaltyProgramWithoutDates',
});

const LoyaltyIdentifier = LoyaltyProgramWithoutDates.pipe(S.pick('id')).annotations({
  identifier: 'LoyaltyIdentifier',
});

const LoyaltyProgramPatch = LoyaltyProgramWithoutDates.pipe(
  S.omit('id'),
  S.partial(),
  S.extend(LoyaltyIdentifier),
  S.filter((patch) => (patch.name ?? patch.enabled ?? patch.config) !== undefined)
).annotations({
  identifier: 'LoyaltyProgramPatch',
});

const GetLoyaltyProgramApiResult = _ApiResult(LoyaltyProgramWithStringifiedDates).annotations({
  identifier: 'GetLoyaltyProgramApiResult',
});

const _LoyaltyProgramApiResponse = () => {
  return S.Union(
    ApiFailureResult,
    S.Struct({
      success: S.Literal(true),
      data: LoyaltyProgramWithStringifiedDates,
    })
  );
};

const GetLoyaltyProgramsApiResultData = S.Struct({
  items: S.Array(LoyaltyProgramWithStringifiedDates),
}).annotations({
  identifier: 'GetLoyaltyProgramsApiResultData',
});

const GetLoyaltyProgramsApiResult = _ApiResult(GetLoyaltyProgramsApiResultData).annotations({
  identifier: 'GetLoyaltyProgramsApiResult',
});

const DeleteLoyaltyProgramsApiResult = _ApiResult(LoyaltyProgramWithStringifiedDates).annotations({
  identifier: 'DeleteLoyaltyProgramsApiResult',
});

const decodeLoyaltyProgramApiResponse = S.decodeUnknownEither(_LoyaltyProgramApiResponse(), { errors: 'all' });
const decodeLoyaltyProgramsApiResponse = S.decodeUnknownEither(GetLoyaltyProgramsApiResult, { errors: 'all' });
const decodeDeleteLoyaltyProgramApiResponse = S.decodeUnknownEither(DeleteLoyaltyProgramsApiResult, { errors: 'all' });

function eitherLoyaltyProgram(decodeType: Function, response: unknown) {
  const loyaltyProgram = decodeType(response);
  if (loyaltyProgram && loyaltyProgram._tag === 'Left') {
    return {
      _tag: loyaltyProgram._tag,
      left: loyaltyProgram.left,
    };
  }
  return {
    _tag: loyaltyProgram._tag,
    right: loyaltyProgram.right,
  };
}

export const LoyaltyProgramsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['LoyaltyPrograms'] }).injectEndpoints({
  endpoints: (builder) => ({
    getLoyaltyPrograms: builder.query<Either<S.Schema.Type<typeof GetLoyaltyProgramsApiResult>>, void>({
      //
      query: () => URL_PREFIX + '/list',
      providesTags: ['LoyaltyPrograms'],
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramsApiResponse, response);
      },
    }),
    getLoyaltyProgram: builder.query<Either<S.Schema.Type<ReturnType<typeof _LoyaltyProgramApiResponse>>>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['LoyaltyPrograms'],
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramApiResponse, response);
      },
    }),
    isLoyaltyProgramUnique: builder.query<
      Either<S.Schema.Type<typeof IsEntityUniqueApiResult>>,
      CommonIsEntityUniqueBase
    >({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
      transformResponse: (response) => {
        return eitherCommonIsEntityUnique(response);
      },
    }),
    postLoyaltyProgram: builder.mutation<
      Either<S.Schema.Type<typeof GetLoyaltyProgramApiResult>>,
      typeof LoyaltyProgramWithoutDates.Type
    >({
      query: (body) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['LoyaltyPrograms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: CHANNELS.LOYALTY_PROGRAMS,
        });
      },
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramApiResponse, response);
      },
    }),
    putLoyaltyProgram: builder.mutation<
      Either<S.Schema.Type<typeof GetLoyaltyProgramApiResult>>,
      { id: string; body: typeof LoyaltyProgramWithoutDates.Type }
    >({
      query: ({ id, body }) => ({
        url: `${URL_PREFIX}?id=${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['LoyaltyPrograms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: CHANNELS.LOYALTY_PROGRAMS,
        });
      },
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramApiResponse, response);
      },
    }),
    patchLoyaltyProgram: builder.mutation<
      Either<S.Schema.Type<typeof GetLoyaltyProgramApiResult>>,
      { id: string; body: typeof LoyaltyProgramPatch.Type; name: string }
    >({
      query: ({ id, body }) => ({
        url: `${URL_PREFIX}/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['LoyaltyPrograms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: CHANNELS.LOYALTY_PROGRAMS,
        });
      },
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramApiResponse, response);
      },
    }),
    deleteLoyaltyProgram: builder.mutation<
      Either<S.Schema.Type<typeof DeleteLoyaltyProgramsApiResult>>,
      { id: string; name: string }
    >({
      query: ({ id }: { id: string; name: string }) => ({
        url: URL_PREFIX,
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: ['LoyaltyPrograms'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: CHANNELS.LOYALTY_PROGRAMS,
        });
      },
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeDeleteLoyaltyProgramApiResponse, response);
      },
    }),
  }),
});

export const {
  useLazyGetLoyaltyProgramsQuery,
  useGetLoyaltyProgramsQuery,
  useLazyGetLoyaltyProgramQuery,
  useLazyIsLoyaltyProgramUniqueQuery,
  usePostLoyaltyProgramMutation,
  usePutLoyaltyProgramMutation,
  usePatchLoyaltyProgramMutation,
  useDeleteLoyaltyProgramMutation,
} = LoyaltyProgramsApiSlice;
