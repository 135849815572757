import { ExportCsv } from '@material-table/exporters';
import { Box } from '@mui/material';
import CulliganTable from './CulliganTable';
import { useTranslation } from 'react-i18next';
import { ExportTableProps } from './typings';
import { useLocation } from 'react-router';
import WithNonHideableColumns from './withNonHideableColumns';

function ExportTable<T extends object>({
  title,
  data,
  columns,
  handleSelection,
  filterRowsBySelected,
  selectedRowsID,
  exportData = true,
  selection = true,
  search = true,
  sx,
  toolbar = true,
  nonHideableColumns,
  ...props
}: ExportTableProps<T>): JSX.Element {
  const { t } = useTranslation();
  const { state } = useLocation();
  const _columns = WithNonHideableColumns(columns, nonHideableColumns);
  const defaultStyles = {
    '.MuiToolbar-root.MuiToolbar-regular': {
      backgroundColor: '#fdfdfd',
      color: '#275da3',
    },
    '.MuiToolbar-root.MuiTablePagination-toolbar': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
    '.MuiTableRow-root': {
      transition: 'all 0.15s ease-out',
    },
    '.MuiTableRow-root:hover': {
      backgroundColor: '#dedede3b',
    },
  };

  return (
    <>
      {/* Remove whitespace from the title (e.g. "CO2 Tanks") */}
      <Box id={`${title.replace(/\s/g, '')}Table`} sx={{ ...defaultStyles, ...sx }}>
        <CulliganTable<T>
          {...props}
          options={{
            columnsButton: true,
            selection: selection !== false,
            emptyRowsWhenPaging: false,
            exportMenu:
              exportData !== false
                ? [
                    {
                      label: t('exportWithFormat', { format: 'CSV' }),
                      exportFunc: (cols) => {
                        ExportCsv(cols, filterRowsBySelected(data, selectedRowsID), title);
                      },
                    },
                  ]
                : [],
            showEmptyDataSourceMessage: true,
            showTitle: false,
            rowStyle: (data: any) =>
              state?.highlight && data[state.highlight.refField] === state.highlight.matchValue
                ? {
                    animationDuration: '1s',
                    animationIterationCount: 1,
                    animationName: 'table-row-background-fade-out',
                    animationTimingFunction: 'ease-out',
                    animationFillMode: 'forwards',
                  }
                : {},
            searchText: state?.searchText,
            search,
            toolbar,
            ...props.options,
          }}
          columns={_columns}
          data={data}
          title={title}
          onSelectionChange={(data) => handleSelection(data)}
        />
      </Box>
    </>
  );
}

export default ExportTable;
