import { Navigate, Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../redux/slices/authSlice';
import AppLayout from '@/components/Core/AppLayout';
import ThemeColors from '@/components/Core/ThemeColors';
import ResetThemeToolbar from '@/components/Core/ThemeColors/ResetThemeToolbar';
import LocalSnackBar from '@/components/Shared/CrudSnackbar/LocalSnackbar';
import PollingsPool from '@/components/Core/Polling';
import { setRequestedLocation } from '@/redux/utils';

const AppOutlet = () => {
  const accessToken = useSelector(selectAccessToken);

  if (!accessToken) {
    setRequestedLocation(window.location);
    return <Navigate to="/login" replace />;
  }

  return (
    <ThemeColors>
      <PollingsPool />
      <LocalSnackBar />
      <AppLayout>
        <Outlet />
      </AppLayout>
      <ResetThemeToolbar />
    </ThemeColors>
  );
};

export default AppOutlet;
