import { Box, BoxProps, CSSObject, Theme, styled } from '@mui/material';

const slideInMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['top', 'opacity'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  top: '0px',
  opacity: 1,
});

const slideOutMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['top', 'opacity'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  top: '100px',
  opacity: 0,
});

interface SlideDivProps extends BoxProps {
  slide?: boolean;
}

export const SlideDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'slide',
})<SlideDivProps>(({ theme, slide }) => ({
  ...(slide && {
    '&': slideInMixin(theme),
  }),
  ...(!slide && {
    '&': slideOutMixin(theme),
  }),
}));
