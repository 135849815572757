import { useSelector } from 'react-redux';
import { operationsSelector } from '@/redux/slices/operationSlice';
import { Stack } from '@mui/material';
import SnackbarWrapper from '.';
import zIndex from '@mui/material/styles/zIndex';

export default function SnackbarsManager() {
  const operations = useSelector(operationsSelector);

  return operations && operations.length > 0 ? (
    <Stack
      sx={{
        position: 'absolute',
        width: '400px',
        zIndex: zIndex.tooltip + 500,
        pointerEvents: 'none',
        gap: 1,
        right: 0,
      }}
    >
      {operations
        .filter((operation) => !operation.showed)
        .map((operation) => (
          <SnackbarWrapper operation={operation} key={operation.uniqueId}></SnackbarWrapper>
        ))}
    </Stack>
  ) : null;
}
