import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Device } from './typings';
import DeviceAlarmCounter from '../DevicePanel/DeviceAlarmCounter';
import BlipChip from '@/components/Shared/Chips/BlipChip';
import TagIcon from '@mui/icons-material/Tag';
import { CHANNELS } from '@/shared/constants';

export const useDevicesTableColumns = (): Array<Column<Device>> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMemo(
    (): Array<Column<Device>> => [
      {
        title: t('modelNameId'),
        width: 'max-content',
        cellStyle: { textAlign: 'left' },
        customFilterAndSearch: (filter, rowData) => {
          const normalizeString = (str: string) => str.replace(/\s+/g, '').toLowerCase();
          const filterLowerCase = filter.toLowerCase();
          const matchesName =
            typeof rowData.name === 'string' ? rowData.name.toLowerCase().includes(filterLowerCase) : false;
          const matchesModel =
            typeof rowData.model === 'string' ? rowData.model.toLowerCase().includes(filterLowerCase) : false;
          const matchesSerialNumber =
            typeof rowData.serialNumber === 'string'
              ? rowData.serialNumber.toLowerCase().includes(filterLowerCase)
              : false;
          const matchesDevicesQnumber =
            typeof rowData.metaData?.deviceQnumber === 'string'
              ? normalizeString(rowData.metaData.deviceQnumber).includes(filterLowerCase)
              : false;
          const matchesManufacturingSerialNumer =
            typeof rowData.metaData?.manufacturingSerialNumber === 'string' &&
            rowData.metaData.manufacturingSerialNumber.includes(filter);

          return (
            matchesName ||
            matchesModel ||
            matchesSerialNumber ||
            matchesDevicesQnumber ||
            matchesManufacturingSerialNumer
          );
        },
        exportTransformer: (device) => `
          ${device.name}\n
          ${device.serialNumber}
          ${device.metaData?.deviceQnumber ? `\n${device.metaData?.deviceQnumber}` : ''}
          ${device.metaData?.manufacturingSerialNumber ? `\n${device.metaData?.manufacturingSerialNumber}` : ''}`,
        field: 'serialNumber',
        render: (device) => {
          return (
            <Box>
              <Typography variant="subtitle1">{device.name}</Typography>
              <Box display="flex" gap={1} alignItems="center" sx={{ flexWrap: 'wrap' }}>
                <Chip icon={<TagIcon />} label={device.model} size="small" />
                <Chip icon={<TagIcon />} label={device.serialNumber} size="small" />
                {device.brandName === 'quench' && (
                  <Chip
                    icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                    label={device.metaData?.deviceQnumber || t('qUnassigned')}
                    size="small"
                  />
                )}
                <Chip
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                  label={device.metaData?.manufacturingSerialNumber || t('manufacturingSerialNumberUnassigned')}
                  size="small"
                />
              </Box>
            </Box>
          );
        },
      },
      {
        title: t('connectionStatus'),
        field: 'connectionStatus',
        customSort: (a, b) => {
          const aOnline = a.status && a.status.connection?.online === true ? 1 : 0;
          const bOnline = b.status && b.status.connection?.online === true ? 1 : 0;
          return aOnline - bOnline;
        },
        customFilterAndSearch: (filter, rowData) => {
          const filterLowerCase = filter.toLowerCase();
          const isOnline =
            rowData.status && Object.keys(rowData.status).length > 0 && rowData.status.connection?.online === true;
          const connectedTranslation = t('connected').toLowerCase();
          const disconnectedTranslation = t('disconnected').toLowerCase();

          if (isOnline && connectedTranslation.startsWith(filterLowerCase)) {
            return true;
          } else if (!isOnline && disconnectedTranslation.startsWith(filterLowerCase)) {
            return true;
          }
          return false;
        },
        exportTransformer: (rowData) => {
          return rowData.status.connection.online ? t('connected') : t('disconnected');
        },
        render: (data) => (
          <Box>
            {!data.status || Object.keys(data.status).length === 0 ? (
              t('notDefined')
            ) : data.status.connection?.online === true ? (
              <Chip
                avatar={
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <Box
                      sx={(theme) => ({
                        height: '80%',
                        bgcolor: theme.palette.success.light,
                        width: '80%',
                        borderRadius: '50%',
                      })}
                    />
                  </Avatar>
                }
                label={t('connected')}
                size="small"
                color="success"
                variant="outlined"
              />
            ) : (
              <BlipChip label={t('disconnected')} size="small" color="error" variant="outlined" />
            )}
          </Box>
        ),
        export: true,
      },
      /*
      Until further notice, the location must be filtered from the table.
      {
        title: t('location'),
        field: 'location',
        customSort: (a, b) => {
          const aValue = `${a.region?.code || ''}-${a.region?.name || ''}`.toLowerCase();
          const bValue = `${b.region?.code || ''}-${b.region?.name || ''}`.toLowerCase();
          if (aValue > bValue) {
            return 1;
          } else if (aValue < bValue) {
            return -1;
          }
          return 0;
        },
        customFilterAndSearch: (filter, rowData) => {
          const filterLowerCase = filter.toLowerCase();
          const locationString = `${rowData.region?.code ? rowData.region.code.toLowerCase() : ''} - ${
            rowData.region?.name ? rowData.region.name.toLowerCase() : ''
          }`;
          return locationString.includes(filterLowerCase);
        },
        exportTransformer: (device) => `${device.region?.code} - ${device.region?.name}`,
        render: (device) => (
          <Typography variant="subtitle1">
            {device.region?.code && device.region?.name ? `${device.region.code} - ${device.region.name}` : 'N/A'}
          </Typography>
        ),
      },
      */
      { title: t('version'), field: 'swVersion', width: '5%' },
      {
        title: t('errors'),
        field: 'errors',
        exportTransformer: (device) => device.status.errors,
        render: (device) => <DeviceAlarmCounter serialNumber={device.serialNumber} data={device.status.errors} />,
      },
      {
        title: t(CHANNELS.ALARMS),
        field: 'status.alarms',
        width: 'max-content',
        exportTransformer: (device) =>
          `NOT-CRITICAL: ${device.status.alarms.nonCritical}\nCRITICAL: ${device.status.alarms.critical}`,
        render: (device) => <DeviceAlarmCounter serialNumber={device.serialNumber} data={device.status.alarms} />,
      },
      {
        title: t('actions'),
        field: 'actions',
        width: '5%',
        sorting: false,
        searchable: false,
        export: false,
        render: (device) => (
          <Button
            key={`buttonDevicesTable${device.serialNumber}`}
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/fleet/devices/${device.serialNumber}`)}
          >
            {t('view')}
          </Button>
        ),
      },
    ],
    [navigate, t]
  );
};
