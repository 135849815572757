import React from 'react';
import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle } from '@/components/Shared/Card/Area/Text';
import { copyToClipboard, dispatchToast } from '@/shared/utils';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Device } from '../../../DevicesPanel/typings';

function Address({ stringAddress, device }: { stringAddress: string; device: Device }) {
  const { t } = useTranslation();
  return (
    <Area
      {...(stringAddress
        ? {
            onClick: (e) =>
              copyToClipboard({
                text: stringAddress,
                onSucces: () => {
                  dispatchToast({
                    message: t('copyToClipboardSuccess'),
                    severity: 'success',
                    position: { x: e.clientX, y: e.clientY },
                  });
                },
                onFail: () => {
                  dispatchToast({
                    message: t('copyToClipboardError'),
                    severity: 'error',
                    position: { x: e.clientX, y: e.clientY },
                  });
                },
              }),
          }
        : {})}
    >
      <AreaHeader>
        <Subtitle>{t('location')}</Subtitle>
        <IconButton size="small">
          <ContentCopyIcon />
        </IconButton>
      </AreaHeader>
      <AreaBody>
        {stringAddress ? (
          <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: AREA_DESIGN_TOKENS.gap }}>
            {Object.entries(device?.installationAddress || {}).map(([key, value]) => (
              <Box key={key}>
                <Typography variant="body2" color="text.secondary">
                  {t(key)}
                </Typography>
                <Typography variant="body1" fontWeight={400}>
                  {value || t('notAvailable')}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography variant="body1" fontWeight={400}>
            {t('addressNotAvailable')}
          </Typography>
        )}
      </AreaBody>
    </Area>
  );
}
export default React.memo(Address);
