import { STATUS, Status } from '@/redux/slices/operationSlice';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';

export const UNKNOWN_PATH = '';
export function getDefaultStatusIcon(status: Status) {
  switch (status) {
    case STATUS.ERROR:
      return PriorityHighIcon;

    case STATUS.INFO:
      return HourglassFullIcon;

    case STATUS.SUCCESS:
      return ThumbUpAltRoundedIcon;

    case STATUS.WARNING:
      return WarningIcon;

    default:
      return CircleRoundedIcon;
  }
}

export const withPathCheck = (path: string, fn: () => void) => {
  if (path === UNKNOWN_PATH) {
    throw new Error('Unknown path. Please make sure that your path is belonging to the ECOSYSTEM or CHANNEL');
  }
  fn();
};
