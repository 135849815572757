import OverviewCard from './DeviceCardInfo';
import { Box, Button, Typography, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomerServiceTypography = styled(Typography)(() => ({
  lineHeight: '1.2rem',
}));

const HeadingCustomerServiceTypography = styled(CustomerServiceTypography)(() => ({
  fontWeight: 600,
  textAlign: 'right',
}));

const ValueCustomerServiceTypography = styled(CustomerServiceTypography)(() => ({
  marginLeft: '2rem',
}));

const CustomerServiceCta = styled(Button)(() => ({
  alignSelf: 'center',
}));

function CustomerServiceCard() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <OverviewCard
      id="deviceCustomerInfoCard"
      title={t('customerService')}
      cardContentProps={{
        sx: {
          ':last-child': {
            p: theme.spacing(2),
          },
        },
      }}
    >
      <Box display="grid" gridTemplateColumns="1fr 2fr 1fr" gap="1rem" my="0.5rem" mx="4rem" alignItems="center">
        {/** Contact */}
        <HeadingCustomerServiceTypography variant="body1">{t('doYouNeedHelp')}</HeadingCustomerServiceTypography>
        <ValueCustomerServiceTypography variant="body1">{t('contactCustomerService')}</ValueCustomerServiceTypography>
        <CustomerServiceCta variant="outlined">{t('contact')}</CustomerServiceCta>

        {/** FAQ */}
        <HeadingCustomerServiceTypography variant="body1">{t('doubtMoreInfo')}</HeadingCustomerServiceTypography>
        <ValueCustomerServiceTypography variant="body1">{t('readFaq')}</ValueCustomerServiceTypography>
        <CustomerServiceCta variant="outlined">{t('read')}</CustomerServiceCta>
      </Box>
    </OverviewCard>
  );
}

export default CustomerServiceCard;
