import { useTranslation } from 'react-i18next';
import BaseOperationTypography, { BoldOperationTypography } from '../../../Operation/BaseOperationTypography';
import { Operation } from '@/redux/slices/operationSlice';

export type OneToolMessageProps = {
  opening: string;
  subject: string;
  entity: string;
  read: Operation['read'];
  status: Operation['status'];
};

export default function OneToolMessage({ opening, subject, entity, status, read }: OneToolMessageProps) {
  const { t } = useTranslation();
  return (
    <BaseOperationTypography status={status} read={read}>
      <BaseOperationTypography status={status} read={read}>
        {opening}{' '}
      </BaseOperationTypography>
      <BoldOperationTypography status={status} read={read}>
        {subject}{' '}
      </BoldOperationTypography>
      <BaseOperationTypography status={status} read={read}>
        {t('in')}{' '}
      </BaseOperationTypography>
      <BoldOperationTypography status={status} read={read}>
        {entity}
      </BoldOperationTypography>
    </BaseOperationTypography>
  );
}
