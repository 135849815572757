import { Box, BoxProps, CSSObject, Theme, styled } from '@mui/material';
import { ReactNode } from 'react';
import { Amount, Delay, Duration, Trigger } from './typings';

const opacityInMixin = (config: Partial<Amount & Duration & Delay> & { theme: Theme }): CSSObject => ({
  transition: config.theme.transitions.create(['opacity'], {
    easing: config.theme.transitions.easing.easeInOut,
    duration: config.theme.transitions.duration.enteringScreen,
    delay: config.delay,
  }),
  opacity: 1,
});

const opacityOutMixin = (config: Partial<Amount & Duration & Delay> & { theme: Theme }): CSSObject => ({
  transition: config.theme.transitions.create(['opacity', 'transform'], {
    easing: config.theme.transitions.easing.easeInOut,
    duration: config.theme.transitions.duration.enteringScreen,
    delay: config.delay,
  }),
  opacity: 0,
});

interface OpacityDivProps extends BoxProps, Partial<Duration & Delay & Amount>, Trigger {}

export const OpacityDivStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'amount' && prop !== 'trigger' && prop !== 'delay' && prop !== 'direction',
})<OpacityDivProps>(({ trigger, ...rest }) => ({
  opacity: 0,
  ...(trigger && {
    '&': opacityInMixin(rest),
  }),
  ...(!trigger && {
    '&': opacityOutMixin(rest),
  }),
}));

export default function OpacityDiv({
  children,
  ...rest
}: { children: ReactNode } & Trigger & Partial<Amount & Duration & Delay>) {
  return (
    <OpacityDivStyled {...rest}>
      <Box sx={{ width: 'fit-content', height: 'fit-content' }}>{children}</Box>
    </OpacityDivStyled>
  );
}
