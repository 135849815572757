import { CSSObject, IconButton, IconButtonProps, Theme, styled } from '@mui/material';

const rotateIconMixin180 = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  transform: 'rotate(180deg)',
});

const rotateIconMixin0 = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  transform: 'rotate(0deg)',
});

interface ExpandIconButton extends IconButtonProps {
  rotate?: boolean;
}

const ExpandIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'rotate',
})<ExpandIconButton>(({ theme, rotate }) => ({
  ...(rotate && {
    '& .MuiSvgIcon-root': rotateIconMixin180(theme),
  }),
  ...(!rotate && { '& .MuiSvgIcon-root': rotateIconMixin0(theme) }),
}));

export default ExpandIcon;
