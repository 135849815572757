import { BoxProps } from '@mui/material';
import { TabsProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabsConfig } from './typings';
import { useLocation, useNavigate } from 'react-router';
import BaseTabsLayout from './BaseTabsLayout';

function NavigationTabsLayout({
  config,
  orientation = 'horizontal',
  navigateOnChange = true,
  ...props
}: TabsProps & {
  config: TabsConfig[];
  navigateOnChange?: boolean;
} & BoxProps) {
  const [defaultIndex, setDefaultIndex] = useState<number | undefined>(undefined);
  const { state, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash) {
      const index = config.findIndex(
        (tab) => tab.label.replace(/\s/g, '_').toLowerCase() === hash.match(/#([^?]+)/)?.[1]
      );

      if (index === -1) {
        return;
      }
      setDefaultIndex(index);
    } else if (!hash) {
      setDefaultIndex(0);
    }
  }, [hash, config, state]);

  const handleTabChange = (nextIndex: any) => {
    navigateOnChange &&
      navigate(window.location.pathname + '#' + config[nextIndex].label.replace(/\s/g, '_').toLowerCase());
    // eslint-disable-next-line no-restricted-globals
    !navigateOnChange && history.pushState(null, '', `#${config[nextIndex].label.replace(/\s/g, '_').toLowerCase()}`);
  };

  return <BaseTabsLayout config={config} defaultIndex={defaultIndex} handleTabChange={handleTabChange} {...props} />;
}

export default NavigationTabsLayout;
