import { ApiResult } from '@typings';
import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';
import apiSlice from '../apiSlice';
import { roleAwareQuery } from '../roleAwareQuery';

const devicesWithConsumablesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetDevices', 'Fleet'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevicesWithConsumables: builder.query<ApiResult<{ items: Device[] }>, void>({
        queryFn: roleAwareQuery('getDevicesWithConsumables'),
        providesTags: ['FleetDevices', 'Fleet'],
      }),
    }),
  });

export const { useGetDevicesWithConsumablesQuery } = devicesWithConsumablesApiSlice;
