import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ConfirmDialogProps } from './typings';
import { useTranslation } from 'react-i18next';

function ConfirmDialog({
  title,
  MessageComponent,
  isOpen,
  onConfirm,
  onClose,
  extra,
  severity = 'error',
}: ConfirmDialogProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{MessageComponent ? MessageComponent : <Typography>{t('confirm')}?</Typography>}</DialogContent>
      <DialogActions sx={{ mx: 2, mb: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color={severity}
          onClick={() => {
            onConfirm(extra);
            onClose && onClose();
          }}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
