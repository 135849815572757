import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';

type FilterOption = {
  id: string;
  name: string;
};

type CustomerFilterOption = {
  id: string;
  firstName: string;
  lastName: string;
};

type BusinessAnalysisFiltersRes = {
  brand: FilterOption[];
  type: FilterOption[];
  customer: CustomerFilterOption[];
};

const businessAnalysisFiltersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessAnalysisFilters: builder.query<ApiResult<{ items: BusinessAnalysisFiltersRes }>, { customer?: string }>({
      query: (params) => {
        const searchParams = params.customer ? new URLSearchParams(params) : '';
        return `${API_VERSION.v2}/business-analysis/filters?` + searchParams;
      },
    }),
  }),
});

export const { useLazyGetBusinessAnalysisFiltersQuery } = businessAnalysisFiltersApiSlice;
