import { NotificationVirtualizationProps } from './types';
import { useNotificationHeight } from './hooks/useNotificationHeight';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const END_REACHED_UNIQUE_ID = 'end-reached';

export default function EndReached({ setNotificationHeight, style }: NotificationVirtualizationProps) {
  const notificationRef = useNotificationHeight({
    id: END_REACHED_UNIQUE_ID,
    setNotificationHeight: setNotificationHeight,
  });
  const { t } = useTranslation();

  return (
    <Typography
      ref={notificationRef}
      sx={{ textAlign: 'center', display: 'block', color: (theme) => theme.palette.grey[400], pt: 1 }}
      style={style}
      variant="caption"
    >
      {t('youReachedTheEnd')}
    </Typography>
  );
}
