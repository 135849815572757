import { Card, Slide, SxProps } from '@mui/material';
import React, { useLayoutEffect, useMemo, useState } from 'react';

const eventCommand = (event: keyof WindowEventMap, listener: EventListenerOrEventListenerObject) => ({
  execute: (): Function | undefined => {
    if (!window) {
      return undefined;
    }
    window.addEventListener(event, listener);
    return () => {
      window.removeEventListener(event, listener);
    };
  },
});

export default function FixedToolbar({
  slideInPredicate,
  event,
  body,
  sx,
}: {
  slideInPredicate: Function;
  event: keyof WindowEventMap | Function;
  body: React.ReactNode;
  sx?: SxProps;
}) {
  const [slide, setSlide] = useState<boolean>(false);

  const registerEvent = useMemo(() => {
    if (typeof event === 'string') {
      return eventCommand(event, () => {
        if (slide === slideInPredicate()) {
          return;
        }

        setSlide(slideInPredicate());
      });
    }
  }, [event, slideInPredicate, slide]);

  useLayoutEffect(() => {
    let unregister: Function | undefined = registerEvent?.execute();

    return () => {
      if (typeof unregister === 'function') {
        unregister();
      }
    };
  }, [registerEvent]);

  return (
    <Slide direction="down" in={slide} mountOnEnter unmountOnExit>
      <Card
        id="fixed-toolbar"
        sx={{
          position: 'fixed',
          width: 'calc(100vw - 128px)',
          zIndex: 3,
          padding: 1,
          top: '100px',
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(255,255,255,0.8)',
          ...sx,
        }}
        elevation={4}
      >
        {body}
      </Card>
    </Slide>
  );
}

export function scrolledAmountIsGreaterThan(amount: number) {
  return () => {
    if (!document) {
      return false;
    }

    return (document.documentElement || document.body.parentNode || document.body).scrollTop > amount;
  };
}
