import { Paper, Typography } from '@mui/material';
import { DateRange } from './typings';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import AreaContainer, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader } from '../Card/Area';
import { Fragment } from 'react';

const PrintFilter = ({ filterKey, filterValue }: { filterKey: string; filterValue: string }) => {
  const { t } = useTranslation();
  return (
    <AreaContainer
      sx={{
        height: 'auto',
        '@media print': {
          printColorAdjust: 'exact',
          WebkitPrintColorAdjust: 'exact',
        },
      }}
    >
      <AreaHeader sx={{ color: 'text.secondary' }}>{t(filterKey) as String}</AreaHeader>
      <AreaBody>{filterValue}</AreaBody>
    </AreaContainer>
  );
};

const PrintFilterDateRange = ({ filterKey, dateRange }: { filterKey: string; dateRange: DateRange<Dayjs> }) => {
  const { t } = useTranslation();
  return (
    <AreaContainer
      sx={{
        height: 'auto',
        '@media print': {
          printColorAdjust: 'exact',
          WebkitPrintColorAdjust: 'exact',
        },
      }}
    >
      <AreaHeader sx={{ color: 'text.secondary' }}>{t(filterKey) as String}</AreaHeader>
      <AreaBody>{`${dayjs(dateRange.start).format('L')} - ${dayjs(dateRange.end).format('L')}`}</AreaBody>
    </AreaContainer>
  );
};

/**
 * `PrintFilters` is a component that displays the applied filters for the print media query.
 *
 * @component
 * @param {Object} props - The properties that define the `PrintFilters` component.
 * @param {Record<string, DateRange<Dayjs> | string>} props.filtersState - The current state of the filters.
 * @param {Record<keyof typeof filtersState, (data: any) => string>} [props.transformers] - Optional
 * transformation functions for the filter values.
 *
 * @example
 * ```tsx
 * <PrintFilters
 *   filtersState={businessAnalysisState}
 *   transformers={{
 *     brand: (value) => data?.data.items.brand.find((item) => item.id === value)?.name || value,
 *     customer: (value) => data?.data.items.customer.find((item) => item.id === value)?.firstName || value,
 *   }}
 * />
 * ```
 *
 * @returns {ReactElement} The `PrintFilters` component.
 */
export default function PrintFilters({
  filtersState,
  transformers,
  ...props
}: {
  filtersState: Record<string, DateRange<Dayjs> | string>;
  transformers?: Record<keyof typeof filtersState, (data: any) => string>;
}) {
  return (
    <Fragment>
      <Typography sx={{ display: 'none', '@media print': { display: 'block', paddingBottom: 2 } }}>Filters</Typography>
      <Paper
        {...props}
        sx={{
          display: 'none',
          '@media print': {
            display: 'flex',
            gap: AREA_DESIGN_TOKENS.gap,
            padding: 2,
            justifyContent: 'space-between',
            marginBottom: 4,
            overflowWrap: 'anywhere',
          },
        }}
      >
        {Object.keys(filtersState).map((filterKey) => {
          if (!filtersState[filterKey]) {
            return null;
          }
          const hasTransformFn = transformers && transformers[filterKey];
          const filterValue = hasTransformFn
            ? transformers[filterKey](filtersState[filterKey])
            : filtersState[filterKey];

          switch (filterKey) {
            case 'dateRange':
              return <PrintFilterDateRange filterKey={filterKey} dateRange={filterValue as DateRange<Dayjs>} />;
            default:
              return <PrintFilter filterKey={filterKey} filterValue={filterValue as string} />;
          }
        })}
      </Paper>
    </Fragment>
  );
}
