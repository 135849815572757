import { STATUS } from '@/redux/slices/operationSlice';
import BaseOperation from '../Operation/BaseOperation';
import StatusOperationMessage from '../Operation/StatusOperationMessage';
import dayjs from 'dayjs';
import BaseOperationBody from '../Operation/BaseOperationBody';
import { BaseComponentProps, StatusNotificationProps } from './types';
import { forwardRef } from 'react';

const BaseNotification = forwardRef<any, BaseComponentProps>(
  ({ onClick, style, status, message, avatar, read, ...rest }, ref) => {
    return (
      <BaseOperation ref={ref} onClick={onClick} style={style || {}} status={status} read={read} {...rest}>
        <BaseOperationBody avatar={avatar} message={message} status={status} read={read} {...rest} />
      </BaseOperation>
    );
  }
);

export const StatusNotification = forwardRef(
  (
    {
      baseComponent,
      hoverIconButtons,
      style,
      timestamp,
      showed,
      location,
      uniqueId,
      operationId,
      state,
      tag,
      status,
      avatar,
      states,
      entity,
      subject,
      read,
    }: StatusNotificationProps,
    ref
  ) => {
    return (
      <baseComponent.Component
        ref={ref}
        status={status}
        read={read}
        style={style}
        extra={baseComponent.extra}
        avatar={avatar === undefined ? states[status]?.avatar : avatar}
        message={
          <StatusOperationMessage
            read={read}
            date={dayjs(timestamp)}
            messages={{
              error: states[STATUS.ERROR]?.message,
              info: states[STATUS.INFO]?.message,
              success: states[STATUS.SUCCESS]?.message,
              warning: states[STATUS.WARNING]?.message,
            }}
            status={status}
            {...(states[STATUS.ERROR] !== undefined
              ? {
                  action: {
                    callback: () =>
                      states[STATUS.ERROR]?.onClick({
                        timestamp,
                        state,
                        showed,
                        location,
                        tag,
                        status,
                        uniqueId,
                        operationId,
                        read,
                        entity,
                        subject,
                      }),
                    label: states[STATUS.ERROR]?.label || ' ',
                  },
                }
              : {})}
          />
        }
        hoverIconButtons={hoverIconButtons === undefined ? states[status]?.hoverIconButtons : hoverIconButtons}
        onClick={() =>
          states[status]?.onClick({
            timestamp,
            state,
            showed,
            location,
            tag,
            status,
            uniqueId,
            operationId,
            read,
            entity,
            subject,
          })
        }
      />
    );
  }
);
export default BaseNotification;
