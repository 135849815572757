import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AlarmsAndErrorsPage from '@/router/pages/Fleet/AlarmsAndErrors';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function AlarmsAndErrorCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems={'center'}>
        <Box>
          <Typography variant="h5">{t('alarmsAndErrors')}</Typography>
          <Box display="flex" gap={0.5} alignItems="center" color={theme.palette.background.grayShades[2]}>
            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
            <Typography color={theme.palette.text.secondary} variant="caption" sx={{ mt: '2px' }}>
              {t('dataBaseOnLastMonst') + ' '}
              <Typography variant="caption">
                ({dayjs().subtract(1, 'month').format('L')} - {dayjs().format('L')})
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Button variant="text" onClick={() => navigate('/fleet/alarms-errors')}>
          {t('seeAll')}
        </Button>
      </Box>
      <AlarmsAndErrorsPage
        navigationTabs={false}
        filters={false}
        pageSize={5}
        selection={false}
        search={false}
        exportData={false}
        hideTableHeader={true}
      />
    </Box>
  );
}
