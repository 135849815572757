import { Polling } from '@/redux/slices/pollingSlice';
import { BaseQueryApi, FetchArgs, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

type Query = UseLazyQuery<
  QueryDefinition<string, (arg: string | FetchArgs, api: BaseQueryApi, extra: any) => Promise<any>, string, any>
>;

export default function usePolling<TQuery extends Query>({
  useQuery,
  maxAttempts = 100,
  interval = 3000,
  pollingState,
  queryArgs,
}: {
  useQuery: TQuery;
  maxAttempts?: number;
  interval?: number;
  pollingState?: Polling;
  queryArgs?: any;
}) {
  const [activeInterval, setActiveInterval] = useState(0);
  const [remainingAttempts, setRemainingAttempts] = useState(pollingState?.remainingAttempts ?? maxAttempts);
  const [triggerQuery, result] = useQuery({ pollingInterval: activeInterval });

  const trigger = useCallback(
    (...args: any) => {
      if (remainingAttempts < 1) {
        return;
      }

      triggerQuery(queryArgs ? queryArgs : args);
      setActiveInterval(interval);
    },
    [interval, queryArgs, remainingAttempts, triggerQuery]
  );

  const stop = useCallback(() => setActiveInterval(0), []);

  useLayoutEffect(() => {
    result.isUninitialized && !pollingState?.result && trigger(queryArgs ? queryArgs : pollingState?.lastArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollingState?.lastArgs, pollingState?.pollingRequestedAt]);

  useLayoutEffect(() => {
    if (result.isError && activeInterval !== 0) {
      setActiveInterval(0);
    }
  }, [activeInterval, result.isError]);

  const polling = useMemo(
    () => ({
      trigger,
      result,
      isPolling: !result.isError && remainingAttempts > 0 && activeInterval !== 0,
      stop,
      remainingAttempts,
    }),
    [activeInterval, remainingAttempts, result, stop, trigger]
  );

  useLayoutEffect(() => {
    if (remainingAttempts < 1) {
      setRemainingAttempts(0);
      setActiveInterval(0);
      return;
    }

    if (result.isFetching) {
      setRemainingAttempts((prev) => prev - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isFetching]);

  return polling;
}
