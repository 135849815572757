import DownloadButton from '@/components/Shared/DownloadButton';
import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDownloadOTABuildFile from './useDownloadOTABuildFile';

export default function DownloadBuildFileButton({ id }: { id: string }) {
  const { t } = useTranslation();
  const { downloadOTABuildFile, flags } = useDownloadOTABuildFile();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(flags.isError);
  }, [flags.isError]);

  return (
    <>
      <Snackbar
        open={isOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2500}
        onClose={() => setIsOpen(false)}
      >
        <Alert
          severity={'error'}
          sx={{ width: '100%', border: '1px solid darkgray', boxShadow: '5px 5px 10px rgba(0,0,0,0.5)' }}
        >
          {t('snackbarError')}
        </Alert>
      </Snackbar>
      <DownloadButton onClick={downloadOTABuildFile} id={id} flags={flags} />
    </>
  );
}
