import React from 'react';
import { BaseOperationConditionalIcon, BaseOperationIconButtonWithTooltip } from '../Operation/BaseOperationIconButton';
import { Operation, toggleRead } from '@/redux/slices/operationSlice';

import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function DefaultNotificationMarkReadUnreadIcon({ read }: { read: Operation['read'] }) {
  return <BaseOperationConditionalIcon WhenFalse={MarkChatReadIcon} WhenTrue={MarkChatUnreadIcon} condition={read} />;
}

export default function DefaultNotificationRightAction({
  uniqueId,
  read,
  status,
}: {
  uniqueId: Operation['uniqueId'];
  read: Operation['read'];
  status: Operation['status'];
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <BaseOperationIconButtonWithTooltip
      read={read}
      status={status}
      icon={<DefaultNotificationMarkReadUnreadIcon read={read} />}
      onClick={() => dispatch(toggleRead(uniqueId))}
      tooltip={{ title: read ? t('markAsUnread') : t('markAsRead') }}
    />
  );
}
