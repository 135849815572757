import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { useTranslation } from 'react-i18next';
import useOtaJobsColumns from './useOtaJobsColumns';
import { MaterialTableProps } from '@material-table/core';
import { OtaJobsRes } from './typings';

export const SafeTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<OtaJobsRes>));

export default function HistoryOTAJobsTable({
  items,
  ...props
}: { items: OtaJobsRes[] } & Omit<MaterialTableProps<any>, 'columns' | 'data'>) {
  const { t } = useTranslation();

  const columns = useOtaJobsColumns();

  return (
    <>
      <GenericExportTable<OtaJobsRes>
        {...props}
        title={t('error')}
        data={items}
        columns={columns}
        exportData={true}
        selection={true}
      />
    </>
  );
}
