import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FleetError } from './typings';
import { useTranslation } from 'react-i18next';
import useErrorsColumns from './useErrorsColumn';
import { useState } from 'react';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { MaterialTableProps } from '@material-table/core';

export const SafeTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<FleetError>));

export default function ErrorsTable({
  items,
  isFleet,
  excludeColumns,
  ...props
}: { items: FleetError[]; isFleet: boolean; excludeColumns?: string[] } & Omit<
  MaterialTableProps<any>,
  'columns' | 'data'
>) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedError, setSelectedError] = useState<FleetError | null>(null);

  const handleOpenDialog = (id: string) => {
    const alarm = items.find((alarm) => alarm.id === id) as FleetError;
    setSelectedError(alarm);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedError(null);
    setOpenDialog(false);
  };

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: handleCloseDialog,
    tabs: [
      {
        id: 'error',
        label: t('error'),
        body: selectedError && <IssueDetails data={selectedError} isFleet={isFleet} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  const columns = useErrorsColumns({
    onDetailClick: handleOpenDialog,
    excludeColumns: excludeColumns,
  });

  return (
    <>
      <GenericExportTable<FleetError>
        {...props}
        title={t('error')}
        data={items}
        columns={columns}
        exportData={true}
        selection={true}
      />

      {openDialog && dialog}
    </>
  );
}
