import React, { ReactElement } from 'react';
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppDrawer from '../AppDrawer';
import AppHeader from '../AppHeader';
import zIndex from '@mui/material/styles/zIndex';

const Main = styled(Box)(({ theme }) => ({
  marginLeft: `calc(${theme.spacing(8)})`,
  marginTop: `calc(${theme.spacing(8)})`,
  width: `calc(100% - ${theme.spacing(8)})`,
  padding: '2rem',
}));

const AppLayout = ({ children }: { children: ReactElement }) => {
  const theme = useTheme();

  const drawerTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      text: {
        ...theme.palette.text,
        primary: theme.palette.grey['100'],
      },
    },
  });

  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <CssBaseline />
      <Main
        id="main"
        sx={{
          '@media print': {
            marginLeft: 0,
            marginTop: 0,
            width: '100%',
            py: 0,
            px: 2,
          },
        }}
      >
        {children}
      </Main>
      <Box sx={{ position: 'fixed', zIndex: zIndex.modal, '@media print': { display: 'none' } }}>
        <ThemeProvider theme={drawerTheme}>
          <AppDrawer />
          <AppHeader />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default AppLayout;
