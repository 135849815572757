import { Box, BoxProps, Typography } from '@mui/material';
import ProgressWithLabel from '@/components/Shared/ProgressWithLabel';
import { AlarmsOverview } from '../DevicesPanel/typings';
import { useNavigate } from 'react-router';
import { WarningAmber } from '@mui/icons-material';
import { ProgressWithLabelProps } from '@/components/Shared/ProgressWithLabel/typings';
import { useMemo } from 'react';
import { SEVERITY } from '@/shared/constants';
import { camelToKebab } from '@/shared/utils';

const DeviceAlarmCounter = ({
  data,
  serialNumber,
  progressWithLabelProps,
  ...props
}: {
  serialNumber: string;
  data: { [key: string]: number } | AlarmsOverview | number;
  progressWithLabelProps?: ProgressWithLabelProps;
} & BoxProps) => {
  let navigate: Function;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigate = useNavigate();
  } catch {
    navigate = (url: any) => {
      window.location = url;
    };
  }

  const isError = typeof data === 'number';

  const alarmData = useMemo<AlarmsOverview | null>(() => {
    if (isError) return null;
    if (Array.isArray(data)) {
      return data.reduce<AlarmsOverview>(
        (acc, curr) => {
          if (curr.level === SEVERITY.NON_CRITICAL) {
            acc[SEVERITY.NON_CRITICAL] += 1;
          } else {
            acc[SEVERITY.CRITICAL] += 1;
          }
          return acc;
        },
        {
          [SEVERITY.NON_CRITICAL]: 0,
          [SEVERITY.CRITICAL]: 0,
        }
      );
    }
    return data as AlarmsOverview;
  }, [data, isError]);

  return (
    <Box
      onClick={() => {
        navigate(`/fleet/devices/${serialNumber}`, { state: { tab: 2 } });
      }}
      sx={{ cursor: 'pointer', display: 'flex' }}
      {...props}
    >
      {isError ? (
        <Box display={'flex'} alignItems={'center'}>
          <WarningAmber sx={{ mr: '0.8rem' }} />
          <Typography variant="subtitle1">{data}</Typography>
        </Box>
      ) : alarmData ? (
        Object.keys(alarmData!).map((alarmLevel) => {
          const alarmsNumber: number = alarmData![alarmLevel as keyof AlarmsOverview];
          const color = camelToKebab(alarmLevel) === SEVERITY.NON_CRITICAL ? 'warning' : 'error';
          return (
            <ProgressWithLabel
              key={`${alarmLevel}${Math.random()}`}
              value={100}
              color={color}
              variant="determinate"
              size={50}
              containerStyle={{ padding: 0 }}
              innerLabel={alarmsNumber}
              innerLabelStyle={{ fontSize: '0.8rem' }}
              {...progressWithLabelProps}
            />
          );
        })
      ) : null}
    </Box>
  );
};

export default DeviceAlarmCounter;
