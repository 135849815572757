import { PasswordPolicy } from '@/router/pages/Auth/typings';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import usePasswordPolicy from './usePasswordPolicy';

const useInputValidations = () => {
  const { t } = useTranslation();

  const passwordPolicyUpdated = usePasswordPolicy();

  return useMemo(() => {
    const validations: Record<string, RegisterOptions> = {
      email: {
        required: { message: t('emailRequiredError'), value: true },
        pattern: { message: t('emailPatternError'), value: /^\S+@\S+$/i },
        minLength: { message: t('emailMinLengthError', { min: 3 }), value: 3 },
      },
      password: {
        required: { message: t('passwordRequiredError'), value: true },
        minLength: { message: t('passwordMinLengthError', { min: 8 }), value: 8 },
      },
    };

    const _passwordPolicy: string | null = sessionStorage.getItem('passwordPolicy');
    if (_passwordPolicy) {
      const passwordPolicy: PasswordPolicy = JSON.parse(_passwordPolicy);
      validations.password = {
        ...validations.password,
        minLength: {
          message: t('passwordMinLengthError', { min: passwordPolicy.minLength }),
          value: passwordPolicy.minLength,
        },
        validate: {
          uppercase: (value: string) =>
            value.match(/[A-Z]/)?.length === passwordPolicy.minUppercase || (t('passwordUppercaseError') as string),
          lowercase: (value: string) =>
            value.match(/[a-z]/)?.length === passwordPolicy.minLowercase || (t('passwordLowercaseError') as string),
          number: (value: string) =>
            value.match(/[0-9]/)?.length === passwordPolicy.minNumbers || (t('passwordNumberError') as string),
          special: (value: string) =>
            value.match(/[^A-Za-z0-9]/)?.length === passwordPolicy.minSymbols || (t('passwordSpecialError') as string),
        },
      };
    }

    return validations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, passwordPolicyUpdated]);
};

export default useInputValidations;
