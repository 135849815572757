import styled from '@emotion/styled';
import { DialogContentText } from '@mui/material';

const DialogContentLabel = styled(DialogContentText)({
  textTransform: 'uppercase',
  textAlign: 'left',
  variant: 'body2',
  fontWeight: 'bold',
});

export default DialogContentLabel;
