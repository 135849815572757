import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityWithRelatedRes } from '../DeviceIdentitiesPanel/typings';
import { AlarmsTable } from '@/components/OneTools/Channels/Alarms';
import { CommandsTable } from '@/components/OneTools/Channels/Commands';
import { ConsumablesTable } from '@/components/OneTools/Channels/Consumables';
import { EventsTable } from '@/components/OneTools/Channels/Events';
import { TelemetryTable } from '@/components/OneTools/Channels/Telemetry';
import { ConfigsTable } from '@/components/OneTools/Channels/Configs';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { removeFieldsFromJson } from '@/shared/utils';
import useTelemetryColumns from '@/components/OneTools/Channels/Telemetry/useTelemetryColumns';
import useAlarmsColumns from '@/components/OneTools/Channels/Alarms/useAlarmsColumns';
import { useCommandsColumns } from '@/components/OneTools/Channels/Commands/useCommandsColumns';
import useConsumablesColumns from '@/components/OneTools/Channels/Consumables/useConsumablesColumns';
import useEventsColumns from '@/components/OneTools/Channels/Events/useEventsColumns';
import useConfigColumns from '@/components/OneTools/Channels/Configs/useConfigColumns';
import PrintItem from './PrintItem';
import { CHANNELS } from '@/shared/constants';

export default function DeviceIdentityChannels({ deviceIdentity }: { deviceIdentity: DeviceIdentityWithRelatedRes }) {
  const { t } = useTranslation();

  const deviceIdentitiesRelated = {
    alarms: deviceIdentity?.alarms || [],
    commands: deviceIdentity?.commands,
    configs: deviceIdentity?.configs || [],
    consumables: deviceIdentity?.consumables,
    events: deviceIdentity?.events || [],
    telemetry: deviceIdentity?.telemetry,
  };

  const deviceIdentitiesRelatedWithoutSomeFields = removeFieldsFromJson(deviceIdentitiesRelated);

  const alarmsColumns = useAlarmsColumns(undefined, undefined, deviceIdentitiesRelated?.alarms);
  const commandsColumns = useCommandsColumns(undefined, undefined, deviceIdentitiesRelated?.commands);
  const configsColumns = useConfigColumns(undefined, undefined, deviceIdentitiesRelated?.configs);
  const consumablesColumns = useConsumablesColumns(undefined, undefined, deviceIdentitiesRelated?.consumables);
  const eventsColumns = useEventsColumns(undefined, undefined, deviceIdentitiesRelated?.events);
  const telemetryColumns = useTelemetryColumns(undefined, undefined, deviceIdentitiesRelated?.telemetry);

  const tabsConfig = [
    {
      id: CHANNELS.TELEMETRY,
      label: t(CHANNELS.TELEMETRY),
      ariaControls: true,
      ContentComponent: (
        <TelemetryTable
          title={t(CHANNELS.TELEMETRY)}
          data={deviceIdentitiesRelated?.telemetry}
          columns={telemetryColumns}
          isLoading={false}
          isError={false}
          resetStateButtonVisible={false}
          exportData={false}
          selection={false}
        />
      ),
    },
    {
      id: CHANNELS.EVENTS,
      label: t(CHANNELS.EVENTS),
      ariaControls: true,
      ContentComponent: (
        <EventsTable
          title={t(CHANNELS.EVENTS)}
          data={deviceIdentitiesRelated?.events}
          columns={eventsColumns}
          isLoading={false}
          isError={false}
          resetStateButtonVisible={false}
          exportData={false}
          selection={false}
        />
      ),
    },
    {
      id: CHANNELS.ALARMS,
      label: t(CHANNELS.ALARMS),
      ariaControls: true,
      ContentComponent: (
        <AlarmsTable
          title={t(CHANNELS.ALARMS)}
          data={deviceIdentitiesRelated?.alarms}
          columns={alarmsColumns}
          isLoading={false}
          isError={false}
          resetStateButtonVisible={false}
          exportData={false}
          selection={false}
        />
      ),
    },
    {
      id: CHANNELS.COMMANDS,
      label: t(CHANNELS.COMMANDS),
      ariaControls: true,
      ContentComponent: (
        <CommandsTable
          title={t(CHANNELS.COMMANDS)}
          data={deviceIdentitiesRelated?.commands}
          columns={commandsColumns}
          isLoading={false}
          isError={false}
          resetStateButtonVisible={false}
          exportData={false}
          selection={false}
        />
      ),
    },
    {
      id: CHANNELS.CONFIGS,
      label: t(CHANNELS.CONFIGS),
      ariaControls: true,
      ContentComponent: (
        <ConfigsTable
          title={t(CHANNELS.CONFIGS)}
          data={deviceIdentitiesRelated?.configs}
          columns={configsColumns}
          isLoading={false}
          isError={false}
          resetStateButtonVisible={false}
          exportData={false}
          selection={false}
        />
      ),
    },
    {
      id: CHANNELS.CONSUMABLES,
      label: t(CHANNELS.CONSUMABLES),
      ariaControls: true,
      ContentComponent: (
        <ConsumablesTable
          title={t(CHANNELS.CONSUMABLES)}
          data={deviceIdentitiesRelated?.consumables}
          columns={consumablesColumns}
          isLoading={false}
          isError={false}
          resetStateButtonVisible={false}
          exportData={false}
          selection={false}
        />
      ),
    },
  ];

  return (
    <Box>
      <Box sx={{ '@media print': { display: 'none' } }}>
        <Typography variant="h5" fontWeight={800} sx={{ marginBottom: 1 }}>
          {t('channels')}
        </Typography>
        <NavigationTabsLayout config={tabsConfig} navigateOnChange={false} />
      </Box>
      <Box sx={{ display: 'none', '@media print': { display: 'block' } }}>
        {Object.keys(deviceIdentitiesRelatedWithoutSomeFields)?.map((related) => (
          <Box sx={{ marginTop: '12mm', marginBottom: '3mm' }} key={related}>
            <Typography variant="h5" fontWeight={400} sx={{ marginBottom: '3mm' }}>
              {t(related)}
            </Typography>
            <>
              {deviceIdentitiesRelatedWithoutSomeFields[related] &&
              deviceIdentitiesRelatedWithoutSomeFields[related].length > 0
                ? deviceIdentitiesRelatedWithoutSomeFields[
                    related as keyof typeof deviceIdentitiesRelatedWithoutSomeFields
                  ]?.map((item: any, index: number) => {
                    return <PrintItem key={related + index} item={item} index={index} />;
                  })
                : t('noRecordsToDisplay')}
            </>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
