import { Column } from '@material-table/core';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OTARes } from './typings';
import { useGetDeviceIdentitiesQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import ReleatedEntityLabel from '@/components/Shared/Chips/ReleatedEntityChip';
import { useNavigate } from 'react-router';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import DownloadBuildFileButton from './DownloadBuildFileButton';

export function useOTAColumns(onEditClick: Function, onDeleteClick: Function): Array<Column<OTARes>> {
  const { t } = useTranslation();
  const columns_array: Array<Column<OTARes>> = [];

  const navigate = useNavigate();

  const { data: deviceIdentities, isLoading: isLoadingDeviceIdentities } = useGetDeviceIdentitiesQuery();

  return columns_array.concat([
    {
      title: t('buildFile'),
      field: 'id',
    },
    {
      title: t('deviceIdentity'),
      field: 'model',
      render: (OTA) => {
        const deviceIdentity = deviceIdentities?.data?.items.find((t) => t.id === OTA.model);
        return (
          !isLoadingDeviceIdentities && (
            <ReleatedEntityLabel
              text={deviceIdentity?.name}
              onEntityClick={() =>
                navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}#device_identities`, {
                  state: {
                    searchText: deviceIdentity?.name,
                    highlight: {
                      refField: 'id',
                      matchValue: deviceIdentity?.id,
                    },
                    switchTab: true,
                  },
                })
              }
            />
          )
        );
      },
    },
    {
      title: t('version'),
      field: 'version',
    },
    {
      title: t('minVersion'),
      field: 'minVersion',
    },
    {
      title: t('actions'),
      render: (OTA) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          <DownloadBuildFileButton id={OTA.id} />
          <Button variant="outlined" color="primary" onClick={() => onEditClick(OTA.id)}>
            {t('edit')}
          </Button>
          <Button variant="outlined" color="error" onClick={() => onDeleteClick({ id: OTA.id, name: OTA.id })}>
            {t('delete')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ]);
}
