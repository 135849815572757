import { Operation, STATUS } from '@/redux/slices/operationSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import OneToolMessage from '../../Notification/ByTag/OneTool/Message';
import BaseOperationIconButton from '../../Operation/BaseOperationIconButton';
import BaseOperationAvatar from '../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../Operation/BaseOperationIcon';
import { StatusNotification } from '../../Notification/BaseNotification';
import { getDefaultStatusIcon } from '../../Notification/ByTag/utils';
import useDefaultErrorCallback from '../../Notification/hooks/useDefaultErrorCallback';
import { CommonSnackbarProps } from '../typings';
import BaseSnackbar from '../BaseSnackbar';
import StatusSnackbarActions from '../StatusSnackbarActions';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function OneToolCreateVariant({
  operation,
  dismissAction,
}: CommonSnackbarProps & { operation: Operation & { path: string } }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultErrorCallback = useDefaultErrorCallback(operation);

  return (
    <StatusNotification
      {...operation}
      baseComponent={{
        Component: BaseSnackbar,
        extra: { snackbarActions: <StatusSnackbarActions operation={operation} dismissAction={dismissAction} /> },
      }}
      avatar={
        <BaseOperationAvatar
          status={operation.status}
          tagIcon={<BaseOperationIcon Icon={PlaylistAddIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(operation.status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: (
            <OneToolMessage
              read={operation.read}
              status={operation.status}
              opening={t('createOneToolError')}
              subject={operation.subject}
              entity={t(operation.entity)}
            />
          ),
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={operation.read}
                status={operation.status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
        [STATUS.INFO]: {
          message: (
            <OneToolMessage
              read={operation.read}
              status={operation.status}
              opening={t('createOneToolPending')}
              subject={operation.subject}
              entity={t(operation.entity)}
            />
          ),
          onClick: () => navigate(operation.path),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={operation.read}
                status={operation.status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
        [STATUS.SUCCESS]: {
          message: (
            <OneToolMessage
              read={operation.read}
              status={operation.status}
              opening={t('createOneToolSuccess')}
              entity={t(operation.entity)}
              subject={operation.subject}
            />
          ),
          onClick: () =>
            navigate(operation.path, {
              state: {
                searchText: operation.subject,
              },
            }),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={operation.read}
                status={operation.status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
      }}
    />
  );
}
