import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CodeHighlighter from './CodeHighlighter';

export default function PrintItem({ item, index }: { item: any; index: number }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        breakInside: 'avoid',
        display: 'grid',
        gridTemplate: '1fr / minmax(60%, auto) minmax(30%, 1fr)',
        marginBottom: '3mm',
        printColorAdjust: 'exact',
        WebkitPrintColorAdjust: 'exact',
        gap: 2,
        '& *': {
          printColorAdjust: 'exact',
          WebkitPrintColorAdjust: 'exact',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplate: 'auto / min-content auto',
          backgroundColor: (theme) => theme.palette.background.grayShades[0],
          borderRadius: 1,
          padding: 1,
          width: '100%',
        }}
      >
        {/* Counter */}
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: 2,
            width: '10mm',
            height: '10mm',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color={(theme) => theme.palette.background.default}
            paddingBottom={0}
            lineHeight={'normal'}
            component={'span'}
            fontSize={15}
            variant="h6"
          >
            {`${+(index + 1 >= 10) && ''}${index + 1}`}
          </Typography>
        </Box>
        {/* Info about item */}
        <Box
          sx={{
            display: 'grid',
            maxWidth: '100%',
            gridTemplate: `auto / ${item.payloadExample ? '1fr 2fr' : 'auto auto'}`,
            gap: 1,
          }}
        >
          {/* General info about item */}
          <Box display="flex" flexWrap="wrap" gap={2} padding={1}>
            {Object.entries(item).flatMap(([key, value]) =>
              !['payloadExample', 'description'].includes(key) && value ? (
                <Box
                  sx={{
                    overflowWrap: 'break-word',
                  }}
                  key={key + String(value)}
                >
                  <Typography variant="body1" color="text.secondary" sx={{ paddingBottom: 0 }}>
                    {t(String(key))}
                  </Typography>
                  <Typography variant="body2" sx={{ paddingBottom: 0, fontSize: '10pt' }}>
                    {String(value)}
                  </Typography>
                </Box>
              ) : (
                []
              )
            )}
          </Box>
          {/* Item payload */}
          {item.payloadExample && (
            <Box>
              <Typography variant="body1" color="text.secondary" sx={{ paddingBottom: 0 }}>
                {t('payloadExample') + ': (json)'}
              </Typography>
              <CodeHighlighter code={item.payloadExample} language="json" />
            </Box>
          )}
        </Box>
      </Box>
      {item.description && (
        <Box>
          <Typography variant="h6">{t('description')}</Typography>
          <Typography variant="body1">{item.description}</Typography>
        </Box>
      )}
    </Box>
  );
}
