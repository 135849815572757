import { ApiResult } from '@typings';
import APP_CONFIG from '../../appConfig';
import apiSlice, { API_VERSION } from './apiSlice';
import { PasswordPolicy } from '@/router/pages/Auth/typings';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        method: 'POST',
        url: `${API_VERSION.v1}/auth/login`,
        body: {
          appId: APP_CONFIG.appId,
          ...credentials,
        },
      }),
    }),
    getPasswordPolicy: builder.query<ApiResult<PasswordPolicy>, void>({
      query: () => `${API_VERSION.v1}/user/passwordpolicy`,
    }),
    requestResetPassword: builder.mutation({
      query: (email: string) => ({
        method: 'POST',
        url: `${API_VERSION.v1}/user/account/reset-password`,
        body: {
          appId: APP_CONFIG.appId,
          email,
          lang: 'en',
        },
      }),
    }),
    confirmResetPassword: builder.mutation({
      query: ({ password, oobCode }: { password: string; oobCode: string }) => ({
        method: 'POST',
        url: `${API_VERSION.v1}/user/account/reset-password/confirm`,
        body: {
          password,
          oobCode,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRequestResetPasswordMutation,
  useConfirmResetPasswordMutation,
  useLazyGetPasswordPolicyQuery,
} = authApiSlice;
