import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import {
  AddEditDialogConfig,
  AddEditDialogConfigs,
  AddEditDialogProps,
  AutocompleteOption,
  FieldsVisibilityHandler,
  MutationEvent,
  Mutations,
  MutationsField,
  ToggleFields,
  ToggleFieldsPredicate,
} from './typings';
import { CustomErrors, NotEmptyArray } from '@/shared/typings';
import React, { MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
  UseFormSetValue,
  UseFormUnregister,
  useForm,
} from 'react-hook-form';
import DialogInput from './DialogInput';
import { useTranslation } from 'react-i18next';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { DevTool } from '@hookform/devtools';
import APP_CONFIG from '@/appConfig';
import RenderIf from '../RenderIf/RenderIf';

const getAutocompleteWrappedValue = (
  options: AutocompleteOption[],
  initialValue: string | string[],
  isMultiple?: boolean
) => {
  return !isMultiple
    ? options.find((o: AutocompleteOption) => o.value === initialValue)
    : initialValue?.length > 0
    ? (initialValue as string[]).flatMap((v: string) => options.find((o: AutocompleteOption) => o.value === v) || [])
    : options.filter((o: AutocompleteOption) => initialValue?.includes(o.value) || o.fixed);
};

const getMutations =
  (_showFields: FieldsVisibilityHandler, _hideFields: FieldsVisibilityHandler, _toggleFields: ToggleFields) =>
  (mutations: MutationsField, config: AddEditDialogConfigs) => {
    return Object.entries(
      (typeof mutations === 'object'
        ? mutations
        : mutations &&
          mutations(
            (...fields) => _showFields(...fields),
            (...fields) => _hideFields(...fields),
            (predicate, ...fields) => _toggleFields(predicate, ...fields)
          )) || {}
    ).reduce<{
      [K in keyof Mutations as `${K}Mutation`]?: MutationEvent;
    }>((acc, curr) => {
      return { ...acc, [`${curr[0]}Mutation`]: curr[1] };
    }, {});
  };

const showFieldsWithOriginalPosition = (
  configs: AddEditDialogConfigs,
  initialConfigs: AddEditDialogConfigs,
  fields: string[]
) => {
  return initialConfigs.reduce<AddEditDialogConfigs>((acc, iC) => {
    const _c = configs.find((c) => c.name === iC.name);
    if (_c) {
      return [...acc, _c];
    }

    if (fields.includes(iC.name)) {
      return [...acc, iC];
    }

    return acc;
  }, []);
};

const handleSetValue = (item: AddEditDialogConfig, getValues: UseFormGetValues<FieldValues>) => {
  const currValue = getValues()?.[item.name];

  if (currValue !== undefined && currValue !== item.initialValue) {
    if (item.type === 'autocomplete') {
      return {
        ...item,
        initialValue: currValue?.value,
      };
    }

    return {
      ...item,
      initialValue: currValue,
    };
  }

  return item;
};

const useFieldsTogglers = (
  initialConfigs: AddEditDialogConfigs,
  configs: AddEditDialogConfigs,
  setConfig: React.Dispatch<React.SetStateAction<AddEditDialogConfigs>>,
  getValues: UseFormGetValues<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  unregister: UseFormUnregister<FieldValues>
) => ({
  show: (...fields: NotEmptyArray<string>) => {
    setConfig((configs) => {
      const newConfig = showFieldsWithOriginalPosition(configs, initialConfigs, fields).map((c) =>
        handleSetValue(c, getValues)
      );

      return newConfig;
    });
  },
  hide: (...fields: NotEmptyArray<string>) => {
    setConfig((configs) => {
      fields.forEach((field) => setValue(field, undefined));
      return configs.filter((c) => !fields.includes(c.name)).map((c) => handleSetValue(c, getValues));
    });

    fields.forEach((name) => unregister(name));
  },
  toggle: (predicate: ToggleFieldsPredicate) => {
    const fieldsToShow = initialConfigs.reduce<string[]>((acc, curr) => {
      if (predicate(curr.name, initialConfigs, configs)) {
        return [...acc, curr.name];
      }
      return acc;
    }, []);

    setConfig((configs) => {
      const newConfig = showFieldsWithOriginalPosition(
        configs.filter((c) => predicate(c.name, initialConfigs, configs)),
        initialConfigs,
        fieldsToShow
      ).map((c) => handleSetValue(c, getValues));

      return newConfig;
    });

    const fieldsToHide = initialConfigs.filter(
      (initialField) => !fieldsToShow.some((field) => field === initialField.name)
    );

    fieldsToHide.forEach((field) => unregister(field.name, undefined));
  },
});

const renderTitle = (
  groupBackground: { curr: string | null; prev: string | null },
  currentGroup: MutableRefObject<string | undefined>,
  groupName?: string,
  groups?: { [k: string]: { title: string; description?: string } }
) => {
  if (!groups || currentGroup.current === groupName) {
    return <></>;
  }

  if (!currentGroup || currentGroup.current !== groupName) {
    currentGroup.current = groupName;
    const currentColor = groupBackground.curr;
    groupBackground.curr = groupBackground.prev;
    groupBackground.prev = currentColor;
  }
  const group = Object.keys(groups).find((key) => key === groupName);

  if (!group) {
    return <></>;
  }

  return (
    <Box
      sx={{
        pt: 4,
        px: 2,
        ...(groupBackground.curr ? { backgroundColor: groupBackground.curr } : {}),
      }}
    >
      <Typography fontSize={20} fontWeight={700} sx={{ position: 'relative' }}>
        {groups[group].title}
      </Typography>
      {groups[group].description && <Typography fontSize={13}>{groups[group].description}</Typography>}
    </Box>
  );
};

export default function AddEditDialog({
  title,
  config: initialConfig,
  isOpen,
  isEditing,
  onClose,
  onSubmit,
  formValidation,
  groups,
  editId,
  mutations,
  revalidateMode,
  validateMode,
}: AddEditDialogProps) {
  const { t } = useTranslation();

  const [config, setConfig] = useState<AddEditDialogConfigs>(initialConfig);
  const useFormReturn = useForm({
    ...(validateMode ? { mode: validateMode } : {}),
    ...(revalidateMode ? { reValidateMode: revalidateMode } : {}),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    watch,
    unregister,
  } = useFormReturn;
  const {
    show: showFields,
    hide: hideFields,
    toggle: toggleFields,
  } = useFieldsTogglers(initialConfig, config, setConfig, getValues, setValue, unregister);
  const _getMutations = getMutations(showFields, hideFields, toggleFields);

  const _isEditing = isEditing;
  const needsInitialValues = config != null && config.some((item) => item.initialValue);

  const errorsNumber = Object.keys(errors).length;
  const isFormDisabled = useMemo(() => {
    let isDisabled = false;
    for (const key in errors) {
      if (errors[key]?.type !== 'custom') {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, errorsNumber]);

  const setCustomErrors = useCallback(
    (field: string, content: CustomErrors) => {
      setError(field, { type: 'custom', message: content });
    },
    [setError]
  );

  useEffect(() => {
    if (formValidation) {
      formValidation({}, setCustomErrors, clearErrors, errors); // trigger custom validation upon creation
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!mutations) {
      return;
    }

    const { onMountMutation } = _getMutations(mutations, initialConfig);

    if (!onMountMutation) {
      return;
    }

    const getPromiseMutation = async () => {
      const updatedConfig = await onMountMutation.impacts.form?.handler(useFormReturn, initialConfig, editId);

      if (updatedConfig) {
        setConfig(updatedConfig);
      }
    };

    getPromiseMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValidation) {
      return watch((value) => {
        formValidation(value, setCustomErrors, clearErrors, errors);
      }).unsubscribe;
    }
  }, [formValidation, watch, setCustomErrors, clearErrors, dirtyFields, config, errors]);

  useEffect(() => {
    if (needsInitialValues) {
      for (const item of config) {
        if (item.initialValue !== undefined && item.initialValue !== null) {
          // Wrapping autocomplete data
          if (item.type === 'autocomplete' && item.selectConfig) {
            if (typeof item.selectConfig.options === 'function') {
              item.selectConfig.options().then((options) => {
                setValue(
                  item.name,
                  getAutocompleteWrappedValue(
                    options,
                    item.initialValue as string | string[],
                    item.selectConfig!.multiple
                  )
                );
              });
            } else {
              setValue(
                item.name,
                getAutocompleteWrappedValue(
                  item.selectConfig.options,
                  item.initialValue as string | string[],
                  item.selectConfig.multiple
                )
              );
            }
            continue;
          }

          setValue(item.name, item.initialValue);
        }
      }
    }
  }, [config, needsInitialValues, setValue]);

  const onInvalidSubmit = useCallback(() => {
    if (formValidation) {
      // N.B. only custom errors needs to be cleaned on invalid submission!
      const clearCustomErrors = (fields: string[]) => {
        for (const field of fields) {
          errors[field]?.type === 'custom' && clearErrors(field);
        }
      };

      const data = getValues();
      formValidation(data, setCustomErrors, clearCustomErrors, errors);
    }
  }, [clearErrors, errors, formValidation, getValues, setCustomErrors]);

  const _onClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const _onSubmit = useCallback(
    (data: any) => {
      const submitData = { ...data };
      for (const key in submitData) {
        if (!submitData[key] && typeof submitData[key] !== 'number' && typeof submitData[key] !== 'boolean') {
          delete submitData[key];
        }
      }

      for (const key in config) {
        // Unwrapping autocomplete data
        const configItem = config[key as any];
        if (configItem.type === 'autocomplete') {
          let formField: AutocompleteOption | AutocompleteOption[] = submitData[configItem.name];
          if (Array.isArray(formField)) submitData[configItem.name] = formField.map((item) => item.value);
          else submitData[configItem.name] = formField?.value;
        }
      }

      _onClose();
      onSubmit(submitData);
    },
    [_onClose, config, onSubmit]
  );

  const currentGroup = useRef<string | undefined>(undefined);
  const theme = useTheme();
  let groupBackground: { curr: string | null; prev: string | null } = useMemo(
    () => ({
      curr: null,
      prev: theme.palette.background.grayShades[0],
    }),
    [theme]
  );

  const handleOnChangeFormMutation = useCallback(async () => {
    if (!mutations) {
      return;
    }

    const formTriggeredMutations = _getMutations(mutations, initialConfig);

    if (!formTriggeredMutations.onChangeMutation) {
      return;
    }

    if (formTriggeredMutations.onChangeMutation.impacts.fields) {
      await Promise.all(
        formTriggeredMutations.onChangeMutation.impacts.fields?.map(async (field) => {
          const targetFieldInitialConfig: AddEditDialogConfig | undefined = config?.find((c) => c.name === field.name);
          if (targetFieldInitialConfig) {
            field.handler(useFormReturn, initialConfig, editId);
          }
        })
      );
    }

    if (formTriggeredMutations.onChangeMutation.impacts.form) {
      const updatedConfig = await formTriggeredMutations.onChangeMutation.impacts.form.handler(
        useFormReturn,
        initialConfig,
        editId
      );

      if (!updatedConfig) {
        return;
      }

      const values = getValues();
      setConfig(
        updatedConfig.map((config) => ({
          ...config,
          initialValue: config.type !== 'autocomplete' ? values[config.name] : values[config.name]?.value,
        }))
      );
    }
  }, [_getMutations, config, editId, getValues, initialConfig, mutations, useFormReturn]);

  const _onChange = useCallback(
    async ({ name }: { name: string }, eventHandler: Function) => {
      eventHandler();

      const fieldTriggeredMutations = config?.find((c) => {
        let _mutations = c.name === name && c.mutations;

        if (_mutations && typeof _mutations === 'object' && _mutations.onChange) {
          return true;
        }

        if (
          _mutations &&
          typeof _mutations !== 'object' &&
          _mutations(
            () => {},
            () => {},
            () => {}
          ).onChange
        ) {
          return true;
        }

        return false;
      })?.mutations;

      if (!fieldTriggeredMutations) {
        return;
      }

      const { onChangeMutation } = _getMutations(fieldTriggeredMutations, config);

      if (onChangeMutation && onChangeMutation.impacts.fields) {
        await Promise.all(
          onChangeMutation.impacts.fields?.map(async (field) => {
            const targetFieldInitialConfig: AddEditDialogConfig | undefined = config?.find(
              (c) => c.name === field.name
            );
            if (targetFieldInitialConfig) {
              field.handler(useFormReturn, initialConfig, editId);
            }
          })
        );
      }

      if (onChangeMutation && onChangeMutation.impacts.form) {
        const newConfig = await onChangeMutation.impacts.form.handler(useFormReturn, initialConfig, editId);

        if (newConfig) {
          setConfig(newConfig);
        }
      }

      handleOnChangeFormMutation();
    },
    [_getMutations, config, editId, handleOnChangeFormMutation, initialConfig, useFormReturn]
  );

  const _register = useCallback(
    (name: any, options?: RegisterOptions<any, any>) => {
      const { onChange, ...registerProps } = register(name, options);
      let _onChange = (event: { target: any; type?: any }) => {
        handleOnChangeFormMutation();
        return onChange(event);
      };

      const mutations = config?.find((c) => {
        let _mutations = c.name === name && c.mutations;

        if (_mutations && typeof _mutations === 'object' && _mutations.onChange) {
          return true;
        }

        if (
          _mutations &&
          typeof _mutations !== 'object' &&
          _mutations(
            () => {},
            () => {},
            () => {}
          ).onChange
        ) {
          return true;
        }

        return false;
      })?.mutations;

      if (mutations) {
        const { onChangeMutation } = _getMutations(mutations, config);

        if (onChangeMutation) {
          _onChange = async (event: { target: any; type?: any }) => {
            const onChangeRes = onChange(event);

            if (onChangeMutation && onChangeMutation.impacts.fields) {
              await Promise.all(
                onChangeMutation.impacts.fields?.map(async (field) => {
                  const targetFieldInitialConfig: AddEditDialogConfig | undefined = config?.find(
                    (c) => c.name === field.name
                  );
                  if (targetFieldInitialConfig) {
                    field.handler(useFormReturn, config, editId);
                  }
                })
              );
            }

            handleOnChangeFormMutation();

            return onChangeRes;
          };
        }
      }

      return {
        ...registerProps,
        onChange: _onChange,
      };
    },
    [_getMutations, config, editId, handleOnChangeFormMutation, register, useFormReturn]
  );

  const fields = useMemo(() => {
    return config.map((item) => {
      return (
        errors[item.name]?.message?.toString() !== CustomErrors.HIDE_FIELD && (
          <React.Fragment key={item.name}>
            <Box>
              {renderTitle(groupBackground, currentGroup, item.group, groups)}
              <Box
                px={2}
                py={1}
                {...(groupBackground.curr
                  ? {
                      sx: {
                        backgroundColor: groupBackground.curr,
                      },
                    }
                  : {})}
              >
                <DialogInput
                  type={item.type}
                  configItem={item}
                  hasErrors={!!errors[item.name]}
                  register={_register}
                  control={control}
                  getValues={getValues}
                  onChange={_onChange}
                  {...((_isEditing && 'id' === item.name && { disabled: true }) || {})}
                />
                {!!errors[item.name] && (
                  <Typography color="error" fontSize={12} marginLeft={'14px'}>
                    {errors[item.name]?.message?.toString()}
                  </Typography>
                )}
              </Box>
            </Box>
          </React.Fragment>
        )
      );
    });
  }, [_isEditing, _onChange, _register, config, control, currentGroup, errors, getValues, groupBackground, groups]);

  const form = useMemo(
    () =>
      config && (
        <form onSubmit={handleSubmit(_onSubmit, onInvalidSubmit)}>
          <DialogContent sx={{ p: 0, pb: 3 }}>{fields}</DialogContent>
          <DialogActions sx={{ px: 2, pb: 2 }}>
            <Button variant="outlined" onClick={_onClose}>
              {t('cancel')}
            </Button>
            <Button variant="contained" type="submit" disabled={isFormDisabled || !isDirty}>
              {t('save')}
            </Button>
          </DialogActions>
          <RenderIf condition={APP_CONFIG.environment === 'local' && APP_CONFIG.debugForm === 'true'}>
            <DevTool control={control} />
          </RenderIf>
        </form>
      ),
    [_onClose, _onSubmit, config, control, fields, handleSubmit, isDirty, isFormDisabled, onInvalidSubmit, t]
  );

  const { dialog } = useCulliganDialog({
    open: isOpen,
    handleClose: (_: any, reason: string) => {
      if (reason !== 'backdropClick') {
        _onClose();
      }
    },
    fullWidth: !!config,
    transitionDuration: { appear: 300 },
    tabs: [
      {
        id: 'addEditDialog',
        label: `${_isEditing ? t('edit') : t('create')} ${title}`,
        body: form,
      },
    ],
    styles: {
      bodyContainer: {
        p: 0,
      },
    },
  });

  return config && dialog;
}

export const DialogLoading = () => (
  <Dialog open={true} transitionDuration={{ exit: 300 }}>
    <CircularProgress sx={{ margin: 4 }} />
  </Dialog>
);
