import { SlideDiv } from '@/components/Shared/SlidingDiv';
import { Box, CircularProgress, Fade, Theme, Typography, alpha, keyframes } from '@mui/material';
import { useTranslation } from 'react-i18next';

const shineAnimation = (theme: Theme) => keyframes`
    to {
      background-position-x: -200%;
    }
  `;

export default function LoadingFeedback({ loading }: { loading: boolean }) {
  const { t } = useTranslation();
  return (
    <>
      <SlideDiv
        slide={loading}
        sx={{
          position: 'absolute',
          zIndex: -1,
          top: 100,
          left: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            p: 1,
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderTopRightRadius: (theme) => theme.shape.borderRadius,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
            backgroundSize: '200% 100%',
            transform: 'translateY(-80%)',
            animation: (theme) => `1s ${shineAnimation(theme)} linear infinite`,
          }}
        >
          <Box display={'flex'} gap={1} alignItems={'center'} paddingBottom={1}>
            <CircularProgress color="inherit" size={7} />
            <Typography variant="caption" sx={{ lineHeight: 1, display: 'block' }}>
              {t('loading')}
            </Typography>
          </Box>
        </Box>
      </SlideDiv>
      <Fade in={loading}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: (theme) => alpha(theme.palette.background.grayShades[0], 0.3),
            zIndex: 2,
          }}
        >
          <Box
            sx={{
              height: '100%',
              background: (theme) =>
                `linear-gradient(110deg, ${alpha(theme.palette.background.grayShades[0], 0)} 8%, #f5f5f5 18%, ${alpha(
                  theme.palette.background.grayShades[0],
                  0
                )} 33%)`,
              backgroundSize: '200% 100%',
              animation: (theme) => `1s ${shineAnimation(theme)} linear infinite`,
              mixBlendMode: 'lighten',
            }}
          ></Box>
        </Box>
      </Fade>
    </>
  );
}
