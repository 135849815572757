import { Dayjs } from 'dayjs';

function defaultSearchByDate(filter: string, date: Dayjs) {
  if (!filter.includes('/') && Number.isNaN(Number(filter))) {
    return false;
  }

  const day = date.date();
  const month = date.get('month') + 1;
  const year = date.get('year');

  const [filterYear, filterMonth, filterDay] = filter.split('/').reverse();
  return Boolean(
    ((filterDay && Number(filterDay) === day) || !filterDay) &&
      ((filterMonth && Number(filterMonth) === month) || !filterMonth) &&
      filterYear &&
      Number(filterYear) === year
  );
}

export { defaultSearchByDate };
