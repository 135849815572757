import useDefaultLoading from '@/hooks/useDefaultLoading';
import { ReactNode } from 'react';

export default function LoadingMessage({ children }: { children?: ReactNode }) {
  const defaultBody = useDefaultLoading();

  const body = children ? children : defaultBody;

  return <>{body}</>;
}
