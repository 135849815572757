import { ReactNode } from 'react';
import { Box, darken, lighten } from '@mui/material';
import { Operation } from '@/redux/slices/operationSlice';

export default function BaseOperationAvatar({
  tagIcon,
  statusIcon,
  status,
}: {
  tagIcon: ReactNode;
  statusIcon: ReactNode;
  status: Operation['status'];
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: (theme) => darken(theme.palette[status || 'primary'].dark, 0.3),
        height: '45px',
        width: '45px',
        borderRadius: '50%',
      }}
    >
      <Box
        sx={{
          fontSize: '15px',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tagIcon}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          fontSize: '9px',
          backgroundColor: (theme) => theme.palette[status || 'primary'].light,
          width: '15px',
          height: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          border: (theme) => `1px solid ${lighten(theme.palette[status || 'primary'].light, 0.8)}`,
          bottom: 0,
          right: 0,
        }}
      >
        {statusIcon}
      </Box>
    </Box>
  );
}
