import { TAGS } from '@/redux/slices/operationSlice';
import DeviceConsumableDepleteVariant from './DeviceConsumableDeplete';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import DeviceConsumableRemoveVariant from './DeviceConsumableRemove';
import DeviceConsumableSetVariant from './DeviceConsumableSet';
import { CommonSnackbarProps } from '../typings';

export default function DeviceConsumableVariant({ operation, dismissAction }: CommonSnackbarProps) {
  switch (operation.tag) {
    case TAGS.DEVICE_CONSUMABLE_DEPLETE:
      return (
        <DeviceConsumableDepleteVariant
          operation={{
            ...operation,
            path: `/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.entity}#consumables`,
          }}
          dismissAction={dismissAction}
        />
      );

    case TAGS.DEVICE_CONSUMABLE_REMOVE:
      return (
        <DeviceConsumableRemoveVariant
          operation={{
            ...operation,
            path: `/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.entity}#consumables`,
          }}
          dismissAction={dismissAction}
        />
      );

    case TAGS.DEVICE_CONSUMABLE_SET:
      return (
        <DeviceConsumableSetVariant
          operation={{
            ...operation,
            path: `/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.entity}#consumables`,
          }}
          dismissAction={dismissAction}
        />
      );

    default:
      return <></>;
  }
}
