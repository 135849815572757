import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlarmRes } from './typings';
import dayjs from 'dayjs';
import ExpandableField from '@/components/Shared/ExpandableField';
import { useNavigate } from 'react-router';
import { ROLES, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';

export default function useAlarmColumns(
  onEditClick?: Function,
  onDeleteClick?: Function,
  itemsInDeviceIdentity?: AlarmRes[]
): Array<Column<AlarmRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns_array: Array<Column<AlarmRes>> = [];

  return columns_array.concat([
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('sendingStyle'),
      field: 'sendingStyle',
    },
    {
      title: t('parameters'),
      field: 'parameters',
      render: (alarms) => <Typography>{alarms.parameters ? t('yes') : t('no')}</Typography>,
    },
    {
      title: t('payloadExample'),
      width: '15%',
      field: 'payloadExample',
      render: (alarms) => alarms.payloadExample && <ExpandableField content={alarms.payloadExample} />,
      sorting: false,
    },
    {
      title: t('roles'),
      field: 'role',
      customFilterAndSearch: (filter, alarms) => {
        const roles = alarms?.role || [];
        const roleFilter = roles.some((role) => Object.values(ROLES).includes(role));

        return roleFilter && roles.some((role) => role.startsWith(filter));
      },
      render: (alarms) =>
        alarms.role?.map((r, index) => (
          <Typography sx={{ width: 'max-content' }} key={`${r}-${index}`}>
            {t(r)}
          </Typography>
        )),
      exportTransformer: (alarms) =>
        (alarms.role as string[])?.join ? (alarms.role as string[])?.join(', ') : alarms.role,
    },
    {
      title: t('severity'),
      field: 'severity',
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (alarms) => dayjs(alarms.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (alarms) => dayjs(alarms.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '10%',
      render: (alarms) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          {!itemsInDeviceIdentity ? (
            <>
              <Button variant="outlined" color="primary" onClick={onEditClick && (() => onEditClick(alarms.id))}>
                {t('edit')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={onDeleteClick && (() => onDeleteClick({ id: alarms.id, name: alarms.name }))}
              >
                {t('delete')}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#alarms`, {
                  state: {
                    searchText: alarms.name,
                    highlight: {
                      refField: 'id',
                      matchValue: alarms.id,
                    },
                  },
                })
              }
            >
              {t('view')}
            </Button>
          )}
        </Box>
      ),
      sorting: false,
      searchable: false,
    },
  ]);
}
