import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Datum, FlyoutProps } from 'victory';
import Pointer from './Pointer';

export const POINTER_DEFAULT = {
  LENGTH: 5,
  WIDTH: 20,
};

const PARENT_SIZE_DEFAULT_WIDTH = 0;
const PARENT_SIZE_DEFAULT_HEIGHT = 0;

const FLYOUT_DEFAULT = {
  DY: 10,
  DX: 0,
};

type FlyoutBody = ({
  datum,
  data,
  flyoutProps,
}: {
  datum: Datum;
  data?: Datum[];
  flyoutProps: FlyoutProps & { activePoints?: any[]; parentSize?: { height?: number; width?: number } };
}) => JSX.Element;

type ParentSize = { height?: number; width?: number };

type CustomFlyoutProps = {
  body: FlyoutBody;
  parentSize: ParentSize;
} & FlyoutProps;

function FlyoutPrivate({
  body,
  x,
  y,
  pointerLength = POINTER_DEFAULT.LENGTH,
  pointerWidth = POINTER_DEFAULT.WIDTH,
  dy = FLYOUT_DEFAULT.DY,
  parentSize = {
    width: PARENT_SIZE_DEFAULT_WIDTH,
    height: PARENT_SIZE_DEFAULT_HEIGHT,
  },
  datum,
  data,
  ...flyoutProps
}: CustomFlyoutProps) {
  const [foreignObjectWidth, setForeignObjectWidth] = useState(0);
  const [foreignObjectHeight, setForeignObjectHeight] = useState(0);
  const [overflow, setOverflow] = useState<boolean>(false);
  const [overflowDelta, setOverflowDelta] = useState<number | undefined>();
  const flyoutContentRef = useRef<HTMLElement>(null);
  const _parentSize = useMemo(
    () => ({ width: PARENT_SIZE_DEFAULT_WIDTH, height: PARENT_SIZE_DEFAULT_HEIGHT, ...parentSize }),
    [parentSize]
  );
  const _body = useMemo(() => body({ datum, data, flyoutProps }), [body, data, datum, flyoutProps]);

  useLayoutEffect(() => {
    if (document && flyoutContentRef?.current) {
      const rect = flyoutContentRef?.current?.getBoundingClientRect();
      setForeignObjectHeight(rect?.height || 0);
      setForeignObjectWidth(rect?.width || 0);
    }
  }, []);

  useLayoutEffect(() => {
    const mainXTranslated = Number(x) - foreignObjectWidth / 2;
    const rightDiff = mainXTranslated + foreignObjectWidth - _parentSize.width;

    if (mainXTranslated < 0 || mainXTranslated + foreignObjectWidth > _parentSize.width) {
      setOverflow(true);
      const isOverflowingToTheRight = mainXTranslated > Math.abs(rightDiff);
      const flyoutForceToBeVisiblePaddingX = isOverflowingToTheRight ? -8 : 8;
      const flyoutForceToVisibleTranslateX = (isOverflowingToTheRight ? rightDiff : mainXTranslated) * -1;

      setOverflowDelta(flyoutForceToVisibleTranslateX + flyoutForceToBeVisiblePaddingX);

      return;
    }
  }, [_parentSize.width, foreignObjectHeight, foreignObjectWidth, pointerWidth, x]);

  return (
    <g id="flyoutForceToVisible" {...(overflowDelta ? { transform: `translate(${overflowDelta} 0)` } : {})}>
      <g transform={`translate(-${foreignObjectWidth / 2} -${foreignObjectHeight + pointerLength + dy})`}>
        <g id="flyoutContent">
          <foreignObject x={x} y={y} height={foreignObjectHeight} width={foreignObjectWidth}>
            {React.cloneElement(_body, {
              ..._body.props,
              ref: flyoutContentRef,
            })}
          </foreignObject>
        </g>
        <Pointer
          pointerLength={pointerLength}
          pointerWidth={pointerWidth}
          flyoutRect={{ x, y, height: foreignObjectHeight, width: foreignObjectWidth }}
          overflow={overflow}
          overflowDelta={overflowDelta}
        />
      </g>
    </g>
  );
}

export default function Flyout({
  shouldHide,
  ...rest
}: CustomFlyoutProps & {
  shouldHide?: (props: { body: FlyoutBody; parentSize: ParentSize; activePoints?: any[] } & FlyoutProps) => boolean;
}) {
  if (shouldHide && shouldHide(rest)) {
    return <></>;
  }

  return <FlyoutPrivate {...rest} />;
}
