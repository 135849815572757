import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { DeviceIdentityRes } from '../DeviceIdentitiesPanel/typings';
import { useGetDeviceIdentityWithRelatedQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import DeviceIdentitySection from './DeviceIdentitySection';
import DeviceIdentityChannels from './DeviceIdentityChannels';

const SafeDeviceIdentitySection = withErrorLoadingManagement(DeviceIdentitySection);

const SafeDeviceIdentityChannels = withErrorLoadingManagement(DeviceIdentityChannels);

function DeviceIdentityPanel({ deviceIdentity }: { deviceIdentity: DeviceIdentityRes }) {
  const {
    data: deviceIdentityWithRelated,
    isError,
    isLoading,
    isFetching,
  } = useGetDeviceIdentityWithRelatedQuery(deviceIdentity.id!);

  return (
    <>
      <SafeDeviceIdentitySection
        isLoading={isLoading || isFetching}
        isError={isError}
        deviceIdentity={deviceIdentityWithRelated?.data}
      />
      <SafeDeviceIdentityChannels
        isLoading={isLoading || isFetching}
        isError={isError}
        deviceIdentity={deviceIdentityWithRelated?.data}
      />
    </>
  );
}

export default withErrorLoadingManagement(DeviceIdentityPanel);
