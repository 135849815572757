import { Polling, pollingInitialState } from '@/redux/slices/pollingSlice';
import { useMemo } from 'react';
import PollDeviceTelemetryUpdate from './PollDeviceTelemetryUpdate';
import PollPostOTAJob from './PollPostOTAJob';
import PollPostOperatingModeCommand from './PollOperatingModeCommand';
import PollPowerProfileCommand from './PollPowerProfileCommand';
import PollLogLevel from './PollLogLevel';
import PollPostRebootCommand from './PollPostRebootCommand';
export default function Poll({
  endpoint,
  polling,
  id,
}: {
  endpoint: keyof typeof pollingInitialState.polls;
  polling: Polling;
  id: string;
}) {
  const body = useMemo(() => {
    switch (endpoint) {
      case 'getLatestTelemetryUpdate':
        return <PollDeviceTelemetryUpdate polling={polling} id={id} />;
      case 'postOTAJob':
        return <PollPostOTAJob polling={polling} id={id} />;
      case 'postOperatingModeCommand':
        return <PollPostOperatingModeCommand polling={polling} id={id} />;
      case 'postPowerProfileCommand':
        return <PollPowerProfileCommand polling={polling} id={id} />;
      case 'postLogLevel':
        return <PollLogLevel polling={polling} id={id} />;
      case 'postRebootCommand':
        return <PollPostRebootCommand polling={polling} id={id} />;
      default:
        return null;
    }
  }, [endpoint, id, polling]);

  return body || null;
}
