function scrollElementIntoViewWithOffset(element: HTMLElement, offset: number) {
  // Get the element's position relative to the viewport
  const rect = element.getBoundingClientRect();

  // Calculate the desired scroll position
  const scrollPosition = window.pageYOffset + rect.top - offset;

  // Scroll to the calculated position
  window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
}

export default function useScrollWithOffset(target: string, offset?: number) {
  return () => {
    if (document) {
      const historyData = document.getElementById(target);
      if (historyData) {
        scrollElementIntoViewWithOffset(historyData, offset || 100);
      }
    }
  };
}
