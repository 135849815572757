import { ReactNode } from 'react';
import BaseOperation from '../Operation/BaseOperation';
import BaseOperationBody from '../Operation/BaseOperationBody';
import { BaseComponentProps } from '../Notification/types';

export default function BaseSnackbar({
  onClick,
  extra,
  ...rest
}: BaseComponentProps & { extra?: { snackbarActions?: ReactNode } }) {
  return (
    <BaseOperation onClick={onClick} {...rest} style={{}}>
      <BaseOperationBody {...rest} />
      {extra?.snackbarActions}
    </BaseOperation>
  );
}
