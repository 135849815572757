import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ChangeEvent, useEffect, useState } from 'react';
import { isValidNumber } from '@/shared/utils';
import { NumberInputProps } from './typings';

export default function NumberInput({
  configItem,
  value,
  getValues,
  helperText,
  ...rest
}: NumberInputProps & TextFieldProps) {
  const [_value, setValue] = useState<number | string | null>(value || '');
  const placeholder = configItem.placeholder && configItem.placeholder + (configItem.options?.required ? ' *' : '');

  useEffect(() => {
    rest.onChange({ target: { value: _value } } as ChangeEvent<HTMLInputElement>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);

  const inputProps = {
    ...rest.InputProps,
    startAdornment: configItem.adornmentStartValue ? (
      <InputAdornment position="start">{configItem.adornmentStartValue}</InputAdornment>
    ) : undefined,
    ...(!configItem.hideControls
      ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  setValue((prev) => {
                    const _prev = prev ?? 0;
                    if (typeof configItem.domain?.min === 'number' && configItem.domain.min >= Number(_prev)) {
                      return prev;
                    }

                    if (isValidNumber(Number(_prev))) {
                      return configItem.stepperFx ? configItem.stepperFx(Number(_prev), -1) : String(Number(_prev) - 1);
                    }

                    return prev;
                  })
                }
              >
                <RemoveIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  setValue((prev) => {
                    const _prev = prev ?? 0;
                    if (typeof configItem.domain?.max === 'number' && configItem.domain.max <= Number(_prev)) {
                      return prev;
                    }

                    if (isValidNumber(Number(_prev))) {
                      return configItem.stepperFx ? configItem.stepperFx(Number(_prev), 1) : String(Number(_prev) + 1);
                    }
                    return prev;
                  })
                }
              >
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }
      : {}),
  };

  return (
    <TextField
      {...rest}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value == null || event.target.value === '') {
          setValue('');
          rest.onChange(event);
          return;
        }
        setValue(event.target.value);
        rest.onChange(event);
      }}
      helperText={helperText}
      value={_value || value || ''}
      label={placeholder}
      InputProps={inputProps}
    />
  );
}
