import { Box } from '@mui/material';
import dayjs from 'dayjs';
import StaticDateRangeWithFixedRanges from './StaticDateRangeWithFixedRanges';
import { useContext } from 'react';
import { FiltersTelemetryContext } from '../../FiltersTelemetryContext';

export default function CalendarPicker() {
  const { rangeStart, rangeEnd, setRangeEnd, setRangeStart } = useContext(FiltersTelemetryContext);
  return (
    <Box>
      <StaticDateRangeWithFixedRanges
        defaultValue={{
          start: rangeStart || +dayjs().subtract(1, 'week').startOf('day'),
          end: rangeEnd || +dayjs().endOf('day'),
        }}
        onChange={(_, range) => {
          if (range.end && range.start) {
            setRangeEnd(range.end);
            setRangeStart(range.start);
          }
        }}
      />
    </Box>
  );
}
