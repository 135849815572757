import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FilterAlarmRequest, FleetAlarm } from '@/components/Fleet/AlarmsAndErrors/typings';

export const AlarmsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetAlarms', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    getFilteredAlarms: builder.query<
      ApiResult<{ itemsCount: number; pagesCount: number; page: number; items: FleetAlarm[] }>,
      FilterAlarmRequest
    >({
      query: ({ sortBy, ...rest }) => {
        const params = {
          ...rest,
          sortField: sortBy?.field,
          sortDirection: sortBy?.direction,
        };
        return {
          url: `${API_VERSION.v2}/fleet/alarms/filteredList`,
          params,
        };
      },
      providesTags: ['FleetAlarms'],
    }),
  }),
});

export const { useGetFilteredAlarmsQuery, useLazyGetFilteredAlarmsQuery } = AlarmsApiSlice;
