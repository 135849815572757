import { Box, BoxProps, CSSObject, Theme, styled } from '@mui/material';
import { ReactNode } from 'react';
import { Amount, DIRECTION, Delay, Direction, Duration, Trigger } from './typings';

const slideInMixin = (config: Partial<Amount & Duration & Delay & Direction> & { theme: Theme }): CSSObject => ({
  transition: config.theme.transitions.create(['transform'], {
    easing: config.theme.transitions.easing.easeInOut,
    duration: config.theme.transitions.duration.enteringScreen,
    delay: config.delay,
  }),
  transform: ['translate(0%, 0%)'],
});

const slideOutMixin = (config: Partial<Amount & Duration & Delay & Direction> & { theme: Theme }): CSSObject => ({
  transition: config.theme.transitions.create(['transform'], {
    easing: config.theme.transitions.easing.easeInOut,
    duration: config.theme.transitions.duration.enteringScreen,
    delay: config.delay,
  }),
  transform: [calculateTranslate(config.direction, config.amount)],
});

const calculateTranslate = (direction: (typeof DIRECTION)[keyof typeof DIRECTION] = DIRECTION.UP, amount?: number) => {
  if (amount) {
    let horizontal =
      Number(direction === DIRECTION.LEFT) * amount + Number(direction === DIRECTION.RIGHT) * (-1 * amount);
    let vertical = Number(direction === DIRECTION.UP) * amount + Number(direction === DIRECTION.DOWN) * -1 * amount;
    return `translate(${horizontal}px, ${vertical}px)`;
  }
  let horizontal = Number(direction === DIRECTION.LEFT) * 100 + Number(direction === DIRECTION.RIGHT) * -100;
  let vertical = Number(direction === DIRECTION.UP) * 100 + Number(direction === DIRECTION.DOWN) * -100;

  return `translate(${horizontal}%, ${vertical}%)`;
};

interface SlideDivProps extends BoxProps, Partial<Duration & Delay & Direction>, Trigger {}

export const SlideDiv = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'amount' && prop !== 'trigger' && prop !== 'delay' && prop !== 'direction',
})<SlideDivProps>(({ trigger, ...rest }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  ...(trigger && {
    '&': slideInMixin(rest),
  }),
  ...(!trigger && {
    '&': slideOutMixin(rest),
  }),
}));

export default function SlidingDiv({
  children,
  ...rest
}: { children: ReactNode } & Trigger & Partial<Amount & Duration & Delay & Direction>) {
  return (
    <SlideDiv {...rest}>
      <Box sx={{ width: 'fit-content', height: 'fit-content' }}>{children}</Box>
    </SlideDiv>
  );
}
