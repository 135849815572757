import { Box, Toolbar, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { Link, Location, useLocation } from 'react-router-dom';
import LogoExtended from '@/assets/logo-extended.svg';
import { routes } from '@/router/routes';
import { ReactNode } from 'react';

const parsePath = (location: Location) => {
  let currentRoute: any = null;

  let res = location.pathname
    .split('/')
    .filter((v) => v.length > 0)
    .flatMap((name) => {
      if (!currentRoute) {
        currentRoute = routes[0].children;
      }

      let route = currentRoute.find((route: any) => route.path?.replace('/', '') === name);
      if (route && route.children) {
        currentRoute = route.children;
      }
      return route?.label || [];
    })
    .join(' > ');

  const hash = location.hash?.replace('#', '');

  if (hash) {
    res += ` > ${hash[0].toUpperCase()}${hash.substring(1)}`;
  }

  return res;
};

export default function Header({ children }: { children?: ReactNode }) {
  const location = useLocation();
  const currentPath = parsePath(location);

  return (
    <Box sx={{ display: 'none', '@media print': { display: 'block' } }}>
      <MuiAppBar
        sx={{
          position: 'initial',
          bgcolor: (theme) => theme.palette.primary.main,
          printColorAdjust: 'exact',
          WebkitPrintColorAdjust: 'exact',
          height: '80px',
          '@media print': {
            '&.MuiAppBar-root': {
              display: 'block',
            },
          },
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            '@media print': { height: '80px' },
          }}
          disableGutters
        >
          <Link
            id="logoMenuItem"
            to="/"
            style={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 20px',
            }}
          >
            <img src={LogoExtended} alt="Culligan Logo" style={{ height: '1.2rem' }} />
          </Link>
          <Typography sx={{ marginRight: '4mm', paddingBottom: 0 }}>{currentPath}</Typography>
        </Toolbar>
      </MuiAppBar>
      {children}
    </Box>
  );
}
