import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

export type TimeRange<T extends string | Dayjs> = {
  start: T;
  end: T;
};

export type BusinessAnalysisFiltersState = {
  dateRange?: TimeRange<string>;
  brand?: string;
  type?: string;
  customer?: string;
};

export type BusinessAnalisysFlatFilterState = {
  dateRangeStart: string;
  dateRangeEnd: string;
} & Omit<BusinessAnalysisFiltersState, 'dateRange'>;

// Define the initial state
const initialState: BusinessAnalysisFiltersState = {
  dateRange: undefined,
  brand: undefined,
  type: undefined,
  customer: undefined,
};

// Create the slice
const businessAnalysisFiltersSlice = createSlice({
  name: 'businessAnalysisFiltersSlice',
  initialState,
  reducers: {
    setFilters: (_, action: PayloadAction<BusinessAnalysisFiltersState>) => {
      return { ...action.payload };
    },
    resetFilters: (_) => initialState,
  },
});

// Transform the timerange from TimeRange<string> to TimeRange<Dayjs>
const transformTimeRange = (dateRange: TimeRange<string>) => {
  const result = {
    start: dateRange.start ? dayjs(+dateRange.start) : undefined,
    end: dateRange.end ? dayjs(+dateRange.end) : undefined,
  };
  return result;
};

// Selector that transforms FilterState of string to FilterState of Dayjs
export const businessAnalysisSelectTransformedValue = createSelector(
  (state: { businessAnalysisFiltersSlice: BusinessAnalysisFiltersState }) => state.businessAnalysisFiltersSlice,
  (businessAnalisysFiltersState) => {
    return {
      ...businessAnalisysFiltersState,
      ...(businessAnalisysFiltersState.dateRange
        ? { dateRange: transformTimeRange(businessAnalisysFiltersState.dateRange) }
        : {}),
    } as {
      dateRange: TimeRange<Dayjs>;
    } & Omit<BusinessAnalysisFiltersState, 'dateRange'>;
  }
);

export const businessAnalisysSelectFlatValue = createSelector(
  (state: { businessAnalysisFiltersSlice: BusinessAnalysisFiltersState }) => state.businessAnalysisFiltersSlice,
  (businessAnalisysFilterState) => {
    const flatValue = {
      ...businessAnalisysFilterState,
      ...(businessAnalisysFilterState.dateRange
        ? {
            dateRangeStart: businessAnalisysFilterState.dateRange.start,
            dateRangeEnd: businessAnalisysFilterState.dateRange.end,
          }
        : {}),
    };
    delete flatValue.dateRange;
    return flatValue as BusinessAnalisysFlatFilterState;
  }
);

// Export actions and reducer
export const { setFilters, resetFilters } = businessAnalysisFiltersSlice.actions;
export default businessAnalysisFiltersSlice.reducer;
