import { AreaBody, AreaHeader, StatusAreaContainer } from '@/components/Shared/Card/Area';
import { Subtitle } from '@/components/Shared/Card/Area/Text';
import { SxProps, Theme, Tooltip } from '@mui/material';
import React from 'react';
import LoadingFeedback from '../../ConsumablesPanel/LoadingFeedback';
function CommandButton({
  disabledReasons,
  isLoading,
  handler,
  color,
  text,
  Icon,
  sx,
}: {
  disabledReasons: Array<{ disabled: boolean; reason?: string }>;
  handler: () => void;
  color: string | (() => string);
  text: string;
  Icon: JSX.Element & any;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
}) {
  const ICONS_SIZE = '32px';
  const TRANSPARENT = '#f0f8ff00';
  const disableReason = disabledReasons.find((r) => r.disabled === true);
  const _color = typeof color === 'function' ? color() : color;

  return (
    <StatusAreaContainer
      shade={TRANSPARENT}
      disabled={disableReason ? disableReason.disabled : false}
      hoverShade={_color}
      hoverLineColor={_color}
      onClick={() => (disableReason?.disabled ? '' : handler())}
      sx={sx ? sx : {}}
    >
      <LoadingFeedback loading={isLoading || false} />
      <AreaHeader sx={{ justifyContent: 'center', opacity: disableReason?.disabled ? 0.4 : 1 }}>
        <Subtitle>{text}</Subtitle>
      </AreaHeader>
      <AreaBody sx={{ alignItems: 'center' }}>
        <Tooltip title={disableReason?.disabled ? disableReason?.reason || '' : ''} placement="top">
          <Icon
            sx={{
              fontSize: ICONS_SIZE,
              fill: _color,
              opacity: !disableReason?.disabled ? 1 : 0.4,
            }}
          />
        </Tooltip>
      </AreaBody>
    </StatusAreaContainer>
  );
}

export default React.memo(CommandButton);
