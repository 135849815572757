import MissingDataCard from '../Shared/MissingDataCard';

export default function withMissingDataManagement(Component: React.FC | ((args: any) => JSX.Element | null)) {
  const WithMissingDataManagement = ({ hasData, ...props }: { hasData: boolean; [k: string]: any }) => {
    if (hasData) {
      return <Component {...props} />;
    }

    return <MissingDataCard />;
  };

  return WithMissingDataManagement;
}
