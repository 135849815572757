export default function useDownloadFile() {
  const downloadFile = (fileName: string, fileUrl: string) =>
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a Blob object and initiate the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName || 'download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  return {
    downloadFile,
  };
}
