import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConsumableSubsetRes } from './typings';
import dayjs from 'dayjs';
import { useGetConsumablesQuery } from '@/redux/api/system/consumablesApiSlice';
import { ConsumableRes } from '../Consumables/typings';
import ReleatedEntityLabel from '@/components/Shared/Chips/ReleatedEntityChip';
import { useNavigate } from 'react-router';
import { CONSUMABLE_KIND, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { isValidNumber } from '@/shared/utils';

export default function useConsumableSubsetsColumns(
  onEditClick?: Function,
  onDeleteClick?: Function
): Array<Column<ConsumableSubsetRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns_array: Array<Column<ConsumableSubsetRes>> = [];

  const { data: allConsumables, isLoading: isLoadingConsumables } = useGetConsumablesQuery();

  return columns_array.concat([
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('consumable'),
      field: 'consumable',
      searchable: true,
      customFilterAndSearch: (filter, consumablesSubset) => {
        const consumable = allConsumables?.data?.items.find(
          (consumable: ConsumableRes) => consumablesSubset.consumable === consumable.id
        );
        return consumable?.name.indexOf(filter) !== -1;
      },
      render: (consumablesSubset) => {
        if (!consumablesSubset.consumable) {
          return;
        }
        let _consumables = allConsumables?.data?.items || [];
        _consumables = _consumables.filter(
          (consumable: ConsumableRes) => consumablesSubset.consumable === consumable.id
        );
        return (
          !isLoadingConsumables &&
          _consumables &&
          (_consumables.length === 0 ? (
            <ReleatedEntityLabel severity="warning" />
          ) : (
            <ReleatedEntityLabel
              key={_consumables[0].id}
              severity="warning"
              text={_consumables[0].name}
              onEntityClick={() =>
                navigate(`/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#consumables`, {
                  state: {
                    searchText: _consumables[0].name,
                    highlight: {
                      refField: 'id',
                      matchValue: _consumables[0].id,
                    },
                  },
                })
              }
            />
          ))
        );
      },
    },
    {
      title: t('lifespan'),
      field: 'lifespan',
    },
    {
      title: t('unitOfMeasure'),
      field: 'unitOfMeasure',
    },
    {
      title: t('range'),
      width: '20%',
      render: (consumable) => (
        <>
          {
            <RenderIf condition={consumable?.rangeExhaust != null && isValidNumber(consumable?.rangeExhaust)}>
              <Typography sx={{ width: 'max-content' }}>{`${t('exhaust')}: ${consumable.rangeExhaust}`}</Typography>
            </RenderIf>
          }
          {
            <RenderIf condition={consumable?.rangeTrigger != null && isValidNumber(consumable?.rangeTrigger)}>
              <Typography sx={{ width: 'max-content' }}>{`${t('trigger')}: ${consumable.rangeTrigger}`}</Typography>
            </RenderIf>
          }
          {
            <RenderIf condition={consumable?.rangeFullCapacity != null && isValidNumber(consumable?.rangeFullCapacity)}>
              <Typography sx={{ width: 'max-content' }}>{`${t('full')}: ${consumable.rangeFullCapacity}`}</Typography>
            </RenderIf>
          }
        </>
      ),
    },
    {
      title: t('flavored'),
      field: 'flavored',
      render: (consumablesSubset) => {
        const consumable = allConsumables?.data?.items.find(
          (item: ConsumableRes) => item.id === consumablesSubset.consumable
        );
        return (
          <Typography>
            {consumable?.kind === CONSUMABLE_KIND.ENHANCEMENTS
              ? consumablesSubset.flavored
                ? 'True'
                : 'False'
              : 'N/A'}
          </Typography>
        );
      },
    },
    {
      title: t('nominalMixRatio'),
      field: 'nominalMixRatio',
      render: (consumablesSubset) => {
        const consumable = allConsumables?.data?.items.find(
          (item: ConsumableRes) => item.id === consumablesSubset.consumable
        );
        return (
          <Typography>
            {consumable?.kind === CONSUMABLE_KIND.ENHANCEMENTS
              ? consumablesSubset.nominalMixRatio
                ? `1/${consumablesSubset.nominalMixRatio}`
                : '1/--'
              : 'N/A'}
          </Typography>
        );
      },
    },
    {
      title: t('mixRatioScalars'),
      field: 'mixRatioScalars',
      render: (consumablesSubset) => {
        const consumable = allConsumables?.data?.items.find(
          (item: ConsumableRes) => item.id === consumablesSubset.consumable
        );
        return (
          <Typography>
            {consumable?.kind === CONSUMABLE_KIND.ENHANCEMENTS
              ? consumablesSubset.mixRatioScalars
                ? (consumablesSubset.mixRatioScalars as string[])?.join(', ')
                : '--'
              : 'N/A'}
          </Typography>
        );
      },
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (consumable) => dayjs(consumable.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (consumable) => dayjs(consumable.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('actions'),
      width: '10%',
      render: (consumable) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          <>
            <Button variant="outlined" color="primary" onClick={onEditClick && (() => onEditClick(consumable.id))}>
              {t('edit')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={onDeleteClick && (() => onDeleteClick({ id: consumable.id, name: consumable.name }))}
            >
              {t('delete')}
            </Button>
          </>
        </Box>
      ),
      sorting: false,
    },
  ]);
}
