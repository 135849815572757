import { useState } from 'react';

export default function useAccumulateData<TData>() {
  const [list, setList] = useState<TData[]>([]);

  return {
    list,
    add: (data: TData[]) => {
      setList((prev) => [...prev, ...data]);
    },
    reset: () => setList([]),
  };
}
