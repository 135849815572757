import { useEffect, useState } from 'react';

function getSize(element: HTMLBaseElement | null, padding?: number) {
  const width = element?.offsetWidth || 0 - (padding || 0);
  const height = element?.offsetHeight || 0 - (padding || 0);
  return { height, width };
}

export type Size = {
  width: number;
  height: number;
};

export default function useSize(element: HTMLBaseElement | null, padding?: number) {
  const [size, setSize] = useState<Size>(getSize(element));

  useEffect(() => {
    setSize(getSize(element, padding));
    const onResize = () => setSize(getSize(element, padding));
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [element, padding]);
  return size;
}
