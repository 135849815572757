import { TAGS } from '@/redux/slices/operationSlice';
import OneToolCreateVariant from './OneToolCreate';
import OneToolUpdateVariant from './OneToolUpdate';
import OneToolDeleteVariant from './OneToolDelete';
import { CommonSnackbarProps } from '../typings';

export default function OneToolCUDVariant({ operation, path, dismissAction }: CommonSnackbarProps & { path: string }) {
  switch (operation.tag) {
    case TAGS.ONE_TOOL_CREATE:
      return <OneToolCreateVariant operation={{ ...operation, path }} dismissAction={dismissAction} />;

    case TAGS.ONE_TOOL_UPDATE:
      return <OneToolUpdateVariant operation={{ ...operation, path }} dismissAction={dismissAction} />;

    case TAGS.ONE_TOOL_DELETE:
      return <OneToolDeleteVariant operation={{ ...operation, path }} dismissAction={dismissAction} />;

    default:
      return <></>;
  }
}
