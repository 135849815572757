import { Fragment, useEffect, useState } from 'react';
import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useStatisticsData from './useStatisticsData';
import LineChartCard from './LineChartCard';
import withMissingDataManagement from './withMissingDataManagement';
import dayjs from 'dayjs';
import WaterDispensedPrintResults from './WaterDispensedPrintResults';

const SafeLineChart = withMissingDataManagement(LineChartCard);

export default function WaterDispensedCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filteredData } = useStatisticsData();

  const [totalLiters, setTotalLiters] = useState<number>(0);
  const [isGallons, setIsGallons] = useState<boolean>(false);

  const GALLONS_PER_LITER = 0.264172;
  const bigNumberLabel = ['Liters', 'Gallons'];

  const hasFilteredData = filteredData && filteredData.waterDispensed && filteredData.waterDispensed.value.length > 1;

  useEffect(() => {
    if (
      !filteredData ||
      !filteredData.waterDispensed ||
      filteredData.waterDispensed.status === 'rejected' ||
      filteredData.waterDispensed.value.length === 0
    ) {
      return;
    }

    const total = filteredData.waterDispensed.value.reduce((acc, data) => acc + data.totalVolume, 0);
    setTotalLiters(total || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData?.waterDispensed]);

  const handleChangeSwitchToggle = () => {
    setIsGallons((prev) => !prev);
  };

  const displayTotal = isGallons ? totalLiters * GALLONS_PER_LITER : totalLiters;

  return (
    <Fragment>
      <StatisticsCard
        variant="boxed"
        title={t('waterDispensed')}
        action={{
          label: t('details'),
          fn: () => navigate('/'),
        }}
      >
        <SafeLineChart
          hasData={!!(filteredData && filteredData.waterDispensed && filteredData.waterDispensed.value.length > 1)}
          data={filteredData?.waterDispensed?.value.map((pouring) => ({
            x: dayjs(pouring.day),
            y: pouring.totalVolume,
          }))}
          id={'pourings'}
          isPercentage={false}
          isValueTogglable={true}
          smallLabel={'Liters / Gallons'}
          bigNumberLabel={bigNumberLabel}
          handleToggleClick={handleChangeSwitchToggle}
          bigNumber={displayTotal}
        />
      </StatisticsCard>
      {hasFilteredData ? (
        <WaterDispensedPrintResults waterDispensed={filteredData?.waterDispensed} isGallons={isGallons} />
      ) : null}
    </Fragment>
  );
}
