import { NotificationVirtualizationProps } from './types';
import { useNotificationHeight } from './hooks/useNotificationHeight';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { markAllAsRead, TODAY_UNIQUE_ID } from '@/redux/slices/operationSlice';
import { useDispatch } from 'react-redux';

export default function Today({ setNotificationHeight, style }: NotificationVirtualizationProps) {
  const notificationRef = useNotificationHeight({
    id: TODAY_UNIQUE_ID,
    setNotificationHeight: setNotificationHeight,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Box
      ref={notificationRef}
      sx={{ display: 'flex', pt: 1, justifyContent: 'space-between', alignItems: 'center' }}
      style={style}
    >
      <Typography
        sx={{ textAlign: 'center', display: 'block', color: (theme) => theme.palette.grey[400] }}
        variant="caption"
      >
        {t('today')}
      </Typography>
      <Button
        onClick={() => dispatch(markAllAsRead(TODAY_UNIQUE_ID))}
        size="small"
        sx={{
          '&:focus': { outline: 'none' },
          color: (theme) => theme.palette.text.primary,
          borderColor: (theme) => theme.palette.text.primary,
          p: 0.5,
          fontSize: '0.5rem',
        }}
      >
        {t('markAllAsRead')}
      </Button>
    </Box>
  );
}
