import { Operation, STATUS, Status } from '@/redux/slices/operationSlice';
import { RequireAtLeastOne } from '@typings';
import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import BaseOperationMessage, { OperationMessageAction } from './BaseOperationMessage';

export default function StatusOperationMessage({
  date,
  status,
  messages,
  action,
  read,
}: {
  date: Dayjs;
  status: Status;
  messages: RequireAtLeastOne<{ [key in Status]: ReactNode }, Status>;
  read: Operation['read'];
  action?: OperationMessageAction;
}) {
  return (
    <BaseOperationMessage
      status={status}
      read={read}
      date={date}
      main={messages[status] && messages[status]}
      {...(status === STATUS.ERROR
        ? {
            action,
          }
        : {})}
    />
  );
}
