import { ERROR_CAUSES } from '@/shared/constants';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router';

function isErrorWithCause(error: unknown): error is { message: string; cause: string } {
  return (error as any)?.cause !== undefined;
}

function ErrorBoundary() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let errorMessage = t('somethingBadHappened');
  let error = useRouteError();
  if (isErrorWithCause(error)) {
    if (error.cause === ERROR_CAUSES.UNAUTHORIZED_ROLE) {
      errorMessage = t('forbidden');
    }
  }
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography fontSize={48}>{errorMessage}</Typography>
      <Button onClick={() => navigate('/')} variant="contained" sx={{ mt: 4 }}>
        {t('goHomepage')}
      </Button>
    </Box>
  );
}

export default ErrorBoundary;
