import { Column } from '@material-table/core';
import i18next from 'i18next';

export default function WithNonHideableColumns<T extends object>(
  columns: Array<Column<T>>,
  nonHideableColumns: String | Array<String> | undefined
): Array<Column<T>> {
  const fallbackNonHideableColumn = i18next.t('actions');
  const hasMultipleNonHidableColumns = Array.isArray(nonHideableColumns);
  const makeNonHideableColumn = (col: Column<T>) => ({ ...col, removable: false });
  const makeFirstColumnNonHideable = (columns: Array<Column<T>>) => {
    const [head, ...rest] = columns;
    return [makeNonHideableColumn(head), ...rest];
  };

  if (typeof nonHideableColumns === 'undefined') {
    /*
     * If we do not have the fallback `Actions` column, we make non hideable only the first column.
     */
    const hasNonHideableColumn = columns.some((col) => col.title === fallbackNonHideableColumn);
    return hasNonHideableColumn
      ? columns.map((col) => (col.title === fallbackNonHideableColumn ? makeNonHideableColumn(col) : col))
      : makeFirstColumnNonHideable(columns);
  }

  return hasMultipleNonHidableColumns
    ? columns.map((col) => (nonHideableColumns.includes(col.title as String) ? makeNonHideableColumn(col) : col))
    : columns.map((col) => (col.title === nonHideableColumns ? makeNonHideableColumn(col) : col));
}
