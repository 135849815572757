import { Column } from '@material-table/core';
import { MutableRefObject } from 'react';
import { Table } from '@/components/Shared/Tables/typings';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import ExportTable from '@/components/Shared/Tables/ExportTable';
import { Device } from '../Devices/DevicesPanel/typings';

const filterRowsBySelected = (data: Device[], selectedRowsID: MutableRefObject<string[]>) =>
  data.filter((row) => {
    if (!selectedRowsID.current || selectedRowsID.current.length === 0) {
      return true;
    }
    return selectedRowsID.current.includes(row.id);
  });

interface ConsumablesTableProps<T extends object> extends Table<T> {
  /** Custom hook that returns the desired columns and data formatting for the table */
  columns: Array<Column<T>>;
  handleSelection: (data: Device[]) => void;
  selectedRowsID: MutableRefObject<string[]>;
}

function ConsumablesPanel({
  data,
  title,
  columns,
  handleSelection,
  selectedRowsID,
  ...props
}: ConsumablesTableProps<Device>) {
  return (
    <ExportTable
      title={title}
      data={data}
      columns={columns}
      handleSelection={handleSelection}
      filterRowsBySelected={filterRowsBySelected}
      selectedRowsID={selectedRowsID}
      search={false}
      {...props}
    />
  );
}

export default withErrorLoadingManagement(ConsumablesPanel);
