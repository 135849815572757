import apiSlice, { API_VERSION } from '../apiSlice';

const fleetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFleet: builder.query<any, void>({
      query: () => `${API_VERSION.v2}/fleet`,
    }),
  }),
});

export const { useGetFleetQuery } = fleetApiSlice;
