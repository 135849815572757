import { Typography } from '@mui/material';

const TEXT_ANCHOR = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end',
} as const;

const DOMINANT_BASELINE = {
  AUTO: 'auto',
  MIDDLE: 'middle',
  HANGING: 'hanging',
} as const;

const getDominantBaseline = (y: number, yAxis: number) =>
  y === yAxis ? DOMINANT_BASELINE.MIDDLE : y > yAxis ? DOMINANT_BASELINE.HANGING : DOMINANT_BASELINE.AUTO;
const getTextAnchor = (x: number, xAxis: number) => {
  return x === xAxis ? TEXT_ANCHOR.MIDDLE : x > xAxis ? TEXT_ANCHOR.START : TEXT_ANCHOR.END;
};

const convertAlignmentToSign = (
  alignment: (typeof DOMINANT_BASELINE)[keyof typeof DOMINANT_BASELINE] | (typeof TEXT_ANCHOR)[keyof typeof TEXT_ANCHOR]
) => {
  switch (alignment) {
    case 'middle':
      return 0;
    case DOMINANT_BASELINE.AUTO:
      return -1;
    case DOMINANT_BASELINE.HANGING:
      return 1;
    case TEXT_ANCHOR.END:
      return -1;
    case TEXT_ANCHOR.START:
      return 1;

    default:
      return 0;
  }
};

const getRelativeDistance = (
  alignment:
    | (typeof DOMINANT_BASELINE)[keyof typeof DOMINANT_BASELINE]
    | (typeof TEXT_ANCHOR)[keyof typeof TEXT_ANCHOR],
  absDistance: number
) => {
  const sign = convertAlignmentToSign(alignment);
  return absDistance * sign;
};

export default function CustomLabel({
  origin,
  ...props
}: { origin: { x: number; y: number } } & { [key in string]: any }) {
  const {
    radius,
    innerRadius,
    slice: { startAngle, endAngle, padAngle, index },
    datum,
    rawData,
  } = props;
  const midAngle = (startAngle + padAngle * (index - 1) + (endAngle + padAngle * (index - 1))) / 2 - Math.PI * 0.5;
  const distanceFromCenter = (radius - innerRadius) / 2 + innerRadius;
  const xText = Math.cos(midAngle) * distanceFromCenter;
  const yText = Math.sin(midAngle) * distanceFromCenter;
  const labelDistanceFromCenter = radius + props.dy;
  const xLabelText = Math.cos(midAngle) * labelDistanceFromCenter;
  const yLabelText = Math.sin(midAngle) * labelDistanceFromCenter;
  const total = rawData.reduce((acc: number, { y }: { y: number }) => acc + y, 0);
  const percentage = Math.round(((rawData[index].y * 100) / total + Number.EPSILON) * 100) / 100;
  const color = props.colors[props.index];

  return (
    <g>
      <rect x={origin.x} y={origin.y} width={1} height={1} fill="blue" />
      <g transform={`translate(${xText + origin.x} ${yText + origin.y})`}>
        <Typography
          component={'text'}
          y={8}
          sx={{
            fill: (theme) => theme.palette.getContrastText(color),
            textAnchor: 'middle',
            dominantBaseline: 'middle',
            fontWeight: '600',
          }}
        >
          {percentage < 0.5 ? '<0.5%' : `${percentage}%`}
        </Typography>
      </g>
      <g transform={`translate(${xLabelText + origin.x} ${yLabelText + origin.y})`}>
        <Typography
          component={'text'}
          y={getRelativeDistance(getDominantBaseline(yLabelText + origin.y, origin.y), 4)}
          x={getRelativeDistance(getTextAnchor(xLabelText + origin.x, origin.x), 50)}
          textAnchor={getTextAnchor(xLabelText + origin.x, origin.x)}
          dominantBaseline={'middle'}
        >
          {datum.x}
        </Typography>
      </g>
      <polyline
        points={
          // first
          `${Math.cos(midAngle) * radius + origin.x},${Math.sin(midAngle) * radius + origin.y} ` +
          // second
          `${Math.cos(midAngle) * (radius + props.dy + 4) + origin.x},${
            Math.sin(midAngle) * (radius + props.dy + 4) + origin.y
          } ` +
          // third
          `${
            Math.cos(midAngle) * (radius + props.dy + 4) +
            origin.x +
            getRelativeDistance(getTextAnchor(Math.cos(midAngle) * (radius + props.dy + 4) + origin.x, origin.x), 40)
          }, ${Math.sin(midAngle) * (radius + props.dy + 4) + origin.y}`
        }
        fill="none"
        stroke={color}
        strokeWidth={3}
      />
    </g>
  );
}
