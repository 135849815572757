import React, { useEffect, useState } from 'react';
import { SelectCardInputProps } from './typings';
import { Box, IconButton, Typography, alpha } from '@mui/material';
import Area, { AreaBody, AreaHeader, StatusAreaContainer } from '../Card/Area';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Body } from '../Card/Area/Text';
import CloseIcon from '@mui/icons-material/Close';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import AddIcon from '@mui/icons-material/Add';

export default function SelectCardInput({
  value,
  onChange,
  configItem: {
    selectCardConfig: {
      labels,
      OptionItemHeader = OptionItemHeaderDefault,
      OptionItemBody = OptionItemBodyDefault,
      options,
      mapItemToHeaderElements,
      mapItemToBodyElements,
      getItemIdentifier,
      isWarningItem,
      isCurrentItem,
      isDisabledItem,
      currentValue,
    },
  },
}: SelectCardInputProps) {
  const { t } = useTranslation();

  const _options = options as any[];
  const currentItem = _options.find((item) => isCurrentItem(item, currentValue));

  const [_value, setValue] = useState<string | null>(value);

  const resetValue = () => setValue(null);
  const handleOptionClick = (item: any) => {
    (!_value || _value !== getItemIdentifier(item)) && setValue(getItemIdentifier(item));
    _value === getItemIdentifier(item) && resetValue();
  };

  useEffect(() => {
    onChange(_value);
  }, [_value, onChange]);

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
        padding: 1,
      }}
    >
      <Box display={'grid'} sx={{ height: 'fit-content', width: '100%', gridTemplate: 'auto / 1fr auto 1fr' }}>
        <Box display="flex" flexDirection="column">
          <Typography sx={{ color: (theme) => theme.palette.grey[600], pb: 1 }}>
            {t('current')} {labels.current}
          </Typography>
          <Box flexGrow={1}>
            <Area>
              <OptionItemHeader
                item={currentItem}
                currentItem={currentItem}
                mapItemToNode={mapItemToHeaderElements}
                role="current"
              />
              <OptionItemBody
                item={currentItem}
                currentItem={currentItem}
                mapItemToNode={mapItemToBodyElements}
                role="current"
              />
            </Area>
          </Box>
        </Box>
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ArrowForwardIcon />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography sx={{ color: (theme) => theme.palette.grey[600], pb: 1 }}>
            {t('selected')} {labels.current}
          </Typography>
          <Box flexGrow={1}>
            <StatusAreaContainer
              {...(_value ? { onClick: () => resetValue() } : {})}
              shade={(theme) =>
                !_value
                  ? theme.palette.background.grayShades[0]
                  : isWarningItem &&
                    isWarningItem(
                      _options.find((item) => isCurrentItem(item, _value)),
                      currentValue
                    )
                  ? theme.palette.warning.main
                  : theme.palette.info.main
              }
              sx={{
                ...(!_value
                  ? {
                      border: (theme) => `1px dashed ${theme.palette.background.grayShades[2]}`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {}),
              }}
            >
              {!_value ? (
                <Body sx={{ opacity: 0.2 }}>{t('nothingSelected')}</Body>
              ) : (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <OptionItemHeader
                      item={_options.find((item) => isCurrentItem(item, _value))}
                      currentItem={currentItem}
                      mapItemToNode={mapItemToHeaderElements}
                      role="selected"
                    />
                    <IconButton size="small" onClick={() => resetValue()}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <OptionItemBody
                    item={_options.find((item) => isCurrentItem(item, _value))}
                    currentItem={currentItem}
                    mapItemToNode={mapItemToBodyElements}
                    role="selected"
                  />
                </>
              )}
            </StatusAreaContainer>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ color: (theme) => theme.palette.grey[600], pb: 1 }}>
          {t('available')} {labels.options}
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplate: 'auto / 1fr 1fr',
            gap: 1,
            overflowY: 'scroll',
            height: '300px',
            borderRadius: 1,
          }}
        >
          {_options.map((item) => (
            <StatusAreaContainer
              key={getItemIdentifier(item)}
              {...(!isDisabledItem || !isDisabledItem(item, currentValue)
                ? {
                    onClick: () => handleOptionClick(item),
                    shade: (theme) =>
                      !_value || !isCurrentItem(item, _value)
                        ? theme.palette.background.grayShades[0]
                        : isWarningItem && isWarningItem(item, currentValue)
                        ? theme.palette.warning.main
                        : theme.palette.info.main,
                  }
                : {
                    shade: (theme) => theme.palette.background.grayShades[0],
                    sx: { opacity: 0.3 },
                  })}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <OptionItemHeader
                  item={item}
                  currentItem={currentItem}
                  mapItemToNode={mapItemToHeaderElements}
                  role="options"
                />
                {(!isDisabledItem || !isDisabledItem(item, currentValue)) && (
                  <IconButton size="small" onClick={() => handleOptionClick(item)}>
                    {!_value && <AddIcon />}
                    {_value && _value !== getItemIdentifier(item) && <UpgradeIcon />}
                    {_value === getItemIdentifier(item) && <CloseIcon />}
                  </IconButton>
                )}
              </Box>
              <OptionItemBody
                item={item}
                currentItem={currentItem}
                mapItemToNode={mapItemToBodyElements}
                role="options"
              />
            </StatusAreaContainer>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export const SELECT_CARD_ROLES = {
  CURRENT: 'current',
  SELECTED: 'selected',
  OPTIONS: 'options',
} as const;

type Role = (typeof SELECT_CARD_ROLES)[keyof typeof SELECT_CARD_ROLES];

export type MapItemToReactNode = (item: any, currentItem: any, role: Role) => React.ReactNode;

export type OptionItemProps = {
  item: any;
  currentItem: any;
  mapItemToNode: MapItemToReactNode;
  role: Role;
};

export function OptionItemHeaderDefault({ item, currentItem, mapItemToNode, role }: OptionItemProps) {
  return <AreaHeader>{mapItemToNode(item, currentItem, role)}</AreaHeader>;
}

export function OptionItemBodyDefault({ item, currentItem, mapItemToNode, role }: OptionItemProps) {
  return <AreaBody>{mapItemToNode(item, currentItem, role)}</AreaBody>;
}
