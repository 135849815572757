import { Box, BoxProps, CircularProgress, Tab, Tabs, useTheme } from '@mui/material';
import { TabsProps } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import TabPanel from './TabPanel';
import { TabsConfig, TabsStructure } from './typings';

function BaseTabsLayout({
  config,
  orientation = 'horizontal',
  defaultIndex,
  handleTabChange,
  ...props
}: TabsProps & {
  config: TabsConfig[];
  defaultIndex?: number;
  handleTabChange?: (nextIndex: number) => void;
} & BoxProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(defaultIndex || 0);
  useEffect(() => setSelectedIndex(defaultIndex || 0), [defaultIndex]);

  const handleOnTabClick = (_: SyntheticEvent, nextIndex: any) => {
    setSelectedIndex(nextIndex);
    if (handleTabChange) {
      handleTabChange(nextIndex);
    }
  };

  const { Tabs: TabsContent, Panels } = config.reduce<TabsStructure>(
    (cfg: TabsStructure, tab: TabsConfig, index: number) => {
      cfg.Tabs.push(
        <Tab
          key={`${tab.id}Tab`}
          label={
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
              {tab.isLoading && <CircularProgress size={12} thickness={6} color="inherit" />}
              {tab.label}
            </Box>
          }
          id={`${tab.id}Tab`}
          {...(tab.ariaControls
            ? {
                'aria-controls': tab.id,
                sx: { paddingX: '2rem' },
              }
            : {})}
          sx={{
            display: 'flex',
          }}
        />
      );

      cfg.Panels.push(
        <TabPanel key={`${tab.id}Panel`} value={selectedIndex} index={index} {...props} orientation={orientation}>
          {typeof tab.ContentComponent === 'function' ? tab.ContentComponent({}) : tab.ContentComponent}
        </TabPanel>
      );

      return cfg;
    },
    { Tabs: [], Panels: [] }
  );

  const theme = useTheme();

  return (
    <>
      <Tabs
        value={selectedIndex}
        onChange={handleOnTabClick}
        sx={{
          '& .MuiTabs-flexContainer': { gap: orientation === 'horizontal' ? '2rem' : '0', overflowX: 'auto' },
          '& .MuiButtonBase-root': { alignItems: 'flex-start', outline: 'none' },
          '&.MuiTabs-vertical .MuiButtonBase-root.Mui-selected': {
            backgroundColor: theme.palette.background.grayShades[2],
          },
          '&.MuiTabs-vertical': {
            backgroundColor: theme.palette.background.grayShades[0],
            py: 2,
          },
          '&.MuiTabs-vertical .MuiTabs-scroller': { display: 'flex', alignContent: 'center', flexWrap: 'wrap' },
          '@media print': { display: 'none' },
        }}
        orientation={orientation}
      >
        {TabsContent}
      </Tabs>
      {Panels}
    </>
  );
}

export default BaseTabsLayout;
