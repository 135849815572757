import { t } from 'i18next';
import { useNavigate } from 'react-router';
import useDefaultErrorCallback from '../../hooks/useDefaultErrorCallback';
import { STATUS, Tag, TAGS } from '@/redux/slices/operationSlice';

import BaseNotification, { StatusNotification } from '../../BaseNotification';
import OneToolMessage from './Message';
import DefaultNotificationRightAction from '../../DefaultNotificationRightAction';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseOperationIconButton from '../../../Operation/BaseOperationIconButton';
import BaseOperationAvatar from '../../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../../Operation/BaseOperationIcon';

import { getDefaultStatusIcon, UNKNOWN_PATH } from '../utils';
import { useMemo } from 'react';
import { CHANNELS, ECOSYSTEM, ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import { NotificationConfig, NotificationVirtualizationProps } from '../../types';
import { Operations } from '../../../Operation/types';
import { useNotificationHeight } from '../../hooks/useNotificationHeight';

type OneToolNotificationProps = Operations.Props & NotificationVirtualizationProps & { tag: OneToolTag };
type OneToolTag = Extract<Tag, typeof TAGS.ONE_TOOL_CREATE | typeof TAGS.ONE_TOOL_UPDATE | typeof TAGS.ONE_TOOL_DELETE>;

const notificationConfig: NotificationConfig<OneToolTag> = {
  [TAGS.ONE_TOOL_CREATE]: {
    icon: PlaylistAddIcon,
    translations: {
      error: 'createOneToolError',
      pending: 'createOneToolPending',
      success: 'createOneToolSuccess',
    },
  },
  [TAGS.ONE_TOOL_UPDATE]: {
    icon: EditNoteIcon,
    translations: {
      error: 'updateOneToolError',
      pending: 'updateOneToolPending',
      success: 'updateOneToolSuccess',
    },
  },
  [TAGS.ONE_TOOL_DELETE]: {
    icon: PlaylistRemoveIcon,
    translations: {
      error: 'deleteOneToolError',
      pending: 'deleteOneToolPending',
      success: 'deleteOneToolSuccess',
    },
  },
};

export default function OneTool(props: OneToolNotificationProps) {
  const { setNotificationHeight, entity, uniqueId, status, read, tag, subject } = props;
  const navigate = useNavigate();
  const notificationRef = useNotificationHeight({ id: uniqueId, setNotificationHeight });
  const defaultErrorCallback = useDefaultErrorCallback({ uniqueId: uniqueId });
  const isMemberOfEcosystem = (s: string): s is keyof typeof ECOSYSTEM => Object.values(ECOSYSTEM).includes(s as any);
  const isMemberOfChannels = (s: string): s is keyof typeof CHANNELS => Object.values(CHANNELS).includes(s as any);
  const path = useMemo(() => {
    if (isMemberOfEcosystem(entity)) {
      return `/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.ECOSYSTEM}#${entity}`;
    }
    if (isMemberOfChannels(entity)) {
      return `/${ROUTE_SECTION.ONETOOL}/${ROUTE_FOLDER.CHANNELS}#${entity}`;
    }
    return UNKNOWN_PATH;
  }, [entity]);

  const withPathCheck = (path: string, fn: () => void) => {
    if (path === UNKNOWN_PATH) {
      throw new Error('Unknown path. Please make sure that your path is belonging to the ECOSYSTEM or CHANNEL');
    }
    fn();
  };

  const config = notificationConfig[tag];

  return (
    <StatusNotification
      {...props}
      ref={notificationRef}
      baseComponent={{ Component: BaseNotification }}
      avatar={
        <BaseOperationAvatar
          status={status}
          tagIcon={<BaseOperationIcon Icon={config.icon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          onClick: defaultErrorCallback,
          message: (
            <OneToolMessage
              opening={t(config.translations.error)}
              subject={subject}
              entity={t(entity)}
              status={status}
              read={read}
            />
          ),
          label: t('whatHappened?'),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={status}
                read={read}
                icon={<BaseOperationIcon Icon={VisibilityIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction status={status} read={read} uniqueId={uniqueId} />,
          },
        },
        [STATUS.INFO]: {
          onClick: () => {
            withPathCheck(path, () => navigate(path));
          },
          message: (
            <OneToolMessage
              status={status}
              read={read}
              opening={t(config.translations.pending)}
              subject={subject}
              entity={t(entity)}
            />
          ),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={status}
                read={read}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction status={status} read={read} uniqueId={uniqueId} />,
          },
        },
        [STATUS.SUCCESS]: {
          onClick: () => withPathCheck(path, () => navigate(path, { state: { searchText: subject } })),
          message: (
            <OneToolMessage
              status={status}
              read={read}
              opening={t(config.translations.success)}
              entity={t(entity)}
              subject={subject}
            />
          ),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={status}
                read={read}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction status={status} read={read} uniqueId={uniqueId} />,
          },
        },
      }}
    />
  );
}
