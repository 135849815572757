import { Box, Divider, Typography, TypographyProps, alpha, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { DateRange, DateRangeListItemProps, StoreDateRangeDefaultValue } from '@/components/Shared/Filters/typings';
import DateRangePicker from '@/components/Shared/Filters/DateRangePicker';

dayjs.extend(LocalizedFormat);

// ===

const DateRangeListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'current',
})<DateRangeListItemProps>(({ theme, current }) => ({
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: current ? theme.palette.primary.contrastText : 'inherit',
  backgroundColor: current ? theme.palette.primary.main : alpha(theme.palette.primary.light, 0.1),
  minWidth: 200,
  transition: 'all 100ms ease-in-out',
  ...(!current
    ? {
        '&:hover': {
          background: alpha(theme.palette.primary.light, 0.3),
          cursor: 'pointer',
        },
      }
    : {}),
}));

interface DateRangeProps extends TypographyProps {
  current?: boolean;
}

const DateRangeLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'current',
})<DateRangeProps>(({ current }) => ({
  fontWeight: current ? 600 : 400,
}));

// ===

const getDateRangeAsText = (range: DateRange<Dayjs>) => {
  return `${range.start?.locale(dayjs.locale()).format('L')} - ${range.end?.locale(dayjs.locale()).format('L')}`;
};
// ===

type DateRangeWithFixedRangesProps = {
  defaultValue: StoreDateRangeDefaultValue;
  onChange: (event: any, dateRange: DateRange<number>) => void;
};

export default function StaticDateRangeWithFixedRanges({ onChange, defaultValue }: DateRangeWithFixedRangesProps) {
  const { t } = useTranslation();
  const [range, setRange] = useState<null | DateRange<Dayjs>>({
    start: dayjs(defaultValue.start),
    end: dayjs(defaultValue.end),
  });
  const [isCustomRange, setIsCustomRange] = useState<boolean>(false);

  useEffect(() => {
    if (!range || !range?.start || !range?.end) {
      return;
    }

    const dateRange: DateRange<number> = {
      start: range.start.startOf('day').valueOf(),
      end: range.end.endOf('day').valueOf(),
    };

    onChange(null, dateRange);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const isCurrentRange = (unit: 'week' | 'month' | 'year' | 'days') => {
    if (!range && unit === 'days') {
      return true;
    }

    if (isCustomRange && unit === 'days') {
      return true;
    }

    if (isCustomRange) {
      return false;
    }

    if (!range || !range.start || !range.end) {
      return false;
    }

    const diff = range.end.endOf('day').diff(range.start.startOf('day'), 'day');

    if (diff === 7 && unit === 'week') {
      return true;
    }

    if (diff >= 28 && diff <= 31 && unit === 'month') {
      return true;
    }

    if (diff >= 364 && diff <= 366 && unit === 'year') {
      return true;
    }

    return false;
  };

  const handleDateRangeListItemClick = (range: DateRange<Dayjs> | null) => {
    if (!range || !range.end) {
      setIsCustomRange(true);
    }

    if (range) {
      setIsCustomRange(false);
    }

    setRange(range);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        backgroundColor: (theme) => theme.palette.background.grayShades[0],
        borderRadius: 1,
        p: 2,
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
        <Box>
          <Typography variant="caption">{t('dateRange')}</Typography>
          <Typography fontWeight="bold">
            {range && range.start && range.end ? getDateRangeAsText(range) : t('selectRange')}
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection="column" sx={{ gap: 1 }}>
          <DateRangeListItem
            current={isCurrentRange('week')}
            onClick={() =>
              handleDateRangeListItemClick({
                start: dayjs().subtract(1, 'week').startOf('day'),
                end: dayjs().endOf('day'),
              })
            }
          >
            <DateRangeLabel variant="body1" current={isCurrentRange('week')}>
              {t('lastWeek')}
            </DateRangeLabel>
            {isCurrentRange('week') && <CheckIcon sx={{ color: 'background.default' }} />}
          </DateRangeListItem>
          <DateRangeListItem
            current={isCurrentRange('month')}
            onClick={() =>
              handleDateRangeListItemClick({
                start: dayjs().subtract(1, 'month').startOf('day'),
                end: dayjs().endOf('day'),
              })
            }
          >
            <DateRangeLabel variant="body1" current={isCurrentRange('month')}>
              {t('lastMonth')}
            </DateRangeLabel>
            {isCurrentRange('month') && <CheckIcon sx={{ color: 'background.default' }} />}
          </DateRangeListItem>
          <DateRangeListItem
            current={isCurrentRange('year')}
            onClick={() =>
              handleDateRangeListItemClick({
                start: dayjs().subtract(1, 'year').startOf('day'),
                end: dayjs().endOf('day'),
              })
            }
          >
            <DateRangeLabel variant="body1" current={isCurrentRange('year')}>
              {t('lastYear')}
            </DateRangeLabel>
            {isCurrentRange('year') && <CheckIcon sx={{ color: 'background.default' }} />}
          </DateRangeListItem>
          <DateRangeListItem onClick={() => handleDateRangeListItemClick(null)} current={isCurrentRange('days')}>
            <DateRangeLabel variant="body1" current={isCurrentRange('days')}>
              {t('custom')}
            </DateRangeLabel>
            {isCurrentRange('days') && <CheckIcon sx={{ color: 'background.default' }} />}
          </DateRangeListItem>
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
        <DateRangePicker
          range={range}
          isCustomRange={isCustomRange}
          isChoosingCustomRangeHandler={setIsCustomRange}
          updateCustomRangeHandler={(range) => setRange(range)}
        />
      </Box>
    </Box>
  );
}
