import { Box, Chip, Paper, Typography, alpha, useTheme } from '@mui/material';
import { HistoryTelemetry } from '../typings';
import { useContext, useMemo, useRef, useState } from 'react';
import { FiltersTelemetryContext } from '../FiltersTelemetryContext';
import useSize from '@/hooks/useSize';
import { RangesTelemetryContext } from '../RangesTelemetryContext';
import { useTranslation } from 'react-i18next';
import {
  Tuple,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import dayjs from 'dayjs';
import { TelemetryContext } from '../TelemetryContext';
import Flyout from '../../../../../Shared/Charts/Flyout';
import { mixStringDeterministic, repeatCharacters, stringToColor } from '@/shared/utils';
import { SCATTER_PROPS } from '@/components/Shared/Charts/constants';

const cropString = (value: string, length: number) => {
  if (String.length > length - 3) {
    return `${value.substring(0, 17)}...`;
  }

  return value;
};

type Datum = {
  x: number;
  y: number;
};

type GroupDatum = {
  [key in string]: Datum[];
};

function isDatum(value: any): value is Datum {
  return Boolean(value?.length);
}

type Multiline = {
  [key in string]: Datum[] | GroupDatum;
};

type Domain = {
  tuple?: Tuple<number>;
  padding?: number;
};

type Colors = { [key in string]: string | { [key in string]: string } };

const findDomain = (data: Multiline, padding?: number): Tuple<number> => {
  let max = Number.MIN_SAFE_INTEGER;
  let min = Number.MAX_SAFE_INTEGER;

  Object.keys(data)?.forEach((key) => {
    if (isDatum(data[key])) {
      const numbers = (data[key] as Datum[])?.map((v) => v.y);
      max = Math.max(max, ...numbers);
      min = Math.min(min, ...numbers);
      return;
    }

    const _data = data[key] as GroupDatum;
    if (!_data) {
      return;
    }
    const keys = Object.keys(_data);
    let numbers: number[] = [];
    keys.forEach((k) => numbers.push(...(_data[k]?.map((v) => v.y) || [])));
    max = Math.max(max, ...numbers);
    min = Math.min(min, ...numbers);
  });

  if (padding) {
    const delta = max - min;
    max += (delta * padding) / 100;
    min -= (delta * padding) / 100;
  }

  return [max, min];
};

export default function TelemetryChart({ data }: { data: HistoryTelemetry[] }) {
  const { keys } = useContext(FiltersTelemetryContext);
  const { telemetries } = useContext(TelemetryContext);

  const plottableData: Multiline = useMemo(() => {
    const reducedKeys: { [key in string]: undefined | { [key in string]: undefined } } = keys.reduce((acc, key) => {
      if (key.items && key.items.length > 0) {
        return {
          ...acc,
          [key.id]: key.items.reduce((acc, i) => ({ ...acc, [i.id]: undefined }), {}),
        };
      }

      return {
        ...acc,
        [key.id]: undefined,
      };
    }, {});
    return Object.keys(reducedKeys).reduce((acc, key) => {
      if (!reducedKeys[key]) {
        return {
          ...acc,
          [key]: data?.map((telemetry) => ({ x: telemetry.lastUpdate, y: telemetry[key] })),
        };
      }

      return {
        ...acc,
        [key]: Object.keys(reducedKeys[key] as { [key in string]: undefined }).reduce(
          (acc, k) => ({ ...acc, [k]: data?.map((telemetry) => ({ x: telemetry.lastUpdate, y: telemetry[k] })) }),
          {}
        ),
      };
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const colors: Colors = useMemo(
    () =>
      keys.reduce((acc, key) => {
        const color = stringToColor(mixStringDeterministic(repeatCharacters(key.id, 4)));
        return {
          ...acc,
          [key.id]:
            key.items && key.items.length > 0
              ? {
                  ...key.items.reduce(
                    (acc, item) => ({
                      ...acc,
                      [item.id]: stringToColor(mixStringDeterministic(repeatCharacters(item.id, 4))),
                    }),
                    {}
                  ),
                }
              : color,
        };
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const chartContainerRef = useRef<HTMLBaseElement | null>(null);
  const size = useSize(chartContainerRef.current);
  const [names, setNames] = useState<string[]>([]);
  const ranges = useContext(RangesTelemetryContext);
  const theme = useTheme();
  const { t } = useTranslation();

  const Lines = useMemo(() => {
    const _names: string[] = [];
    if (plottableData && Object.keys(plottableData).length > 0) {
      let Lines = Object.keys(plottableData)
        .flatMap((key) => {
          if (isDatum(plottableData[key])) {
            const _data = plottableData[key] as Datum[];
            _names.push(key);
            const range = ranges.find((range) => range.id === key);
            if (!_data?.length || _data?.length === 0) {
              return [{ Line: <></> }];
            }

            return [
              {
                Area: range?.min && range?.max && (
                  <VictoryArea
                    key={`${key}~~Area`}
                    name={`${key}~~Area`}
                    style={{
                      data: {
                        ...(colors ? { fill: alpha(colors[key] as string, 0.2) as string } : {}),
                        opacity: 0,
                      },
                    }}
                    data={[
                      { x: Number(_data[0].x), y: Number(range.max || 0), y0: Number(range.min || 0) },
                      { x: Number(_data[_data.length - 1].x), y: Number(range.max || 0), y0: Number(range.min || 0) },
                    ]}
                  />
                ),
              },
              {
                Line: (
                  <VictoryLine
                    padding={10}
                    name={`${key}~~Line`}
                    style={{
                      data: {
                        ...(colors ? { stroke: colors[key] as string } : {}),
                        strokeWidth: 6,
                        strokeLinecap: 'round',
                      },
                    }}
                    interpolation="cardinal"
                    data={_data.filter((v) => v.x && v.y)}
                    key={`${key}~~Line`}
                  />
                ),
              },
              {
                Scatter: (
                  <VictoryScatter
                    padding={10}
                    name={`${key}~~Scatter`}
                    style={{
                      data: {
                        ...(colors ? { stroke: colors[key] as string } : {}),
                        pointerEvents: 'none',
                        strokeWidth: 6,
                        strokeLinecap: 'round',
                      },
                    }}
                    data={_data.filter((v) => v.x && v.y)}
                    key={`${key}~~Scatter`}
                  />
                ),
              },
            ];
          }

          const _data = plottableData[key] as GroupDatum;
          if (!plottableData[key]) {
            return [];
          }

          return Object.keys(_data).flatMap((k) => {
            const color = (colors?.[key] as { [key in string]: string })[k];
            _names.push(`${key}~~${k}`);
            const range = ranges.find((range) => range.id === k);
            if (!_data[k]?.length || _data[k]?.length === 0) {
              return [{ Line: <></> }];
            }
            return [
              {
                Area: range?.min && range?.max && (
                  <VictoryArea
                    key={`${key}~~${k}~~Area`}
                    name={`${key}~~${k}~~Area`}
                    style={{
                      data: {
                        ...(colors ? { fill: alpha(color as string, 0.2) as string } : {}),
                        opacity: 0,
                      },
                    }}
                    data={[
                      { x: Number(_data[k][0].x), y: Number(range.max || 0), y0: Number(range.min || 0) },
                      {
                        x: Number(_data[k][_data[k].length - 1].x),
                        y: Number(range.max || 0),
                        y0: Number(range.min || 0),
                      },
                    ]}
                  />
                ),
              },
              {
                Line: (
                  <VictoryLine
                    padding={10}
                    name={`${key}~~${k}~~Line`}
                    style={{
                      data: {
                        ...(colors ? { stroke: color } : {}),
                        strokeWidth: 6,
                        strokeLinecap: 'round',
                      },
                    }}
                    interpolation="cardinal"
                    data={_data[k].filter((v) => v.x && v.y)}
                    key={`${key}~~${k}~~Line`}
                  />
                ),
              },
              {
                Scatter: (
                  <VictoryScatter
                    {...SCATTER_PROPS}
                    data={_data[k].filter((v) => v.x && v.y)}
                    style={{
                      ...SCATTER_PROPS.style,
                      data: {
                        ...SCATTER_PROPS.style?.data,
                        ...(colors ? { stroke: color } : {}),
                        fill: color,
                      },
                    }}
                    padding={10}
                    name={`${key}~~${k}~~Scatter`}
                    key={`${key}~~${k}~~Scatter`}
                  />
                ),
              },
            ];
          });
        })
        .sort((curr) => {
          if (curr.Area) {
            return -1;
          }

          if (curr.Scatter) {
            return 0;
          }

          return 1;
        })
        .flatMap((v) => [v.Area || v.Line || v.Scatter]);

      setNames(_names);
      return Lines;
    }
  }, [colors, plottableData, ranges]);

  const _domain = useMemo(() => {
    const domain = { padding: 10 };
    if ((domain as Domain)?.tuple) {
      return (domain as Domain)?.tuple;
    }

    return findDomain(plottableData, (domain as Domain)?.padding);
  }, [plottableData]);

  return (
    <Paper sx={{ p: 2, '@media print': { boxShadow: 0, padding: 0 } }}>
      <Box>
        {Object.keys(colors || {}).length > 0 && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
              '@media print': { printColorAdjust: 'exact', WebkitPrintColorAdjust: 'exact' },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                '@media print': { display: 'block', lineHeight: 1, marginBottom: '5px' },
              }}
            >
              {t('legend')}
            </Typography>
            <Box display="flex" gap={2} alignItems="start" flexWrap="wrap">
              {Object.keys(colors || {})
                .filter((key) => typeof colors?.[key] !== 'string')
                .map((key) => {
                  return (
                    <Box key={key + 'Legend'}>
                      <Typography fontWeight={'bold'}>{key}</Typography>
                      <Box display="flex" gap={0.5} flexDirection="column">
                        {Object.keys(colors?.[key] || {}).map((k) => (
                          <Box key={key + k + 'Legend'} display="flex" alignItems="center" gap={1}>
                            <Chip
                              icon={
                                <Box
                                  width={15}
                                  height={15}
                                  bgcolor={(colors?.[key] as { [x: string]: string })?.[k]}
                                  sx={{
                                    borderRadius: 2,
                                    border: '1px solid white',
                                    '@media print': {
                                      bgcolor: (colors?.[key] as { [x: string]: string })?.[k],
                                      printColorAdjust: 'exact',
                                      WebkitPrintColorAdjust: 'exact',
                                    },
                                  }}
                                ></Box>
                              }
                              size="small"
                              label={k}
                            />
                            <Typography>
                              {telemetries?.find((t) => t.id === key)?.items?.find((t) => t.id === k)?.name
                                ? telemetries?.find((t) => t.id === key)?.items?.find((t) => t.id === k)?.name + ' '
                                : false}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  );
                })}
              <Box>
                {Object.keys(colors || {}).filter((key) => typeof colors?.[key] === 'string').length > 0 && (
                  <Typography fontWeight={'bold'}>{t('singles')}</Typography>
                )}
                <Box display="flex" gap={0.3} flexDirection="column">
                  {Object.keys(colors || {})
                    .filter((key) => typeof colors?.[key] === 'string')
                    .map((key) => {
                      return (
                        <Box key={key + 'Legend'} display="flex" alignItems="center" gap={1}>
                          <Chip
                            icon={
                              <Box
                                width={15}
                                height={15}
                                bgcolor={colors?.[key]}
                                sx={{
                                  borderRadius: 2,
                                  border: '1px solid white',
                                  '@media print': {
                                    bgcolor: colors?.[key],
                                    printColorAdjust: 'exact',
                                    WebkitPrintColorAdjust: 'exact',
                                  },
                                }}
                              ></Box>
                            }
                            size="small"
                            label={key}
                          />

                          <Typography>
                            {telemetries?.find((t) => t.id === key)?.name
                              ? telemetries?.find((t) => t.id === key)?.name + ' '
                              : false}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          ref={chartContainerRef}
          sx={{
            height: '400px',
            width: '100%',
            '& .VictoryContainer': { top: '-24px' },
            '@media print': {
              marginTop: '-10mm',
            },
          }}
        >
          <VictoryChart
            theme={VictoryTheme.material}
            {...size}
            containerComponent={
              <VictoryVoronoiContainer
                labels={() => ' '}
                labelComponent={
                  <VictoryTooltip
                    constrainToVisibleArea={true}
                    pointerLength={4}
                    flyoutComponent={
                      <Flyout
                        dy={10}
                        parentSize={size}
                        shouldHide={(props) => (props.activePoints?.[0]?.childName as string).includes('Area')}
                        body={({ datum, flyoutProps }) => {
                          const keys = flyoutProps.activePoints?.[0]?.childName
                            .replace('Line', '')
                            .replace('Area', '')
                            .split('~~');
                          return (
                            <Box
                              sx={{
                                background: 'black',
                                color: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 1,
                                gap: 1,
                                width: 'fit-content',
                                borderRadius: 1,
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Box
                                  sx={{
                                    backgroundColor: flyoutProps.activePoints?.[0].style.data.stroke || 'white',
                                    height: 20,
                                    width: 20,
                                    borderRadius: 10,
                                  }}
                                />
                                <Typography>{cropString(keys?.[1] || keys?.[0] || '', 20)}</Typography>
                                <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                                  {datum.y}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                  {dayjs(datum.x).format('L')}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }}
                      />
                    }
                    flyoutStyle={{ fill: theme.palette.text.primary }}
                  />
                }
              />
            }
            events={names.map((name) => ({
              childName: `${name}~~Line`,
              target: 'data',
              eventHandlers: {
                onMouseOver: () => {
                  return [
                    ...names
                      .filter((n) => n !== name)
                      .map((name) => ({
                        childName: `${name}~~Line`,
                        target: 'data',
                        mutation: ({ style }: { style: any }) => ({
                          style: Object.assign({}, style, { strokeWidth: 2 }),
                        }),
                      })),
                    {
                      childName: `${name}~~Area`,
                      target: 'data',
                      mutation: ({ style }) => ({ style: Object.assign({}, style, { opacity: 1 }) }),
                    },
                  ];
                },
                onMouseOut: () => {
                  return [
                    ...names
                      .filter((n) => n !== name)
                      .map((name) => ({
                        childName: `${name}~~Line`,
                        target: 'data',
                        mutation: () => null,
                      })),
                    {
                      childName: `${name}~~Area`,
                      target: 'data',
                      mutation: () => null,
                    },
                  ];
                },
              },
            }))}
          >
            <VictoryAxis dependentAxis domain={_domain} />
            <VictoryAxis tickFormat={(tick) => `${dayjs(tick).format('L\nLTS')}`} />
            {Lines}
          </VictoryChart>
        </Box>
      </Box>
    </Paper>
  );
}
