import AddEditDialog, { DialogLoading } from '@/components/Shared/AddEditDialog';
import {
  AddEditDialogConfigAutocomplete,
  AddEditDialogConfigBase,
  AddEditDialogConfigSelectCard,
  AddEditDialogConfigs,
  UseAddEditDialog,
} from '@/components/Shared/AddEditDialog/typings';
import withRenderFragment from '@/components/Shared/withRenderFragment';
import { CustomErrors } from '@typings';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';

const AddEditDialogsWithConfig = withRenderFragment(AddEditDialog);

export function useAddEditDialog<T>({
  title,
  baseConfig,
  getEditData,
  onSubmit,
  formValidation = () => {},
  groups,
  mutations,
  validateMode,
  revalidateMode,
}: UseAddEditDialog<T>) {
  const [config, setConfig] = useState<AddEditDialogConfigs | null>(null);
  const [dialogId, setDialogId] = useState<boolean | string>(false);
  const dataFetchRequested = useRef(false);
  const handleInitialValue = (value: string | number) => (typeof value === 'number' && value === 0 ? '0' : value);
  // This handle initial value it is because when we get 0 as a value it is not shown in the input
  // This is a known issue in React and MUI so we need to convert it to a string to get properly rendered

  useEffect(() => {
    dataFetchRequested.current = false;
  }, [baseConfig.length]);

  useEffect(() => {
    async function getInitialValues(initCfg: AddEditDialogConfigs) {
      const cfg = [...(initCfg || baseConfig)];
      if (getEditData) {
        const editData = await getEditData(dialogId);
        for (const field in editData) {
          const configItemIndex = cfg.findIndex((item) => item.name === field);
          let configItem = cfg[configItemIndex];
          if (!configItem) continue;
          configItem = { ...cfg[configItemIndex] };
          configItem.initialValue = handleInitialValue(editData[field]);
          cfg.splice(configItemIndex, 1, configItem);
        }
      }
      setConfig(cfg);
    }

    async function getAsyncOptions() {
      const cfg = [...baseConfig];
      for (const item of cfg) {
        const _itemAsAutocomplete = item as AddEditDialogConfigBase & AddEditDialogConfigAutocomplete;
        if (
          _itemAsAutocomplete.selectConfig?.options &&
          typeof _itemAsAutocomplete.selectConfig?.options === 'function'
        ) {
          const options = await _itemAsAutocomplete.selectConfig.options();
          _itemAsAutocomplete.selectConfig.options = options;
          continue;
        }

        const _itemAsSelectCard = item as AddEditDialogConfigBase & AddEditDialogConfigSelectCard;
        if (
          _itemAsSelectCard.selectCardConfig?.options &&
          typeof _itemAsSelectCard.selectCardConfig?.options === 'function'
        ) {
          const options = await _itemAsSelectCard.selectCardConfig.options();
          _itemAsSelectCard.selectCardConfig.options = options;
          continue;
        }
      }
      return cfg;
    }

    if (typeof dialogId === 'string' && !dataFetchRequested.current) {
      dataFetchRequested.current = true;
      getAsyncOptions().then(getInitialValues);
    }

    if (dialogId === true && !dataFetchRequested.current) {
      dataFetchRequested.current = true;
      getAsyncOptions().then((cfg) => {
        setConfig(cfg);
      });
    }
  }, [dialogId, getEditData, baseConfig]);

  const handleCloseDialog = () => {
    setDialogId(false);
    setConfig(null);
    dataFetchRequested.current = false;
  };

  const handleOpenDialog = (id?: string) => {
    id && setConfig(null);
    setDialogId(id || true);
  };

  const isLoading = dataFetchRequested.current && !config;

  const _AddEditDialog = useMemo(() => {
    const _onSubmit = async (data: any) => {
      await onSubmit(dialogId, data);
      setDialogId(false);
    };

    const _formValidation = async (
      formState: Record<string, any>,
      setCustomError: (name: string, content: CustomErrors) => void,
      clearErrors: (fields: string[]) => void,
      errors: FieldErrors
    ) => {
      await formValidation(formState, setCustomError, clearErrors, errors, dialogId);
    };

    return () => {
      if (isLoading) {
        return <DialogLoading />;
      }

      return (
        <AddEditDialogsWithConfig
          renderFragment={config === null}
          title={title}
          config={config}
          isOpen={!!dialogId}
          isEditing={typeof dialogId === 'string'}
          editId={typeof dialogId === 'string' ? dialogId : undefined}
          onClose={handleCloseDialog}
          onSubmit={_onSubmit}
          formValidation={_formValidation}
          groups={groups}
          mutations={mutations}
          validateMode={validateMode}
          revalidateMode={revalidateMode}
        />
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogId, isLoading, title, config]);

  return {
    AddEditDialog: _AddEditDialog,
    openDialog: handleOpenDialog,
  };
}

export default useAddEditDialog;
