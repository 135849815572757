import { STATUS } from '@/redux/slices/operationSlice';
import { useTranslation } from 'react-i18next';
import BaseOperationTypography, { BoldOperationTypography } from '../../Operation/BaseOperationTypography';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseOperationAvatar from '../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../Operation/BaseOperationIcon';
import { useNavigate } from 'react-router';
import { ROUTE_FOLDER, ROUTE_SECTION } from '@/shared/constants';
import BaseOperationIconButton from '../../Operation/BaseOperationIconButton';
import OTAUpdateMessage from '../../Notification/ByTag/OTA/OTAUpdateMessage';
import { StatusNotification } from '../../Notification/BaseNotification';
import { getDefaultStatusIcon } from '../../Notification/ByTag/utils';
import BaseSnackbar from '../BaseSnackbar';
import { CommonSnackbarProps } from '../typings';
import StatusSnackbarActions from '../StatusSnackbarActions';

export default function OTAUpdateVariant({ operation, dismissAction }: CommonSnackbarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StatusNotification
      {...operation}
      baseComponent={{
        Component: BaseSnackbar,
        extra: { snackbarActions: <StatusSnackbarActions operation={operation} dismissAction={dismissAction} /> },
      }}
      avatar={
        <BaseOperationAvatar
          status={operation.status}
          tagIcon={<BaseOperationIcon Icon={SystemUpdateAltIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(operation.status)} />}
        />
      }
      hoverIconButtons={{
        main: (
          <BaseOperationIconButton
            read={operation.read}
            status={operation.status}
            icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
          />
        ),
      }}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.subject}#ota_jobs`),
          message: (
            <BaseOperationTypography read={operation.read} status={operation.status}>
              <BaseOperationTypography read={operation.read} status={operation.status}>
                {t('OTAUpdateError')}{' '}
              </BaseOperationTypography>
              <BoldOperationTypography read={operation.read} status={operation.status}>
                {operation.subject}{' '}
              </BoldOperationTypography>
              <BaseOperationTypography read={operation.read} status={operation.status}>
                {t('somethingWentWrong')}.{' '}
              </BaseOperationTypography>
            </BaseOperationTypography>
          ),
        },
        [STATUS.INFO]: {
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.subject}`),
          message: (
            <OTAUpdateMessage
              read={operation.read}
              status={operation.status}
              actionStatement={t('OTAUpdatePending')}
              subject={operation.subject}
            />
          ),
        },
        [STATUS.SUCCESS]: {
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.subject}`),
          message: (
            <OTAUpdateMessage
              read={operation.read}
              status={operation.status}
              actionStatement={t('OTAUpdateSuccess')}
              subject={operation.subject}
            />
          ),
        },
        [STATUS.WARNING]: {
          onClick: () => navigate(`/${ROUTE_SECTION.FLEET}/${ROUTE_FOLDER.DEVICES}/${operation.subject}`),
          message: (
            <OTAUpdateMessage
              read={operation.read}
              status={operation.status}
              actionStatement={t('OTAUpdateWarning')}
              subject={operation.subject}
            />
          ),
        },
      }}
    />
  );
}
