import { Fragment } from 'react';
import { Chip, lighten, Typography } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DeviceWithoutCoordinate } from '@/components/Fleet/Devices/DevicesPanel/DevicesWithoutLocationPanel';
import { AreaBody, AreaHeader, StatusAreaContainer } from '../Card/Area';
import { useNavigate } from 'react-router';

const DeviceWithoutCoordinateCard = ({ device }: { device: DeviceWithoutCoordinate }) => {
  const navigate = useNavigate();
  const canNavigate = device.id != null && device.id !== '';
  return (
    <StatusAreaContainer
      sx={{ justifyContent: 'space-between', flex: '1 0 max(calc(100% / 4), 30%);' }}
      style={{ height: '130px', minWidth: '200px' }}
      shade={(theme) => lighten(theme.palette.warning.light, 0.6)}
      onClick={() => canNavigate && navigate(`/fleet/devices/${device.id}`)}
    >
      <AreaHeader>
        <Chip icon={<TagIcon />} label={device.model} size="small" />
        <OpenInNewIcon sx={(theme) => ({ color: theme.palette.grey[800] })} />
      </AreaHeader>

      <AreaBody>
        <Typography variant="subtitle1" fontWeight={500}>
          {device.name}
        </Typography>
        <Typography variant="caption">{device.serialNumber}</Typography>
      </AreaBody>
    </StatusAreaContainer>
  );
};

export default function DevicesWithoutCoordinatesList({ devices }: { devices: DeviceWithoutCoordinate[] }) {
  return (
    <Fragment>
      {devices?.map((device) => (
        <DeviceWithoutCoordinateCard device={device} key={device.id} />
      ))}
    </Fragment>
  );
}
