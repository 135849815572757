import Section from '@/components/Shared/Section';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLazyGetFilteredTelemetryQuery } from '@/redux/api/fleet/telemetryApiSlice';
import dayjs from 'dayjs';
import Filters from './Filters';
import { FiltersTelemetryContext } from '../FiltersTelemetryContext';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { useHistoryTelemetryColumns } from './useHistoryTelemetryColumns';
import { TelemetryContext } from '../TelemetryContext';
import TelemetryChart from './TelemetryChart';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { HISTORYDATASECTION_ID } from '..';
import PrintItem from './PrintItem';
import { HistoryTelemetry } from '../typings';
import withRenderFragment from '@/components/Shared/withRenderFragment';
import { ApiState } from '@typings';

const filterOutBooleansAndStrings = (item: { [key in string]: boolean | string | number | undefined }) => {
  return {
    ...Object.keys(item).reduce<{ [key in string]: number }>((acc, key) => {
      return { ...acc, ...(typeof item[key] === 'number' ? { [key]: item[key] as number } : {}) };
    }, {}),
  } as HistoryTelemetry;
};

const SafeTelemetryChart = withErrorLoadingManagement(withRenderFragment(TelemetryChart));

export default function HistoryDataSection() {
  const { t } = useTranslation();
  const { keys, rangeEnd, rangeStart } = useContext(FiltersTelemetryContext);
  const { telemetries } = useContext(TelemetryContext);
  const deviceId = useSelector((state) => {
    const queries = (state as ApiState<{}>).api.queries;
    const query = queries[Object.keys(queries).find((key) => key.includes('getDevice(')) || ''] || null;
    return query?.originalArgs;
  });
  const [trigger, { isLoading, isFetching, isError, data }] = useLazyGetFilteredTelemetryQuery();
  const { columns, isLoading: isLoadingColumns } = useHistoryTelemetryColumns(
    telemetries || [],
    data?.data?.items || []
  );

  useEffect(() => {
    if (deviceId && keys.length > 0 && rangeEnd && rangeStart) {
      trigger(
        {
          dateRangeEnd: rangeEnd || dayjs().endOf('day').valueOf(),
          dateRangeStart: rangeStart || dayjs().subtract(1, 'week').startOf('day').valueOf(),
          deviceId,
          keys: keys
            .flatMap((key) => (key.items && key.items.length > 0 ? key.items.map((i) => i.id) : key.id))
            .flatMap((key, i, arr) => (arr.indexOf(key) !== i ? [] : key)),
        },
        true
      );
    }
  }, [deviceId, keys, rangeEnd, rangeStart, trigger]);

  const filteredData = useMemo(() => {
    return data?.data?.items.flatMap<HistoryTelemetry>((v) => {
      const filteredValue = filterOutBooleansAndStrings(v);

      if (Object.keys(filteredValue).some((k) => k !== 'lastUpdate')) {
        return filteredValue;
      }

      return [];
    });
  }, [data?.data?.items]);

  const keyNameMap: any = useMemo(
    () =>
      telemetries
        ?.flatMap((telemetry) => {
          if (telemetry.type === 'group' && telemetry.items) {
            // Mappatura per gli items di tipo 'group'
            return telemetry.items.map((item) => ({ [item.id]: item.name }));
          } else if (telemetry.type === 'single') {
            // Mappatura per gli items di tipo 'single'
            return { [telemetry.id]: telemetry.name };
          }
          return [];
        })
        .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
    [telemetries]
  );

  const itemsWithNamesAndValues = useMemo(
    () =>
      data?.data?.items.map((item) => {
        return Object.keys(item).reduce<HistoryTelemetry>((acc, key) => {
          const name = keyNameMap[key] || key;
          acc[name] = item[key];
          return acc;
        }, {} as HistoryTelemetry);
      }),
    [data?.data?.items, keyNameMap]
  );

  const itemsGroupedByDay = useMemo(() => {
    const res = itemsWithNamesAndValues?.reduce<Map<number, HistoryTelemetry[]>>((acc, historyTelemetry) => {
      const day = dayjs(historyTelemetry.lastUpdate).startOf('d').valueOf();
      acc.set(day, [...(acc.get(day) || []), historyTelemetry]);

      return acc;
    }, new Map<number, HistoryTelemetry[]>());

    if (res) {
      return [...res.entries()];
    }
    return [];
  }, [itemsWithNamesAndValues]);

  return (
    <Section
      variant="color"
      sx={{
        position: 'relative',
        '@media print': { outline: '#fff', backgroundColor: (theme) => theme.palette.background.paper, margin: 0 },
      }}
    >
      <Typography
        variant="h5"
        mb={4}
        sx={{ '@media print': { fontSize: '1.2rem', fontWeight: '700', marginBottom: '5px' } }}
      >
        {t('historicalData')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Filters />
        <Box id={HISTORYDATASECTION_ID} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <SafeTelemetryChart
            isError={isError}
            isLoading={isLoading || isFetching}
            data={filteredData}
            renderFragment={(filteredData?.length || 0) < 2}
          />
          {!isError ? (
            <>
              <GenericExportTable
                sx={{ minHeight: '500px', '@media print': { display: 'none' } }}
                isLoading={isLoading || isFetching || isLoadingColumns}
                title={t('telemetryHistory')}
                columns={keys?.length && !isLoadingColumns && !isLoading ? columns : []}
                exportData={true}
                selection={false}
                data={
                  keys?.length && !isLoading
                    ? data?.data?.items
                        ?.map((i, index) => ({ ...i, id: index.toString() }))
                        ?.sort((a, b) => b?.lastUpdate - a?.lastUpdate) || []
                    : []
                }
                options={{
                  pageSize: 20,
                  sorting: true,
                  pageSizeOptions: [20, 50, 100],
                  headerStyle: {
                    fontSize: '1rem',
                    position: 'sticky',
                    top: 0,
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    backgroundColor: 'rgb(242, 242, 242)',
                    zIndex: 10,
                  },
                  maxBodyHeight: '500px',
                  overflowY: 'scroll',
                }}
              />
              <Box sx={{ display: 'none', '@media print': { display: 'block', marginTop: '-10mm' } }}>
                {itemsGroupedByDay.map((item, index) => {
                  return <PrintItem key={item[0]} item={item} index={index} />;
                })}
              </Box>
            </>
          ) : (
            <Typography>{t('error')}</Typography>
          )}
        </Box>
      </Box>
    </Section>
  );
}
