import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useDevices from '@/hooks/useDevices';
import { Device } from '@/components/Fleet/Devices/DevicesPanel/typings';
import LocalFilters from '@/components/Shared/Filters/LocalFilters';
import Map from '@/components/Shared/Map/index';
import { useMemo } from 'react';
import { MarkerInfo } from '@/components/Shared/Map/typings';
import useIsTablet from '@/hooks/useIsTablet';
import { MARKER_TYPE } from '@/shared/constants';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import LoadingMap from './LoadingMap';
import { isValidNumber } from '@/shared/utils';

const DevicesLocationMap = () => {
  const { t } = useTranslation();
  const { filterConfigs, remoteFetchState, applyFilters, filteredData } = useDevices();
  const { devicesWithCoordinates } = useMemo(
    () =>
      filteredData?.reduce(
        (acc: { devicesWithCoordinates: Device[]; devicesWithoutCoordinates: Device[] }, device: Device) => {
          isValidNumber(device.lat) && isValidNumber(device.lon)
            ? acc.devicesWithCoordinates.push(device)
            : acc.devicesWithoutCoordinates.push(device);
          return acc;
        },
        {
          devicesWithCoordinates: [],
          devicesWithoutCoordinates: [],
        }
      ),
    [filteredData]
  );
  const isTablet = useIsTablet();

  const markers: MarkerInfo[] = useMemo(() => {
    return devicesWithCoordinates.map((fd) => ({
      position: { lat: fd.lat, lng: fd.lon },
      title: fd.name || '',
      description: fd.serialNumber,
      type: MARKER_TYPE.DEVICE,
      extra: fd,
    }));
  }, [devicesWithCoordinates]);

  const SafeMapBody = withErrorLoadingManagement(() => (
    <Box display="grid" sx={{ gridTemplate: isTablet ? 'auto 1fr / auto' : 'auto / auto 1fr', gap: 2 }}>
      <Card sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
        {!isTablet ? (
          <Box display="flex" justifyContent="space-between" alignItems="flex-end" pl={1}>
            <Typography variant="h6">{t('filters')}</Typography>
          </Box>
        ) : null}

        <LocalFilters<Device>
          filterConfigs={filterConfigs}
          onFiltersApplied={applyFilters}
          flexDirection={isTablet ? 'row' : 'column'}
          boxShadow={0}
        />
      </Card>

      <Box position="relative" height="400px" overflow="hidden" borderRadius={1}>
        <Map markers={markers} noMarkersMessage={t('noDevicesFound') as string} />
      </Box>
    </Box>
  ));

  return (
    <SafeMapBody
      isError={remoteFetchState.isError}
      isLoading={remoteFetchState.isLoading}
      LoadingComponent={<LoadingMap />}
    />
  );
};

export default DevicesLocationMap;
