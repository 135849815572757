import { SvgIconProps } from '@mui/material';

export default function BaseOperationIcon({
  Icon,
  color,
}: {
  Icon: React.ComponentType<SvgIconProps>;
  color?: string;
}) {
  return <Icon sx={{ fontSize: 'inherit', color: color ? color : (theme) => theme.palette.background.default }} />;
}
