import { Button, useTheme, Box, IconButton, Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useDispatch } from 'react-redux';
import { initialThemeState, resetTheme, setTheme, themeSelector } from '@/redux/slices/themeSlice';
import useTypedSelector from '@/hooks/useTypedSelector';

export default function ResetThemeToolbar() {
  const { t } = useTranslation();
  const theme = useTheme();
  const themeSlice = useTypedSelector(themeSelector);
  const dispatch = useDispatch();
  const isDefaultTheme = useMemo(() => {
    const isDefault =
      theme.palette.primary.main === initialThemeState.primary &&
      theme.palette.secondary.main === initialThemeState.secondary &&
      theme.palette.mode === initialThemeState.mode;

    return isDefault;
  }, [theme.palette.mode, theme.palette.primary.main, theme.palette.secondary.main]);

  const [open, setOpen] = useState(!isDefaultTheme);

  useEffect(() => {
    if (!isDefaultTheme) {
      setOpen(true);
    }
  }, [isDefaultTheme]);

  const handleClose = () => {
    if (!isDefaultTheme) {
      return;
    }

    setOpen(false);
  };

  const handleButtonClick = () => {
    dispatch(resetTheme());
    setOpen(false);
  };

  const handleColorModeClick = () => {
    const mode = theme.palette.mode === 'light' ? 'dark' : 'light';
    dispatch(setTheme({ ...themeSlice, mode }));
  };

  const toggleMode = true;

  const action = (
    <React.Fragment>
      <Box display={'flex'}>
        <Button color="inherit" size="small" onClick={handleButtonClick} sx={{ mr: '10px' }}>
          {t('reset')}
        </Button>
        {toggleMode && (
          <>
            <Divider orientation="vertical" flexItem />
            <IconButton size="small" onClick={handleColorModeClick} color="inherit" sx={{ ml: '10px' }}>
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </>
        )}
      </Box>
    </React.Fragment>
  );

  return (
    <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert
        onClose={handleClose}
        variant="filled"
        sx={{
          width: '100%',
          '& .MuiAlert-action': {
            alignItems: 'center',
            paddingTop: 0,
          },
        }}
        icon={<></>}
        action={action}
        elevation={10}
        style={{
          backgroundColor: theme.palette.background.grayShades[0],
          color: theme.palette.text.primary,
        }}
      >
        {t('defaultThemeModified')}
      </Alert>
    </Snackbar>
  );
}
