import { useEffect, useState } from 'react';

export default function useUrlChangeListener() {
  const [url, setUrl] = useState<string | undefined>(window?.location.href);
  useEffect(() => {
    const handleUrlChange = () => {
      setUrl(window.location.href);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  return url;
}
