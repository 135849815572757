import { Box, LinearProgress, Typography } from '@mui/material';
import { ProgressWithLabelProps } from './typings';

const __LinearProgressWithLabel = ({
  containerStyle,
  externalLabel,
  innerLabel,
  externalLabelStyle,
  innerLabelStyle,
  size = 100,
  value = 100,
  ...props
}: ProgressWithLabelProps) => (
  <Box sx={containerStyle}>
    {externalLabel && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          whiteSpace: 'pre-line',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={'0.7rem'}
          fontWeight="bold"
          sx={externalLabelStyle}
        >
          {externalLabel}
        </Typography>
      </Box>
    )}
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '70%', mr: 1 }}>
        <LinearProgress {...props} variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: '10%' }}>
        <Typography variant="body2" color={props.color} style={innerLabelStyle}>
          {innerLabel}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default __LinearProgressWithLabel;
