import { Box, BoxProps } from '@mui/material';

const TabPanel = (props: BoxProps & { value: number; index: number; orientation?: 'horizontal' | 'vertical' }) => {
  const { children, value, index, orientation, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      mt={orientation === 'horizontal' ? '2rem' : '0'}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: orientation === 'horizontal' ? '0.62rem' : '0',
            height: orientation === 'horizontal' ? 'auto' : '100%',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
