import { TAGS } from '@/redux/slices/operationSlice';
import { Fragment } from 'react';
import OneTool from './ByTag/OneTool';
import { Operations } from '../Operation/types';
import Consumable from './ByTag/Consumable';
import { NotificationVirtualizationProps } from './types';
import DeviceCommand from './ByTag/DeviceCommand';
import OTAUpdate from './ByTag/OTA/OTAUpdate';
import DeviceTelemetryUpdateVariant from './ByTag/TelemetryUpdate';

export default function Notification({ tag, ...props }: Operations.Props & NotificationVirtualizationProps) {
  switch (tag) {
    case TAGS.ONE_TOOL_CREATE:
    case TAGS.ONE_TOOL_UPDATE:
    case TAGS.ONE_TOOL_DELETE:
      return <OneTool tag={tag} {...props} />;
    case TAGS.DEVICE_CONSUMABLE_DEPLETE:
    case TAGS.DEVICE_CONSUMABLE_REMOVE:
    case TAGS.DEVICE_CONSUMABLE_SET:
      return <Consumable tag={tag} {...props} />;
    case TAGS.OTA_UPDATE:
      return <OTAUpdate tag={tag} {...props} />;
    case TAGS.DEVICE_TELEMETRY_UPDATE:
      return <DeviceTelemetryUpdateVariant tag={tag} {...props} />;
    case TAGS.DEVICE_COMMAND_SEND:
      return <DeviceCommand tag={tag} {...props} />;
    default:
      return <Fragment />;
  }
}
