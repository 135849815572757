import useIsTablet from '@/hooks/useIsTablet';
import { Box, Skeleton } from '@mui/material';

export default function LoadingMap() {
  const isTablet = useIsTablet();

  return (
    <Box display="grid" sx={{ gridTemplate: isTablet ? 'auto 1fr / auto' : 'auto / auto 1fr', gap: 2, width: '100%' }}>
      <Skeleton variant="rounded" width="300px" height={'100%'} animation="pulse" />
      <Box position="relative" height="400px" overflow="hidden" borderRadius={1}>
        <Skeleton variant="rounded" height="40rem" animation="pulse" sx={{ flexGrow: 2 }} />
      </Box>
    </Box>
  );
}
