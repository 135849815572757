import { Box, colors, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ErrorMessage() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        border: `1px solid ${colors.red[200]}`,
        alignItems: 'center',
        backgroundColor: colors.red[50],
        p: 1,
        borderRadius: 1,
        color: colors.red[800],
        position: 'relative',
      }}
    >
      <Typography>{t('fetchingDataError')}</Typography>
    </Box>
  );
}
