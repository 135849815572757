import { Column } from '@material-table/core';
import { Box, Button, Chip, Typography, alpha } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import TagIcon from '@mui/icons-material/Tag';
import { Device } from '../Devices/DevicesPanel/typings';
import { getColorsFromPercentage } from '@/shared/utils';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';

export const useConsumablesDeviceTableColumns = (selectedTab: string): Array<Column<Device>> => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMemo(
    (): Array<Column<Device>> => [
      {
        title: t('modelNameId'),
        cellStyle: { textAlign: 'left' },
        exportTransformer: (device) => `${device.model}\n${device.serialNumber}`,
        field: 'serialNumber',
        render: (device) => (
          <Box>
            <Typography variant="subtitle1">{device.name}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Chip icon={<TagIcon />} label={device.model} size="small" />
              <Typography variant="caption">{device.serialNumber}</Typography>
            </Box>
          </Box>
        ),
      },
      /*
       * Removed until further notice.
       * {
       *   title: t('location'),
       *   field: 'location',
       *   exportTransformer: (device) => `${device.region?.code} - ${device.region?.name}`,
       *   render: (device) => (
       *     <Typography variant="subtitle1">
       *       {device.region?.code && device.region?.name ? `${device.region.code} - ${device.region.name}` : 'N/A'}
       *     </Typography>
       *   ),
       *   sorting: false,
       * },
       */
      {
        title: t('levels'),
        field: 'levels',
        exportTransformer: (data) =>
          data.consumables
            .filter((consumable: any) => consumable.consumable.kind === selectedTab)
            .map((consumable: any) => {
              if (!consumable.subset?.id) {
                return null;
              }
              const normalise = (value: number, min: number, max: number) =>
                Math.round(((value - min) * 100) / (max - min));
              const fillPercentage = normalise(
                consumable.current,
                consumable.subset?.rangeExhaust || 0,
                consumable.subset?.rangeFullCapacity
              );

              return consumable.subset?.id + ' ' + fillPercentage + '%\n';
            })
            .toString()
            .replaceAll(',', ''),
        render: (data) => (
          <Box display="flex" flexDirection="column" gap={1} sx={{ width: '100%' }}>
            {data.consumables.map((consumable: any, index: number) => {
              const normalise = (value: number, min: number, max: number) =>
                Math.round(((value - min) * 100) / (max - min));
              const fillPercentage = normalise(
                consumable.current,
                consumable.subset?.rangeExhaust || 0,
                consumable.subset?.rangeFullCapacity
              );

              const bgColor = getColorsFromPercentage(fillPercentage);
              const transparentBgColor = alpha(bgColor, 0.2);

              return (
                <AreaContainer
                  key={`consumableSubset-${index}-${data.serialNumber}`}
                  sx={{
                    borderRadius: 1,
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: transparentBgColor,
                  }}
                >
                  <AreaBody sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        padding: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '1rem',
                      }}
                    >
                      <Typography variant="caption" component="span">
                        {consumable.subset?.name ? consumable.subset?.name : consumable.subset?.id || 'Empty slot'}
                      </Typography>
                      {consumable.subset?.id && (
                        <Typography component="span" sx={{ marginTop: 0.5 }}>
                          {fillPercentage}%
                        </Typography>
                      )}
                    </Box>
                    {consumable.subset?.id && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          overflow: 'hidden',
                          height: '100%',
                          top: 0,
                          left: 0,
                          width: `${fillPercentage}%`,
                          backgroundColor: bgColor,
                          padding: 1,
                          borderRadius: 1,
                          color: 'white',
                        }}
                      >
                        <Typography variant="caption" component="span">
                          {consumable.subset?.name}
                        </Typography>
                        <Typography component="span" sx={{ marginLeft: 'auto' }}>
                          {fillPercentage}%
                        </Typography>
                      </Box>
                    )}
                  </AreaBody>
                </AreaContainer>
              );
            })}
          </Box>
        ),
        sorting: false,
      },
      {
        title: t('actions'),
        field: 'actions',
        width: '5%',
        sorting: false,
        export: false,
        render: (device) => (
          <Button
            key={`buttonDevicesTable${device.serialNumber}`}
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/fleet/devices/${device.serialNumber}#consumables`)}
          >
            {t('view')}
          </Button>
        ),
      },
    ],
    [navigate, t, selectedTab]
  );
};
