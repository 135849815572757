import { SerializedError } from '@reduxjs/toolkit';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { ApiResult, CustomErrors, Either } from '@typings';

type UniquenessEndpointRequest<TData, TField extends keyof TData> = {
  [key in TField]: TData[TField];
};

type IsUniqueTrigger<TData, TField extends keyof TData> = LazyQueryTrigger<
  QueryDefinition<
    UniquenessEndpointRequest<TData, TField>,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    | ApiResult<{
        [key in TField]?: boolean;
      }>
    | Either<
        ApiResult<{
          [key in TField]?: boolean;
        }>
      >,
    'api'
  >
>;

export default function useUniqueness<TData, TField extends keyof TData>({
  isUniqueTrigger,
}: {
  isUniqueTrigger: IsUniqueTrigger<TData, TField>;
}) {
  const validate = async (field: TField, value?: TData[TField]) => {
    let body: { [key in TField]?: TData[TField] } = {};
    body[field] = value;

    const isUniqueRes = await isUniqueTrigger(body as UniquenessEndpointRequest<TData, TField>);

    if (
      (
        isUniqueRes as {
          error: FetchBaseQueryError | SerializedError;
        }
      ).error ||
      (
        isUniqueRes?.data as Either<{
          [key in TField]?: boolean;
        }>
      )?._tag === 'Left'
    ) {
      // TODO - Handle the possible error
      // TODO - Maybe with a notification
      return;
    }

    let isFieldUnique = false;
    const eitherRes = isUniqueRes?.data as Either<{
      [key in TField]?: boolean;
    }>;

    if (eitherRes._tag === 'Right') {
      isFieldUnique = !!eitherRes.right[field];
    }

    if (!eitherRes._tag) {
      isFieldUnique = !!(
        isUniqueRes?.data as ApiResult<{
          [key in TField]?: boolean;
        }>
      )?.data?.[field];
    }

    if (isFieldUnique === false) {
      return CustomErrors.UNIQUE;
    }

    return true;
  };

  return validate;
}
