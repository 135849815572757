import { useTheme } from '@mui/material';
import ColorChip from '@/components/Shared/Chips/ColorChip';

export default function UnsetColorChip({ label }: { label: string }) {
  const theme = useTheme();
  const lightGrayHex = theme.palette.grey[400];
  const darkGrayHex = theme.palette.grey[800];

  return (
    <ColorChip
      hex="#000"
      label={label}
      avatarSx={{
        backgroundColor: lightGrayHex,
        backgroundImage: `
            linear-gradient(45deg, ${darkGrayHex} 25%, transparent 25%), 
            linear-gradient(135deg, ${darkGrayHex} 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, ${darkGrayHex} 75%),
            linear-gradient(135deg, transparent 75%, ${darkGrayHex} 75%)`,
        backgroundSize: '0.625rem 0.625rem',
        backgroundPosition: '0 0, 0.313rem 0, 0.313rem -0.313rem, 0px 0.313rem',
      }}
    />
  );
}
