import { Typography, TypographyProps } from '@mui/material';

export function Subtitle(props: TypographyProps) {
  return <Typography {...props} variant="body1" color="text.secondary" />;
}

export function Title(props: TypographyProps) {
  return <Typography {...props} variant="h5" fontWeight={800} />;
}

export function Caption(props: TypographyProps) {
  return <Typography {...props} variant="caption" />;
}

export function Body(props: TypographyProps) {
  return <Typography {...props} variant="body1" />;
}
